/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Density Unit.
 */
export enum DensityUnit {
    KG_M_ = 'kg/m³',
    G_CM_ = 'g/cm³',
}
