<div class="part-edit-inputs">
  <app-input
    *ngIf="part.name"
    label="Title"
    placeholder="Title..."
    [value]="part.name"
    [id]="part.id+'_name'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart($event, 'name', part.id+'_name')"
  ></app-input>
  <ng-container >
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="ConceptInputType.AERO">
        <app-aerodynamics-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])"></app-aerodynamics-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.MASS">
        <app-mass-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])" (forceUpdate)="forceUpdate($event)"></app-mass-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.WHEEL">
        <app-wheels-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])"></app-wheels-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.ANCILLARY">
        <app-input
          label="Ancillary Load"
          placeholder="Load..."
          [value]="part.load || '0'"
          [type]="InputTypes.NUMBER"
          [unit]="UnitType.ELECTRICAL_POWER"
          [id]="part.id+'_load'"
          [updater]="update"
          [readonly]="readonly"
          (update)="setPart($event, 'load', part.id+'_load')"
        ></app-input>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.DECELERATION">
        <app-input
          label="Deceleration Limit"
          placeholder="Limit..."
          [value]="part.limit || '0'"
          [unit]="UnitType.ACCELERATION"
          [type]="InputTypes.NUMBER"
          [id]="part.id+'_limit'"
          [updater]="update"
          [readonly]="readonly"
          (update)="setPart($event, 'limit', part.id+'_limit')"
        ></app-input>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.CLUTCH">
        <app-clutch-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])"></app-clutch-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.BATTERY">
        <app-battery-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])"></app-battery-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.TRANSMISSION">
        <app-transmission-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])" (forceUpdate)="forceUpdate($event)"></app-transmission-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.MOTOR">
        <app-motor-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])" (forceUpdate)="forceUpdate($event)" [fileResponse]="fileResponse"></app-motor-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.INVERTER">
        <app-inverter-inputs [readonly]="readonly" [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])" (forceUpdate)="forceUpdate($event)"></app-inverter-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.STATIC_REQUIREMENT">
        <app-static-requirements-inputs [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])" (forceUpdate)="forceUpdate($event)"></app-static-requirements-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.DYNAMIC_REQUIREMENT">
        <app-dynamic-requirements-inputs [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])" (forceUpdate)="forceUpdate($event)"></app-dynamic-requirements-inputs>
      </ng-container>
      <ng-container *ngSwitchCase="ConceptInputType.DRIVE_CYCLE">
        <app-drive-cycles-inputs [part]="part" [update]="update" (setPart)="setPart($event[0], $event[1], $event[2], $event[3])" (forceUpdate)="forceUpdate($event)"></app-drive-cycles-inputs>
      </ng-container>
    </ng-container>
  </ng-container>

</div>
