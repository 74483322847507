/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Length Unit.
 */
export enum LengthUnit {
    M = 'm',
    MM = 'mm',
    CM = 'cm',
    IN = 'in',
    FT = 'ft',
    YD = 'yd',
    KM = 'km',
    MILES = 'miles',
}
