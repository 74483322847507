/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MotorTorqueCurvesData } from './MotorTorqueCurvesData';
/**
 * Motor in Database.
 */
export type MotorTorqueCurvesInDB = {
    item_type?: MotorTorqueCurvesInDB.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: MotorTorqueCurvesInDB.component_type;
    torque_curves: MotorTorqueCurvesData;
    _id?: string;
    id?: string;
};
export namespace MotorTorqueCurvesInDB {
    export enum item_type {
      COMPONENT = 'component',
    }
    export enum component_type {
        MOTOR_TORQUE_CURVES = 'MotorTorqueCurves',
    }
}

