<awc-select 
  label="Aerodynamics"
  placeholder="Aerodynamics..."
  [items]="aerodynamics"
  [layout]="layout"
  [selectedItems]="selectedAero"
  (selectionChanged)="selectionIDSet($event[0].id, 'aero_id')"></awc-select>
<awc-select 
  label="Mass"
  placeholder="Mass..."
  [items]="massses"
  [layout]="layout"
  [selectedItems]="selectedMass"
  (selectionChanged)="selectionIDSet($event[0].id, 'mass_id')"></awc-select>
<awc-select 
  label="Wheel"
  placeholder="Wheel..."
  [items]="wheels"
  [layout]="layout"
  [selectedItems]="selectedWheel"
  (selectionChanged)="selectionIDSet($event[0].id, 'wheel_id')"></awc-select>
<div class="two-col-inputs">
  <app-input
    label="Start speed"
    placeholder="Start speed..."
    [value]="part.from_speed"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.SPEED"
    [id]="part.id+'_from_speed'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'from_speed', part.id+'_from_speed'])"></app-input>
  <app-input
    label="End speed"
    placeholder="End speed..."
    [value]="part.to_speed"
    [unit]="UnitType.SPEED"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_to_speed'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'to_speed', part.id+'_to_speed'])"></app-input>
</div>
<div class="two-col-inputs">
  <app-input
    label="State of Charge"
    placeholder="State of Charge..."
    [value]="part.state_of_charge"
    [unit]="UnitType.RATIO"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_state_of_charge'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'state_of_charge', part.id+'_state_of_charge'])"></app-input>
  <app-input
    label="No. of points"
    placeholder="Number of points..."
    [value]="part.no_of_points"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_no_of_points'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'no_of_points', part.id+'_no_of_points'])"></app-input>

</div>
<div class="two-col-inputs" *ngIf="!part.max_capability">
  <app-input
    label="Base speed ratio"
    *ngIf="!part.max_capability"
    placeholder="Base speed ratio..."
    [value]="part.base_speed_ratio"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_base_speed_ratio'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'base_speed_ratio', part.id+'_base_speed_ratio'])"></app-input>
  <app-input
    *ngIf="!part.max_capability"
    label="Time step"
    placeholder="timestep..."
    [value]="part.time_step"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_time_step'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'time_step', part.id+'_time_step'])"></app-input>
</div>
<div class="two-col-inputs">
  <app-input
    label="Required time"
    placeholder="Required time..."
    [value]="part.required_time"
    [unit]="UnitType.TIME"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_required_time'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'required_time', part.id+'_required_time'])"></app-input>
  <app-input
    label="Required distance"
    placeholder="Required distance..."
    [unit]="UnitType.DISTANCE"
    [value]="part.required_distance"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_required_distance'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'required_distance', part.id+'_required_distance'])"></app-input>
</div>
<div>
  <awc-switch 
    [checked]="!!part.max_capability"
    [label]="'Max Capability'"
    [labelPosition]="labelPosition"
    [size]="size"
    [awcSizing]="awcSizing"
    [awcTooltip]="'Toggle Max Capability'"
    (changes)="toggleMC($event)"></awc-switch>
</div>