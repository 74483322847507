/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Signal,
} from '@angular/core';
import { Icons } from '@ansys/awc-angular/icons';
import { State } from '@ansys/andromeda/store';
import { ConceptSections } from 'src/app/shared/enums/concept.enum';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { EventBus } from 'src/app/shared/tools/event-bus';
import { SectionService } from 'src/app/shared/services/section.service';
import { PartServiceType } from 'src/app/shared/types';
import {
  DataDisplayService,
  DataDisplayState,
  DataDisplayType,
} from 'src/app/shared/services/data-display.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { InputTypes } from '../forms/input/input.component';
import { ConceptService } from 'src/app/shared/services/concept.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SelectedItemState } from 'src/app/state/lib/selected-item.state';
import { ActiveSectionState } from '@ansys/andromeda/workspace';

@Component({
  selector: 'active-selection-edit-inputs',
  templateUrl: './active-selection-edit-inputs.component.html',
  styleUrls: ['./active-selection-edit-inputs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveSelectionEditInputsComponent
  implements OnChanges, OnDestroy
{
  @Input() activeTab: string = '';
  @Input() filteredItems: any[] = [];
  @Input() type!: ConceptSections;

  @Output() setConfig: EventEmitter<any> = new EventEmitter();
  get sectionService(): PartServiceType {
    if (!this.$sectionService) {
      this.$sectionService = this._sectionService.getService(this.type);
    }
    return this.$sectionService;
  }
  readonly DataDisplayState = DataDisplayState;
  readonly DataDisplayType = DataDisplayType;
  readonly InputTypes = InputTypes;

  //@TODO type this
  protected item!: any;
  protected chevron: Icons = Icons.RIGHT_ARROW;
  protected plotIcon: Icons = Icons.PLOTS;
  protected selectedSection!: ConceptSections;
  protected activeID!: string;
  protected maxHeight!: Signal<number>;
  private $sectionService!: PartServiceType;
  private bus = EventBus.getInstance();
  private dataTypeSub: Subscription;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private state: State,
    private _cdr: ChangeDetectorRef,
    private _sectionService: SectionService,
    private conceptService: ConceptService,
    protected dataDisplay: DataDisplayService,
    private layoutService: LayoutService
  ) {
    this.maxHeight = this.layoutService.innerCardHeight;
    this.dataTypeSub = this.dataDisplay.stateChanged.subscribe(
      this._cdr.markForCheck.bind(this._cdr)
    );
    this.state.get(ActiveSectionState).subscribe((activeSection) => {
      this.state
        .get(SelectedItemState, activeSection?.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((selectedItem?: AWCTreeItem) => {
          if (selectedItem) {
            this.item = selectedItem;
            this.activeID = selectedItem.id;
            this._cdr.markForCheck();
          }
        });
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filteredItems']) {
      this.filteredItems = changes['filteredItems'].currentValue.sort(
        (a: AWCTreeItem, b: AWCTreeItem): number => {
          if (
            !a.userData ||
            !a.userData['_parentID'] ||
            !b.userData ||
            !b.userData['_parentID']
          )
            return -1;
          return (a.userData['_parentID'] as string).localeCompare(
            b.userData['_parentID'] as string
          );
        }
      );
      if (this.filteredItems.length === 0) {
        this.activeID = '';
        return;
      }
      if (
        !this.filteredItems.find(
          (item: AWCTreeItem) => item?.id === this.activeID
        )
      ) {
        this.activeID = this.filteredItems[0] ? this.filteredItems[0].id : '';
      }
      this._cdr.detectChanges();
    }
  }
  ngOnDestroy(): void {
    this.dataTypeSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  expandItem(item: AWCTreeItem): void {
    this.activeID = item.id;
  }
  protected sendUpdate($event: unknown, item: AWCTreeItem): void {
    this.sectionService
      .updatePart(item)
      .then(() => {
        this.bus.dispatch('input:update:complete', [$event, false]);
      })
      .catch((err) => {
        console.error(err);
        this.bus.dispatch('input:update:complete', [$event, true]);
      });
  }
}
