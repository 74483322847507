import {
  AWCTreeItem,
  AWCTreeToolbarItem,
  TreeToolVisibility,
} from '@ansys/awc-angular/trees';
import { Icons } from '@ansys/awc-angular/icons';

/**
 * Add toolbar item
 */
export class AddItemTB implements AWCTreeToolbarItem {
  public id = 'view';
  public prefixIcon = {
    icon: Icons.ADD,
    src: 'https://s2.svgbox.net/materialui.svg?ic=add',
  };
  public tooltip = 'Add New';
  public visibility = TreeToolVisibility.HOVER;
  private _visible = true;
  private _execute: (item: AWCTreeItem) => void;
  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }
  public onContextChange(item: AWCTreeItem): void {
    if (item.parent) {
      this._visible = false;
      this.visibility = TreeToolVisibility.NEVER;
    }
  }
  public async execute(item: AWCTreeItem): Promise<void> {
    this._execute(item);
  }
}
/**
 * Duplicate toolbar item
 */
export class CloneItemTB implements AWCTreeToolbarItem {
  public id = 'view';
  public prefixIcon = { icon: Icons.COPY };
  public tooltip = 'Clone Item';
  public visibility = TreeToolVisibility.HOVER;
  private _visible = true;
  private _execute: (item: AWCTreeItem) => void;
  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }
  public onContextChange(item: AWCTreeItem): void {
    if (!item.parent) {
      this._visible = false;
      this.visibility = TreeToolVisibility.NEVER;
    }
  }
  public async execute(item: AWCTreeItem): Promise<void> {
    this._execute(item);
  }
}

export class AddItemToLibraryTB implements AWCTreeToolbarItem {
  public id = 'export';
  public prefixIcon = { icon: Icons.LEGO };
  public tooltip = 'Export Item';
  public visibility = TreeToolVisibility.HOVER;
  private _visible = true;
  private _execute: (item: AWCTreeItem) => void;
  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }
  public onContextChange(item: AWCTreeItem): void {
    if (!item.parent) {
      this._visible = false;
      this.visibility = TreeToolVisibility.NEVER;
    }
  }
  public async execute(item: AWCTreeItem): Promise<void> {
    this._execute(item);
  }
}
/**
 * Delete toolbar item
 */
export class DeleteItemTB implements AWCTreeToolbarItem {
  public id = 'view';
  public prefixIcon = { icon: Icons.DELETE };
  public tooltip = 'Delete Item';
  public visibility = TreeToolVisibility.HOVER;
  private _visible = true;
  private _execute: (item: AWCTreeItem) => void;
  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }
  public onContextChange(item: AWCTreeItem): void {
    if (!item.parent) {
      this._visible = false;
      this.visibility = TreeToolVisibility.NEVER;
    }
  }
  public async execute(item: AWCTreeItem): Promise<void> {
    this._execute(item);
  }
}
