/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Part Names.
 */
export enum PartNames {
    ARCHITECTURE = 'architecture',
    COMPONENTS = 'components',
    CONFIGURATIONS = 'configurations',
    REQUIREMENTS = 'requirements',
    DRIVE_CYCLES = 'drive_cycles',
}
