export enum ConceptSections {
  CONFIGURATIONS = 'configurations',
  COMPONENT = 'component',
  REQUIREMENT = 'requirement',
  ARCHITECTURE = 'architecture',
  ENERGY = 'energy',
}
export enum ConceptSubSection {
  MAIN = 'main',
  PLOTS = 'plots',
  RESULTS = 'results',
}
export enum ConceptInputType {
  AERO = 'aero',
  MASS = 'mass',
  WHEEL = 'wheel',
  BATTERY = 'battery',
  ANCILLARY = 'ancillary_load',
  DECELERATION = 'deceleration_limit',
  MOTOR = 'motor',
  TRANSMISSION = 'transmission',
  INVERTER = 'inverter',
  CLUTCH = 'clutch',
  STATIC_REQUIREMENT = 'static_requirement',
  DYNAMIC_REQUIREMENT = 'dynamic_requirement',
  DRIVE_CYCLE = 'drive_cycle',
}
export enum ArchitectureTemplate {
  SINGLE_FRONT = 'single_front',
  SINGLE_REAR = 'single_rear',
  DUAL = 'dual',
}
