/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, AuthTokenState } from '@ansys/andromeda/store';
import { Observable, tap } from 'rxjs';
import { ComponentFileType } from 'src/api';
import { environment } from 'src/environments/environment';

/**
 * This service will return the appropriate service for the section type. This is used to abstract the logic of getting the correct service from the section type away from the shared component.
 */

@Injectable({ providedIn: 'root' })
export class FileService {
  private readonly componentUploadUrl = '/components:upload';
  constructor(private http: HttpClient, private state: State) {}
  uploadFile(
    type: ComponentFileType,
    instantId: string,
    file: Blob
  ): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post(
        `${environment.apiURL}${this.componentUploadUrl}?component_file_type=${type}&design_instance_id=${instantId}&return_speed_only=false`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
          headers: {
            Accept: 'application/json',
            Authorization: `${this.state.value(AuthTokenState)}`,
          },
        }
      )
      .pipe(
        tap((event: any): any => {
          if (event.type === HttpEventType.UploadProgress) {
            // UPDATE PROGRESS BAR UPLOAD
          }
          if (event.type === HttpEventType.DownloadProgress) {
            // UPDATE PROGRESS BAR DOWNLOAD
          }
          return event;
        })
      );
  }
}
