/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MotorLabData } from './MotorLabData';
/**
 * Motor in Database.
 */
export type MotorLabInDB = {
    item_type?: MotorLabInDB.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: 'MotorLabModel';
    lab_data: MotorLabData;
    max_speed: number;
    stator_winding_temp?: (number | null);
    rotor_temp?: (number | null);
    stator_current_limit?: (number | null);
    inverter_losses_included?: boolean;
    _id?: string;
    id? : string;
};
export namespace MotorLabInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
}

