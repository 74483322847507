import { AWCListContent } from '@ansys/awc-angular/lists';
import { DensityUnit } from 'src/api';

export const density: AWCListContent[] = [
  {
    text: 'kg/m³',
    id: DensityUnit.KG_M_,
  },
  {
    text: 'g/cm³',
    id: DensityUnit.G_CM_,
  },
];
