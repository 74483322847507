/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Resistance Unit.
 */
export enum ResistanceUnit {
    OHM = 'ohm',
}
