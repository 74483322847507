/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatteryLookupTableData } from './BatteryLookupTableData';
/**
 * Input values for Battery Model from Lookup Data.
 */
export type BatteryLookupTable = {
    item_type?: BatteryLookupTable.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: BatteryLookupTable.component_type;
    lookup_table: BatteryLookupTableData;
};
export namespace BatteryLookupTable {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        BATTERY_LOOKUP_DATA = 'BatteryLookupData',
    }
}

