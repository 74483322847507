<div class="cont">
  <span>{{errCode}}</span>
  <p>{{message}}</p>

  <div class="button-cont">
    <awc-button
      [text]="'Download Log'"
      [suffixIcon]="!downloadingLog ? downloadIcon : loadingIcon"
      [disabled]="downloadingLog"
      (clicked)="onDownloadLog()"
    ></awc-button>
    <awc-button
      [text]="'Download Input'"
      [suffixIcon]="!downloadingInput ? downloadIcon : loadingIcon"
      [disabled]="downloadingInput"
      (clicked)="onDownloadInput()"
    ></awc-button>
  </div>

</div>
