export enum PartType {
  AERO = 'aero',
  MASS = 'mass',
  WHEEL = 'wheel',
  ANCILLARY = 'ancillary_load',
  DECELERATION = 'deceleration_limit',
  BATTERY = 'battery',
  CLUTCH = 'clutch',
  MOTOR = 'motor',
  TRANSMISSION = 'transmission',
  INVERTER = 'inverter',
  STATIC_REQUIREMENT = 'static_requirement',
  DYNAMIC_REQUIREMENT = 'dynamic_requirement',
  DRIVE_CYCLE = 'drive_cycle',
  MOTOR_LAB = 'motor_lab_file',
  MOTOR_TORQUE_CURVE = 'motor_torque_speed_file',
}
