import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { Coords } from '../types/blueprint.type';
import { SolvedBattery, UnitType } from 'src/api';
import { Inject, Injectable } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';

@Injectable()
export class BatteryComponentMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA) private markerData: [Coords, SolvedBattery],
    private units: ConceptUnitService
  ) {
    const [coords, results] = markerData;
    super(DataType.COMPONENT, MarkerType.BATTERY, [], true, coords);
    this.updateData([results]);
    this.title = results.name || 'Battery';
  }
  override updateData(data: SolvedBattery[], index: number = 0): void {
    const results = data.find(
      (c: SolvedBattery) => c.solved_component_type === 'battery'
    );
    this.data = [
      {
        label: 'VDC',
        value: toSN(
          this.units.convertUnit(
            UnitType.VOLTAGE,
            results?.in_voltages?.[index] ?? 0
          )
        ),
      },
      {
        label: 'Losses',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results?.losses[index] ?? 0)
        ),
        unit: UnitType.POWER,
      },
    ];
  }
}
