/* eslint-disable @typescript-eslint/naming-convention */
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ComponentFileType } from 'src/api';

export const transmissionSelectTypes: AWCListItem[] = [
  {
    text: 'Loss Coefficients',
    id: 'item1',
  },
  {
    text: 'Loss Map',
    id: 'TransmissionLossMapID',
    userData: {
      type: ComponentFileType.TRANSMISSION_TORQUE_GRID_FILE,
      defaultPart: {},
      upload: true,
      file_types: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
    },
  },
];
