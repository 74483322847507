import { AWCListContent } from '@ansys/awc-angular/lists';
import { TorqueUnit } from 'src/api';

export const torque: AWCListContent[] = [
  {
    text: 'N·m',
    id: TorqueUnit.N_M,
  },
  {
    text: 'dyn·cm',
    id: TorqueUnit.DYN_CM,
  },
  {
    text: 'ft·lbf',
    id: TorqueUnit.FT_LBF,
  },
  {
    text: 'kN·m',
    id: TorqueUnit.K_N_M,
  },
  {
    text: 'MN·m',
    id: TorqueUnit.MN_M,
  },
];
