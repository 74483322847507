/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { Template } from '../models/Template';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class TemplatesService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Add To Templates
     * Restricted to template creators.
     * @param templateName
     * @param accountId
     * @param designInstanceId
     * @returns Template Successful Response
     * @throws ApiError
     */
    public addToTemplatesTemplatesPost(
        templateName: string,
        accountId: string,
        designInstanceId: string,
    ): Observable<Template> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/templates',
            query: {
                'template_name': templateName,
                'account_id': accountId,
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * Restricted to template creators.
     * @param accountId
     * @param requestBody
     * @returns Template Successful Response
     * @throws ApiError
     */
    public updateTemplatesPut(
        accountId: string,
        requestBody: Template,
    ): Observable<Template> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/templates',
            query: {
                'account_id': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove From Templates
     * Restricted to template creators.
     * @param templateId
     * @param accountId
     * @returns any Successful Response
     * @throws ApiError
     */
    public removeFromTemplatesTemplatesDelete(
        templateId: string,
        accountId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/templates',
            query: {
                'template_id': templateId,
                'account_id': accountId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Templates
     * List Templates. Get name from the design name.
     * @param skip
     * @param limit
     * @returns Template Successful Response
     * @throws ApiError
     */
    public listTemplatesTemplatesListGet(
        skip?: number,
        limit: number = 100,
    ): Observable<Array<Template>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/templates/list',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
