import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  ErrorListPageResponse,
  JobFilesApiService,
} from '@ansys/andromeda/api/rest';
import { DIALOG_DATA, SnackBarService } from '@ansys/andromeda/shared';
import { ButtonComponent } from '@ansys/awc-angular/buttons';
import { lastValueFrom } from 'rxjs';
import { JobsService } from '../../../../api';
import { DownloadFilesAction } from '../../../actions';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { MimeType } from '../../../shared/enums/mime-type.enum';
import { HttpClient } from '@angular/common/http';
import { Icons, IconType } from '@ansys/awc-angular/icons';
import { ResultsService } from '../../../shared/services/results.services';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { AnalyticEventEnum } from '../../../shared/enums';

interface AdditionalData {
  instanceId: string;
  jobName: string;
  jobDockerTag: string;
}
@Component({
  selector: 'app-job-error-dialog',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './job-error-dialog.component.html',
  styleUrl: './job-error-dialog.component.scss',
})
export class JobErrorDialogComponent {
  protected message: string = 'There was an error running this simulation.';
  protected errCode: string = 'Error Code: 0x00000000';
  protected downloadIcon: IconType = {
    icon: Icons.DOWNLOAD,
  };
  protected loadingIcon: IconType = {
    icon: Icons.SPINNER,
  };
  protected downloadingLog: boolean = false;
  protected downloadingInput: boolean = false;
  private fallbackMessage: string =
    'There was an error running this simulation.';

  constructor(
    @Inject(DIALOG_DATA) public data: ErrorListPageResponse & AdditionalData,
    private jobService: JobsService,
    private actions: ActionContributionsService,
    public readonly http: HttpClient,
    private resultsService: ResultsService,
    private _cdr: ChangeDetectorRef
  ) {
    if (this.data?.errors?.[0]) {
      this.message =
        this.data.errors[0].simulations?.[0]?.statusComment ??
        this.fallbackMessage;
      this.errCode = `Error Code: ${
        this.data.errors[0]?.errorCode ?? '0x00000000'
      }`;
    }
  }

  protected async onDownloadLog(): Promise<void> {
    if (this.downloadingLog) {
      return;
    }
    this.downloadingLog = true;

    const simulationId = this.data.errors?.[0].simulations?.length
      ? this.data.errors[0].simulations?.[0].simulationId
      : '';

    /* eslint-disable @typescript-eslint/naming-convention */
    const requestBody = {
      job_id: this.data?.request?.jobId as string,
      job_name: this.data?.jobName as string,
      docker_tag: this.data?.jobDockerTag as string,
      simulation_id: simulationId as string,
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    const logContent = await lastValueFrom(
      this.jobService.requestConsoleLogJobsErrorFilePost(
        this.data?.instanceId,
        requestBody
      )
    );

    // with the file content, download it
    await this.actions.execute(DownloadFilesAction, [
      `${this.data?.jobName}-err.log`,
      logContent,
      MimeType.PLAIN_TEXT,
    ]);

    const eventData: AnalyticsEventPayload = {
      type: AnalyticEventEnum.JOB_ERROR_LOG_DOWNLOAD,
      data: requestBody,
    };
    this.actions.execute(SubmitAnalyticsEventAction, eventData);

    this.downloadingLog = false;
  }

  protected async onDownloadInput(): Promise<void> {
    if (this.downloadingInput) {
      return;
    }
    this.downloadingInput = true;

    const getJobFile = await lastValueFrom(
      this.resultsService.getJobFile(
        this.data?.request?.jobId as string,
        'cev_job.json'
      )
    );

    const fileName = `${this.data?.jobName}-cev_job.json`;
    await this.actions.execute(DownloadFilesAction, [
      fileName,
      JSON.stringify(getJobFile),
      MimeType.JSON,
    ]);

    const eventData: AnalyticsEventPayload = {
      type: AnalyticEventEnum.JOB_INPUT_DOWNLOAD,
      data: { jobId: this.data?.request?.jobId, fileName },
    };
    this.actions.execute(SubmitAnalyticsEventAction, eventData);

    this.downloadingInput = false;
    this._cdr.detectChanges();
  }
}
