export enum LineWidth {
  OUTLINE = 0.5,
  HAIRLINE = 1,
  THIN = 2,
  MEDIUM = 4,
  THICK = 8,
}
export enum DataType {
  MECHANICAL = 'mechanical',
  ELECTRICAL = 'electrical',
  COMPONENT = 'component',
}

export enum MarkerType {
  BATTERY = 'battery',
  TRANSMISSION = 'transmission',
  MOTOR = 'motor',
  WHEEL = 'wheel',
  ROAD = 'road',
  Inverter = 'inverter',
}

export enum Axle {
  FRONT = 'Front',
  REAR = 'Rear',
}
