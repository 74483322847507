/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { ArchitectureInputIds } from '../models/ArchitectureInputIds';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ArchitecturesService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Create Architectures
     * Create architecture from different inputs types.
     * @param designInstanceId
     * @param requestBody
     * @returns ArchitectureInputIds Successful Response
     * @throws ApiError
     */
    public createArchitecturesArchitecturesPost(
        designInstanceId: string,
        requestBody: ArchitectureInputIds,
    ): Observable<ArchitectureInputIds> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/architectures',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read Architecture
     * Get Architecture from ID.
     * @param itemId
     * @param designInstanceId
     * @returns ArchitectureInputIds Successful Response
     * @throws ApiError
     */
    public readArchitectureArchitecturesItemIdGet(
        itemId: string,
        designInstanceId: string,
    ): Observable<ArchitectureInputIds> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/architectures/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Architecture
     * Update Architecture with new Architecture.
     * @param itemId
     * @param designInstanceId
     * @param requestBody
     * @returns ArchitectureInputIds Successful Response
     * @throws ApiError
     */
    public updateArchitectureArchitecturesItemIdPut(
        itemId: string,
        designInstanceId: string,
        requestBody: ArchitectureInputIds,
    ): Observable<ArchitectureInputIds> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/architectures/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Architecture
     * Delete architecture by ID.
     * @param itemId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteArchitectureArchitecturesItemIdDelete(
        itemId: string,
        designInstanceId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/architectures/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Architecture Max Speed
     * Get the max linear speed of the architecture from component bounds.
     * @param wheelId
     * @param architectureId
     * @param designInstanceId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getArchitectureMaxSpeedArchitecturesMaxSpeedGet(
        wheelId: string,
        architectureId: string,
        designInstanceId: string,
    ): Observable<number> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/architectures:max_speed',
            query: {
                'wheel_id': wheelId,
                'architecture_id': architectureId,
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
