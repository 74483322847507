import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  Injector,
  Type,
} from '@angular/core';
import {
  AWCTreeItem,
  AWCTreeToolbarItem,
  TreeComponent,
} from '@ansys/awc-angular/trees';
import {
  AddItemTB,
  AddItemToLibraryTB,
  DeleteItemTB,
} from '../../../shared/models/tree-item-toolbars.model';
import { State, StateNode, StateType } from '@ansys/andromeda/store';
import {
  componentTreeView,
  vehicleOverviewTreeView,
  requirementTreeView,
} from '../../../shared/models/parent-tree-items.model';
import { DialogService } from '@ansys/andromeda/shared';
import { TreeService } from 'src/app/shared/services/tree.service';
import { ConceptSections } from 'src/app/shared/enums/concept.enum';
import { PartServiceType } from 'src/app/shared/types/part-service.type';
import { SectionService } from 'src/app/shared/services/section.service';
import { AddPartComponent } from '../../dialogs/add-part/add-part.component';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { AddPartDialogData } from '../../../shared/types';
import { ConceptPartType } from 'src/app/shared/types';
import { ActiveConceptStateKeys } from 'src/app/shared/enums';
import { LibraryService } from '@ansys/andromeda/dashboard';
import { LibraryExportComponent } from '../../dialogs/library-export/library-export.component';
import { ActiveSectionState, ActiveTabState } from '@ansys/andromeda/workspace';
import { SelectedItemState } from 'src/app/state/lib/selected-item.state';
import { SelectedConfigurationsState } from 'src/app/state/lib/selected-configuration.state';
import { SelectedComponentState } from 'src/app/state/lib/selected-component.state';
import { SelectedRequirementState } from 'src/app/state/lib/selected-requirement.state';
import { Subject, takeUntil } from 'rxjs';
import { ConfigurationItemState } from 'src/app/state/lib/configurations.state';
import { ComponentsState } from 'src/app/state/lib/components.state';
import { RequirementItemState } from 'src/app/state/lib/requirements.state';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ComponentFileType } from '../../../../api';
import { HttpResponse } from '@angular/common/http';
import { UploadFileResponseType } from '../../../shared/types/upload-file-response.type';

type ServiceType = PartServiceType;
@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss'],
})
export class TreeviewComponent implements AfterViewInit {
  @Input() public type!: string;
  @ViewChild('tree') tree!: TreeComponent;
  @ViewChild('fileUpload') upload!: ElementRef;
  private get service(): ServiceType {
    if (!this.$service)
      this.$service = this.sectionService.getService(
        this.type as ConceptSections
      );
    return this.$service;
  }
  public toolbarItems!: AWCTreeToolbarItem[];
  public multi: boolean = false;
  public treeSelectedIds: string[] = [];
  public items: AWCTreeItem[] = [];

  private $service!: ServiceType;
  private treeItems: Map<ConceptSections, AWCTreeItem[]> = new Map([
    [ConceptSections.CONFIGURATIONS, vehicleOverviewTreeView],
    [ConceptSections.COMPONENT, componentTreeView],
    [ConceptSections.REQUIREMENT, requirementTreeView],
  ]);
  private selectedStateFromType: Map<
    ConceptSections,
    Type<StateNode<string[]>>
  > = new Map([
    [ConceptSections.CONFIGURATIONS, SelectedConfigurationsState],
    [ConceptSections.COMPONENT, SelectedComponentState],
    [ConceptSections.REQUIREMENT, SelectedRequirementState],
  ]);
  private itemStateFromType: Map<
    ConceptSections,
    Type<StateNode<AWCListItem[]>>
  > = new Map([
    [ConceptSections.CONFIGURATIONS, ConfigurationItemState],
    [ConceptSections.COMPONENT, ComponentsState],
    [ConceptSections.REQUIREMENT, RequirementItemState],
  ]);
  private stateDataLabel: Map<ConceptSections, ActiveConceptStateKeys> =
    new Map([
      [ConceptSections.CONFIGURATIONS, ActiveConceptStateKeys.VEHICLE_DATA],
      [ConceptSections.COMPONENT, ActiveConceptStateKeys.COMPONENT_DATA],
      [ConceptSections.REQUIREMENT, ActiveConceptStateKeys.REQUIREMENTS_DATA],
      [ConceptSections.ENERGY, ActiveConceptStateKeys.ENERGY_DATA],
      [ConceptSections.ARCHITECTURE, ActiveConceptStateKeys.ARCHITECTURE_DATA],
    ]);
  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private sectionService: SectionService,
    private treeService: TreeService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private state: State,
    private injector: Injector,
    private elRef: ElementRef,
    private _cdr: ChangeDetectorRef,
    private libraryService: LibraryService
  ) {}
  ngOnInit(): void {
    this.multi = this.type === ConceptSections.CONFIGURATIONS;
    const tab = this.state.value(ActiveTabState);
    const state = this.itemStateFromType.get(this.type as ConceptSections);
    state &&
      this.state
        .get(state, tab?.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.handleTreeData(data);
        });
    const selectedState = this.selectedStateFromType.get(
      this.type as ConceptSections
    );
    selectedState &&
      this.state
        .get(selectedState, tab?.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: StateType) => {
          if (!data) return;
          this.treeSelectedIds = data as string[];
        });
    this.treeService.selectItem.subscribe((item: AWCTreeItem) => {
      this.treeItemClicked(item, true);
    });
  }
  ngAfterViewInit(): void {
    const addToLib =
      this.type === ConceptSections.REQUIREMENT
        ? []
        : [new AddItemToLibraryTB(this.exportItem.bind(this))];

    this.toolbarItems = [
      ...addToLib,
      new DeleteItemTB(this.removeItem.bind(this)),
      new AddItemTB(this.addItem.bind(this)),
    ];
    this.tree?.setToolbarItems(this.toolbarItems);
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  /**
   * Tree item clicked/selected
   * @param $event
   * @param fromCreate
   */
  public treeItemClicked($event: AWCTreeItem, fromCreate?: boolean): void {
    if (!$event) return;
    if ($event?.ignoreSelection) return;
    const tab = this.state.value(ActiveTabState);
    const activeSection = this.state.value(ActiveSectionState);
    if (this.multi && this.treeSelectedIds.length > 0) {
      let dupSelectedIds = [...this.treeSelectedIds];
      this.treeSelectedIds.forEach((id) => {
        const parent = !fromCreate
          ? $event.parent
          : this.items.find(
              (item) =>
                item.id === ($event.userData && $event.userData['_parentID'])
            );
        const item = parent?.children?.find(
          (selectedItem) => selectedItem.id === id
        );
        if (item) {
          dupSelectedIds = dupSelectedIds.filter(
            (selectedId) => selectedId !== id
          );
        }
      });
      this.treeSelectedIds = dupSelectedIds.concat($event.id);
    } else this.treeSelectedIds = [$event.id];
    const state = this.selectedStateFromType.get(this.type as ConceptSections);
    state &&
      this.state.set(state, this.treeSelectedIds, {
        binding: tab?.id,
      });

    this.state.set(SelectedItemState, $event, {
      binding: activeSection?.id,
    });
  }
  protected selectedItemsChanged(): void {
    this.treeSelectedIds = [...this.treeSelectedIds];
  }
  protected async exportItem(item: AWCTreeItem): Promise<void> {
    // item;
    const assetJson = JSON.stringify(item.userData);
    const blob = new Blob([assetJson], { type: 'application/json' });
    this.dialogService.open(LibraryExportComponent, {
      title: `Export ${this.type}`,
      data: {
        type: this.type,
        item,
        blob,
      },
    });
  }
  /**
   * Build fresh tree items
   */
  private buildFreshTreeItems(): void {
    const resetChild = (item: AWCTreeItem): AWCTreeItem => {
      item.children = [];
      return item;
    };
    this.items = (this.treeItems.get(this.type as ConceptSections) || []).map(
      resetChild
    );
    this._cdr.detectChanges();
  }
  /**
   * Appends tree item to the appropairte parent
   * @param {AWCTreeItem} item - Item to append
   * @param {string} parentID - ID of the parent item
   */
  private appendTreeItem(item: AWCTreeItem, parentID?: string): void {
    if (!this.items) this.buildFreshTreeItems();
    this.items.find((parent) => parent.id === parentID)?.children?.push(item);
  }
  private checkIfDirtyParent(): boolean {
    return this.items.some((item) => (item as any).dirty);
  }
  private handleTreeData(data: AWCTreeItem[]): void {
    if (!this.items.length) this.buildFreshTreeItems();
    if (this.checkIfDirtyParent()) {
      this.items = this.items.slice();
      this.items.forEach((item) => ((item as any).dirty = false));
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
    let dirty = false;
    data &&
      data.forEach((item: AWCTreeItem) => {
        const parent = this.items.find(
          (p) => p.id === (item?.userData ? item.userData['_parentID'] : '')
        );
        // CHECK ITS CHANGED AND IF SO UPDATE ITEM
        if (
          parent?.children?.find((child) => child.id === item.id) ||
          !parent
        ) {
          return;
        }
        dirty = true;
        this.tree && this.tree.addItem(item, parent?.id);
        if (!this.tree) this.appendTreeItem(item, parent?.id);
      });

    if (dirty) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }
  /**
   * Remove item from tree
   * @param {AWCTreeItem} item
   */
  private removeItem(item: AWCTreeItem): void {
    (item as any).dirty = true;
    const dialog = this.dialogService.open(ConfirmComponent, {
      title: `Remove Concept ${this.type}`,
      data: {
        type: this.type,
        parent: item,
        onConfirm: () => {
          this.service
            .deletePart(item)
            .then(() => {
              item.parent?.children?.splice(
                item.parent?.children?.indexOf(item),
                1
              );
              this.tree.setItems(this.items);
              dialog.close();
            })
            .catch(() => {
              dialog.close();
            });
        },
        onCancel: () => {
          dialog.close();
        },
      } as AddPartDialogData,
    });
  }
  /**
   * Clone item from tree
   * @param {AWCTreeItem} item
   */
  private cloneItem(item: AWCTreeItem): void {
    item;
    // item.parent && this.service.addPart(item.parent, item.userData);
  }
  /**
   * Add item to tree
   * @param {AWCTreeItem} item
   */
  private addItem(item: AWCTreeItem): void {
    const dialog = this.dialogService.open(AddPartComponent, {
      title: `Add Concept ${this.type}`,
      data: {
        type: this.type,
        parent: item,
        onUploadFilePart: async (
          file: File,
          type: ComponentFileType
        ): Promise<any> => {
          if (this.service.uploadFilePart) {
            const uploadedFile: HttpResponse<any> = await this.service
              .uploadFilePart(file, type)
              .then((fileResponse) => {
                return fileResponse as HttpResponse<any>;
              })
              .catch((e) => {
                throw new Error(e);
              });

            const [id, responseObject] = uploadedFile.body;

            return { id, responseObject };
          }
        },
        onConfirm: (
          addedItem: ConceptPartType,
          file: File,
          type: ComponentFileType,
          uploadedFile?: UploadFileResponseType
        ) => {
          // add new or from library
          this.service
            .addPart(addedItem, file, type, uploadedFile)
            .then((part) => {
              if (this.type === ConceptSections.COMPONENT) {
                this.service.shouldUpdateSection?.call(
                  this.service,
                  part as any,
                  item.id
                );
              }
              const treeItem = this.treeService.addVehiclePartItem(
                item.id,
                part
              );
              this.treeItemClicked(treeItem, true);
              dialog.close();
            })
            .catch(() => {
              dialog.close();
            });
        },
      } as AddPartDialogData,
    });
    // dialog.afterClosed().subscribe((result) => {});
  }
}
