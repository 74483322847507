/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Motor Loss Map.
 *
 * Input lists are two-dimensional, with each sub-list referring to
 * a different voltage.
 */
export type MotorLossMapData = {
    speeds: Array<Array<number>>;
    torques: Array<Array<number>>;
    voltages: Array<number>;
    losses: Array<Array<number>>;
    currents?: (Array<Array<number>> | null);
    power_factors?: (Array<Array<number>> | null);
    component_file_type?: MotorLossMapData.component_file_type;
};
export namespace MotorLossMapData {
    export enum component_file_type {
        MOTOR_LOSS_MAP = 'MotorLossMap',
    }
}

