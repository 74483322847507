import { LineWidth } from '../enums/blueprint.enum';

export const setStrokeStyle = (
  ctx: CanvasRenderingContext2D,
  width: LineWidth,
  style: string,
  cap?: CanvasLineCap
): void => {
  ctx.strokeStyle = style;
  ctx.lineWidth = width;
  ctx.lineCap = cap ?? 'round';
};
