/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Ratio Unit.
 */
export enum RatioUnit {
  RATIO  = '',
  _ = '%',
}
