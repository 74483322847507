import { AWCListContent } from '@ansys/awc-angular/lists';
import { PressureUnit } from '../../../../../api';

export const pressure: AWCListContent[] = [
  {
    text: 'Pa',
    id: PressureUnit.PA,
  },
  {
    text: 'kPa',
    id: PressureUnit.K_PA,
  },
  {
    text: 'MPa',
    id: PressureUnit.MPA,
  },
  {
    text: 'psi',
    id: PressureUnit.PSI,
  },
];
