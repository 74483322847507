import { AWCListContent } from '@ansys/awc-angular/lists';
import { TimeUnit } from 'src/api';

export const time: AWCListContent[] = [
  {
    text: 's',
    id: TimeUnit.S,
  },
  {
    text: 'ms',
    id: TimeUnit.MS,
  },
  {
    text: 'min',
    id: TimeUnit.MIN,
  },
  {
    text: 'hr',
    id: TimeUnit.HR,
  },
];
