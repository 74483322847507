import { AWCListContent } from '@ansys/awc-angular/lists';
import { RoadEfficiencyUnit } from 'src/api';

export const roadEfficiency: AWCListContent[] = [
  {
    text: 'm/J',
    id: RoadEfficiencyUnit.M_J,
  },
  {
    text: 'km/kWh',
    id: RoadEfficiencyUnit.KM_K_WH,
  },
  {
    text: 'miles/kWh',
    id: RoadEfficiencyUnit.MILES_K_WH,
  },
  {
    text: 'MPGe',
    id: RoadEfficiencyUnit.MPGE,
  },
];
