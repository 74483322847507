/* eslint-disable @typescript-eslint/naming-convention */
import { SolvedInverter } from 'src/api';
import {
  ComponentBounds,
  Coords,
  VehicleBounds,
} from '../types/blueprint.type';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import { BlueprintMarker } from '../markers/marker';
import { Axle } from '../enums/blueprint.enum';
import { InverterComponentMarker } from '../markers/inverter-component';
import { InverterElectricalMarker } from '../markers/inverter-electrical';
const INVERTER_DATA = new InjectionToken<
  [
    [ComponentBounds, ComponentBounds],
    [SolvedInverter, SolvedInverter],
    number[],
    VehicleBounds
  ]
>('InverterData');
@Injectable()
export class BlueprintInverterComponents {
  private frontComponentMarker!: InverterComponentMarker;
  private rearComponentMarker!: InverterComponentMarker;
  private frontElectricalMarker!: InverterElectricalMarker;
  private rearElectricalMarker!: InverterElectricalMarker;
  private bounds: [ComponentBounds, ComponentBounds];
  private data: [SolvedInverter, SolvedInverter];
  private motors: number[];
  private _vBounds: VehicleBounds;
  constructor(
    @Inject(INVERTER_DATA)
    private _data: [
      [ComponentBounds, ComponentBounds],
      [SolvedInverter, SolvedInverter],
      number[],
      VehicleBounds
    ],
    private units: ConceptUnitService
  ) {
    [this.bounds, this.data, this.motors, this._vBounds] = _data;
    const [frontCMP, rearCMP, frontEMP, rearEMP] = this.getCoords();
    const [frontData, rearData] = this.data;

    if (frontData) {
      this.frontComponentMarker = new InverterComponentMarker(
        [frontCMP, frontData, !!this.motors[0], Axle.FRONT],
        this.units
      );
      this.frontElectricalMarker = new InverterElectricalMarker(
        [frontEMP, frontData, !!this.motors[0], Axle.FRONT],
        this.units
      );
    }
    if (rearData) {
      this.rearComponentMarker = new InverterComponentMarker(
        [rearCMP, rearData, !!this.motors[1], Axle.REAR],
        this.units
      );
      this.rearElectricalMarker = new InverterElectricalMarker(
        [rearEMP, rearData, !!this.motors[1], Axle.REAR],
        this.units
      );
    }
  }
  getCoords(): [
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[]
  ] {
    let frontCMP, rearCMP, frontEMP, rearEMP: Coords | Coords[];
    const [frontBounds, rearBounds] = this.bounds;
    const [frontMotors, rearMotors] = this.motors;
    const gap = this._vBounds.componentGap;
    // ComponentMarkerPosition
    if (frontMotors === 2) {
      frontCMP = [
        {
          x: frontBounds.x + frontBounds.width / 2,
          y: frontBounds.y - this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width / 2,
          y: frontBounds.y + this._vBounds.componentHeight,
        },
      ];
    } else {
      frontCMP = {
        x: frontBounds.x + frontBounds.width / 2,
        y: frontBounds.y,
      };
    }
    if (rearMotors === 2) {
      rearCMP = [
        {
          x: rearBounds.x + rearBounds.width / 2,
          y: rearBounds.y - this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x + rearBounds.width / 2,
          y: rearBounds.y + this._vBounds.componentHeight,
        },
      ];
    } else {
      rearCMP = {
        x: rearBounds.x + rearBounds.width / 2,
        y: rearBounds.y,
      };
    }

    // ElectricalMarkerPosition

    if (frontMotors === 2) {
      frontEMP = [
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height * 0.25 -
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height * 0.75 -
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height * 0.25 +
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height * 0.75 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      frontEMP = [
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y: frontBounds.y + frontBounds.height * 0.25,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y: frontBounds.y + frontBounds.height * 0.75,
        },
      ];
    }

    if (rearMotors === 2) {
      rearEMP = [
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.25 -
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.75 -
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.25 +
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.75 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      rearEMP = [
        {
          x: rearBounds.x - gap / 2,
          y: rearBounds.y + rearBounds.height * 0.25,
        },
        {
          x: rearBounds.x - gap / 2,
          y: rearBounds.y + rearBounds.height * 0.75,
        },
      ];
    }

    return [frontCMP, rearCMP, frontEMP, rearEMP];
  }
  getMarkers(): BlueprintMarker[] {
    const markers = [];
    if (this.frontComponentMarker) markers.push(this.frontComponentMarker);
    if (this.rearComponentMarker) markers.push(this.rearComponentMarker);
    if (this.frontElectricalMarker) markers.push(this.frontElectricalMarker);
    if (this.rearElectricalMarker) markers.push(this.rearElectricalMarker);
    return markers;
  }
  updateMarkerPositions(
    frontBounds: ComponentBounds,
    rearBounds: ComponentBounds
  ): void {
    this.bounds = [frontBounds, rearBounds];
    const [frontCMP, rearCMP, frontEMP, rearEMP] = this.getCoords();
    if (this.frontComponentMarker)
      this.frontComponentMarker.nodeCoords = frontCMP;
    if (this.rearComponentMarker) this.rearComponentMarker.nodeCoords = rearCMP;
    if (this.frontElectricalMarker)
      this.frontElectricalMarker.nodeCoords = frontEMP;
    if (this.rearElectricalMarker)
      this.rearElectricalMarker.nodeCoords = rearEMP;
  }
}
