<div class="cont">
  <awc-logo [appName]="appName"></awc-logo>
  <div class="meta">
    <div>App Version</div>
    <div>{{ version }}</div>
  </div>
  <div class="meta">
    <div>API Version</div>
    @if (apiVersion) {
      <div>{{ apiVersion.version }}</div>
    } @else {
      <div><awc-icon [icon]="Icons.SPINNER"></awc-icon></div>
    }
  </div>
</div>
