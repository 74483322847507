<div class="dropdown-container" [style.width]="fillWidth ? '100%' : 'auto'">
  <app-input
    [label]="label"
    [value]="_selectedItems[0] && _selectedItems[0].text || placeholder"
    [type]="InputTypes.SELECT"
    [layout]="layout"
    (setLabelWidth)="labelWidth = layout === 'row' && $event ? $event + 8 : 0"
    (click)="optionsActive = !optionsActive"
    [active]="optionsActive"
    [readonly]="true"></app-input>
  <div class="dropdown-content" cdkScrollable [style.left]="labelWidth+'px'" [style.minWidth]="'calc(100% - '+labelWidth+'px)'" [style.display]="optionsActive ? 'flex' : 'none'">
    <cdk-virtual-scroll-viewport itemSize="40" class="viewport" [style.height.px]="items.length < 5 ? items.length * 40 : 200">
      <div *cdkVirtualFor="let item of items" class="dropdown-item" [ngClass]="selectedItems.includes(item.id) ? 'active' : ''" (click)="selectItem(item)">
        <span class="dropdown-item-content">
          {{item.text}}
        </span>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
