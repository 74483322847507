import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { UnitType } from '../../../api';
import { RequirementPlotType } from '../../shared/enums/reqirement-plot-type.enum';
import { ConvertUnitValuesAction } from '../convert-unit-values/convert-unit-values.action';
import { ConceptUnitService } from '../../shared/services/unit.service';

// TODO: Don't use any[] in InputType or ReturnType
type InputType = any[];
type ReturnType = [any, any[]];

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class ProcessDriveCycleResultDataAction
  implements AWFAction<InputType, ReturnType>
{
  // This can be used to track end user operations and can be used to analyze the user behavior.
  readonly reportAnalysis = false;

  constructor(
    private actions: ActionContributionsService,
    private unitChoices: ConceptUnitService
  ) {}

  async execute(results: InputType): Promise<ReturnType> {
    const data: any[] = [];
    const layouts: any[] = [];
    let maxTime = 0;
    let minTime = 0;
    let maxTorqueAchieved = 0;
    let maxTorqueDC = 0;
    let minTorqueAchieved = 0;
    let minTorqueDC = 0;
    let minBatteryCharge = Infinity;
    let maxBatteryCharge = 0;
    let convertedTime: number[] = [];
    let convertedTorqueAchieved: number[] = [];
    let convertedTorqueDC: number[] = [];
    let convertedBatteryCharge: number[] = [];

    for (const req of results) {
      [convertedTime, minTime, maxTime] = await this.actions.execute(
        ConvertUnitValuesAction,
        [req.time, UnitType.TIME, minTime, maxTime]
      );

      [convertedTorqueAchieved, minTorqueAchieved, maxTorqueAchieved] =
        await this.actions.execute(ConvertUnitValuesAction, [
          req.torques_achieved,
          UnitType.TORQUE,
          minTorqueAchieved,
          maxTorqueAchieved,
        ]);

      [convertedTorqueDC, minTorqueDC, maxTorqueDC] =
        await this.actions.execute(ConvertUnitValuesAction, [
          req.torques_drive_cycle,
          UnitType.TORQUE,
          minTorqueDC,
          maxTorqueDC,
        ]);

      [convertedBatteryCharge, minBatteryCharge, maxBatteryCharge] =
        await this.actions.execute(ConvertUnitValuesAction, [
          req.battery_charge,
          UnitType.ELECTRICAL_ENERGY,
          minBatteryCharge,
          maxBatteryCharge,
        ]);

      let markers = {};
      if (req.points_not_achieved) {
        markers = {
          type: 'scatter',
          name: 'Points Not Achieved',
          x: req.points_not_achieved?.map((i: number) => convertedTime[i]),
          y: req.points_not_achieved?.map(
            (i: number) => convertedTorqueAchieved[i]
          ),
          marker: { color: 'red', symbol: 'x' },
          id: req.id,
          mode: 'markers',
        };
      }

      data.push(
        {
          type: 'scatter',
          _type: RequirementPlotType.CAPABILITY_CURVE,
          name: 'Achieved Torque',
          x: convertedTime,
          y: convertedTorqueAchieved,
          line: { color: 'grey' },
          id: req.id,
        },
        {
          type: 'scatter',
          _type: RequirementPlotType.REQUIREMENT,
          name: 'Required Torque',
          x: convertedTime,
          y: convertedTorqueDC,
          marker: { color: 'grey' },
          id: req.id,
        },
        {
          type: 'scatter',
          _type: RequirementPlotType.EXTRA,
          name: 'Battery Charge',
          x: convertedTime,
          y: convertedBatteryCharge,
          yaxis: 'y2',
          marker: { color: 'rgb(33, 150, 243)' },
          id: req.id,
        },
        markers
      );
      const layout = {
        id: req.id,
        xaxis: {
          range: [0, maxTime],
          title: `Time (${this.unitChoices.getChoice(UnitType.TIME)})`,
        },
        yaxis: {
          range: [
            Math.min(minTorqueAchieved, minTorqueDC),
            Math.max(maxTorqueAchieved, maxTorqueDC),
          ],
          title: `Tractive Torque (${this.unitChoices.getChoice(
            UnitType.TORQUE
          )})`,
        },
        yaxis2: {
          range: [minBatteryCharge, maxBatteryCharge],
          title: `Battery Charge (${this.unitChoices.getChoice(
            UnitType.ELECTRICAL_ENERGY
          )})`,
          overlaying: 'y',
          side: 'right',
          mirror: 'ticks',
          zeroline: false,
          tickmode: 'sync',
          rangemode: 'tozero',
          showgrid: true,
        },
      };
      layouts.push(layout);
    }

    return [data, layouts];
  }
}
