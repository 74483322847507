<div class="confirm-dialog">
  <awc-button 
    text="Cancel"
    [type]="alttype"
    [size]="size"
    (clicked)="cancel()"></awc-button>
  <awc-button 
    text="Confirm"
    [type]="type"
    [size]="size"
    (clicked)="confirm()"></awc-button>
</div>