import { AWFAction } from '@ansys/andromeda/contributions';
import { LayoutAxis } from 'plotly.js-dist-min';
import { AppThemeState, State } from '@ansys/andromeda/store';
import {
  defaultAxisColorDarkConfig,
  defaultAxisColorLightConfig,
} from '../../components/widgets/charts/graph-display/lib/models/default-axis-color-config';
import { Injectable } from '@angular/core';
import { ActionsEnum } from '../actions.enum';

type Input = null;
type Return = Partial<LayoutAxis>;

@Injectable({ providedIn: 'root' })
export class DefaultChartAxisColourConfig implements AWFAction<Input, Return> {
  public readonly type = ActionsEnum.DEFAULT_CHART_AXIS_COLOUR_CONFIG;

  constructor(private state: State) {}

  execute: (context: Input) => Promise<Return> = async (context: Input) => {
    const theme = this.state.value(AppThemeState);

    const isDarkMode =
      theme === 'dark' ||
      (theme === 'auto' &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches);

    return isDarkMode
      ? defaultAxisColorDarkConfig
      : defaultAxisColorLightConfig;
  };
}
