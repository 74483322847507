/* eslint-disable @typescript-eslint/naming-convention */
import { SolvedTransmission } from 'src/api';
import {
  ComponentBounds,
  Coords,
  VehicleBounds,
} from '../types/blueprint.type';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import { TransmissionComponentMarker } from '../markers/transmission-component';
import { TransmissionMechanicalMarker } from '../markers/transmission-mechanical';
import { BlueprintMarker } from '../markers/marker';
import { Axle } from '../enums/blueprint.enum';
export const TRANSMISSION_DATA = new InjectionToken<
  [
    [ComponentBounds, ComponentBounds],
    [SolvedTransmission, SolvedTransmission],
    number[],
    VehicleBounds
  ]
>('TransmissionData');
@Injectable()
export class BlueprintTransmissionComponent {
  private frontComponentMarker!: TransmissionComponentMarker;
  private rearComponentMarker!: TransmissionComponentMarker;
  private frontMechanicalMarker!: TransmissionMechanicalMarker;
  private rearMechanicalMarker!: TransmissionMechanicalMarker;
  private bounds: [ComponentBounds, ComponentBounds];
  private data: [SolvedTransmission, SolvedTransmission];
  private motors: number[];
  private _vBounds: VehicleBounds;
  constructor(
    @Inject(TRANSMISSION_DATA)
    private _data: [
      [ComponentBounds, ComponentBounds],
      [SolvedTransmission, SolvedTransmission],
      number[],
      VehicleBounds
    ],
    private units: ConceptUnitService
  ) {
    [this.bounds, this.data, this.motors, this._vBounds] = _data;
    const [frontCMP, rearCMP, frontMMP, rearMMP] = this.getCoords();
    const [frontData, rearData] = this.data;
    if (frontData) {
      this.frontComponentMarker = new TransmissionComponentMarker(
        [frontCMP, frontData, !!this.motors[0], Axle.FRONT],
        this.units
      );
      this.frontMechanicalMarker = new TransmissionMechanicalMarker(
        [frontMMP, frontData, !!this.motors[0], Axle.FRONT],
        this.units
      );
    }
    if (rearData) {
      this.rearComponentMarker = new TransmissionComponentMarker(
        [rearCMP, rearData, !!this.motors[1], Axle.REAR],
        this.units
      );
      this.rearMechanicalMarker = new TransmissionMechanicalMarker(
        [rearMMP, rearData, !!this.motors[1], Axle.REAR],
        this.units
      );
    }
  }
  getCoords(): [
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[]
  ] {
    let frontCMP, rearCMP, frontMMP, rearMMP: Coords | Coords[];
    const [frontBounds, rearBounds] = this.bounds;
    const [frontMotors, rearMotors] = this.motors;
    const componentGap = this._vBounds.componentGap;
    if (frontMotors === 2) {
      frontCMP = [
        {
          x: frontBounds.x + frontBounds.width / 2,
          y: frontBounds.y - this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width / 2,
          y: frontBounds.y + this._vBounds.componentHeight,
        },
      ];
    } else {
      frontCMP = {
        x: frontBounds.x + frontBounds.width / 2,
        y: frontBounds.y,
      };
    }
    if (rearMotors === 2) {
      rearCMP = [
        {
          x: rearBounds.x + rearBounds.width / 2,
          y: rearBounds.y - this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x + rearBounds.width / 2,
          y: rearBounds.y + this._vBounds.componentHeight,
        },
      ];
    } else {
      rearCMP = {
        x: rearBounds.x + rearBounds.width / 2,
        y: rearBounds.y,
      };
    }
    //MechanicalMarkerPosition
    if (frontMotors === 2) {
      frontMMP = [
        {
          x: frontBounds.x - componentGap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 2 -
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x - componentGap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 2 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      frontMMP = {
        x: frontBounds.x - componentGap / 2,
        y: frontBounds.y + frontBounds.height / 2,
      };
    }
    if (rearMotors === 2) {
      rearMMP = [
        {
          x: rearBounds.x + rearBounds.width + componentGap / 2,
          y:
            rearBounds.y +
            rearBounds.height / 2 -
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x + rearBounds.width + componentGap / 2,
          y:
            rearBounds.y +
            rearBounds.height / 2 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      rearMMP = {
        x: rearBounds.x + rearBounds.width + componentGap / 2,
        y: rearBounds.y + rearBounds.height / 2,
      };
    }
    return [frontCMP, rearCMP, frontMMP, rearMMP];
  }
  getMarkers(): BlueprintMarker[] {
    const markers = [];
    if (this.frontComponentMarker) markers.push(this.frontComponentMarker);
    if (this.rearComponentMarker) markers.push(this.rearComponentMarker);
    if (this.frontMechanicalMarker) markers.push(this.frontMechanicalMarker);
    if (this.rearMechanicalMarker) markers.push(this.rearMechanicalMarker);
    return markers;
  }
  updateMarkerPositions(
    frontBounds: ComponentBounds,
    rearBounds: ComponentBounds
  ): void {
    this.bounds = [frontBounds, rearBounds];
    const [frontCMP, rearCMP, frontMMP, rearMMP] = this.getCoords();
    if (this.frontComponentMarker)
      this.frontComponentMarker.nodeCoords = frontCMP;
    if (this.rearComponentMarker) this.rearComponentMarker.nodeCoords = rearCMP;
    if (this.frontMechanicalMarker)
      this.frontMechanicalMarker.nodeCoords = frontMMP;
    if (this.rearMechanicalMarker)
      this.rearMechanicalMarker.nodeCoords = rearMMP;
  }
}
