import { LibraryObject } from '@ansys/andromeda/api/rest';
import { Injectable } from '@angular/core';
import {
  AWCTableCell,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { TableColumn } from '@ansys/andromeda/contributions';

@Injectable({ providedIn: 'root' })
export class LibraryDescriptionColumn implements TableColumn {
  public header: string = 'Description';
  public id: string = 'library-table-description';
  public priority: number = 150;
  public type: TableCellTypes = TableCellTypes.TEXT;
  public align: TableCellAlign = TableCellAlign.CENTER;

  public render(data: LibraryObject): Promise<AWCTableCell> {
    return Promise.resolve({
      value: data.objectDescription,
    });
  }
}
