/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { UnitChoices } from '../models/UnitChoices';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class UnitChoicesService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Read
     * Get from ID.
     * @returns UnitChoices Successful Response
     * @throws ApiError
     */
    public readUnitChoicesGet(): Observable<UnitChoices> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/unit_choices',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Update
     * Update with new parameters.
     * @param requestBody
     * @returns UnitChoices Successful Response
     * @throws ApiError
     */
    public updateUnitChoicesPut(
        requestBody: UnitChoices,
    ): Observable<UnitChoices> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/unit_choices',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Unit Choices
     * Create.
     * @param requestBody
     * @returns UnitChoices Successful Response
     * @throws ApiError
     */
    public createUnitChoicesUnitChoicesPost(
        requestBody: UnitChoices,
    ): Observable<UnitChoices> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/unit_choices',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * Delete by ID.
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteUnitChoicesDelete(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/unit_choices',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Get Info
     * Get table of units for frontend generation.
     * @returns any Successful Response
     * @throws ApiError
     */
    public getInfoUnitChoicesInfoGet(): Observable<Record<string, any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/unit_choices/info',
            errors: {
                404: `Not found`,
            },
        });
    }
}
