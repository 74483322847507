/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  Signal,
} from '@angular/core';
import { BlueprintMarker } from '../src/markers/marker';
import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { Coords } from '../src/types/blueprint.type';
import { AppThemeState, State } from '@ansys/andromeda/store';
import { IconSize, Icons } from '@ansys/awc-angular/icons';
import { DataType } from '../src/enums/blueprint.enum';

@Component({
  selector: 'app-marker-display',
  templateUrl: './marker-display.component.html',
  styleUrls: ['./marker-display.component.scss'],
})
export class MarkerDisplayComponent implements AfterViewInit {
  @Input()
  marker!: BlueprintMarker;
  @Input() _markers!: BlueprintMarker[];
  @Input() boundsWidth: number = 0;
  @Input() boundsHeight: number = 0;
  @Output() render: EventEmitter<void> = new EventEmitter<void>();
  @Output() markerChange: EventEmitter<BlueprintMarker> =
    new EventEmitter<BlueprintMarker>();
  @ViewChild('markerEl') markerRef!: ElementRef<HTMLDivElement>;
  protected get _theme(): string {
    const theme = this.state.value(AppThemeState);
    const isDarkMode =
      theme === 'dark' ||
      (theme === 'auto' &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches);
    return isDarkMode ? 'dark' : ('light' as string);
  }
  protected theme: Signal<string> = this.state.signal(AppThemeState);
  protected componentIcon: Icons = Icons.SETTINGS;
  protected componentSrc: string = '';
  protected electricalICon: Icons = Icons.ELECTRICAL;
  protected mechanicalIcon: Icons = Icons.MECHANICAL;
  protected smallsize: IconSize = IconSize.X_SMALL;
  protected readonly DataType = DataType;
  private padding: number = 3;
  private componentIconMap: Map<string, Icons | { src: string }> = new Map([
    ['transmission', Icons.SETTINGS],
    ['inverter', Icons.ELECTRICAL],
  ]);
  private componentSrcMap: Map<string, string> = new Map([
    ['motor', 'assets/icons/Motor.svg'],
    ['battery', 'assets/icons/battery.svg'],
    ['clutch', 'assets/icons/clutch.svg'],
  ]);
  constructor(private state: State) {}
  ngOnInit(): void {
    if (this.marker?.marker) {
      this.componentIcon = this.componentIconMap.get(
        this.marker.marker
      ) as Icons;
      this.componentSrc = this.componentSrcMap.get(this.marker.marker) || '';
    }
  }
  ngAfterViewInit(): void {
    if (!this.markerRef) return;
    this.marker.width = this.markerRef.nativeElement.offsetWidth;
    this.marker.height = this.markerRef.nativeElement.offsetHeight;
    this.marker.elRef = this.markerRef.nativeElement;
    if (this.marker === this._markers[0]) {
      this.checkMarkerIntersections();
    } else {
      const stateCheck = setInterval(() => {
        if (this.marker._ready) {
          this.checkMarkerIntersections();
          clearInterval(stateCheck);
        }
      }, 100);
    }
  }
  ngAfterViewChecked(): void {
    if (!this.markerRef) return;
    if (this.marker?.width !== this.markerRef.nativeElement.offsetWidth) {
      this.marker.width = this.markerRef.nativeElement.offsetWidth;
      this.marker.height = this.markerRef.nativeElement.offsetHeight;
      this.render.emit();
    }
  }

  /**
   * Emitted when a marker is dragged to assign new position and re-render canvas for linking markers to new link positions
   * @param $event Drag event
   * @param marker Marker being dragged
   */
  protected setMarkerPositions(
    $event: CdkDragMove,
    marker: BlueprintMarker
  ): void {
    const position = $event.source.getFreeDragPosition();
    this.marker.width = this.markerRef.nativeElement.offsetWidth;
    this.marker.height = this.markerRef.nativeElement.offsetHeight;
    $event.source.setFreeDragPosition({ x: 0, y: 0 });
    if (marker.originX === undefined || marker.originY === undefined) {
      marker.originX = marker.linkX;
      marker.originY = marker.linkY;
    }
    marker.linkX = marker.originX + position.x;
    marker.linkY = marker.originY + position.y;

    this.render.emit();
  }
  /**
   * Emitted when a marker is dropped to reset origin.
   * @param $event Drag end event
   * @param marker Marker being dragged
   */
  protected endDrag($event: CdkDragEnd, marker: BlueprintMarker): void {
    marker.originX = undefined;
    marker.originY = undefined;
  }
  /**
   * Checks for marker intersections and moves marker to avoid overlap. Will self-call to check for intersections with markers that have already been placed.
   * @param indexTo Optional index to stop checking for intersections at. Used to avoid checking markers that have not been placed yet.
   */
  private checkMarkerIntersections(indexTo?: number): void {
    const originX = this.marker.linkX;
    const originY = this.marker.linkY;
    this._markers.forEach((intersectCheck, i) => {
      if (intersectCheck === this.marker) return;
      if (indexTo && i >= indexTo) return;
      if (
        this.intersects(this.marker, intersectCheck) ||
        this.nodeIntersects(this.marker, intersectCheck)
      ) {
        let angle = 270;
        let r = 50;
        do {
          const x = originX + r * Math.cos((angle * Math.PI) / 180);
          const y = originY + r * Math.sin((angle * Math.PI) / 180);
          this.marker.linkX = x < 0 ? 0 : x;
          this.marker.linkY = y < 0 ? 0 : y;
          i && this.checkMarkerIntersections(i);
          angle = angle + 45 >= 360 ? 0 : angle + 45;
          if (angle === 180) {
            r += this.marker.height + this.padding;
          }
        } while (
          this.intersects(this.marker, intersectCheck) ||
          this.nodeIntersects(this.marker, intersectCheck)
        );
        this.marker.placed = true;
      }
    });
    const index = this._markers.findIndex((m) => m === this.marker);
    if (this._markers[index + 1]) {
      this._markers[index + 1]._ready = true;
    }
    this.render.emit();
  }
  /**
   * Checks if a marker intersects with another marker's node
   */
  private nodeIntersects(a: BlueprintMarker, b: BlueprintMarker): boolean {
    const isInside = (point: Coords, rect: BlueprintMarker): boolean =>
      point.x > rect.linkX &&
      point.x < rect.linkX + rect.width &&
      point.y > rect.linkY &&
      point.y < rect.linkY + rect.height;
    const boundaryIntersect = (point: Coords, rect: BlueprintMarker): boolean =>
      point.x - 8 > rect.linkX + rect.width + this.padding ||
      point.x + 8 < rect.linkX - this.padding ||
      point.y - 8 > rect.linkY + rect.height + this.padding ||
      point.y + 8 < rect.linkY - this.padding;

    if (Array.isArray(b.nodeCoords)) {
      return b.nodeCoords.some((coord) => {
        return !(!isInside(coord, a) || boundaryIntersect(coord, a));
      });
    } else {
      return !(
        !isInside(b.nodeCoords, a) || boundaryIntersect(b.nodeCoords, a)
      );
    }
  }
  /**
   * Checks if a marker intersects with another marker
   */
  private intersects(a: BlueprintMarker, b: BlueprintMarker): boolean {
    return !(
      b.linkX > a.linkX + a.width + this.padding ||
      b.linkX + b.width < a.linkX - this.padding ||
      b.linkY > a.linkY + a.height + this.padding ||
      b.linkY + b.height < a.linkY - this.padding
    );
  }
}
