/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Speed Unit.
 */
export enum SpeedUnit {
    M_S = 'm/s',
    KM_HR = 'km/hr',
    MPH = 'mph',
    FT_S = 'ft/s',
}
