import { Icons } from '@ansys/awc-angular/icons';
import { AWCTreeItem } from '@ansys/awc-angular/trees';

export const vehicleOverviewTreeView: AWCTreeItem[] = [
  {
    text: 'Aerodynamics',
    id: 'aero',
    prefixIcon: { icon: Icons.STREAM_LINES },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Masses',
    id: 'mass',
    prefixIcon: { icon: Icons.PRESSURE_LOAD },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Wheels',
    id: 'wheel',
    prefixIcon: { icon: Icons.RADIO_ACTIVE },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Ancillary Loads',
    id: 'ancillary_load',
    prefixIcon: { icon: Icons.ACOUSTIC },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Deceleration Limits',
    id: 'deceleration_limit',
    prefixIcon: { icon: Icons.TRACTION_LOAD },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
];

export const componentTreeView: AWCTreeItem[] = [
  {
    text: 'Transmissions',
    id: 'transmission',
    prefixIcon: { icon: Icons.SETTINGS },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Motors',
    id: 'motor',
    prefixIcon: { src: 'assets/icons/Motor.svg' },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Inverter',
    id: 'inverter',
    prefixIcon: { icon: Icons.ELECTRICAL },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Battery',
    id: 'battery',
    prefixIcon: { src: 'assets/icons/battery.svg' },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Disconnect Clutch',
    id: 'clutch',
    prefixIcon: { src: 'assets/icons/clutch.svg' },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
];

export const requirementTreeView: AWCTreeItem[] = [
  {
    text: 'Static Requirements',
    id: 'static_requirement',
    prefixIcon: { icon: Icons.PIN },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Dynamic Requirements',
    id: 'dynamic_requirement',
    prefixIcon: { icon: Icons.ROCKET },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
  {
    text: 'Drive Cycle Requirements',
    id: 'drive_cycle',
    prefixIcon: { icon: Icons.ELECTRICAL },
    expanded: true,
    ignoreSelection: true,
    children: [],
  },
];
