/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Unit of Electrical Power.
 */
export enum ElectricalPowerUnit {
    W = 'W',
    K_W = 'kW',
    VA = 'VA',
    K_VA = 'kVA',
}
