/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Data for transmission loss maps.
 *
 * 2D lists, one list per gear ratio.
 */
export type TransmissionLossMapData = {
    gear_ratios: Array<number>;
    speeds: Array<Array<number>>;
    torques: Array<Array<number>>;
    losses: Array<Array<number>>;
    component_file_type?: TransmissionLossMapData.component_file_type;
};
export namespace TransmissionLossMapData {
    export enum component_file_type {
        TRANSMISSION_LOSS_MAP = 'TransmissionLossMap',
    }
}

