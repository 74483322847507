/* eslint-disable @typescript-eslint/naming-convention */
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ComponentFileType } from 'src/api';

export const motorSelectTypes: AWCListItem[] = [
  {
    text: 'Loss Map',
    id: 'item1',
    userData: {
      type: ComponentFileType.MOTOR_TORQUE_GRID_FILE,
      component_type: 'MotorLossMapID',
      upload: true,
      file_types: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
    },
  },
  {
    text: 'Torque Curves',
    id: 'item2',
    userData: {
      type: ComponentFileType.MOTOR_TORQUE_SPEED_FILE,
      component_type: 'MotorTorqueCurvesID',
      upload: true,
      file_types: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
    },
  },
  {
    text: 'Lab Model',
    id: 'item3',
    userData: {
      type: ComponentFileType.MOTOR_LAB_FILE,
      component_type: 'MotorLabModelID',
      upload: true,
      file_types: ['.lab'],
    },
  },
];
