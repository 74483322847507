<app-input
  label="Mass"
  placeholder="Mass..."
  [value]="part.base_mass ?? part.mass"
  [unit]="UnitType.MASS"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_mass'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'mass', part.id+'_mass'])"></app-input>
<app-input
  label="Center of Mass Horizontal Offset"
  placeholder="Optional..."
  [value]="part.com_horizontal_offset ?? ''"
  [unit]="UnitType.LENGTH"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_com_horizontal_offset'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'com_horizontal_offset', part.id+'_com_horizontal_offset'])"></app-input>

<app-input
  label="Center of Mass Vertical Height"
  placeholder="Optional..."
  [value]="part.com_vertical_height ?? ''"
  [unit]="UnitType.LENGTH"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_com_vertical_height'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'com_vertical_height', part.id+'_com_vertical_height'])"></app-input>

<div>
  <awc-switch 
    [disabled]="readonly"
    [checked]="!!part.add_components_mass"
    [label]="'Add Components Mass'"
    [labelPosition]="labelPosition"
    [size]="size"
    [awcSizing]="awcSizing"
    [awcTooltip]="'Add the components mass'"
    (changes)="toggleIncludeComponentMass($event)"></awc-switch>
</div>