/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Aero } from './Aero';
import type { AncillaryLoad } from './AncillaryLoad';
import type { DecelerationLimit } from './DecelerationLimit';
import type { DriveCycle } from './DriveCycle';
import type { Mass } from './Mass';
import type { WheelInput } from './WheelInput';
/**
 * Drive Cycle Requirement Populated From Database.
 */
export type DriveCycleRequirement = {
    item_type?: DriveCycleRequirement.item_type;
    name?: string;
    description?: string;
    mass?: Mass;
    aero?: Aero;
    wheel?: WheelInput;
    requirement_input_type?: DriveCycleRequirement.requirement_input_type;
    solver_id?: number;
    drive_cycle: DriveCycle;
    deceleration_limit: (DecelerationLimit | null);
    starting_state_of_charge: number;
    shift_delta: number;
    ancillary_load: (AncillaryLoad | null);
    full_range_calculation: boolean;
    range?: (number | null);
};
export namespace DriveCycleRequirement {
    export enum item_type {
        REQUIREMENT = 'requirement',
    }
    export enum requirement_input_type {
        DRIVE_CYCLE = 'drive_cycle',
    }
}

