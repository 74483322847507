import { Axle, DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import { SolvedTransmission, UnitType } from 'src/api';
import * as Utils from './util';
import { Inject } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';

export class TransmissionComponentMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA)
    private markerData: [Coords | Coords[], SolvedTransmission, boolean, Axle],
    private units: ConceptUnitService // coords: Coords | Coords[], // results: any, // display: boolean, // axle: Axle, // converter: (unit: UnitType, value: number) => number
  ) {
    const [coords, results, display, axle] = markerData;

    super(DataType.COMPONENT, MarkerType.TRANSMISSION, [], display, coords);
    this.axle = axle;
    this.updateData([results]);
    const title = results.name
      .replace('(Front) (Left)', '(Front)')
      .replace('(Front) (Right)', '(Front)')
      .replace('(Rear) (Left)', '(Rear)')
      .replace('(Rear) (Right)', '(Rear)');
    this.title = title || 'Transmission';
  }
  override updateData(data: any, index: number = 0): void {
    const results = data.find(
      (c: any) =>
        c.solved_component_type === 'transmission' && c.axle === this.axle
    );

    this.data = [
      {
        label: 'Losses',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.losses[index])
        ),
        unit: UnitType.POWER,
      },
      {
        label: 'Efficiency',
        value:
          toSN(
            Utils.calculateEff(
              results.out_powers[index],
              results.in_powers[index]
            )
          ) + '%',
      },
      { label: 'Gear Ratio', value: toSN(results.gear_ratios[index]) },
    ];
  }
}
