/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MassInDB, UnitType } from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import { LabelPosition, SwitchSize } from '@ansys/awc-angular/forms';
import { ComponentWidth } from '@ansys/awc-angular/core';
export type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-mass-inputs',
  templateUrl: './mass.component.html',
  styleUrls: ['../inputs.scss'],
})
export class MassComponent {
  @Input() part!: MassInDB;
  @Input() update!: boolean;
  @Input() readonly!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  protected labelPosition: LabelPosition = LabelPosition.BEFORE;
  protected size: SwitchSize = SwitchSize.MEDIUM;
  protected awcSizing: ComponentWidth = ComponentWidth.FILL;
  protected toggleIncludeComponentMass(toggle: boolean): void {
    this.part['add_components_mass'] = toggle;
    this.forceUpdate.emit('');
  }
}
