/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PWMFrequencyDefinition } from './PWMFrequencyDefinition';
/**
 * Wrapper for inverter MOSFET model to handle units.
 */
export type InverterMOSFETData = {
    modulation_index?: number;
    dc_harness_resistance?: number;
    ac_harness_resistance?: number;
    switching_energy_on?: number;
    switching_energy_off?: number;
    switching_energy_reverse?: number;
    voltage_ref?: number;
    current_ref?: number;
    pwm_frequency?: number;
    pwm_ratio?: number;
    pwm_definition?: PWMFrequencyDefinition;
    diode_voltage_drop?: number;
    diode_dynamic_resistance?: number;
    drain_source_on_resistance?: number;
    inverter_type?: InverterMOSFETData.inverter_type;
};
export namespace InverterMOSFETData {
    export enum inverter_type {
        MOSFET = 'MOSFET',
    }
}

