@if (!conceptService.loadingErrorMessage()) {
  <div *ngIf="display" class="breadcrumb-cont">
    <div class="breadcrumb-inner">
      <span *ngIf="!loading else loadingSkeleton">{{projectTitle}}</span>
      <span>/</span>
      <span *ngIf="!loading else loadingSkeleton">{{branchTitle}}</span>
      <span>/</span>
      <div *ngIf="!loading else loadingSkeleton"  class="version-title">
        {{versionTitle}}
      </div>
    </div>
  </div>

  <ng-template #loadingSkeleton>
    <awc-loading-skeleton
      [width]="width"
      [lineHeight]="lineHeight"
      [type]="type"
      ></awc-loading-skeleton>
  </ng-template>
}
