/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MotorLossMapData } from './MotorLossMapData';
/**
 * Create Motor from Loss Map.
 */
export type MotorLossMap = {
    item_type?: MotorLossMap.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: MotorLossMap.component_type;
    loss_map: MotorLossMapData;
    poles?: number;
    inverter_losses_included?: boolean;
};
export namespace MotorLossMap {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        MOTOR_LOSS_MAP = 'MotorLossMap',
    }
}

