<ng-container *ngIf="activeResult?.requirement_solved_type !== this.resultType.STATIC; else downloadStaticPoint">
  <div class="group-container">
    <span>Select Points</span>
    <div class="inputs">
      <concept-select
        placeholder="Time Point"
        [items]="pointIndexes"
        [layout]="layout"
        [(selectedItems)]="selectedPointIndex"
        (selectedChanged)="changePointSelected($event)"
        [fillWidth]="true"
      ></concept-select>

      <awc-button
        text="Download Point"
        [type]="type"
        [size]="size"
        [suffixIcon]=downloadIcon
        (clicked)="downloadSingleDataset()"
      ></awc-button>
    </div>
  </div>

  <div class="group-container">
    <span>Download Dataset Range</span>
    <div class="inputs">
      <div style="width: 100%; display: flex; grid-gap: 0.5rem;">
        <app-input placeholder="Start" [value]="rangeStart" (update)="setStartRange($event)" [type]="inputType.NUMBER"></app-input>
        <app-input placeholder="End" [value]="rangeEnd"  (update)="setEndRange($event)" [type]="inputType.NUMBER"></app-input>
      </div>

      <awc-button
        text="Download Range"
        [type]="type"
        [size]="size"
        [suffixIcon]=downloadIcon
        (clicked)="downloadFullDataset({ start: rangeStart, end: rangeEnd})"
      ></awc-button>
    </div>
  </div>

  <div class="group-container">
    <span>Download Full Dataset</span>
    <div class="inputs">
      <awc-button
        text="Download Full Dataset"
        [type]="type"
        [size]="size"
        [suffixIcon]=downloadIcon
        (clicked)="downloadFullDataset()"
      ></awc-button>
    </div>
  </div>
</ng-container>

<ng-template #downloadStaticPoint>
  <div class="group-container">
    <awc-button
      text="Download Point"
      [type]="type"
      [size]="size"
      [suffixIcon]=downloadIcon
      (clicked)="downloadSingleDataset()"
    ></awc-button>
  </div>
</ng-template>
