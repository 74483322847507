/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArchitectureInputIds } from './ArchitectureInputIds';
import type { ComponentInDB } from './ComponentInDB';
import type { ConfigurationInDB } from './ConfigurationInDB';
import type { DriveCycleInDB } from './DriveCycleInDB';
import type { Requirement } from './Requirement';
/**
 * Expanded class with populated members.
 */
export type ConceptPopulated = {
    concept_type?: ConceptPopulated.concept_type;
    _id?: string;
    id?: string;
    name?: string;
    user_id: string;
    project_id: string;
    design_id: string;
    design_instance_id: string;
    architecture_id?: (string | null);
    components_ids: Array<string>;
    configurations_ids: Array<string>;
    requirements_ids: Array<string>;
    jobs_ids: Array<string>;
    capabilities_ids: Array<string>;
    drive_cycles_ids: Array<string>;
    file_items_ids?: Array<string>;
    architecture?: (ArchitectureInputIds | null);
    components?: (Array<ComponentInDB> | null);
    configurations?: (Array<ConfigurationInDB> | null);
    requirements?: (Array<Requirement> | null);
    jobs?: (Array<string> | null);
    drive_cycles?: (Array<DriveCycleInDB> | null);
};
export namespace ConceptPopulated {
    export enum concept_type {
        POPULATED = 'populated',
    }
}

