import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from '@ansys/awc-angular/images';
import { MenuBarComponent } from '@ansys/awc-angular/menus';
import { MenuBarItemComponent } from '@ansys/awc-angular/menus';
import { InputComponent } from '@ansys/awc-angular/forms';
import { ListComponent, ListItemComponent } from '@ansys/awc-angular/lists';
import { CardComponent } from '@ansys/awc-angular/panels';
import { IconComponent } from '@ansys/awc-angular/icons';
import { TableComponent } from '@ansys/awc-angular/tables';
import { TreeComponent } from '@ansys/awc-angular/trees';
import { SelectComponent } from '@ansys/awc-angular/forms';
import { TabsComponent } from '@ansys/awc-angular/tabs';
import { BadgeComponent } from '@ansys/awc-angular/chips';
import { ScientificNotationPipe } from './pipes/scientific-notation/scientific-notation.pipe';
import { UnitFromTypePipe } from './pipes/unit-from-type/unit-from-type.pipe';
import { UnitNameTransformPipe } from './pipes/unit-name/unit-name-transform.pipe';

@NgModule({
  declarations: [
    ScientificNotationPipe,
    UnitFromTypePipe,
    UnitNameTransformPipe,
  ],
  imports: [
    CommonModule,
    LogoComponent,
    InputComponent,
    TabsComponent,
    MenuBarComponent,
    MenuBarItemComponent,
    ListComponent,
    CardComponent,
    IconComponent,
    TableComponent,
    ListItemComponent,
    TreeComponent,
    SelectComponent,
    BadgeComponent,
  ],
  exports: [
    LogoComponent,
    InputComponent,
    TabsComponent,
    MenuBarComponent,
    MenuBarItemComponent,
    ListComponent,
    CardComponent,
    IconComponent,
    TableComponent,
    ListItemComponent,
    TreeComponent,
    SelectComponent,
    BadgeComponent,
    ScientificNotationPipe,
    UnitFromTypePipe,
    UnitNameTransformPipe,
  ],
})
export class SharedModule {}
