<app-input
  label="Efficiency"
  placeholder="Optional..."
  [value]="part.efficiency"
  [unit]="UnitType.RATIO"
  [type]="InputTypes.NUMBER"
  [id]="part.component_type+'_efficiency'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'efficiency', part.component_type+'_efficiency'])"></app-input>
<app-input
  label="Switch Energy"
  placeholder="Optional..."
  [value]="part.switch_energy"
  [unit]="UnitType.ENERGY"
  [type]="InputTypes.NUMBER"
  [id]="part.component_type+'_switch_energy'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'switch_energy', part.component_type+'_switch_energy'])"></app-input>
<app-input
  label="Engaged Power"
  placeholder="Optional..."
  [value]="part.engaged_power"
  [unit]="UnitType.POWER"
  [type]="InputTypes.NUMBER"
  [id]="part.component_type+'_engaged_power'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'engaged_power', part.component_type+'_engaged_power'])"></app-input>
  <div class="two-col-inputs">
    <app-input
      label="Cost"
      placeholder="Optional..."
      [value]="part.cost"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_cost'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'cost', part.component_type+'_cost'])"></app-input>
    <app-input
      label="Mass"
      placeholder="Optional..."
      [value]="part.mass"
      [unit]="UnitType.MASS"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_mass'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'mass', part.component_type+'_mass'])"></app-input>
  </div>
