<div class="flex f-col inputs" *ngIf="result; else noResult" [style.maxHeight]="maxHeight() + 'px'">
  <div class="flex">
    <div class="input-cont f-col flex p1rem">
      <app-input
        label="Outcome Message"
        [value]="result.outcome_message"
        [type]="InputTypes.TEXT"
        [readonly]="true"></app-input>

      <ng-container *ngIf="result.requirement_solved_type === ResultType.STATIC">
        <div class="two-col">
          <app-input
            label="Acceleration"
            [value]="units.convertUnit(UnitType.ACCELERATION, requirement.acceleration)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.ACCELERATION"
            [readonly]="true"></app-input>
          <app-input
            label="Speed"
            [value]="units.convertUnit(UnitType.SPEED, requirement.speed)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.SPEED"
            [readonly]="true"></app-input>
          <app-input
            label="Voltage"
            [value]="units.convertUnit(UnitType.VOLTAGE, requirement.voltage_oc)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.VOLTAGE"
            [readonly]="true"></app-input>
          <app-input
            label="SOC"
            [value]="units.convertUnit(UnitType.RATIO, requirement.state_of_charge)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.RATIO"
            [readonly]="true"></app-input>
          <app-input
            label="Altitude"
            [value]="units.convertUnit(UnitType.LENGTH, requirement.altitude)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.LENGTH"
            [readonly]="true"></app-input>
          <app-input
            label="Total Tractive Power"
            [value]="units.convertUnit(UnitType.POWER, requirement.total_tractive_power)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.POWER"
            [readonly]="true"></app-input>
          <app-input
            label="Total Tractive Torque"
            [value]="units.convertUnit(UnitType.TORQUE, requirement.total_tractive_torque)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.TORQUE"
            [readonly]="true"></app-input>
          <app-input
            label="Gradient"
            [value]="units.convertUnit(UnitType.GRADIENT, requirement.gradient)"
            [unit]="UnitType.GRADIENT"
            [readonly]="true"></app-input>
          <app-input
            label="Headwind"
            [value]="units.convertUnit(UnitType.SPEED, requirement.headwind)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.SPEED"
            [readonly]="true"></app-input>
          <app-input
            label="Deceleration Limit"
            [value]="units.convertUnit(UnitType.ACCELERATION, requirement.deceleration_limit?.limit)"
            [unit]="UnitType.ACCELERATION"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <ng-template [ngTemplateOutlet]="componentMassCost"></ng-template>
        </div>
        <awc-accordion
          label="Forces"
          [isOpened]="false"
          [backgroundColor]="backgroundColor">
          <div class="header" slot="header-slot">
            <awc-icon [icon]="Icons.LOAD"></awc-icon>
          </div>
          <div class="content" slot="body-slot">
            <div class="two-col">
              <app-input
                label="Mass"
                [value]="units.convertUnit(UnitType.FORCE, requirement.mass_force)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.FORCE"
                [readonly]="true"></app-input>
              <app-input
                label="Rolling Resistance"
                [value]="units.convertUnit(UnitType.FORCE, requirement.rolling_resistance_force)"
                [unit]="UnitType.FORCE"
                [type]="InputTypes.NUMBER"
                [readonly]="true"></app-input>
              <app-input
                label="Aerodynamic"
                [value]="units.convertUnit(UnitType.FORCE, requirement.aero_force)"
                [unit]="UnitType.FORCE"
                [type]="InputTypes.NUMBER"
                [readonly]="true"></app-input>
              <app-input
                label="Total"
                [value]="units.convertUnit(UnitType.FORCE , requirement.total_force)"
                [unit]="UnitType.FORCE"
                [type]="InputTypes.NUMBER"
                [readonly]="true"></app-input>
            </div>
          </div>
        </awc-accordion>

        <ng-template [ngTemplateOutlet]="configurations"></ng-template>
      </ng-container>

      <ng-container *ngIf="result.requirement_solved_type === ResultType.DYNAMIC">
        <div class="two-col">
          <app-input
            *ngIf="!requirement.max_capability"
            label="Base Speed"
            [value]="units.convertUnit(UnitType.SPEED, requirement.base_speed)"
            [unit]="UnitType.SPEED"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            *ngIf="!requirement.max_capability"
            label="Base Speed Ratio"
            [value]="requirement.base_speed_ratio"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Distance"
            [value]="units.convertUnit(UnitType.DISTANCE, requirement.end_distance)"
            [unit]="UnitType.DISTANCE"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Time"
            [value]="units.convertUnit(UnitType.TIME, requirement.end_time)"
            [unit]="UnitType.TIME"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            *ngIf="requirement.max_capability"
            label="Required Distance"
            [value]="units.convertUnit(UnitType.DISTANCE, dynamicRequiredDistance ?? 0)"
            [unit]="UnitType.DISTANCE"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            *ngIf="requirement.max_capability"
            label="Required Time"
            [value]="units.convertUnit(UnitType.TIME, dynamicRequiredTime ?? 0)"
            [unit]="UnitType.TIME"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Start Speed"
            [value]="units.convertUnit(UnitType.SPEED, requirement.from_speed)"
            [unit]="UnitType.SPEED"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="End Speed"
            [value]="units.convertUnit(UnitType.SPEED, requirement.to_speed)"
            [unit]="UnitType.SPEED"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="No. of Points"
            [value]="requirement.no_of_points"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            *ngIf="!requirement.max_capability"
            label="Time Step"
            [value]="requirement.time_step"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Voltage"
            [value]="units.convertUnit(UnitType.VOLTAGE, requirement.voltage_oc)"
            [unit]="UnitType.VOLTAGE"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="SOC"
            [value]="units.convertUnit(UnitType.RATIO, requirement.state_of_charge)"
            [unit]="UnitType.RATIO"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
        </div>
        <ng-template [ngTemplateOutlet]="componentMassCost"></ng-template>
        <ng-template [ngTemplateOutlet]="configurations"></ng-template>
      </ng-container>

      <ng-container *ngIf="result.requirement_solved_type === ResultType.DRIVE_CYCLE">
        <div class="wrapping-inputs">
          <app-input
            label="SOC"
            [value]="units.convertUnit(UnitType.RATIO, requirement.starting_state_of_charge)"
            [unit]="UnitType.RATIO"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Shift Delta"
            [value]="units.convertUnit(UnitType.ELECTRICAL_POWER, requirement.shift_delta)"
            [unit]="UnitType.ELECTRICAL_POWER"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Ancillary Load"
            *ngIf="requirement.ancillary_load"
            [value]="units.convertUnit(UnitType.ELECTRICAL_POWER, requirement.ancillary_load?.load)"
            [unit]="UnitType.ELECTRICAL_POWER"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Deceleration Limit"
            *ngIf="requirement.deceleration_limit"
            [value]="units.convertUnit(UnitType.ACCELERATION, requirement.deceleration_limit?.limit)"
            [unit]="UnitType.ACCELERATION"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Vehicle Range"
            [value]="units.convertUnit(UnitType.DISTANCE, result.vehicle_range)"
            [unit]="UnitType.DISTANCE"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            *ngIf="requirement.range"
            label="Target Range"
            [value]="units.convertUnit(UnitType.DISTANCE, requirement.range)"
            [unit]="UnitType.DISTANCE"
            [type]="InputTypes.NUMBER"
            [readonly]="true"></app-input>
          <app-input
            label="Efficiency"
            [value]="units.convertUnit(UnitType.ROAD_EFFICIENCY, result.efficiency)"
            [type]="InputTypes.NUMBER"
            [unit]="UnitType.ROAD_EFFICIENCY"
            [readonly]="true"></app-input>
        </div>
        <ng-template [ngTemplateOutlet]="componentMassCost"></ng-template>

        <awc-accordion
          label="Totals"
          [isOpened]="false"
          [backgroundColor]="backgroundColor">
          <div class="header" slot="header-slot">
          </div>
          <div class="content" slot="body-slot">
            <div class="two-col">
              <app-input
                label="Energy Consumed"
                [value]="units.convertUnit(UnitType.ELECTRICAL_ENERGY, result.total_values.energy_consumed)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.ELECTRICAL_ENERGY"
                [readonly]="true"></app-input>
              <app-input
                label="Energy Recovered"
                [value]="units.convertUnit(UnitType.ELECTRICAL_ENERGY, result.total_values.energy_recovered)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.ELECTRICAL_ENERGY"
                [readonly]="true"></app-input>
            </div>
            <div class="two-col">
              <app-input
                label="Net Energy Consumed"
                [value]="units.convertUnit(UnitType.ELECTRICAL_ENERGY, result.total_values.net_energy_consumed)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.ELECTRICAL_ENERGY"
                [readonly]="true"></app-input>
              <app-input
                label="Aero Contribution"
                [value]="units.convertUnit(UnitType.ELECTRICAL_ENERGY, result.total_values.aero_contribution)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.ELECTRICAL_ENERGY"
                [readonly]="true"></app-input>

            </div>
            <div class="two-col">
              <app-input
                label="Ancillary Load"
                [value]="units.convertUnit(UnitType.ELECTRICAL_ENERGY, result.total_values.ancillary_load)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.ELECTRICAL_ENERGY"
                [readonly]="true"></app-input>
              <app-input
                label="Mass Contribution"
                [value]="units.convertUnit(UnitType.ELECTRICAL_ENERGY, result.total_values.mass_contribution)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.ELECTRICAL_ENERGY"
                [readonly]="true"></app-input>

            </div>
              <app-input
                label="Rolling Resistance Contribution"
                [value]="units.convertUnit(UnitType.ELECTRICAL_ENERGY, result.total_values.rolling_resistance_contribution)"
                [type]="InputTypes.NUMBER"
                [unit]="UnitType.ELECTRICAL_ENERGY"
                [readonly]="true"></app-input>
              </div>
            </awc-accordion>
            <awc-accordion
              label="Total Losses by Component"
              [isOpened]="false"
              [backgroundColor]="backgroundColor">
              <div class="content total-losses" slot="body-slot">
                <ng-container *ngFor="let item of result.total_values.loss_by_component | keyvalue">
                  <app-input
                    [label]="''+item.key"
                    [value]="''+item.value || ''"
                    [value]="item.value ? units.convertUnit(UnitType.ELECTRICAL_ENERGY, number(item.value)) : ''"
                    [type]="InputTypes.NUMBER"
                    [unit]="UnitType.ELECTRICAL_ENERGY"
                    [readonly]="true"></app-input>
                </ng-container>
              </div>
            </awc-accordion>
        <ng-template [ngTemplateOutlet]="configurations"></ng-template>

      </ng-container>
    </div>
  </div>
</div>

<!-- cost & mass -->
<ng-template #componentMassCost>
  <div class="two-col">
    <app-input
      *ngIf="componentCost"
      label="Total Component Cost"
      [value]="componentCost"
      [type]="InputTypes.NUMBER"
      [readonly]="true"></app-input>
    <app-input
      *ngIf="componentMass"
      label="Total Component Mass"
      [value]="units.convertUnit(UnitType.MASS, componentMass)"
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.MASS"
      [readonly]="true"></app-input>
  </div>
</ng-template>
<!-- configurations -->
<ng-template #configurations>
  <awc-accordion
    label="Aerodynamic Configuration"
    [isOpened]="false"
    [backgroundColor]="backgroundColor">
    <div class="header" slot="header-slot">
      <awc-icon [icon]="Icons.STREAM_LINES"></awc-icon>
    </div>
    <div class="content" slot="body-slot">
      <div class="two-col">
        <app-input
          label="Cross Sectional Area"
          [value]="aero.cross_sectional_area ? units.convertUnit(UnitType.AREA, aero.cross_sectional_area) : ''"
          [type]="InputTypes.NUMBER"
          [unit]="UnitType.AREA"
          [readonly]="true"></app-input>
        <app-input
          label="Drag Coefficient"
          [value]="aero.drag_coefficient || ''"
          [type]="InputTypes.NUMBER"
          [readonly]="true"></app-input>
      </div>
    </div>
  </awc-accordion>
  <awc-accordion
    label="Mass Configuration"
    [isOpened]="false"
    [backgroundColor]="backgroundColor">
    <div class="header" slot="header-slot">
      <awc-icon [icon]="Icons.PRESSURE_LOAD"></awc-icon>
    </div>
    <div class="content" slot="body-slot">
      <div class="two-col">
        <app-input
          label="Base Mass"
          [value]="mass.mass || mass.base_mass ? units.convertUnit(UnitType.MASS, mass.base_mass || mass.mass || 0) : ''"
          [type]="InputTypes.NUMBER"
          [unit]="UnitType.MASS"
          [readonly]="true"></app-input>
        <app-input
          label="Total Mass"
          [value]="units.convertUnit(UnitType.MASS, (mass.base_mass ?? mass.mass ?? 0) + (mass.add_components_mass ? componentMass : 0))"
          [type]="InputTypes.NUMBER"
          [unit]="UnitType.MASS"
          [readonly]="true"></app-input>
      </div>
    </div>
  </awc-accordion>
  <awc-accordion
    label="Wheel Configuration"
    [isOpened]="false"
    [backgroundColor]="backgroundColor">
    <div class="header" slot="header-slot">
      <awc-icon [icon]="Icons.RADIO_ACTIVE"></awc-icon>
    </div>
    <div class="content" slot="body-slot">
      <div class="two-col">
        <app-input
          label="Rolling Radius"
          [value]="wheel.rolling_radius ? units.convertUnit(UnitType.LENGTH, wheel.rolling_radius) : ''"
          [type]="InputTypes.NUMBER"
          [unit]="UnitType.LENGTH"
          [readonly]="true"></app-input>
        <app-input
          label="Rolling Resistance"
          [value]="wheel.rolling_resistance_coefficient || ''"
          [type]="InputTypes.NUMBER"
          [readonly]="true"></app-input>
        <app-input
          label="Traction Coefficient"
          [value]="wheel.traction_coefficient || ''"
          [type]="InputTypes.NUMBER"
          [readonly]="true"></app-input>
      </div>
    </div>
  </awc-accordion>
</ng-template>

<ng-template #noResult>
  <div class="no-results">
    <awf-no-results></awf-no-results>
    <span>No Selection</span>
  </div>
</ng-template>
