import { AWCListContent } from '@ansys/awc-angular/lists';
import { MassUnit } from 'src/api';

export const mass: AWCListContent[] = [
  {
    text: 'kg',
    id: MassUnit.KG,
  },
  {
    text: 'g',
    id: MassUnit.G,
  },
  {
    text: 'lb',
    id: MassUnit.LB,
  },
  {
    text: 'oz',
    id: MassUnit.OZ,
  },
  {
    text: 'tn',
    id: MassUnit.TN,
  },
  {
    text: 't',
    id: MassUnit.T,
  },
  {
    text: 'lt',
    id: MassUnit.LT,
  },
];
