/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Ancillary load with Database ID.
 */
export type AncillaryLoadInDB = {
    item_type?: AncillaryLoadInDB.item_type;
    name?: string;
    load?: number;
    config_type?: 'ancillary_load';
    _id?: string;
};
export namespace AncillaryLoadInDB {
    export enum item_type {
        CONFIG = 'config',
    }
}

