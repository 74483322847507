import { Axle, DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import { SolvedMotor, UnitType } from 'src/api';
import { Inject } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';

export class MotorElectricalMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA)
    private markerData: [Coords | Coords[], SolvedMotor, boolean, Axle],
    private units: ConceptUnitService
  ) {
    const [coords, results, display, axle] = markerData;
    super(DataType.ELECTRICAL, MarkerType.MOTOR, [], display, coords);
    this.axle = axle;
    this.updateData([results]);
    this.title = results.name || 'Motor';
  }
  override updateData(data: any, index: number = 0): void {
    const results: SolvedMotor = data.find(
      (c: any) => c.solved_component_type === 'motor' && c.axle === this.axle
    );
    this.data = [
      {
        label: 'I',
        value: toSN(
          this.units.convertUnit(
            UnitType.CURRENT,
            results.currents?.[index] ?? 0
          )
        ),
      },
      {
        label: 'V',
        value: toSN(
          this.units.convertUnit(UnitType.VOLTAGE, results.in_voltages[index])
        ),
        unit: UnitType.VOLTAGE,
      },
      {
        label: 'P',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.in_powers[index])
        ),
        unit: UnitType.POWER,
      },
    ];
  }
}
