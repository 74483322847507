/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UnitType } from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import { IconType, Icons } from '@ansys/awc-angular/icons';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { transmissionComponentType } from '../../../../../shared/models/concept-components/transmission.model';
export type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-transmission-inputs',
  templateUrl: './transmission.component.html',
  styleUrls: ['../inputs.scss'],
})
export class TransmissionComponent {
  @Input() part!: transmissionComponentType;
  @Input() update!: boolean;
  @Input() readonly!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  protected addIcon: IconType = { icon: Icons.ADD };
  protected delIcon: IconType = { icon: Icons.DELETE };
  protected alttype: ButtonType = ButtonType.SECONDARY;
  protected smallsize: ButtonSize = ButtonSize.SMALL;
  protected normalsize: ButtonSize = ButtonSize.LARGE;
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  /**
   * Add a new gear ratio and efficiency to the part
   */
  protected addGear(): void {
    if (this.part.default_values?.gear_ratios) {
      this.part.gear_ratios?.push(this.part.default_values.gear_ratios[0]);
    }
    if (this.part.default_values?.headline_efficiencies) {
      this.part.headline_efficiencies?.push(
        this.part.default_values?.headline_efficiencies[0]
      );
    }
    if (this.part.default_values?.friction_ratios) {
      this.part.friction_ratios?.push(
        this.part.default_values?.friction_ratios[0]
      );
    }
    if (this.part.default_values?.static_drags) {
      this.part.static_drags?.push(this.part.default_values?.static_drags[0]);
    }
    this.forceUpdate.emit('gear_ratios');
  }
  protected removeGear(index: number): void {
    this.part.gear_ratios?.splice(index, 1);
    this.part.headline_efficiencies?.splice(index, 1);
    this.part.friction_ratios?.splice(index, 1);
    this.part.static_drags?.splice(index, 1);

    if (this.part.gear_ratios && this.part.gear_ratios.length <= 1) {
      this.part.shift_time = 0;
    }
    this.forceUpdate.emit('gear_ratios');
  }
}
