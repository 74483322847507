<ng-container *ngIf="display">
  <div class="tb-cont">
    <awc-menu-bar
    [items]="items"
    [display]="displayBar"
    [layout]="layout"></awc-menu-bar>
  </div>

</ng-container>

