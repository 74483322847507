/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Data for a lookup table battery.
 */
export type BatteryLookupTableData = {
    voltage: Array<number>;
    state_of_charge: Array<number>;
    usable_charge: Array<number>;
    power_limit_charge: Array<number>;
    internal_resistance: Array<number>;
    component_file_type?: BatteryLookupTableData.component_file_type;
};
export namespace BatteryLookupTableData {
    export enum component_file_type {
        BATTERY_LOOKUP_TABLE = 'BatteryLookupTable',
    }
}

