export enum ConceptEnum {
  TAB_TYPE = 'concept',
  OVERVIEW_SECTION = 'overview-section',
  OVERVIEW_SECTION_CONTENT = 'overview-section-content',
  REQUIREMENT_SECTION = 'requirement-section',
  REQUIREMENT_SECTION_CONTENT = 'requirement-section-content',
  ARCHITECTURE_SECTION = 'architecture-section',
  ARCHITECTURE_SECTION_CONTENT = 'architecture-section-content',
  CONFIGURATIONS_SECTION = 'configurations-section',
  CONFIGURATIONS_SECTION_CONTENT = 'configurations-section-content',
  COMPONENT_SECTION = 'component-section',
  COMPONENT_SECTION_CONTENT = 'component-section-content',
}
