import { AWCListContent } from '@ansys/awc-angular/lists';
import { AngleUnit } from 'src/api';

export const gradient: AWCListContent[] = [
  {
    text: 'deg',
    id: AngleUnit.DEG,
  },
  {
    text: 'rad',
    id: AngleUnit.RAD,
  },
];
