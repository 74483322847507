/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UnitType, DynamicRequirementInputsIds } from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import { ComponentWidth, FlexLayout } from '@ansys/awc-angular/core';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import { LabelPosition, SwitchSize } from '@ansys/awc-angular/forms';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
export type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-dynamic-requirements-inputs',
  templateUrl: './dynamic-requirements.component.html',
  styleUrls: ['../inputs.scss'],
})
export class DynamicRequirementsComponent implements OnInit {
  @Input() part!: DynamicRequirementInputsIds;
  @Input() update!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected aerodynamics: AWCListItem[] = [];
  protected massses: AWCListItem[] = [];
  protected wheels: AWCListItem[] = [];

  protected selectedAero: string[] = [];
  protected selectedMass: string[] = [];
  protected selectedWheel: string[] = [];
  protected labelPosition: LabelPosition = LabelPosition.BEFORE;
  protected size: SwitchSize = SwitchSize.MEDIUM;
  protected awcSizing: ComponentWidth = ComponentWidth.FILL;
  protected awcTooltip: string = 'Include Losses';
  constructor(private configurationService: ConfigurationsService) {}
  ngOnInit(): void {
    this.selectedAero = [this.part.aero_id];
    this.selectedMass = [this.part.mass_id];
    this.selectedWheel = [this.part.wheel_id];
    [this.aerodynamics, this.massses, this.wheels] =
      this.configurationService.configurationLists.map((list) => {
        return list.map((item) => {
          const clone = { ...item };
          delete (clone as AWCTreeItem).parent;
          return clone;
        });
      });
  }
  protected selectionIDSet($event: string, type: string): void {
    this.part[type as keyof DynamicRequirementInputsIds] = $event as never;
    this.forceUpdate.emit('');
  }
  protected toggleMC($event: boolean): void {
    this.part.max_capability = $event;
    this.forceUpdate.emit('');
  }
}
