/* eslint-disable @typescript-eslint/naming-convention */
import { StaticRequirementAccelerationIds } from 'src/api';
import { RequirementType } from '../../enums';

export const staticRequirement: StaticRequirementAccelerationIds = {
  requirement_type: RequirementType.STATIC_ACCELERATION,
  mass_id: '',
  aero_id: '',
  wheel_id: '',
};
