import { AWCListContent } from '@ansys/awc-angular/lists';
import { SpeedUnit } from 'src/api';

export const speed: AWCListContent[] = [
  {
    text: 'km/hr',
    id: SpeedUnit.KM_HR,
  },
  {
    text: 'm/s',
    id: SpeedUnit.M_S,
  },
  {
    text: 'mph',
    id: SpeedUnit.MPH,
  },
  {
    text: 'ft/s',
    id: SpeedUnit.FT_S,
  },
];
