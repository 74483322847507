import { Inject, Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { ConceptPopulated, DriveCyclesService, UnitType } from '../../../api';
import { lastValueFrom } from 'rxjs';
import { SelectedItemState } from '../../state/lib/selected-item.state';
import { ConceptSections } from '../../shared/enums';
import { ActiveConceptState } from '../../state/lib/active-concept.state';
import { State } from '@ansys/andromeda/store';
import { PlotService } from '../../shared/services/plot.service';
import { ConceptUnitService } from '../../shared/services/unit.service';
import { AWF_LOCATION, AWFLocation } from '@ansys/andromeda/shared';
import { driveCyclePlotModel } from '../../shared/models/plot-data/drive-cycle-plot.model';
import { ActiveSectionState } from '@ansys/andromeda/workspace';

type InputType = [id: string, parentId: string];
type ReturnType = boolean;

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class GetDriveCycleDataAction
  implements AWFAction<InputType, ReturnType>
{
  // This can be used to track end user operations and can be used to analyze the user behavior.
  readonly reportAnalysis = false;
  private conceptSignal = this.state.signal(ActiveConceptState);

  constructor(
    private state: State,
    private driveCycleService: DriveCyclesService,
    private plotService: PlotService,
    private unitChoices: ConceptUnitService,
    @Inject(AWF_LOCATION) private location: AWFLocation,
    private actions: ActionContributionsService
  ) {}

  async execute(context: InputType): Promise<ReturnType> {
    const [id, parentId] = context;
    const instanceID = (this.conceptSignal() as ConceptPopulated)
      .design_instance_id as string;
    const data = await lastValueFrom(
      this.driveCycleService.readDriveCyclesItemIdGet(id, instanceID)
    );
    let maxSpeed = 0;
    let maxAcceleration = 0;
    let minAcceleration = Infinity;
    const xAxis: number[] = [];
    const yAxisSpeed: number[] = [];
    const yAxisAcceleration: number[] = [];
    let duration: number = 0;

    data.points?.forEach((point) => {
      duration += point.duration;
      maxSpeed = Math.max(maxSpeed, point.speed);
      maxAcceleration = Math.max(maxAcceleration, point.acceleration);
      minAcceleration = Math.min(minAcceleration, point.acceleration);
      yAxisSpeed.push(point.speed);
      yAxisAcceleration.push(point.acceleration);
      xAxis.push(duration);
    });

    const plotData = driveCyclePlotModel;
    plotData[0].x = xAxis;
    plotData[0].y = yAxisSpeed;
    plotData[1].x = xAxis;
    plotData[1].y = yAxisAcceleration;

    const activeSection = this.state.value(ActiveSectionState);
    const currentSelection = this.state.value(
      SelectedItemState,
      activeSection?.id
    );

    if (
      currentSelection?.id === parentId &&
      this.location.section?.type === ConceptSections.REQUIREMENT
    ) {
      this.plotService.setGraphData(plotData);
      this.plotService.updateGraphLayout({
        xaxis: {
          range: [0, duration],
          title: `Time (${this.unitChoices.getChoice(UnitType.TIME)})`,
        },
        yaxis: {
          range: [0, maxSpeed],
          title: `Speed (${this.unitChoices.getChoice(UnitType.SPEED)})`,
        },
        yaxis2: {
          side: 'right',
          overlaying: 'y',
          mirror: 'ticks',
          range: [minAcceleration, maxAcceleration],
          title: `Acceleration (${this.unitChoices.getChoice(
            UnitType.ACCELERATION
          )})`,
          zeroline: false,
          tickmode: 'sync' as any, // Type assertion here is necessary  because the type definition is incorrect
          rangemode: 'tozero',
          showgrid: true,
        },
      });
      return true;
    }
    return false;
  }
}
