import {
  Aero,
  WheelInput,
  Mass,
  BatteryFixedVoltages,
  MotorCTCP,
  MotorTorqueCurves,
  MotorLossMap,
  TransmissionLossCoefficients,
  TransmissionLossMap,
  StaticRequirementAccelerationIds,
  DynamicRequirementInputsIds,
  DisconnectClutchInputInDB,
  InverterAnalytical,
  MotorLossMapID,
  TransmissionLossMapID,
  MotorLabID,
  MotorTorqueCurvesID,
} from 'src/api';

export type ConceptPartType =
  | Aero
  | WheelInput
  | Mass
  | BatteryFixedVoltages
  | MotorCTCP
  | MotorTorqueCurves
  | MotorLossMap
  | MotorLossMapID
  | MotorLabID
  | MotorTorqueCurvesID
  | TransmissionLossCoefficients
  | TransmissionLossMap
  | TransmissionLossMapID
  | StaticRequirementAccelerationIds
  | DynamicRequirementInputsIds
  | DisconnectClutchInputInDB
  | InverterAnalytical;
