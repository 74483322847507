/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Torque Unit.
 */
export enum TorqueUnit {
    N_M = 'N·m',
    FT_LBF = 'ft·lbf',
    K_N_M = 'kN·m',
    MN_M = 'MN·m',
    DYN_CM = 'dyn·cm',
}
