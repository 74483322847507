/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Unit Types.
 */
export enum UnitType {
    MASS = 'mass',
    TIME = 'time',
    FORCE = 'force',
    TORQUE = 'torque',
    TEMPERATURE = 'temperature',
    LENGTH = 'length',
    DISTANCE = 'distance',
    AREA = 'area',
    VOLUME = 'volume',
    SPEED = 'speed',
    ACCELERATION = 'acceleration',
    ANGULAR_SPEED = 'angular_speed',
    ANGULAR_ACCELERATION = 'angular_acceleration',
    ENERGY = 'energy',
    POWER = 'power',
    DENSITY = 'density',
    INERTIA = 'inertia',
    PRESSURE = 'pressure',
    RATIO = 'ratio',
    VOLTAGE = 'voltage',
    CURRENT = 'current',
    RESISTANCE = 'resistance',
    ELECTRIC_CHARGE = 'electric_charge',
    ELECTRICAL_ENERGY = 'electrical_energy',
    ELECTRICAL_POWER = 'electrical_power',
    GRADIENT = 'gradient',
    ROAD_EFFICIENCY = 'road_efficiency',
    FREQUENCY = 'frequency',
}
