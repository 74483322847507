import { StateNode } from '@ansys/andromeda/store';
import { ConfigurationEnvironment } from 'src/app/shared/services/configurations.service';

export class ConfigurationEnvState
  implements StateNode<ConfigurationEnvironment>
{
  key: string = 'configurationEnv';
  defaultValue: () => ConfigurationEnvironment = () => {
    return {
      gradient: 0,
      speed: 50,
      acceleration: 0,
      altitude: 0,
      headwind: 0,
      stepsize: 0.2,
    };
  };
}
