/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Current Unit.
 */
export enum CurrentUnit {
    A = 'A',
    M_A = 'mA',
    K_A = 'kA',
}
