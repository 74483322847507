<ng-container *ngIf="part?.component_type === 'BatteryFixedVoltages'">
  <div class="two-col-inputs">
    <app-input
      label="Min Voltage"
      placeholder="Optional..."
      [value]="part.voltage_min"
      [unit]="UnitType.VOLTAGE"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_voltage_min'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'voltage_min', part.component_type+'_voltage_min'])"></app-input>
    <app-input
      label="Max Voltage"
      placeholder="Max Voltage..."
      [value]="part.voltage_max"
      [unit]="UnitType.VOLTAGE"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_voltage_max'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'voltage_max', part.component_type+'_voltage_max'])"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Charge Acceptance Limit"
      placeholder="Optional..."
      [value]="part.charge_acceptance_limit"
      [unit]="UnitType.ELECTRICAL_POWER"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_charge_acceptance_limit'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'charge_acceptance_limit', part.component_type+'_charge_acceptance_limit'])"></app-input>
    <app-input
      label="Internal Resistance"
      placeholder="Resistance..."
      [value]="part.internal_resistance"
      [unit]="UnitType.RESISTANCE"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_internal_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'internal_resistance', part.component_type+'_internal_resistance'])"></app-input>
    <app-input
      label="Capacity"
      placeholder="Optional..."
      [value]="part.capacity"
      [unit]="UnitType.ELECTRICAL_ENERGY"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_capacity'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'capacity', part.component_type+'_capacity'])"></app-input>
  </div>
</ng-container>
<div class="two-col-inputs">
  <app-input
    label="Cost"
    placeholder="Optional..."
    [value]="part.cost"
    [type]="InputTypes.NUMBER"
    [id]="part.component_type+'_cost'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart.emit([''+$event, 'cost', part.component_type+'_cost'])"></app-input>
  <app-input
    label="Mass"
    placeholder="Optional..."
    [value]="part.mass"
    [unit]="UnitType.MASS"
    [type]="InputTypes.NUMBER"
    [id]="part.component_type+'_mass'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart.emit([''+$event, 'mass', part.component_type+'_mass'])"></app-input>
</div>
