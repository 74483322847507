import { Icons } from '@ansys/awc-angular/icons';
import { ConceptSubSection } from '../enums/concept.enum';
import { SectionCardTab } from 'src/app/components/layout/section-card/types/section-card-tab.type';

export const requirementSections: SectionCardTab[] = [
  {
    id: ConceptSubSection.MAIN,
    text: 'Requirements',
    prefixIcon: { icon: Icons.PLOTS },
    slotName: ConceptSubSection.MAIN,
  },
  {
    id: ConceptSubSection.RESULTS,
    text: 'Results',
    prefixIcon: { icon: Icons.RESULTS },
    slotName: ConceptSubSection.RESULTS,
  },
];
