import { AWCListContent } from '@ansys/awc-angular/lists';
import { InertiaUnit } from 'src/api';

export const inertia: AWCListContent[] = [
  {
    text: 'kg·m²',
    id: InertiaUnit.KG_M_,
  },
  {
    text: 'g·mm²',
    id: InertiaUnit.G_MM_,
  },
];
