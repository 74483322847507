import { PartType } from 'src/app/shared/enums';
import { batterySelectTypes } from 'src/app/shared/models/lists/select-options/battery-types.model';
import { inverterSelectTypes } from 'src/app/shared/models/lists/select-options/inverter-types.model';
import { motorSelectTypes } from 'src/app/shared/models/lists/select-options/motor-types.model';
import { transmissionSelectTypes } from 'src/app/shared/models/lists/select-options/tranmission-types.model';

export const selectItemMap = new Map([
  [PartType.AERO, []],
  [PartType.MASS, []],
  [PartType.WHEEL, []],
  [PartType.INVERTER, inverterSelectTypes],
  [PartType.ANCILLARY, []],
  [PartType.DECELERATION, []],
  [PartType.CLUTCH, []],
  [PartType.BATTERY, batterySelectTypes],
  [PartType.MOTOR, motorSelectTypes],
  [PartType.TRANSMISSION, transmissionSelectTypes],
  [PartType.STATIC_REQUIREMENT, []],
  [PartType.DYNAMIC_REQUIREMENT, []],
  [PartType.DRIVE_CYCLE, []],
]);
