import { Injectable } from '@angular/core';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { AWFLocationMeta, AWFQueryInterceptor } from '@ansys/andromeda/shared';
import { State } from '@ansys/andromeda/store';
import { DesignCreateAction } from 'src/app/actions/lib/design-create.action';
import { Sections, Tabs } from '@ansys/andromeda/dashboard';

@Injectable({
  providedIn: 'root',
})
export class ConceptUrlInterceptor implements AWFQueryInterceptor {
  constructor(
    private actions: ActionContributionsService,
    private state: State
  ) {}

  queryInterceptor = (query: Map<string, string>): AWFLocationMeta => {
    const projectId = query.get('createProjectFile');

    let locationResponse = {};
    if (projectId) {
      this.actions.execute(DesignCreateAction, projectId);

      locationResponse = {
        tab: { type: Tabs.DASHBOARD },
        section: { type: Sections.DASHBOARD_PROJECTS, params: [projectId] },
      };
    }

    query;
    return locationResponse;
  };
}
