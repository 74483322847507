/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubmittedJob } from './SubmittedJob';
/**
 * Motor Lab with the data referenced by ID.
 */
export type BatteryLookupTableID = {
    item_type?: BatteryLookupTableID.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    _id?: string;
    id?: string;
    data_id?: (string | null);
    submitted_job?: (SubmittedJob | null);
    component_type?: 'BatteryLookupTableID';
};
export namespace BatteryLookupTableID {
    export enum item_type {
        COMPONENT = 'component',
    }
}

