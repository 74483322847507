<awc-select
  label="Drive Cycle"
  placeholder="Drive Cycle..."
  [items]="drivecycles"
  [layout]="layout"
  [selectedItems]="selectedDC"
  [iconButton]="iconButton"
  (iconButtonAction)="addDriveCycle()"
  (selectionChanged)="selectionIDSet($event[0].id, 'drive_cycle_id')"></awc-select>
<input #fileUpload type="file" (click)="uploadClick()" (change)="importDriveCycle($event)" style="display:none" accept=".csv">
<awc-select
  label="Aerodynamics"
  placeholder="Aerodynamics..."
  [items]="aerodynamics"
  [layout]="layout"
  [selectedItems]="selectedAero"
  (selectionChanged)="selectionIDSet($event[0].id, 'aero_id')"></awc-select>
<awc-select
  label="Mass"
  placeholder="Mass..."
  [items]="massses"
  [layout]="layout"
  [selectedItems]="selectedMass"
  (selectionChanged)="selectionIDSet($event[0].id, 'mass_id')"></awc-select>
<awc-select
  label="Wheel"
  placeholder="Wheel..."
  [items]="wheels"
  [layout]="layout"
  [selectedItems]="selectedWheel"
  (selectionChanged)="selectionIDSet($event[0].id, 'wheel_id')"></awc-select>
<awc-select
  label="Deceleration Limit"
  placeholder="Optional"
  [items]="limits"
  [layout]="layout"
  [selectedItems]="selectedLimit"
  (selectionChanged)="selectionIDSet($event[0].id, 'deceleration_limit_id')"></awc-select>

<awc-select
  label="Ancillary Load"
  placeholder="Optional"
  [items]="loads"
  [layout]="layout"
  [selectedItems]="selectedLoad"
  (selectionChanged)="selectionIDSet($event[0].id, 'ancillary_load_id')"></awc-select>
<app-input
  label="Shift Delta"
  placeholder="Shift delta..."
  [value]="part.shift_delta"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_shift_delta'"
  [unit]="UnitType.POWER"
  [updater]="update"
  (update)="setPart.emit([''+$event, 'shift_delta', part.id+'_shift_delta'])"></app-input>
<app-input
  label="Starting State of Charge"
  placeholder="Starting State of Charge..."
  [value]="part.starting_state_of_charge"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_starting_state_of_charge'"
  [unit]="UnitType.RATIO"
  [updater]="update"
  (update)="setPart.emit([''+$event, 'starting_state_of_charge', part.id+'_starting_state_of_charge'])"></app-input>
<app-input
  label="Range"
  placeholder="Optional"
  [value]="part.range ?? ''"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_range'"
  [unit]="UnitType.DISTANCE"
  [updater]="update"
  (update)="setPart.emit([''+$event, 'range', part.id+'_range'])"></app-input>

<div>
  <awc-switch
    [checked]="!!part.full_range_calculation"
    [label]="'Full Range Calculation'"
    [labelPosition]="labelPosition"
    [size]="size"
    [awcSizing]="awcSizing"
    [awcTooltip]="'Toggle Full Range Calculation'"
    (changes)="toggleIFullRange($event)"></awc-switch>
</div>
