/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  Aero,
  AeroInDB,
  ConceptPopulated,
  ConfigurationsService as API,
  Mass,
  MassInDB,
  TotalTractiveTorqueGraph,
  UnitType,
  WheelInput,
  WheelInDB,
  type DecelerationLimit,
  type AncillaryLoad,
  DecelerationLimitInDB,
  AncillaryLoadInDB,
} from 'src/api';
import { PartServiceType, ConfigurationSelection } from '../types';
import { State } from '@ansys/andromeda/store';
import { PlotService } from './plot.service';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { SnackBarService } from '@ansys/andromeda/shared';
import { ActiveSectionState, ActiveTabState } from '@ansys/andromeda/workspace';
import { DataDisplayService, DataDisplayState } from './data-display.service';
import { ConceptUnitService } from './unit.service';
import { lastValueFrom } from 'rxjs';
import { ConceptSections } from '../enums';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { ConfigurationItemState } from 'src/app/state/lib/configurations.state';
import { ConfigurationEnvState } from 'src/app/state/lib/configuration-env.state';
import { NotificationService } from './notification.service';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { AnalyticEventEnum } from '../enums';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { ConceptPartType } from '../types';

export interface ConfigurationEnvironment {
  speed?: number;
  gradient?: number;
  acceleration?: number;
  altitude?: number;
  headwind?: number;
  stepsize?: number;
}
type ConfigType = Aero | WheelInput | Mass | DecelerationLimit | AncillaryLoad;

type ConfigResponse =
  | AeroInDB
  | MassInDB
  | WheelInDB
  | DecelerationLimitInDB
  | AncillaryLoadInDB;

@Injectable({ providedIn: 'root' })
export class ConfigurationsService implements PartServiceType {
  private instanceId!: string;
  private _cachedSelection?: ConfigurationSelection | undefined;
  constructor(
    private API: API,
    private state: State,
    private plotService: PlotService,
    private dataDisplay: DataDisplayService,
    private snackbar: SnackBarService,
    private unitChoices: ConceptUnitService,
    private notification: NotificationService,
    private actions: ActionContributionsService
  ) {
    this.state
      .get(ActiveConceptState)
      .subscribe((concept?: ConceptPopulated) => {
        if (concept) {
          this.instanceId = concept.design_instance_id as string;
        }
      });
  }
  public get configurationLists(): [
    aero: AWCListItem[],
    mass: AWCListItem[],
    wheel: AWCListItem[],
    limits: AWCListItem[],
    loads: AWCListItem[]
  ] {
    const tab = this.state.value(ActiveTabState);
    const currentState: AWCTreeItem[] = this.state.value(
      ConfigurationItemState,
      tab?.id || ''
    );
    const filter = (item: AWCTreeItem, type: string): boolean => {
      return !!(item.userData && item.userData['_parentID'] === type);
    };
    const aero = currentState.filter((item) => filter(item, 'aero'));
    const mass = currentState.filter((item) => filter(item, 'mass'));
    const wheel = currentState.filter((item) => filter(item, 'wheel'));
    const limits = currentState.filter((item) =>
      filter(item, 'deceleration_limit')
    );
    const loads = currentState.filter((item) => filter(item, 'ancillary_load'));
    return [aero, mass, wheel, limits, loads];
  }

  public async addPart(item: ConceptPartType): Promise<ConfigResponse> {
    if ((item as ConfigType) === undefined) {
      throw new TypeError("Configuration types don't match.");
    }
    const itemSubType = item as ConfigType;

    return await lastValueFrom(
      this.API.createConfigurationsPost(this.instanceId, {
        ...itemSubType,
      } as ConfigType)
    )
      .then(async (config) => {
        const eventData: AnalyticsEventPayload = {
          type: AnalyticEventEnum.CONFIGURATION_ADDED,
          data: { config_type: itemSubType.config_type },
        };
        this.actions.execute(SubmitAnalyticsEventAction, eventData);
        return config;
      })
      .catch((err) => {
        this.notification.error('Error Creating Configuration', err);
        throw new Error(err);
      });
  }
  public deletePart(item: AWCTreeItem): Promise<ConfigResponse> {
    return new Promise((resolve, reject) => {
      const id = item.userData ? (item.userData['id'] as string) : null;
      if (!id) throw new Error('No ID provided');
      this.API.deleteConfigurationsItemIdDelete(id, this.instanceId).subscribe(
        async (config: ConfigResponse) => {
          const tab = this.state.value(ActiveTabState);
          const currentState = this.state.value(
            ConfigurationItemState,
            tab?.id
          );
          const index = currentState.findIndex(
            (stateItem) => stateItem.id === item.id
          );
          currentState.splice(index, 1);
          this.state.set(ConfigurationItemState, currentState, {
            binding: tab?.id,
          });
          if (item.userData?.['config_type']) {
            const eventData: AnalyticsEventPayload = {
              type: AnalyticEventEnum.CONFIGURATION_DELETED,
              data: { config_type: item.userData?.['config_type'] },
            };
            this.actions.execute(SubmitAnalyticsEventAction, eventData);
          }

          this.snackbar.success('Configuration Deleted!');
          resolve(config);
        },
        (err) => {
          this.snackbar.error('Error Deleting Configuration!');
          reject();
          throw new Error(err);
        }
      );
    });
  }
  public async updatePart(item: AWCTreeItem): Promise<ConfigResponse> {
    const id = item.userData ? (item.userData['id'] as string) : null;
    if (!id) throw new Error('No ID provided');
    this.snackbar.info('Updating Component!');
    const resp = await lastValueFrom(
      this.API.updateConfigurationsItemIdPut(
        id,
        this.instanceId,
        item.userData as
          | Aero
          | Mass
          | WheelInput
          | DecelerationLimit
          | AncillaryLoad
      )
    ).catch((err) => {
      this.snackbar.error('Error Updating Configuration!');
      throw new Error(err);
    });
    item.text = resp.name;
    item.userData = {
      ...item.userData,
      ...resp,
    };
    if (item.parent) {
      (item.parent as any).dirty = true;
    }

    if (item.userData?.['config_type']) {
      const eventData: AnalyticsEventPayload = {
        type: AnalyticEventEnum.CONFIGURATION_UPDATED,
        data: { config_type: item.userData?.['config_type'] },
      };
      this.actions.execute(SubmitAnalyticsEventAction, eventData);
    }

    this.snackbar.success('Configuration Updated!');
    this.dataDisplay.displayState === DataDisplayState.GRAPH_DISPLAY &&
      this.toPlot();
    return resp;
  }
  public toPlot(): void {
    this.snackbar.info('Updating Plot!');
    this.dataDisplay.displayState = DataDisplayState.LOADING;
    this.displayData().then(() => {
      this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
      this.snackbar.success('Plot Updated!');
    });
  }

  public setConfigurationIDs(items: AWCTreeItem[]): ConfigurationSelection {
    return {
      aero: items.find(
        (a) => a.userData && (a.userData['_parentID'] as string) === 'aero'
      )?.id as string,
      mass: items.find(
        (a) => a.userData && (a.userData['_parentID'] as string) === 'mass'
      )?.id as string,
      wheel: items.find(
        (a) => a.userData && (a.userData['_parentID'] as string) === 'wheel'
      )?.id as string,
    };
  }
  public displayData(
    ids: ConfigurationSelection | undefined = this._cachedSelection
  ): Promise<void> {
    if (!ids) {
      throw new Error('No IDs provided');
    }
    const tab = this.state.value(ActiveTabState);
    this._cachedSelection = ids;
    const configEnv: ConfigurationEnvironment = this.state.value(
      ConfigurationEnvState,
      tab?.id
    );

    const plotEnv = {
      speed: 50,
      gradient: 0,
      acceleration: 0,
      altitude: 0,
      headwind: 0,
      stepsize: 0.2,
      ...configEnv,
    };
    // Check if we have data cached for this selection then if not get it
    return new Promise<void>((resolve, reject) => {
      this.API.calculateTotalForcesConfigurationsCalculateForcesGet(
        this.instanceId.toString(),
        ids['aero'],
        ids['mass'],
        ids['wheel'],
        plotEnv.speed, // set speed
        plotEnv.acceleration,
        plotEnv.altitude,
        plotEnv.headwind,
        plotEnv.gradient, // set gradients
        plotEnv.stepsize
      ).subscribe(async (resp: TotalTractiveTorqueGraph) => {
        const data = [
          {
            type: 'scatter',
            x: resp.speeds as never[],
            y: resp.total_tractive_torques as never[],
          },
        ];
        const layout = {
          xaxis: {
            range: [],
            title: `Speed (${this.unitChoices.getChoice(UnitType.SPEED)})`,
          },
          yaxis: {
            range: [],
            title: `Total Tractive Torque (${this.unitChoices.getChoice(
              UnitType.TORQUE
            )})`,
          },
          showlegend: false,
        };
        const section = this.state.value(ActiveSectionState);
        if (section?.type === ConceptSections.CONFIGURATIONS) {
          this.plotService.setGraphData(data);
          this.plotService.updateGraphLayout(layout);
          resolve();
        } else reject();
      });
    });
  }
  /**
   * Gets configuration relative to the config id and design id
   * @param {string} id - ID of the configuration to get
   * @param {string} instanceId - ID of the design instance to get the configuration from
   * @returns {void}
   */
  public async get(id: string, instanceId: string): Promise<ConfigResponse> {
    return await lastValueFrom(
      this.API.readConfigurationsItemIdGet(id, instanceId)
    );
  }
}
