import { Injectable } from '@angular/core';
import { Design, DesignApiService } from '@ansys/andromeda/api/rest';
import {
  AWFAction,
  ActionContributionsService,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { DialogService, ProductContextService } from '@ansys/andromeda/shared';
import { lastValueFrom } from 'rxjs';
import { ActionsEnum } from 'src/app/actions/actions.enum';
import { ConceptService } from 'src/app/shared/services/concept.service';
import { LoadingComponent } from 'src/app/components/dialogs/loading/loading.component';
import { CreateDesignAction } from '@ansys/andromeda/dashboard';
import { State } from '@ansys/andromeda/store';
import { AuthSessionService } from '@ansys/andromeda/auth';

type InputType = string;
type ReturnType = Design;

@Injectable({
  providedIn: 'root',
})
export class DesignCreateAction implements AWFAction<InputType, ReturnType> {
  public readonly type = ActionsEnum.COMPOSE_FULL_DATASET;

  projectSection!: WorkspaceTabSection | undefined;
  constructor(
    private designAPIService: DesignApiService,
    private productContextService: ProductContextService,
    private conceptService: ConceptService,
    private actions: ActionContributionsService,
    private dialog: DialogService,
    private state: State,
    private authSessionService: AuthSessionService
  ) {}

  execute: (context: InputType) => Promise<ReturnType> = async (
    context: InputType
  ) => {
    await this.waitFor(() => this.authSessionService.authenticated);

    const loadingRef = this.dialog.open(LoadingComponent, {
      title: 'Creating your new Concept',
    });

    const projectId = context;
    const product = await this.productContextService.getProduct();

    const [design] = await this.actions.execute(CreateDesignAction, [
      {
        projectId: projectId,
        productId: product?.partNumber,
        designTitle: 'Branch 1',
        designDescription: '',
      },
      true,
      null,
    ]);
    const concept = await lastValueFrom(
      this.conceptService.$createConcept(design)
    );

    if (!concept.design_instance_id) {
      throw new Error('No instance found');
    }

    loadingRef.close();

    return design;
  };

  waitFor(fn: () => boolean): Promise<void> {
    return new Promise((resolve) => {
      const localFn = (): void => {
        fn() ? resolve() : setTimeout(localFn, 1000);
      };
      localFn();
    });
  }
}
