<div class="plot-cont">
  <div class="view-button" *ngIf="section === sections.REQUIREMENT && showPlotNav">
    <awc-button
      text="Table"
      awcTooltip="Table View"
      [suffixIcon]="tableIcon"
      [type]="altType"
      [iconOnly]="true"
      (clicked)="switchToTableView()"></awc-button>
    <awc-button
      text="Blueprint"
      awcTooltip="Blueprint View"
      [suffixIcon]="blueprintIcon"
      [type]="altType"
      [iconOnly]="true"
      (clicked)="switchToBlueprintView()"></awc-button>
    <awc-button
      text="Download"
      awcTooltip="Download"
      [suffixIcon]="downloadIcon"
      [type]="altType"
      [iconOnly]="true"
      (clicked)="downloadAllData()"></awc-button>
  </div>

  <awc-chart
    *ngIf="plotState && plotState.data && plotState.data.length > 0 && plotState.layout"
    [data]="plotState.data"
    [toolbarDisplay]="toolbarDisplay"
    [layout]="plotState.layout"
    (plotlyClick)="plotSelect($event)"
    (legendClick)="legendSelect($event)"></awc-chart>
</div>
