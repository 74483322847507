/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Force Unit.
 */
export enum ForceUnit {
    N = 'N',
    LBF = 'lbf',
    DYN = 'dyn',
}
