/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Placeholder class for when neglecting transmission.
 *
 * This is used when we have in wheel motors.
 */
export type TransmissionNeglect = {
    item_type?: TransmissionNeglect.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: TransmissionNeglect.component_type;
};
export namespace TransmissionNeglect {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        TRANSMISSION_NEGLECT = 'TransmissionNeglect',
    }
}

