import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scientificNotation',
})
export class ScientificNotationPipe implements PipeTransform {
  transform(
    value: string | number | undefined | null,
    ...args: unknown[]
  ): string | undefined {
    args;
    if (value === null || value === undefined || ('' + value).length === 0)
      return '';
    return toSN(value);
  }
}
export const toSN = (x: string | number | undefined | null): string => {
  const numX = Number(x);
  const trimTrailingZeros = (str: string): string => {
    const notationSplit = str.split('e');
    const preNote = notationSplit[0];
    if (preNote.slice(-1) === '0' || preNote.slice(-1) === '.') {
      const split = preNote.split('');
      split.pop();
      str = split.join('') + 'e' + notationSplit[1];
      return trimTrailingZeros(str);
    }
    return str;
  };
  if (!x || x === '0' || x === 0) return '0';
  if (Math.abs(numX) < 0.009) {
    x = parseFloat(numX.toPrecision(5)).toExponential(5);
    x = trimTrailingZeros(x);
  } else {
    if ((x as number) > 9999 || (x as number) < -9999) {
      x = parseFloat(numX.toPrecision(5)).toExponential(5);
      x = trimTrailingZeros(x);
    } else {
      x = Math.round(numX * 100000) / 100000;
    }
  }

  return x.toString();
};
