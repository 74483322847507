/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Aero } from './Aero';
import type { Mass } from './Mass';
import type { StaticRequirement } from './StaticRequirement';
import type { WheelInput } from './WheelInput';
/**
 * Dynamic Requirements.
 */
export type DynamicRequirement = {
    item_type?: DynamicRequirement.item_type;
    name?: string;
    description?: string;
    mass?: Mass;
    aero?: Aero;
    wheel?: WheelInput;
    from_speed?: number;
    to_speed?: number;
    time_step?: number;
    no_of_points?: number;
    base_speed_ratio?: number;
    state_of_charge?: number;
    required_time?: number;
    required_distance?: number;
    max_capability?: boolean;
    base_speed: number;
    stall_torque: number;
    end_time: number;
    end_distance: number;
    static_requirements: Array<StaticRequirement>;
    times: Array<number>;
    speeds: Array<number>;
    distances: Array<number>;
    accelerations: Array<number>;
    voltage_oc: number;
    requirement_type?: DynamicRequirement.requirement_type;
};
export namespace DynamicRequirement {
    export enum item_type {
        REQUIREMENT = 'requirement',
    }
    export enum requirement_type {
        DYNAMIC = 'dynamic',
    }
}

