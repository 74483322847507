/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Concept.
 */
export type Concept = {
    concept_type?: Concept.concept_type;
    _id?: string;
    id?: string;
    name?: string;
    user_id: string;
    project_id: string;
    design_id: string;
    design_instance_id: string;
    architecture_id?: (string | null);
    components_ids: Array<string>;
    configurations_ids: Array<string>;
    requirements_ids: Array<string>;
    jobs_ids: Array<string>;
    capabilities_ids: Array<string>;
    drive_cycles_ids: Array<string>;
    file_items_ids?: Array<string>;
};
export namespace Concept {
    export enum concept_type {
        NOT_POPULATED = 'not populated',
    }
}

