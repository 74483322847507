/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  HostListener,
  Injectable,
  WritableSignal,
  signal,
} from '@angular/core';

/**
 * This service will return the appropriate service for the section type. This is used to abstract the logic of getting the correct service from the section type away from the shared component.
 */

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private navBarOffset: number = 118;
  private tabBarOffset: number = 45;
  private layoutHeight!: number;
  private innerContentHeight!: number;
  get sectionLayoutHeight(): number {
    return this.layoutHeight;
  }
  get innerSectionLayoutHeight(): number {
    return this.innerContentHeight;
  }
  public cardHeight: WritableSignal<number> = signal<number>(0);
  public innerCardHeight: WritableSignal<number> = signal<number>(0);
  constructor() {
    this.getLayoutOffsets();
  }
  //listen to window resize and update offset
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.getLayoutOffsets();
  }
  private getLayoutOffsets(): void {
    const height = window.innerHeight;
    this.layoutHeight = height - this.navBarOffset - 22;
    this.innerContentHeight = this.layoutHeight - this.tabBarOffset;
    this.cardHeight.set(this.layoutHeight);
    this.innerCardHeight.set(this.innerContentHeight);
  }
}
