/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatteryLookupTableData } from './BatteryLookupTableData';
/**
 * Battery in Database.
 */
export type BatteryLookupTableInDB = {
    item_type?: BatteryLookupTableInDB.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: 'BatteryLookupData';
    lookup_table: BatteryLookupTableData;
    _id?: string;
};
export namespace BatteryLookupTableInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
}

