/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  Aero,
  AncillaryLoad,
  WheelInput,
  BatteryFixedVoltages,
  DecelerationLimit,
  DisconnectClutchInputInDB,
  DriveCycleRequirementIds,
  DynamicRequirementInputsIds,
  Mass,
  MotorLabID,
  MotorLossMapID,
  MotorTorqueCurvesID,
  StaticRequirementAccelerationIds,
  UnitType,
} from 'src/api';
import {
  AccelerationUnit,
  AngleUnit,
  AngularAccelerationUnit,
  AngularSpeedUnit,
  AreaUnit,
  CurrentUnit,
  DensityUnit,
  ElectricChargeUnit,
  ElectricalEnergyUnit,
  ElectricalPowerUnit,
  EnergyUnit,
  ForceUnit,
  InertiaUnit,
  LengthUnit,
  MassUnit,
  PowerUnit,
  PressureUnit,
  RatioUnit,
  ResistanceUnit,
  RoadEfficiencyUnit,
  SpeedUnit,
  TemperatureUnit,
  TimeUnit,
  TorqueUnit,
  VoltageUnit,
  VolumeUnit,
} from 'src/api';
import { inverterSelectTypes } from '../models/lists/select-options/inverter-types.model';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { defaultPartsMap } from '../../components/dialogs/add-part/lib/default-parts-map';
import { PartType as PT } from '../enums';
import { transmissionComponentType } from '../models/concept-components/transmission.model';
import { motorLossMap } from '../models/concept-components/motor-loss-map.model';
import { motorTorque } from '../models/concept-components/motor-torque-curve.model';
import { MapModelTypes } from '../types/map-model-types.type';
import { batterySelectTypes } from '../models/lists/select-options/battery-types.model';
import { wheelRadiusConfig } from '../models/concept-configurations/wheel-radius.model';

type UnitInfo = {
  [key in UnitType]: {
    [unit: string]: string | number;
  };
};

interface VariableInfo {
  [key: string]: {
    [key: string]: string;
  };
}
type UnitChoice =
  | MassUnit
  | TimeUnit
  | ForceUnit
  | TorqueUnit
  | TemperatureUnit
  | LengthUnit
  | AreaUnit
  | VolumeUnit
  | SpeedUnit
  | AccelerationUnit
  | AngularSpeedUnit
  | AngularAccelerationUnit
  | EnergyUnit
  | PowerUnit
  | DensityUnit
  | InertiaUnit
  | PressureUnit
  | RatioUnit
  | VoltageUnit
  | CurrentUnit
  | ResistanceUnit
  | ElectricChargeUnit
  | ElectricalEnergyUnit
  | ElectricalPowerUnit
  | AngleUnit
  | RoadEfficiencyUnit;

@Injectable({ providedIn: 'root' })
export class ConceptUnitService {
  public set unitInfo(info: UnitInfo) {
    this.setUnitFactors(info);
    this._unitInfo = info;
  }
  public get unitInfo(): UnitInfo {
    return this._unitInfo;
  }
  public set variableInfo(info: VariableInfo) {
    this._variableInfo = info;
  }
  public get variableInfo(): VariableInfo {
    return this._variableInfo;
  }
  public set choices(choices: Record<string, UnitChoice>) {
    this._choices = choices;
  }
  public get choices(): Record<string, UnitChoice> {
    return this._choices;
  }
  public getChoice(unit: UnitType): UnitChoice | string {
    if (!this.choices) return '';
    if (!this.choices[unit]) return '';
    return this.choices[unit];
  }
  public convertUnit(unit: UnitType, value: number): number {
    if (!this.unitInfo) return value;
    if (!this.unitInfo[unit]) return value;

    return value / (this.unitInfo[unit][this.getChoice(unit)] as number) ?? 1;
  }

  public setModelData(
    model: MapModelTypes,
    modelName: string,
    units: Record<string, any>
  ): void {
    const newObject = (object: unknown): unknown => {
      return JSON.parse(JSON.stringify(object));
    };

    switch (modelName) {
      // Vehicle Configurations
      case 'aeroConfig':
        (model as Aero) = {
          ...units['variable_info']['Aero']['default_values'],
        };

        defaultPartsMap.set(PT.AERO, newObject(model));
        break;
      case 'massConfig':
        (model as Mass) = {
          ...units['variable_info']['Mass']['default_values'],
        };

        defaultPartsMap.set(PT.MASS, newObject(model));
        break;
      case 'ancillaryConfig':
        (model as AncillaryLoad) = {
          ...units['variable_info']['AncillaryLoad']['default_values'],
        };

        defaultPartsMap.set(PT.ANCILLARY, newObject(model));
        break;
      case 'wheel':
        (model as WheelInput) = {
          ...units['variable_info']['WheelInput']['default_values'],
        };

        defaultPartsMap.set(PT.WHEEL, newObject(model));
        break;
      case 'decelerationConfig':
        (model as DecelerationLimit) = {
          ...units['variable_info']['DecelerationLimit']['default_values'],
        };

        defaultPartsMap.set(PT.DECELERATION, newObject(model));
        break;

      // Component Configurations
      case 'transmissionComponent':
        (model as transmissionComponentType) = {
          ...units['variable_info']['TransmissionLossCoefficients'][
            'default_values'
          ],
          default_values:
            units['variable_info']['TransmissionLossCoefficients'][
              'default_values'
            ],
        };

        defaultPartsMap.set(PT.TRANSMISSION, newObject(model));
        break;
      case 'transmissionSelectTypes':
        (model as { id: string; userData: { defaultPart: any } }[]).forEach(
          (selectItem: { id: string; userData: { defaultPart: any } }) => {
            if (selectItem.id === 'TransmissionLossMapID') {
              selectItem.userData.defaultPart =
                units['variable_info']['TransmissionLossMap']['default_values'];
            }
          }
        );
        break;
      case 'motorLossMap':
        (model as MotorLossMapID) =
          units['variable_info'][
            (model as MotorLossMapID).component_type as string
          ].default_values;
        defaultPartsMap.set(PT.MOTOR, newObject(model));
        break;
      case 'motorLabModel':
        (model as MotorLabID) =
          units['variable_info'][
            (model as MotorLabID).component_type as string
          ]['default_values'];
        defaultPartsMap.set(PT.MOTOR_LAB, newObject(model));
        break;
      case 'motorTorque':
        (model as MotorTorqueCurvesID) =
          units['variable_info']['MotorTorqueCurvesID']['default_values'];
        defaultPartsMap.set(PT.MOTOR_TORQUE_CURVE, newObject(model));
        break;
      case 'inverterSelectTypes':
        (model as AWCListItem[]).forEach(
          (item: { id: string | number }, index: number) => {
            if (units['variable_info'] && units['variable_info'][item.id]) {
              inverterSelectTypes[index].userData =
                units['variable_info'][item.id]['default_values'];
            }
          }
        );
        break;
      case 'batteryComponent':
        (model as BatteryFixedVoltages) =
          units['variable_info'][
            (model as BatteryFixedVoltages).component_type as string
          ]['default_values'];

        defaultPartsMap.set(PT.BATTERY, newObject(model));
        break;
      case 'batterySelectTypes':
        (model as AWCListItem[]).forEach(
          (item: { id: string | number }, index: number) => {
            if (
              units['variable_info'] &&
              units['variable_info'][item.id] &&
              batterySelectTypes[index].userData?.['defaultPart']
            ) {
              batterySelectTypes[index].userData = {
                ...batterySelectTypes[index].userData,
                defaultPart: units['variable_info'][item.id]['default_values'],
              };
            }
          }
        );
        break;
      case 'clutchComponent':
        (model as DisconnectClutchInputInDB) =
          units['variable_info']['DisconnectClutchInput']['default_values'];

        defaultPartsMap.set(PT.CLUTCH, newObject(model));
        break;

      // Requirement Configuration
      case 'staticRequirement':
        (model as StaticRequirementAccelerationIds) =
          units['variable_info']['StaticRequirementAccelerationIds'][
            'default_values'
          ];

        defaultPartsMap.set(PT.STATIC_REQUIREMENT, newObject(model));
        break;
      case 'dynamicRequirement':
        (model as DynamicRequirementInputsIds) =
          units['variable_info']['DynamicRequirementInputsIds'][
            'default_values'
          ];

        defaultPartsMap.set(PT.DYNAMIC_REQUIREMENT, newObject(model));
        break;
      case 'driveCycle':
        (model as DriveCycleRequirementIds) =
          units['variable_info']['DriveCycleRequirementIds']['default_values'];

        defaultPartsMap.set(PT.DRIVE_CYCLE, newObject(model));
        break;
    }
  }

  private _variableInfo!: VariableInfo;
  private _unitInfo!: UnitInfo;
  private _choices!: Record<string, UnitChoice>;

  private setUnitFactors(info: UnitInfo): void {
    Object.keys(info).forEach((unitType) => {
      Object.keys(info[unitType as UnitType]).forEach((unit) => {
        const factor = (info[unitType as UnitType][unit] as string).split(
          ' '
        )[0];
        info[unitType as UnitType][unit] = parseFloat(factor);
      });
    });
  }
}
