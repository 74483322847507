/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  Type,
} from '@angular/core';
import { FrequencyUnit, UnitChoicesService, UnitType } from 'src/api';
import { lastValueFrom } from 'rxjs';
import { AWCListContent, AWCListItem } from '@ansys/awc-angular/lists';
import {
  volume,
  voltage,
  torque,
  time,
  accelerations,
  angularAccelerations,
  angularSpeed,
  area,
  current,
  density,
  distance,
  electricChage,
  electricEnergy,
  electricPower,
  energy,
  force,
  gradient,
  inertia,
  length,
  mass,
  power,
  resistance,
  roadEfficiency,
  speed,
  temperature,
} from 'src/app/shared/models/lists';
import { FlexLayout } from '@ansys/awc-angular/core';
import { Icons } from '@ansys/awc-angular/icons';
import { ConceptService } from 'src/app/shared/services/concept.service';
import { DIALOG_REF, SnackBarService } from '@ansys/andromeda/shared';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { State, StateNode } from '@ansys/andromeda/store';
import { ConceptSections } from 'src/app/shared/enums/concept.enum';
import { UnitChoice } from 'src/app/shared/types';
import { ratio } from 'src/app/shared/models/lists/units/ratio.model';
import { UnitNameTransformPipe } from 'src/app/shared/pipes/unit-name/unit-name-transform.pipe';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { ActiveTabState } from '@ansys/andromeda/workspace';
import { UpdateInputs } from '../../../actions';
import { SelectedComponentState } from 'src/app/state/lib/selected-component.state';
import { SelectedConfigurationsState } from 'src/app/state/lib/selected-configuration.state';
import { SelectedRequirementState } from 'src/app/state/lib/selected-requirement.state';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { AnalyticEventEnum } from '../../../shared/enums';
import { pressure } from '../../../shared/models/lists/units/pressure.model';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnitNameTransformPipe],
})
export class UnitsComponent implements OnInit {
  protected unitChoiceLabels: UnitType[] = [
    UnitType.ACCELERATION,
    UnitType.ANGULAR_ACCELERATION,
    UnitType.ANGULAR_SPEED,
    UnitType.AREA,
    UnitType.CURRENT,
    UnitType.DENSITY,
    UnitType.DISTANCE,
    UnitType.ELECTRICAL_ENERGY,
    UnitType.ELECTRICAL_POWER,
    UnitType.ELECTRIC_CHARGE,
    UnitType.ENERGY,
    UnitType.FORCE,
    UnitType.INERTIA,
    UnitType.LENGTH,
    UnitType.GRADIENT,
    UnitType.MASS,
    UnitType.POWER,
    UnitType.PRESSURE,
    UnitType.RATIO,
    UnitType.RESISTANCE,
    UnitType.ROAD_EFFICIENCY,
    UnitType.SPEED,
    UnitType.TEMPERATURE,
    UnitType.TIME,
    UnitType.TORQUE,
    UnitType.VOLTAGE,
    UnitType.VOLUME,
  ];
  protected unitChoiceMap: Map<UnitType, AWCListContent[]> = new Map([
    [UnitType.ACCELERATION, accelerations],
    [UnitType.ANGULAR_ACCELERATION, angularAccelerations],
    [UnitType.ANGULAR_SPEED, angularSpeed],
    [UnitType.AREA, area],
    [UnitType.CURRENT, current],
    [UnitType.DENSITY, density],
    [UnitType.DISTANCE, distance],
    [UnitType.ELECTRICAL_ENERGY, electricEnergy],
    [UnitType.ELECTRICAL_POWER, electricPower],
    [UnitType.ELECTRIC_CHARGE, electricChage],
    [UnitType.ENERGY, energy],
    [UnitType.FORCE, force],
    [UnitType.INERTIA, inertia],
    [UnitType.LENGTH, length],
    [UnitType.GRADIENT, gradient],
    [UnitType.MASS, mass],
    [UnitType.POWER, power],
    [UnitType.PRESSURE, pressure],
    [UnitType.RATIO, ratio],
    [UnitType.RESISTANCE, resistance],
    [UnitType.ROAD_EFFICIENCY, roadEfficiency],
    [UnitType.SPEED, speed],
    [UnitType.TEMPERATURE, temperature],
    [UnitType.TIME, time],
    [UnitType.TORQUE, torque],
    [UnitType.VOLTAGE, voltage],
    [UnitType.VOLUME, volume],
  ]);
  protected spinner: Icons = Icons.SPINNER;
  protected loading: boolean = true;
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected choices: Record<string, UnitChoice> = {};
  protected selectedSection: ConceptSections = ConceptSections.CONFIGURATIONS;
  private selectedStateFromType: Map<
    ConceptSections,
    Type<StateNode<string[]>>
  > = new Map([
    [ConceptSections.CONFIGURATIONS, SelectedConfigurationsState],
    [ConceptSections.COMPONENT, SelectedComponentState],
    [ConceptSections.REQUIREMENT, SelectedRequirementState],
  ]);
  private analyticData: { [key: string]: any } = [];

  constructor(
    private unitService: UnitChoicesService,
    private conceptService: ConceptService,
    private snackbar: SnackBarService,
    private state: State,
    @Inject(DIALOG_REF) private _dialogRef: DialogComponent,
    private actions: ActionContributionsService,
    private _cdr: ChangeDetectorRef
  ) {}
  async ngOnInit(): Promise<void> {
    this.loading = true;
    const unitChoices = await lastValueFrom(
      this.unitService.readUnitChoicesGet()
    );
    if (unitChoices.unit_type_to_unit_map) {
      this.choices = unitChoices.unit_type_to_unit_map as Record<
        string,
        UnitChoice
      >;
      if (!this.choices['frequency']) {
        this.choices['frequency'] = FrequencyUnit.HZ;
      }
    }
    this.loading = false;
    this._cdr.detectChanges();
  }
  protected selectionChanged($event: AWCListItem[], unit: UnitType): void {
    this.analyticData[unit] = $event;
    this.choices[unit] = $event[0].id as UnitChoice;
  }
  protected onSubmit(): void {
    this.snackbar.info('Updating Unit Choices!');
    this.loading = true;
    this.unitService
      .updateUnitChoicesPut({ unit_type_to_unit_map: this.choices })
      .subscribe(() => {
        this.conceptService.clearConceptsAndFetch().then(async () => {
          this.loading = false;
          this._dialogRef.close();
          const tab = this.state.value(ActiveTabState);

          // Fire analytics event
          const eventData: AnalyticsEventPayload = {
            type: AnalyticEventEnum.UNITS_UPDATED,
            data: this.analyticData,
          };
          this.actions.execute(SubmitAnalyticsEventAction, eventData);

          this.snackbar.success('Unit Choices Updated!');
          const state = this.selectedStateFromType.get(this.selectedSection);
          if (state) {
            const selection = this.state.value(state, tab?.id);
            this.state.set(state, [], {
              binding: tab?.id,
            });
            this.state.set(state, selection, {
              binding: tab?.id,
            });
          }
          this.actions.execute(UpdateInputs);
        });
      });
  }
}
