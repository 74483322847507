/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Mass Unit.
 */
export enum MassUnit {
    KG = 'kg',
    G = 'g',
    LB = 'lb',
    OZ = 'oz',
    T = 't',
    LT = 'LT',
    TN = 'tn',
}
