import { AWCListContent } from '@ansys/awc-angular/lists';
import { AngularSpeedUnit } from 'src/api';

export const angularSpeed: AWCListContent[] = [
  {
    text: 'rad/s',
    id: AngularSpeedUnit.RAD_S,
  },
  {
    text: 'rpm',
    id: AngularSpeedUnit.RPM,
  },
  {
    text: 'rps',
    id: AngularSpeedUnit.RPS,
  },
  {
    text: 'deg/s',
    id: AngularSpeedUnit.DEG_S,
  },
];
