import { AWFAction } from '@ansys/andromeda/contributions';
import { AWCTableCell, TableCellTypes } from '@ansys/awc-angular/tables';
import { SolvedComponent } from '../../components/widgets/charts/blueprint-display/blueprint-display.component';
import { ActionsEnum } from '../../shared/enums';
import { UnitType } from '../../../api';
import { ConceptUnitService } from '../../shared/services/unit.service';
import { ScientificNotationPipe } from '../../shared/pipes/scientific-notation/scientific-notation.pipe';
import { Injectable } from '@angular/core';

type Input = [SolvedComponent, string[], number];

@Injectable({
  providedIn: 'root',
})
export class BuildComponentCellAction
  implements AWFAction<Input, AWCTableCell[]>
{
  public readonly type = ActionsEnum.BUILD_COMPONENT_CELL;

  constructor(
    private unitChoices: ConceptUnitService,
    private snPipe: ScientificNotationPipe
  ) {}

  execute: (context: Input) => Promise<AWCTableCell[]> = async (
    context: Input
  ) => {
    const [component, columns, index] = context;
    const emptyCellIdentifier: string = '-';

    const cells: AWCTableCell[] = columns.map((column) => {
      let value: number | string;
      const type: TableCellTypes = TableCellTypes.TEXT;

      if (column === 'efficiencies') {
        const outP = component.out_powers[index];
        const inP = component.in_powers[index];
        const efficiency = (outP >= 0 ? outP / inP : inP / outP) * 100;
        value = this.snPipe.transform(efficiency) || emptyCellIdentifier;
      } else if (column === 'point') {
        value = index.toString();
      } else {
        const unitTypes: Map<string, UnitType> = new Map([
          ['speeds', UnitType.ANGULAR_SPEED],
          ['in_torques', UnitType.TORQUE],
          ['out_torques', UnitType.TORQUE],
          ['in_powers', UnitType.POWER],
          ['out_powers', UnitType.POWER],
          ['in_voltages', UnitType.VOLTAGE],
          ['out_voltages', UnitType.VOLTAGE],
          ['losses', UnitType.POWER],
        ]);

        const columnValue = component[column as keyof SolvedComponent];
        const unitType = unitTypes.get(column);
        if (Array.isArray(columnValue)) {
          if (unitType) {
            value =
              this.snPipe.transform(
                this.unitChoices.convertUnit(unitType, columnValue[index])
              ) || emptyCellIdentifier;
          } else {
            value =
              this.snPipe.transform(columnValue[index]) || emptyCellIdentifier;
          }
        } else {
          if (unitType) {
            value = columnValue
              ? this.snPipe.transform(
                  this.unitChoices.convertUnit(unitType, columnValue as number)
                ) || emptyCellIdentifier
              : emptyCellIdentifier;
          } else {
            value = this.snPipe.transform(columnValue) || emptyCellIdentifier;
          }
        }
      }

      return { value, type };
    });
    return [
      {
        value: component.name.toString() || emptyCellIdentifier,
        type: TableCellTypes.TEXT,
      },
      ...cells,
    ];
  };
}
