import { StateNode } from '@ansys/andromeda/store';
import { PlotlyDataLayoutConfig } from 'plotly.js-dist-min';

export class PlotState implements StateNode<Partial<PlotlyDataLayoutConfig>> {
  key: string = 'plotLayout';

  defaultValue: () => Partial<PlotlyDataLayoutConfig> =
    (): Partial<PlotlyDataLayoutConfig> => {
      return {
        data: [],
        layout: {
          autosize: true,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          paper_bgcolor: 'rgba(0,0,0,0)',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          plot_bgcolor: 'rgba(0,0,0,0)',
          showlegend: true,
          legend: { orientation: 'h' },
          xaxis: {
            gridwidth: 0.1,
            range: [0, 0],
            title: 'Speed (m/s)',
          },
          yaxis: {
            gridwidth: 0.1,
            range: [0, 0],
            title: 'Total Tractive Torque (Nm)',
          },
          yaxis2: {},
        },
        config: {
          displayModeBar: false,
        },
      };
    };
}
