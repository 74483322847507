export const driveCycleColumnsModel: string[] = [
  'speeds',
  'in_torques',
  'out_torques',
  'in_powers',
  'out_powers',
  'in_voltages',
  'out_voltages',
  'efficiencies',
  'losses',
  'gear_ratios',
];
