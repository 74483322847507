/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Size } from '@ansys/awc-angular/core';
import { SectionCardTab } from './types/section-card-tab.type';
import { ConceptSubSection } from 'src/app/shared/enums/concept.enum';

@Component({
  selector: 'section-card',
  templateUrl: './section-card.component.html',
  styleUrls: ['./section-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SectionCardComponent implements OnChanges {
  @Input() selectedSectionId: string = '';
  @Input() sections: SectionCardTab[] = [];
  @Output() selectedSectionIdChange: EventEmitter<ConceptSubSection> =
    new EventEmitter<ConceptSubSection>();
  protected readonly Size = Size;
  constructor(private _cdr: ChangeDetectorRef) {}
  ngOnChanges(): void {
    this._cdr.detectChanges();
  }
  protected selectSection(sectionId: string): void {
    this.selectedSectionId = sectionId;
    this.selectedSectionIdChange.emit(sectionId as ConceptSubSection);
  }
}
