/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Pressure Unit.
 */
export enum PressureUnit {
    PA = 'Pa',
    K_PA = 'kPa',
    MPA = 'MPa',
    PSI = 'psi',
}
