/* eslint-disable @typescript-eslint/naming-convention */
import { DriveCycleRequirementIds } from 'src/api';
import { RequirementType } from '../../enums';

export const driveCycle: DriveCycleRequirementIds = {
  requirement_type: RequirementType.DRIVE_CYCLE,
  drive_cycle_id: '',
  aero_id: '',
  mass_id: '',
  wheel_id: '',
};
