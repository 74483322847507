import { RequirementPlotType } from '../../enums/reqirement-plot-type.enum';
import { PlotDataModelType } from '../../types/plot-data-model.type';

export const driveCyclePlotModel: PlotDataModelType[] = [
  {
    type: 'scatter',
    x: [] as number[],
    y: [] as number[],
    line: { color: 'green' },
    name: 'Drive Cycle Speed/Time',
    _type: RequirementPlotType.PRE_SOLVE,
  },
  {
    type: 'scatter',
    x: [] as number[],
    y: [] as number[],
    yaxis: 'y2',
    line: { color: 'purple' },
    name: 'Drive Cycle Acceleration/Time',
    _type: RequirementPlotType.PRE_SOLVE,
  },
];
