import { AWCListContent } from '@ansys/awc-angular/lists';
import { ForceUnit } from 'src/api';

export const force: AWCListContent[] = [
  {
    text: 'N',
    id: ForceUnit.N,
  },
  {
    text: 'lbf',
    id: ForceUnit.LBF,
  },
  {
    text: 'dyn',
    id: ForceUnit.DYN,
  },
];
