import { AWCListContent } from '@ansys/awc-angular/lists';
import { ElectricChargeUnit, ElectricalEnergyUnit } from 'src/api';

export const electricChage: AWCListContent[] = [
  {
    text: 'A·s',
    id: ElectricChargeUnit.A_S,
  },
];

export const electricEnergy: AWCListContent[] = [
  {
    text: 'J',
    id: ElectricalEnergyUnit.J,
  },
  {
    text: 'kWh',
    id: ElectricalEnergyUnit.K_WH,
  },
  {
    text: 'VA·hr',
    id: ElectricalEnergyUnit.VA_HR,
  },
  {
    text: 'Wh',
    id: ElectricalEnergyUnit.WH,
  },
];
