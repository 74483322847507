import { AWCListContent } from '@ansys/awc-angular/lists';
import { AccelerationUnit } from 'src/api';

export const accelerations: AWCListContent[] = [
  {
    text: 'm/s²',
    id: AccelerationUnit.M_S_,
  },
  {
    text: 'km/hr/s',
    id: AccelerationUnit.KM_HR_S,
  },
  {
    text: 'mph/s',
    id: AccelerationUnit.MPH_S,
  },
];
