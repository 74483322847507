import { ConceptTabModule } from './tabs/concept-tab/concept-tab.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SessionModule } from '@ansys/andromeda/auth';
import { PageComponent } from '@ansys/andromeda/shared';
import { HttpClientModule } from '@angular/common/http';
import { GraphQLModule } from '@ansys/andromeda/api/graph';
import { provideActions } from '@ansys/andromeda/providers';
import { DashboardModule } from '@ansys/andromeda/dashboard';
import { PagesModule } from './pages/pages.module';
import { TokensModule } from './tokens/tokens.module';
import { ActionsModule } from './actions/actions.module';

@NgModule({
  declarations: [],
  imports: [
    ConceptTabModule,
    DashboardModule,
    CommonModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    PageComponent,
    SessionModule,
    PagesModule,
    TokensModule,
    ActionsModule,
  ],
  exports: [PageComponent],
  providers: [provideActions()],
})
export class AndromedaModule {
  constructor() {}
}
