import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { AWCTreeItem } from '@ansys/awc-angular/trees';

@Component({
  selector: 'app-no-data-display',
  templateUrl: './no-data-display.component.html',
  styleUrls: ['./no-data-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataDisplayComponent implements OnInit, OnChanges {
  @Input() part!: AWCTreeItem;
  protected name: string = '';
  private readonly prettyNameMap: Map<string, string> = new Map([
    ['TransmissionLossCoefficients', 'Transmission Loss Coefficients'],
    ['BatteryFixedVoltages', 'Fixed Voltage Battery'],
    ['InverterAnalytical', 'Analytical Inverter'],
    ['ClutchInput', 'Clutch Input'],
  ]);
  constructor(private _cdr: ChangeDetectorRef) {}
  ngOnChanges(): void {
    this.setName();
    this._cdr.detectChanges();
  }
  ngOnInit(): void {
    this.setName();
  }
  setName(): void {
    if (this.part?.userData && this.part.userData['component_type']) {
      this.name =
        this.prettyNameMap.get(
          this.part.userData['component_type'] as string
        ) || this.name;
    }
  }
}
