import { AWCListItem } from '@ansys/awc-angular/lists';

export const core: AWCListItem[] = [
  {
    id: 'in_powers',
    text: 'In Powers',
  },
  {
    id: 'out_powers',
    text: 'Out Powers',
  },
  {
    id: 'losses',
    text: 'Losses',
  },
];

export const clutchList: AWCListItem[] = [
  ...core,
  {
    id: 'in_torques',
    text: 'In Torques',
  },
  {
    id: 'out_torques',
    text: 'Out Torques',
  },
  {
    id: 'in_speeds',
    text: 'In Speeds',
  },
  {
    id: 'out_speeds',
    text: 'Out Speeds',
  },
];

export const wheelList: AWCListItem[] = [
  ...core,
  {
    id: 'speeds',
    text: 'Speeds',
  },
  {
    id: 'in_torques',
    text: 'In Torques',
  },
  {
    id: 'out_torques',
    text: 'Out Torques',
  },
];

export const motorList: AWCListItem[] = [
  ...wheelList,
  {
    id: 'in_voltages',
    text: 'In Voltages',
  },
  {
    id: 'currents_d',
    text: 'Current D',
  },
  {
    id: 'currents_q',
    text: 'Current Q',
  },
];

export const transmissionList: AWCListItem[] = [
  ...core,
  {
    id: 'in_torques',
    text: 'In Torques',
  },
  {
    id: 'out_torques',
    text: 'Out Torques',
  },
  {
    id: 'gear_ratios',
    text: 'Gear Ratios',
  },
];

export const invertBatteryList: AWCListItem[] = [
  ...core,
  {
    id: 'in_voltages',
    text: 'In Voltages',
  },
  {
    id: 'out_voltages',
    text: 'Out Voltages',
  },
];
