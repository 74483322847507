/* eslint-disable @typescript-eslint/naming-convention */
import { SolvedBattery } from 'src/api';
import { BatteryBounds, Coords } from '../types/blueprint.type';
import { BatteryComponentMarker } from '../markers/battery-component';
import { BatteryElectricalMarker } from '../markers/battery-electrical';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
export const BATTERY_DATA = new InjectionToken<[BatteryBounds, SolvedBattery]>(
  'BatteryData'
);
@Injectable()
export class BlueprintBatteryComponent {
  private componentMarker: BatteryComponentMarker;
  private electricalMarker: BatteryElectricalMarker;
  private bounds: BatteryBounds;
  private data: SolvedBattery;
  constructor(
    @Inject(BATTERY_DATA) private batteryData: [BatteryBounds, SolvedBattery],
    private units: ConceptUnitService
  ) {
    [this.bounds, this.data] = batteryData;
    const [componentMarkerPosition, electricalMarkerPosition] =
      this.getCoords();
    this.componentMarker = new BatteryComponentMarker(
      [componentMarkerPosition, this.data],
      this.units
    );
    this.electricalMarker = new BatteryElectricalMarker(
      [electricalMarkerPosition, this.data],
      this.units
    );
  }
  getCoords(): [Coords, Coords[]] {
    const componentP: Coords = {
      x: this.bounds.x + this.bounds.width / 2,
      y: this.bounds.y + this.bounds.height,
    };
    const electricalP: Coords[] = [
      {
        x: this.bounds.positiveNodeOffset,
        y: this.bounds.y - 30,
      },
      {
        x: this.bounds.negativeNodeOffset,
        y: this.bounds.y - 30,
      },
    ];
    return [componentP, electricalP];
  }
  getMarkers(): [BatteryComponentMarker, BatteryElectricalMarker] {
    return [this.componentMarker, this.electricalMarker];
  }
  updateMarkerPositions(bounds: BatteryBounds): void {
    this.bounds = bounds;
    const [componentMarkerPosition, electricalMarkerPosition] =
      this.getCoords();
    this.componentMarker.nodeCoords = componentMarkerPosition;
    this.electricalMarker.nodeCoords = electricalMarkerPosition;
  }
}
