import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { ConceptPartType } from 'src/app/shared/types';
import { ComponentFileType } from '../../../api';
import { HttpResponse } from '@angular/common/http';
import { UploadFileResponseType } from './upload-file-response.type';

export interface AddPartDialogData {
  parent: AWCTreeItem;
  onConfirm: (
    item: ConceptPartType,
    file?: File,
    type?: string,
    uploadedFile?: UploadFileResponseType
  ) => void;
  type: string;
  onUploadFilePart?: (
    file: File,
    type?: ComponentFileType
  ) => Promise<UploadFileResponseType>;
}
