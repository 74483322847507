<awf-dashboard-panel [padding]="Size._0x">
  <div class="section-card-outer">
    <div class="section-card-tabs">
      <div *ngFor="let section of sections; let i = index;" class="section-card-tab" [ngClass]="{
          selected: section.id === selectedSectionId,
          first: i === 0,
          last: i === sections.length - 1
        }"
        (click)="selectSection(section.id)">
        <awc-icon [icon]="section.prefixIcon.icon" [src]="section.prefixIcon.src"></awc-icon>
        <span *ngIf="selectedSectionId === section.id" class="section-card-tab-title">{{section.text}}</span>
      </div>
    </div>
      <ng-container *ngFor="let section of sections;">
        <ng-container *ngIf="selectedSectionId === section.id">
          <slot [ngClass]="{disabled: section.disabled}" [name]="section.slotName"></slot>
        </ng-container>
      </ng-container>
  </div>
</awf-dashboard-panel>