import { Injectable } from '@angular/core';
import { AWFAction } from '@ansys/andromeda/contributions';
import { UnitType } from '../../../api';
import { ConceptUnitService } from '../../shared/services/unit.service';

type InputType = [number[], UnitType, number, number];
type ReturnType = [number[], number, number];

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class ConvertUnitValuesAction
  implements AWFAction<InputType, ReturnType>
{
  // This can be used to track end user operations and can be used to analyze the user behavior.
  readonly reportAnalysis = false;

  constructor(private unitChoices: ConceptUnitService) {}

  async execute(context: InputType): Promise<ReturnType> {
    const [values, unit, min = Infinity, max = 0] = context;
    let newMin = min;
    let newMax = max;

    const convertedValues: number[] = values.map((v: number) => {
      const convertedValue = this.unitChoices.convertUnit(unit, v);
      newMin = Math.min(newMin, convertedValue);
      newMax = Math.max(newMax, convertedValue);
      return convertedValue;
    });

    return [convertedValues, newMin, newMax];
  }
}
