/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Inertia Unit.
 */
export enum InertiaUnit {
    KG_M_ = 'kg·m²',
    G_MM_ = 'g·mm²',
}
