export const staticColumnsModel: string[] = [
  'speeds',
  'in_torques',
  'out_torques',
  'in_powers',
  'out_powers',
  'efficiencies',
  'losses',
  'gear_ratios',
];
