import { Icons } from '@ansys/awc-angular/icons';
import { ConceptSubSection } from 'src/app/shared/enums';

export const panelTabs = [
  {
    id: ConceptSubSection.MAIN,
    text: 'Data',
    prefixIcon: { icon: Icons.LOGS },
    slotName: ConceptSubSection.MAIN,
  },
  {
    id: ConceptSubSection.PLOTS,
    text: 'Data Config',
    prefixIcon: { icon: Icons.PLOTS },
    slotName: ConceptSubSection.PLOTS,
  },
];
