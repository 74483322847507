/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Component side.
 */
export enum ComponentSide {
    LEFT = 'Left',
    RIGHT = 'Right',
    NONE = 'None',
}
