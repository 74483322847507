import { AWCListContent } from '@ansys/awc-angular/lists';
import { PowerUnit } from 'src/api';

export const power: AWCListContent[] = [
  {
    text: 'kW',
    id: PowerUnit.K_W,
  },
  {
    text: 'W',
    id: PowerUnit.W,
  },
  {
    text: 'mW',
    id: PowerUnit.M_W,
  },
  {
    text: 'MW',
    id: PowerUnit.MW,
  },
  {
    text: 'hp',
    id: PowerUnit.HP,
  },
];
