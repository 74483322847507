/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Volume Unit.
 */
export enum VolumeUnit {
    M_ = 'm³',
    MM_ = 'mm³',
    CM_ = 'cm³',
    IN_ = 'in³',
    FT_ = 'ft³',
    YD_ = 'yd³',
    L = 'l',
    ML = 'ml',
    CC = 'cc',
}
