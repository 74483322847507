/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  effect,
} from '@angular/core';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ArchitectureTemplate } from 'src/app/shared/enums/concept.enum';
import { ArchitectureService } from 'src/app/shared/services/architecture.service';
import { AppThemeState, State, StateType } from '@ansys/andromeda/store';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { ArchitectureInputIds, MotorLossMap, UnitType } from 'src/api';
import { Size } from '@ansys/awc-angular/core';
import { Icons } from '@ansys/awc-angular/icons';
import { ActiveTabState } from '@ansys/andromeda/workspace';
import { ComponentsState } from 'src/app/state/lib/components.state';
import { Subject, takeUntil } from 'rxjs';
interface RelativePosition {
  x: number;
  y: number;
  height: number;
  width: number;
}
(Array.prototype as any).removeIcons = function (): void {
  return this.map((item: AWCListItem) => {
    const clone = { ...item };
    delete clone.prefixIcon;
    return clone;
  });
};
@Component({
  selector: 'app-motor-architecture',
  templateUrl: './motor-architecture.component.html',
  styleUrls: ['./motor-architecture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MotorArchitectureComponent implements OnDestroy {
  @Input() template!: ArchitectureTemplate;
  @ViewChild('mainCont') mainContainer!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;

  protected checked: boolean = true;
  protected label: string = 'Template';
  protected placeholder: string = 'Select a template';
  protected transmissionsFront: AWCListItem[] = [];
  protected transmissionsRear: AWCListItem[] = [];
  protected transmissions: AWCListItem[] = [];
  protected motors: AWCListItem[] = [];
  protected inverters: AWCListItem[] = [];
  protected clutches: AWCListItem[] = [];
  protected batteries: AWCListItem[] = [];
  protected inheritedItem: AWCListItem[] = [
    {
      text: 'Losses included in motor',
      id: 'inherit',
      userData: {
        id: 'inherit',
      },
      prefixIcon: { icon: Icons.ELECTRICAL },
    },
  ];
  protected inWheelMotor: AWCListItem[] = [
    {
      text: 'In Wheel Motor',
      id: 'in-wheel',
      userData: {
        id: 'in-wheel',
      },
    },
  ];
  protected zeroPadding: Size = Size._0x;
  protected componentsCost: number | undefined;
  protected componentsMass: number | undefined;
  protected readonly ArchitectureTemplate = ArchitectureTemplate;
  protected readonly UnitType = UnitType;
  protected theme!: string;
  protected itemsToDisplay: number = 5;
  protected selectedItems: Map<string, string> = new Map([
    ['transmissionFront', ''],
    ['clutchFront', 'neglect'],
    ['motorFront', ''],
    ['inverterFront', 'neglect'],
    ['transmissionRear', ''],
    ['motorRear', ''],
    ['clutchRear', 'neglect'],
    ['inverterRear', 'neglect'],
    ['battery', ''],
  ]);
  private canvasBounds!: DOMRect;
  private ctxStrokeStyle: string = '#fff';
  private ctxStrokeWidth: number = 3;
  private ctxLineCap: CanvasLineCap = 'round';
  private ctxLineJoin: CanvasLineJoin = 'round';
  private layerOffset: number = 15;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    protected architectureService: ArchitectureService,
    private state: State,
    private _cdr: ChangeDetectorRef
  ) {
    const tab = this.state.value(ActiveTabState);
    this.state
      .get(ComponentsState, tab?.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((li: AWCTreeItem[]) => {
        if (!li) return;
        this.filterListItems(li);
      });
    this.state
      .get(AppThemeState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme: StateType) => {
        if (theme && this.theme !== theme) {
          const isDarkMode =
            theme === 'dark' ||
            (theme === 'auto' &&
              window.matchMedia &&
              window.matchMedia('(prefers-color-scheme: dark)').matches);
          this.theme = isDarkMode ? 'dark' : ('light' as string);
          this.ctxStrokeStyle = isDarkMode ? '#fff' : 'rgb(66, 66, 66)';
          this.renderCanvas();
          this._cdr.markForCheck();
        }
      });
    effect(() => {
      const architecture = this.architectureService.architecture();
      const frontWheels = this.architectureService.frontWheelsCount();
      const rearWheels = this.architectureService.rearWheelsCount();
      const frontMotors = this.architectureService.frontMotorsCount();
      const rearMotors = this.architectureService.rearMotorsCount();

      if (architecture) {
        this.componentsCost = architecture.components_cost;
        this.componentsMass = architecture.components_mass;
        this.selectedItems.set('battery', architecture.battery_id);
        this.selectedItems.set(
          'clutchFront',
          architecture.front_clutch_id ?? 'neglect'
        );
        this.selectedItems.set(
          'transmissionFront',
          this.getTransmissionId(
            architecture.front_transmission_id,
            frontWheels === frontMotors
          )
        );
        this.selectedItems.set('motorFront', architecture.front_motor_id ?? '');
        this.selectedItems.set(
          'inverterFront',
          this.getInverterId(
            this.selectedItems.get('motorFront') || '',
            architecture.front_inverter_id ?? ''
          )
        );
        this.selectedItems.set(
          'transmissionRear',
          this.getTransmissionId(
            architecture.rear_transmission_id,
            rearWheels === rearMotors
          )
        );
        this.selectedItems.set('motorRear', architecture.rear_motor_id ?? '');
        this.selectedItems.set(
          'clutchRear',
          architecture.rear_clutch_id ?? 'neglect'
        );
        this.selectedItems.set(
          'inverterRear',
          this.getInverterId(
            this.selectedItems.get('motorRear') || '',
            architecture.rear_inverter_id ?? ''
          )
        );

        if (
          architecture.number_of_front_motors !== frontMotors ||
          architecture.number_of_rear_motors !== rearMotors ||
          architecture.number_of_front_wheels !== frontWheels ||
          architecture.number_of_rear_wheels !== rearWheels
        ) {
          this.setArchitecture();
        }
      }

      this.transmissionsFront = this.addInWheelMotor(
        this.transmissions,
        frontWheels === frontMotors
      );
      this.transmissionsRear = this.addInWheelMotor(
        this.transmissions,
        rearWheels === rearMotors
      );

      setTimeout(() => {
        this._cdr.detectChanges();
        this.renderCanvas();
      });
    });
  }
  ngAfterViewInit(): void {
    this.renderCanvas();
  }
  ngOnChanges(): void {
    setTimeout(() => {
      this.renderCanvas();
      this.selectedItems.set(
        'inverterFront',
        this.getInverterId(
          this.selectedItems.get('motorFront') || '',
          this.selectedItems.get('inverterFront') || ''
        )
      );
      this.selectedItems.set(
        'inverterRear',
        this.getInverterId(
          this.selectedItems.get('motorRear') || '',
          this.selectedItems.get('inverterRear') || ''
        )
      );
    }, 0);
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    [...this.selectedItems.entries()].forEach(([k, v]) => {
      v;
      this.selectedItems.set(k, '');
    });
  }
  protected getInverterId(
    motorId: string,
    inverterId: string | null | undefined
  ): string {
    let inheritFromMotor: boolean = false;
    if (motorId) {
      const motor = this.motors.find((motor) => motor.id === motorId);
      inheritFromMotor = (motor?.userData as MotorLossMap)
        ?.inverter_losses_included as boolean;
    }
    return (inheritFromMotor ? 'inherit' : '') || inverterId || 'neglect';
  }
  protected getTransmissionId(
    transmissionId: string | null | undefined,
    inWheel: boolean
  ): string {
    if (!transmissionId && inWheel) return 'in-wheel';
    return transmissionId || '';
  }
  protected setArchitectureValue(id: string | unknown, type: string): void {
    if (this.selectedItems.get(type) === undefined) {
      throw new Error('Invalid type');
    }
    this.selectedItems.set(type, id as string);
    this.setArchitecture();
    this.renderCanvas();
  }
  protected renderCanvas(): void {
    if (!this.canvas || !this.mainContainer) return;
    const canvas = this.canvas.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const padding = 8;
    canvas.width = this.mainContainer.nativeElement.clientWidth;
    canvas.height = this.mainContainer.nativeElement.clientHeight;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineCap = this.ctxLineCap;
    ctx.lineJoin = this.ctxLineJoin;
    this.canvasBounds = canvas.getBoundingClientRect();
    const [
      clutchVector,
      transmissionVector,
      motorVector,
      inverterVector,
      clutchVectorRear,
      transmissionVectorDual,
      motorVectorDual,
      inverterVectorDual,
      batteryVector,
    ] = [
      ...this.mainContainer.nativeElement.getElementsByClassName('svg-cont'),
    ].map((el: HTMLElement) => {
      return this.getRelativePosition(el.getBoundingClientRect());
    });
    const singleYOffset = clutchVector.y + clutchVector.height / 2;
    this.drawWheelLines(
      ctx,
      this.selectedItems.get('clutchFront') !== 'neglect'
        ? clutchVector
        : transmissionVector,
      this.selectedItems.get('clutchRear') !== 'neglect'
        ? clutchVectorRear
        : transmissionVectorDual,
      padding
    );
    if (this.architectureService.frontMotorsCount() > 0) {
      if (this.selectedItems.get('clutchFront') !== 'neglect') {
        this.drawComponentLines(
          ctx,
          clutchVector,
          transmissionVector,
          singleYOffset
        );
      }
      this.drawComponentLines(
        ctx,
        transmissionVector,
        motorVector,
        singleYOffset
      );
      this.drawComponentLines(ctx, motorVector, inverterVector, singleYOffset);
    }
    const selectedFrontInverter = this.selectedItems.get('inverterFront');
    const selectedRearInverter = this.selectedItems.get('inverterRear');
    if (this.architectureService.rearMotorsCount() === 0) {
      this.drawComponentLines(
        ctx,
        selectedFrontInverter !== 'neglect' ? inverterVector : motorVector,
        batteryVector,
        singleYOffset
      );
    } else {
      const dualYOffset = clutchVectorRear.y + clutchVectorRear.height / 2;
      if (this.selectedItems.get('clutchRear') !== 'neglect') {
        this.drawComponentLines(
          ctx,
          clutchVectorRear,
          transmissionVectorDual,
          dualYOffset,
          true
        );
      }
      this.drawComponentLines(
        ctx,
        transmissionVectorDual,
        motorVectorDual,
        dualYOffset,
        true
      );
      this.drawComponentLines(
        ctx,
        motorVectorDual,
        inverterVectorDual,
        dualYOffset,
        true
      );
      this.drawBatteryLines(
        ctx,
        selectedFrontInverter !== 'neglect' ? inverterVector : motorVector,
        selectedRearInverter !== 'neglect'
          ? inverterVectorDual
          : motorVectorDual,
        batteryVector,
        singleYOffset,
        dualYOffset
      );
    }
  }
  private filterListItems(li: AWCTreeItem[]): void {
    const _ = (item: AWCTreeItem, type: string): boolean => {
      return !!(item.userData && item.userData['_parentID'] === type);
    };
    const removeIcon = (item: AWCTreeItem): AWCListItem => {
      const clone = { ...item };
      delete clone.prefixIcon;
      delete clone.parent;
      return clone;
    };
    this.transmissions = li.filter((l) => _(l, 'transmission')).map(removeIcon);
    this.transmissionsFront = this.addInWheelMotor(
      this.transmissions,
      this.architectureService.frontWheelsCount() ===
        this.architectureService.frontMotorsCount()
    );
    this.transmissionsRear = this.addInWheelMotor(
      this.transmissions,
      this.architectureService.rearWheelsCount() ===
        this.architectureService.rearMotorsCount()
    );
    this.motors = li.filter((l) => _(l, 'motor')).map(removeIcon);
    this.inverters = this.appendNeglectItem(
      li.filter((l) => _(l, 'inverter'))
    ).map(removeIcon);
    this.clutches = this.appendNeglectItem(
      li.filter((l) => _(l, 'clutch'))
    ).map(removeIcon);
    this.batteries = li.filter((l) => _(l, 'battery')).map(removeIcon);
    this._cdr.markForCheck();
  }
  private addInWheelMotor(list: AWCTreeItem[], add: boolean): AWCTreeItem[] {
    if (add) {
      return [...this.inWheelMotor, ...list];
    } else return list;
  }
  private appendNeglectItem(list: AWCTreeItem[]): AWCTreeItem[] {
    const neglect: AWCTreeItem = {
      id: 'neglect',
      text: 'Neglect',
      userData: {
        id: 'neglect',
      },
      prefixIcon: { icon: Icons.ELECTRICAL },
    };
    return [neglect, ...list];
  }
  /**
   */
  private setArchitecture(): void {
    const partNeglected = (inverter: string): boolean => {
      return inverter !== 'neglect' && inverter !== 'inherit';
    };
    const useTransmission = (transmission: string): boolean => {
      return !!(transmission.length && transmission !== 'in-wheel');
    };
    const architectureTemplate: ArchitectureInputIds = {
      front_transmission_id: useTransmission(
        this.selectedItems.get('transmissionFront') || ''
      )
        ? this.selectedItems.get('transmissionFront')
        : null,
      front_motor_id: this.selectedItems.get('motorFront') || null,
      rear_transmission_id: useTransmission(
        this.selectedItems.get('transmissionRear') || ''
      )
        ? this.selectedItems.get('transmissionRear')
        : null,
      rear_motor_id: this.selectedItems.get('motorRear') || null,
      battery_id: this.selectedItems.get('battery') || '',
      front_inverter_id: partNeglected(
        this.selectedItems.get('inverterFront') || ''
      )
        ? this.selectedItems.get('inverterFront')
        : null,
      rear_inverter_id: partNeglected(
        this.selectedItems.get('inverterRear') || ''
      )
        ? this.selectedItems.get('inverterRear')
        : null,
      front_clutch_id: partNeglected(
        this.selectedItems.get('clutchFront') || ''
      )
        ? this.selectedItems.get('clutchFront')
        : null,
      rear_clutch_id: partNeglected(this.selectedItems.get('clutchRear') || '')
        ? this.selectedItems.get('clutchRear')
        : null,
      number_of_front_wheels: this.architectureService.frontWheelsCount(),
      number_of_front_motors: this.architectureService.frontMotorsCount(),
      number_of_rear_wheels: this.architectureService.rearWheelsCount(),
      number_of_rear_motors: this.architectureService.rearMotorsCount(),
      wheelbase: this.architectureService.architecture()?.wheelbase || null,
    };

    const architecture = Object.entries(architectureTemplate).reduce(
      (a: ArchitectureInputIds, [k, v]) =>
        v === null ? a : ((a[k as keyof ArchitectureInputIds] = v as never), a),
      {} as ArchitectureInputIds
    );

    this.selectedItems.set(
      'inverterFront',
      this.getInverterId(
        this.selectedItems.get('motorFront') || '',
        architecture.front_inverter_id
      )
    );
    this.selectedItems.set(
      'inverterRear',
      this.getInverterId(
        this.selectedItems.get('motorRear') || '',
        architecture.rear_inverter_id
      )
    );
    this.architectureService.setArchitecture(architecture);
  }

  private drawBatteryLines(
    ctx: CanvasRenderingContext2D,
    inverterOne: RelativePosition,
    inverterTwo: RelativePosition,
    battery: RelativePosition,
    yOffsetOne: number,
    yOffsetTwo: number,
    padding = 8
  ): void {
    ctx.save();
    ctx.beginPath();
    if (this.architectureService.frontMotorsCount() > 0) {
      if (this.architectureService.frontMotorsCount() > 1) {
        ctx.moveTo(
          inverterOne.x + inverterOne.width + padding + this.layerOffset,
          yOffsetOne - this.layerOffset
        );
        ctx.lineTo(
          battery.x + battery.width / 2,
          yOffsetOne - this.layerOffset
        );
        ctx.lineTo(battery.x + battery.width / 2, yOffsetOne);
      }
      ctx.moveTo(inverterOne.x + inverterOne.width + padding, yOffsetOne);
      ctx.lineTo(battery.x + battery.width / 2, yOffsetOne);
      ctx.lineTo(battery.x + battery.width / 2, battery.y - padding);
    }
    const [batteryCont] = [
      ...this.mainContainer.nativeElement.getElementsByClassName(
        'battery-cont'
      ),
    ].map((el: HTMLElement) => {
      return this.getRelativePosition(el.getBoundingClientRect());
    });
    if (this.architectureService.rearMotorsCount() > 1) {
      ctx.moveTo(
        inverterTwo.x + inverterTwo.width + padding + this.layerOffset,
        yOffsetTwo - this.layerOffset
      );
      if (this.architectureService.frontMotorsCount() > 0) {
        ctx.lineTo(
          battery.x + battery.width / 2,
          yOffsetTwo - this.layerOffset
        );
        ctx.lineTo(battery.x + battery.width / 2, yOffsetTwo);
      } else {
        ctx.lineTo(battery.x - padding, yOffsetTwo - this.layerOffset);
      }
    }
    ctx.moveTo(inverterTwo.x + inverterTwo.width + padding, yOffsetTwo);
    if (this.architectureService.frontMotorsCount() > 0) {
      ctx.lineTo(battery.x + battery.width / 2, yOffsetTwo);
      ctx.lineTo(
        battery.x + battery.width / 2,
        batteryCont.y + batteryCont.height + padding
      );
    } else {
      ctx.lineTo(battery.x - padding, yOffsetTwo);
    }
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }
  private drawComponentLines(
    ctx: CanvasRenderingContext2D,
    componentOne: RelativePosition,
    componentTwo: RelativePosition,
    yOffset: number,
    rear = false,
    padding = 8
  ): void {
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(componentOne.x + componentOne.width + padding, yOffset);
    ctx.lineTo(componentTwo.x - padding, yOffset);
    if (
      (!rear && this.architectureService.frontMotorsCount() > 1) ||
      (rear && this.architectureService.rearMotorsCount() > 1)
    ) {
      ctx.save();
      ctx.strokeStyle = `rgba(${this.ctxStrokeStyle}, 0,1)`;
      ctx.moveTo(
        componentOne.x + componentOne.width + padding + this.layerOffset,
        yOffset - this.layerOffset
      );
      ctx.lineTo(
        componentTwo.x - padding + this.layerOffset,
        yOffset - this.layerOffset
      );
      ctx.restore();
    }
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }
  private getRelativePosition(boundingBox: DOMRect): RelativePosition {
    return {
      x: boundingBox.x - this.canvasBounds.x,
      y: boundingBox.y - this.canvasBounds.y,
      width: boundingBox.width,
      height: boundingBox.height,
    };
  }
  private getYOffset(pos1: RelativePosition, pos2: RelativePosition): number {
    return pos2.y + pos2.height / 2 - (pos1.y + pos1.height / 2);
  }
  private drawWheelLines(
    ctx: CanvasRenderingContext2D,
    transmissionVector: RelativePosition,
    transmissionVectorDual: RelativePosition,
    padding = 8
  ): [number, number] {
    const [wheelOne, wheelTwo] = [
      ...this.mainContainer.nativeElement.getElementsByClassName('wheel-front'),
    ].map((el: HTMLElement) => {
      return this.getRelativePosition(el.getBoundingClientRect());
    });
    const [wheelThree, wheelFour] = [
      ...this.mainContainer.nativeElement.getElementsByClassName('wheel-rear'),
    ].map((el: HTMLElement) => {
      return this.getRelativePosition(el.getBoundingClientRect());
    });
    // eslint-disable-next-line no-unused-vars
    const [firstColSingle, firstColDual] = [
      ...this.mainContainer.nativeElement.getElementsByClassName('first-col'),
    ].map((el: HTMLElement) => {
      return this.getRelativePosition(el.getBoundingClientRect());
    });
    ctx.beginPath();
    ctx.lineWidth = this.ctxStrokeWidth;
    ctx.strokeStyle = this.ctxStrokeStyle;
    const colXOffset =
      firstColSingle.x - (wheelOne.x + wheelOne.width) - padding;
    const firstWheelYOffset = this.getYOffset(wheelOne, transmissionVector);

    let secondWheelYOffset = 0;

    const drawWheelJoin = (
      wheel: RelativePosition,
      yOffset: number,
      motors?: number
    ): void => {
      const yJoinOffset = motors && motors > 1 ? this.layerOffset : 0;
      ctx.moveTo(wheel.x + wheel.width + padding, wheel.y + wheel.height / 2);
      ctx.lineTo(
        wheel.x + wheel.width + colXOffset / 2,
        wheel.y + wheel.height / 2
      );
      ctx.lineTo(
        wheel.x + wheel.width + colXOffset / 2,
        wheel.y + wheel.height / 2 + yOffset - yJoinOffset
      );
      if (yJoinOffset) {
        ctx.moveTo(
          wheel.x + wheel.width + colXOffset / 2,
          wheel.y + wheel.height / 2 + yOffset
        );
      }
    };
    // wheel 1
    if (this.architectureService.frontMotorsCount() > 0) {
      if (this.architectureService.frontWheelsCount() > 1) {
        drawWheelJoin(
          wheelOne,
          firstWheelYOffset,
          this.architectureService.frontMotorsCount()
        );
      } else {
        ctx.moveTo(
          wheelOne.x + wheelOne.width + colXOffset * 0.25,
          wheelOne.y + wheelOne.height / 2 + firstWheelYOffset
        );
      }
      ctx.lineTo(
        transmissionVector.x - padding,
        wheelOne.y + wheelOne.height / 2 + firstWheelYOffset
      );
      // wheel 2
      if (this.architectureService.frontWheelsCount() > 1) {
        secondWheelYOffset = this.getYOffset(wheelTwo, transmissionVector);
        drawWheelJoin(wheelTwo, secondWheelYOffset);
      }
    }
    if (this.architectureService.frontMotorsCount() > 1) {
      ctx.save();
      ctx.strokeStyle = `rgba(${this.ctxStrokeStyle}, 0,1)`;

      ctx.moveTo(
        wheelOne.x + wheelOne.width + colXOffset / 2,
        wheelOne.y + wheelOne.height / 2 + firstWheelYOffset - this.layerOffset
      );
      ctx.lineTo(
        transmissionVector.x - padding + this.layerOffset,
        wheelOne.y + wheelOne.height / 2 + firstWheelYOffset - this.layerOffset
      );
      ctx.restore();
    }

    if (this.architectureService.rearMotorsCount() > 0) {
      const firstWheelYOffset = this.getYOffset(
        wheelThree,
        transmissionVectorDual
      );
      let secondWheelYOffset = 0;
      // wheel 3

      if (this.architectureService.rearWheelsCount() > 1) {
        drawWheelJoin(
          wheelThree,
          firstWheelYOffset,
          this.architectureService.rearMotorsCount()
        );
      } else {
        ctx.moveTo(
          wheelThree.x + wheelThree.width + colXOffset * 0.25,
          wheelThree.y + wheelThree.height / 2 + firstWheelYOffset
        );
      }
      ctx.lineTo(
        transmissionVectorDual.x - padding,
        wheelThree.y + wheelThree.height / 2 + firstWheelYOffset
      );
      if (this.architectureService.rearMotorsCount() > 1) {
        ctx.moveTo(
          wheelThree.x + wheelThree.width + colXOffset / 2,
          wheelThree.y +
            wheelThree.height / 2 +
            firstWheelYOffset -
            this.layerOffset
        );
        ctx.lineTo(
          transmissionVector.x - padding + this.layerOffset,
          wheelThree.y +
            wheelThree.height / 2 +
            firstWheelYOffset -
            this.layerOffset
        );
      }
      // wheel 4
      if (this.architectureService.rearWheelsCount() > 1) {
        secondWheelYOffset = this.getYOffset(wheelFour, transmissionVectorDual);
        drawWheelJoin(wheelFour, secondWheelYOffset);
      }
    }
    ctx.stroke();
    ctx.closePath();
    return [firstWheelYOffset, secondWheelYOffset];
  }
}
