/* eslint-disable @typescript-eslint/naming-convention */
export interface Registry {
  unregister: () => void;
}

export interface Callable {
  [key: string]: (args?: unknown) => void;
}

export interface Subscriber {
  [key: string]: Callable;
}

export interface IEventBus {
  dispatch<T>(event: string, arg?: T): void;
  register(event: string, callback: (args: unknown) => void): Registry;
}
/**
 * @description A simple event bus implementation
 * @example
 * const eventBus = new EventBus();
 * eventBus.register('myEventLabel', (arg) => console.log(arg));
 * eventBus.dispatch('myEventLabel', 'Hello World!');
 */
export class EventBus implements IEventBus {
  private static nextId = 0;
  private static instance?: EventBus = undefined;
  public static getInstance(): EventBus {
    if (this.instance === undefined) {
      this.instance = new EventBus();
    }
    return this.instance;
  }

  private subscribers: Subscriber;

  private constructor() {
    this.subscribers = {};
  }

  public dispatch<T>(event: string, arg?: T): void {
    const subscriber = this.subscribers[event];
    if (subscriber === undefined) {
      return;
    }
    Object.keys(subscriber).forEach((key) => subscriber[key](arg));
  }

  public register(event: string, callback: (args?: any) => void): Registry {
    const id = this.getNextId();
    if (!this.subscribers[event]) this.subscribers[event] = {};

    this.subscribers[event][id] = callback;

    return {
      unregister: (): void => {
        delete this.subscribers[event][id];
        if (Object.keys(this.subscribers[event]).length === 0)
          delete this.subscribers[event];
      },
    };
  }

  private getNextId(): number {
    return EventBus.nextId++;
  }
}
