<app-input
  label="Rolling Resistance"
  placeholder="Rolling Resistance..."
  [value]="part.rolling_resistance_coefficient"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_rolling_resistance_coefficient'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'rolling_resistance_coefficient', part.id+'_rolling_resistance_coefficient'])"></app-input>
<app-input
  label="Traction Coefficient"
  placeholder="Traction..."
  [value]="part.traction_coefficient"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_traction_coefficient'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'traction_coefficient', part.id+'_traction_coefficient'])"></app-input>
<app-input
  label="Rolling Radius"
  placeholder="Rolling Radius..."
  [value]="part.rolling_radius"
  [unit]="UnitType.LENGTH"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_rolling_radius'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'rolling_radius', part.id+'_rolling_radius'])"></app-input>
<app-input
  label="Mass"
  placeholder="Optional..."
  [value]="part.mass"
  [unit]="UnitType.MASS"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_mass'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'mass', part.id+'_mass'])"></app-input>