<ng-container *ngIf="filteredItems.length; else noResults;">
  <div class="selection-container" [style.height]="maxHeight()+'px'">
    <ng-container *ngFor="let item of filteredItems">
      <div class="flex f-col" *ngIf="item?.userData" >
        <div class="flex config-header" *ngIf="filteredItems.length > 1" (click)="expandItem(item)">
          <div class="config-header__text">
            <awc-icon [icon]="item.prefixIcon?.icon" [ngStyle]="{color: activeID === item.id ? 'rgb(33, 150, 243)' : ''}" [src]="item.prefixIcon?.src" style="margin: auto 15px; margin-right: 0;"></awc-icon>
            <span>{{item?.userData?.name}}</span>
          </div>
          <awc-icon [icon]="chevron" [ngClass]="activeID === item.id ? 'active-item-icon' : ''"></awc-icon>
        </div>
        <div class="flex item" [ngClass]="activeID === item.id ? 'active-item' : ''" >
          <div class="input-cont f-col flex p1rem">
            <app-part-inputs [(part)]="item.userData" [type]="item.userData._parentID" [update]="true" (updateInput)="sendUpdate($event, item)"></app-part-inputs>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #noResults>
  <div class="no-results">
    <awf-no-results></awf-no-results>
    <span>No Selection</span>
  </div>
</ng-template>
