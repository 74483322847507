/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Axle, DataType, LineWidth, MarkerType } from '../enums/blueprint.enum';
import { Coords, DataLabel } from '../types/blueprint.type';
import * as render from '../render/render';
import { InjectionToken } from '@angular/core';
import { SolvedComponent } from '../../blueprint-display.component';

export const MARKER_DATA = new InjectionToken<
  [Coords | Coords[], SolvedComponent, boolean?, Axle?]
>('MarkerData');

export class BlueprintMarker {
  get data(): DataLabel[] {
    return this.$data;
  }
  set data(data: DataLabel[]) {
    this.$data = data;
  }
  get originX(): number | undefined {
    return this.$originX;
  }
  set originX(x: number | undefined) {
    this.$originX = x;
  }
  get originY(): number | undefined {
    return this.$originY;
  }
  set originY(y: number | undefined) {
    this.$originY = y;
  }
  get linkX(): number {
    return (
      this.linkCoords?.x ??
      (Array.isArray(this.nodeCoords)
        ? (this.nodeCoords as Array<Coords>)[0].x
        : this.nodeCoords.x)
    );
  }
  set linkX(x: number) {
    this.linkCoords = { x, y: this.linkCoords?.y || 0 };
  }
  get linkY(): number {
    return (
      this.linkCoords?.y ??
      (Array.isArray(this.nodeCoords)
        ? (this.nodeCoords as Array<Coords>)[0].y
        : this.nodeCoords.y)
    );
  }
  set linkY(y: number) {
    this.linkCoords = { x: this.linkCoords?.x || 0, y };
  }
  public width: number = 110;
  public height: number = 40;
  public placed: boolean = false;
  public _ready: boolean = false;
  public title: string = '';
  public elRef: HTMLElement | undefined;
  public _yOffset: number = 0;
  private $originX!: number | undefined;
  private $originY!: number | undefined;
  private fillMap = new Map<DataType, string>([
    [DataType.MECHANICAL, '#FF9800'],
    [DataType.ELECTRICAL, '#4CB243'],
    [DataType.COMPONENT, '#5783F5'],
  ]);
  private fill!: string;
  constructor(
    public type: DataType,
    public marker: MarkerType,
    public $data: DataLabel[],
    public display: boolean,
    public nodeCoords: Coords | Coords[],
    public axle?: Axle,
    public linkCoords?: Coords
  ) {}
  render(ctx: CanvasRenderingContext2D): void {
    if (!this.display) return;
    ctx.save();
    this.fill = this.fillMap.get(this.type) || '#5783F5';
    ctx.fillStyle = this.fill;
    ctx.strokeStyle = this.fill;
    render.setStrokeStyle(ctx, LineWidth.HAIRLINE, this.fill);
    let markerCoord: Coords = { x: 0, y: 0 };
    if (!Array.isArray(this.nodeCoords)) {
      markerCoord = this.nodeCoords;
      ctx.beginPath();
      ctx.ellipse(
        this.nodeCoords.x,
        this.nodeCoords.y,
        4,
        4,
        0,
        0,
        2 * Math.PI
      );
      ctx.fill();
      ctx.closePath();
      ctx.beginPath();
      ctx.ellipse(
        this.nodeCoords.x,
        this.nodeCoords.y,
        10,
        10,
        0,
        0,
        2 * Math.PI
      );
      ctx.stroke();
      ctx.closePath();
    } else {
      this.nodeCoords.forEach((node, i, coords) => {
        if (!i) markerCoord = node;
        if (coords[i + 1]) {
          ctx.beginPath();
          ctx.moveTo(node.x, node.y);
          ctx.lineTo(coords[i + 1].x, coords[i + 1].y);
          ctx.stroke();
        }
        ctx.beginPath();
        ctx.ellipse(node.x, node.y, 4, 4, 0, 0, 2 * Math.PI);
        ctx.fill();
        ctx.closePath();
        ctx.beginPath();
        ctx.ellipse(node.x, node.y, 10, 10, 0, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();
      });
    }
    ctx.beginPath();
    ctx.moveTo(markerCoord.x, markerCoord.y);
    if (!this.linkCoords) {
      this.linkCoords = markerCoord;
    }
    // find closest corner of marker to markerCoord
    const bottomLeftY = this.linkY + this.height;
    const topDiff = Math.abs(this.linkY - markerCoord.y);
    const bottomDiff = Math.abs(bottomLeftY - markerCoord.y);
    const linkY = topDiff < bottomDiff ? this.linkY : bottomLeftY;

    const bottomRightX = this.linkX + this.width;
    const leftDiff = Math.abs(this.linkX - markerCoord.x);
    const rightDiff = Math.abs(bottomRightX - markerCoord.x);
    const linkX = leftDiff < rightDiff ? this.linkX : bottomRightX;
    ctx.lineTo(linkX, linkY);
    ctx.stroke();
    ctx.closePath();
    ctx.ellipse(linkX, linkY, 3, 3, 0, 0, 2 * Math.PI);
    ctx.fill();
    ctx.restore();
  }
  updateData(data: any, index: number | string = 0): void {
    data;
    index;
  }
  updateBounds(): void {
    this.width = this.elRef?.offsetWidth || 110;
    this.height = this.elRef?.offsetHeight || 40;
  }
}
