import {
  TabTitleDisplayStrategy,
  TabType,
  WorkspaceTab,
} from '@ansys/andromeda/contributions';
import { ConceptEnum } from './enums/concept.enum';
import { ConceptService } from '../../shared/services/concept.service';
import { DesignApiService, ProjectApiService } from '@ansys/andromeda/api/rest';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConceptTab implements TabType {
  type: string = ConceptEnum.TAB_TYPE;
  public title = 'Concept';
  public urlParamKeys = ['instanceId'];
  public closable = true;
  public displayStrategy = TabTitleDisplayStrategy.TEXT;
  constructor(
    private conceptService: ConceptService,
    private projectService: ProjectApiService,
    private designService: DesignApiService
  ) {}
  async getTitle(tab: WorkspaceTab): Promise<string> {
    let project;
    if (tab?.userData) {
      const instance = await lastValueFrom(
        this.designService.designInstanceLoadHandler({
          designInstanceId: tab?.userData?.['id'] as string,
        })
      );
      project = await lastValueFrom(
        this.projectService.projectLoadHandler({
          projectId: instance?.projectId as string,
        })
      );
    }
    return project?.projectTitle ?? this.title;
  }
}
