import { Axle, DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import { SolvedInverter, UnitType } from 'src/api';
import * as Utils from './util';
import { Inject } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import {
  SolvedComponent,
  SolvedComponents,
} from '../../blueprint-display.component';
export class InverterComponentMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA)
    private markerData: [Coords | Coords[], SolvedInverter, boolean, Axle],
    private units: ConceptUnitService
  ) {
    const [coords, results, display, axle] = markerData;
    super(DataType.COMPONENT, MarkerType.Inverter, [], display, coords);
    const title = results.name
      .replace('(Front) (Left)', '(Front)')
      .replace('(Front) (Right)', '(Front)')
      .replace('(Rear) (Left)', '(Rear)')
      .replace('(Rear) (Right)', '(Rear)');
    this.title = title || 'Inverter';
    this.axle = axle;
    this.updateData([results]);
  }
  override updateData(data: SolvedComponents, index: number = 0): void {
    const results = data.find(
      (c: SolvedComponent) =>
        c.solved_component_type === 'inverter' && c.axle === this.axle
    ) as SolvedInverter;
    this.data = [
      {
        label: 'Losses',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.losses[index])
        ),
        unit: UnitType.POWER,
      },
      {
        label: 'Efficiency',
        value:
          '' +
          toSN(
            Utils.calculateEff(
              results.out_powers[index],
              results.in_powers[index]
            )
          ) +
          '%',
      },
      {
        label: 'Vdrop',
        value: toSN(
          this.units.convertUnit(
            UnitType.VOLTAGE,
            results.in_voltages[index] - results.out_voltages[index]
          )
        ),
        unit: UnitType.VOLTAGE,
      },
      {
        label: 'Modulation Depth',
        value: toSN(results.modulation_depths?.[index]),
      },
      // { label: 'Duty Cycle', value: '' },
    ];
  }
}
