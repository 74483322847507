import { TabSectionType } from '@ansys/andromeda/contributions';

import { DashboardSection } from '@ansys/andromeda/dashboard';
import { ConceptSections } from '../../../../shared/enums';

export class VehicleSection extends DashboardSection implements TabSectionType {
  public override type: ConceptSections = ConceptSections.CONFIGURATIONS;
  public override title: string = 'Vehicle';
  public override src: string = 'assets/icons/vehicle.svg';
  constructor() {
    super();
  }
}
