import { TabSectionType } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { DashboardSection } from '@ansys/andromeda/dashboard';
import { ConceptSections } from '../../../../shared/enums';

export class ComponentSection
  extends DashboardSection
  implements TabSectionType
{
  public override type: ConceptSections = ConceptSections.COMPONENT;
  public override title: string = 'Component';
  public override icon: Icons = Icons.MODELER;
  constructor() {
    super();
  }
}
