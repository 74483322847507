/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Deceleration limit with Database ID.
 */
export type DecelerationLimitInDB = {
    item_type?: DecelerationLimitInDB.item_type;
    name?: string;
    limit: number;
    config_type?: 'deceleration_limit';
    _id?: string;
};
export namespace DecelerationLimitInDB {
    export enum item_type {
        CONFIG = 'config',
    }
}

