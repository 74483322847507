/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { Body_create_dynamic_requirement_examples_requirements_calculate_examples_post } from '../models/Body_create_dynamic_requirement_examples_requirements_calculate_examples_post';
import type { DynamicRequirement } from '../models/DynamicRequirement';
import type { Requirement } from '../models/Requirement';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class RequirementsService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Create
     * Create from parameters.
     * @param designInstanceId
     * @param requestBody
     * @returns Requirement Successful Response
     * @throws ApiError
     */
    public createRequirementsPost(
        designInstanceId: string,
        requestBody: Requirement,
    ): Observable<Requirement> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/requirements',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read
     * Get from ID.
     * @param itemId
     * @param designInstanceId
     * @returns Requirement Successful Response
     * @throws ApiError
     */
    public readRequirementsItemIdGet(
        itemId: string,
        designInstanceId: string,
    ): Observable<Requirement> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/requirements/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * Update with new parameters.
     * @param itemId
     * @param designInstanceId
     * @param requestBody
     * @returns Requirement Successful Response
     * @throws ApiError
     */
    public updateRequirementsItemIdPut(
        itemId: string,
        designInstanceId: string,
        requestBody: Requirement,
    ): Observable<Requirement> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/requirements/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * Delete by ID.
     * @param itemId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteRequirementsItemIdDelete(
        itemId: string,
        designInstanceId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/requirements/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Dynamic Requirement Examples
     * Create dynamic requirements from inputs.
     * @param designInstanceId
     * @param requestBody
     * @returns DynamicRequirement Successful Response
     * @throws ApiError
     */
    public createDynamicRequirementExamplesRequirementsCalculateExamplesPost(
        designInstanceId: string,
        requestBody: Body_create_dynamic_requirement_examples_requirements_calculate_examples_post,
    ): Observable<Array<DynamicRequirement>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/requirements:calculate_examples',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
