<ng-container [ngSwitch]="plotConfigType">
  <div *ngSwitchCase="PlotConfigType.NONE" class="no-results">
    <awf-no-results></awf-no-results>
    <span>Nothing to see here!</span>
  </div>
  <div class="config-inputs" *ngSwitchCase="PlotConfigType.GEARS">
    <awc-select
      label="Gears"
      placeholder="Gears..."
      [items]="componentGears"
      [layout]="layout"
      [selectedItems]="selectedGear"
      (selectionChanged)="selectionIDSet($event[0].id, 'gearRatio')"></awc-select>
  </div>
  <div class="config-inputs" *ngSwitchCase="PlotConfigType.VOLTAGE">
    <awc-select
      label="Voltage"
      placeholder="Voltage..."
      [items]="componentVoltages"
      [layout]="layout"
      [selectedItems]="selectedVoltage"
      (selectionChanged)="selectionIDSet($event[0].id, 'voltage')"></awc-select>
  </div>
  <div class="config-inputs" *ngSwitchCase="PlotConfigType.CONFIGURATIONS">
    <app-input
      label="Max Speed"
      placeholder="Speed..."
      [type]="InputTypes.NUMBER"
      id="speed_env_config"
      [unit]="UnitType.SPEED"
      [value]="vehicleOverviewSpeed"
      [updater]="false"
      (update)="setEnv($event, 'speed')"></app-input>
    <app-input
      label="Gradient"
      placeholder="Gradient..."
      [type]="InputTypes.NUMBER"
      [value]="vehicleOverviewGradient"
      [unit]="UnitType.GRADIENT"
      id="gradient_env_config"
      [updater]="false"
      (update)="setEnv($event, 'gradient')"></app-input>
    <app-input
      label="Acceleration"
      placeholder="Acceleration..."
      [unit]="UnitType.ACCELERATION"
      [type]="InputTypes.NUMBER"
      [value]="vehicleOverviewAcceleration"
      id="acceleration_env_config"
      [updater]="false"
      (update)="setEnv($event, 'acceleration')"></app-input>
    <app-input
      label="Altitude"
      placeholder="Altitude..."
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.LENGTH"
      [value]="vehicleOverviewAltitude"
      id="altitude_env_config"
      [updater]="false"
      (update)="setEnv($event, 'altitude')"></app-input>
    <app-input
      label="Headwind"
      placeholder="Headwind..."
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.SPEED"
      [value]="vehicleOverviewHeadwind"
      id="headwind_env_config"
      [updater]="false"
      (update)="setEnv($event, 'headwind')"></app-input>
    <app-input
      label="Stepsize"
      placeholder="Step..."
      [type]="InputTypes.NUMBER"
      [value]="vehicleOverviewStepsize"
      id="stepsize_env_config"
      [updater]="false"
      (update)="setEnv($event, 'stepsize')"></app-input>
  </div>
  <div class="config-inputs" *ngSwitchCase="PlotConfigType.REQUIREMENTS">
    <ng-container *ngIf="this.dataDisplay.displayState == DataDisplayState.TABLE_DISPLAY && requirementColumns.length > 0">
      <div class="f-cont-wide">
        <awc-label [text]="'Data Display'"></awc-label>
        <div class="toggle-all">
          <awc-button
            [text]="'Select All'"
            [type]="btnType"
            [size]="btnSize"
            (clicked)="onToggleAllColumns(true)"
          ></awc-button>
          <awc-button
            [text]="'Deselect All'"
            [type]="btnType"
            [size]="btnSize"
            (clicked)="onToggleAllColumns(false)"
          ></awc-button>
        </div>

        <awc-switch *ngFor="let column of requirementColumns"
          [checked]="selectedRequirementColumns.includes(column.id)"
          [label]="column.text"
          [awcTooltip]="'Toggle ' + column.text"
          (changes)="onToggleColumns($event, column)"></awc-switch>
      </div>
    </ng-container>

    <ng-container *ngIf="this.dataDisplay.displayState == DataDisplayState.GRAPH_DISPLAY && activeResult?.requirement_solved_type === 'drive_cycle'">
      <div class="part-edit-inputs__subtitle">
        <span>Add Plot Axis</span>
        <awc-button
          text="Add Axis"
          [suffixIcon]="addIcon"
          [type]="alttype"
          [size]="smallsize"
          [iconOnly]="true"
          (clicked)="addPlotAxis()"></awc-button>
      </div>
      <div>
        <ng-container *ngFor="let axis of driveCycleAxis; let i = index;">
          <div class="axis-container">
            <awc-select
              label="Component"
              placeholder="Component..."
              [items]="driveCycleComponents"
              [layout]="layout"
              [selectedItems]="[axis.component]"
              (selectionChanged)="selectionDriveCycleComponentAxis($event[0], axis)"></awc-select>
            <awc-select
              label="Attribute"
              placeholder="Attribute..."
              [items]="getMatchingAttributes(axis.type)"
              [layout]="layout"
              [selectedItems]="[axis.attribute]"
              (selectionChanged)="selectionDriveCycleAttributeAxis($event[0].id, axis)"></awc-select>
            <input type="color" id="html5colorpicker" (input)="changeAxisColor($event, axis)" [value]="axis.color" style="width:40px;">
            <awc-button
              text="Delete Axis"
              [suffixIcon]="{icon: Icons.DELETE}"
              [type]="alttype"
              [size]="smallsize"
              [iconOnly]="true"
              (clicked)="removeAxis(i)"></awc-button>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="this.dataDisplay.displayState == DataDisplayState.GRAPH_DISPLAY && (activeResult?.requirement_solved_type === 'static' || activeResult?.requirement_solved_type === 'dynamic')">
      <div class="part-edit-inputs__subtitle">
<!--        <awc-switch-->
<!--          [checked]="requirementService.usePowerSignal() || false"-->
<!--          [label]="'Show Power'"-->
<!--          [awcTooltip]="'Toggle Power'"-->
<!--          (changes)="onTogglePower($event)"></awc-switch>-->
        <awc-select
         label="Select Y Axis"
         placeholder="Y Axis..."
         [items]="requirementYAxis"
         [selectedItems]="[requirementService.requirementYAxisSignal()]"
         (selectionChanged)="onSelectedYAxis($event[0].id)"
        ></awc-select>
      </div>
    </ng-container>
  </div>
  <div class="config-inputs" *ngSwitchCase="PlotConfigType.LAB_MOTOR">
    <awc-select
      label="Losses"
      placeholder="Losses..."
      [items]="motorLossesTypes"
      [selectedItems]="selectedMotorLossesType"
      (selectionChanged)="selectionMotorLossType($event[0].id)"></awc-select>
    <app-input
      label="Speed"
      placeholder="Optional..."
      [type]="InputTypes.NUMBER"
      id="speed_motor_config"
      [unit]="UnitType.ANGULAR_SPEED"
      [value]="motorLossesSpeed"
      [updater]="false"
      (update)="setMotorSpeed($event)"></app-input>
  </div>
</ng-container>
