import { AWCListContent } from '@ansys/awc-angular/lists';
import { VolumeUnit } from 'src/api';

export const volume: AWCListContent[] = [
  {
    text: 'm³',
    id: VolumeUnit.M_,
  },
  {
    text: 'mm³',
    id: VolumeUnit.MM_,
  },
  {
    text: 'cm³',
    id: VolumeUnit.CM_,
  },
  {
    text: 'in³',
    id: VolumeUnit.IN_,
  },
  {
    text: 'ft³',
    id: VolumeUnit.FT_,
  },
  {
    text: 'yd³',
    id: VolumeUnit.YD_,
  },
];
