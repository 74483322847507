<div class="job-list-cont" [style.height]="maxHeight+'px'">
  <ng-container *ngIf="!selectedJob; else selectedJobCont">
    <ng-container *ngFor="let job of jobsList">
      <div class="job-list-item" (click)="selectJob(job)" *ngIf="job.jobId">
        <!-- <awc-icon [icon]="Icons.PLOTS" [size]="iconSize" ></awc-icon> -->

        <div class="job-list-item-meta">
          <div class="job-list-item-title">
            <ng-container *ngIf="job.jobName && job.jobId; else generatingJob">
              {{job.jobName}}
            </ng-container>
            <ng-template #generatingJob>
              <div class="generating">
                <awc-icon [icon]="Icons.SPINNER" [size]="iconXSmall"></awc-icon>
                <span>Generating Job Information...</span>
              </div>
            </ng-template>
          </div>

          <div class="job-list-item-status" [ngClass]="{'failed': this.barlabels[job.jobId] === 'FAILED' || job.finalStatus === 'FAILED', 'complete': this.barlabels[job.jobId] === 'COMPLETED' || job.finalStatus === 'COMPLETED'}">
            <span class="job-list-item-status-date">{{(job.queuedStatusDate || now) | date: 'mediumDate' }}</span>
            <span class="job-list-item-status-state" [ngClass]="{'failed': job.finalStatus === 'FAILED', 'complete': job.finalStatus === 'COMPLETED'}">
              {{(barlabels[job.jobId] || job.finalStatus || job.lastStatus) | uppercase}}
            </span>
          </div>
          <div class="delete-wrapper">
            <div class="delete-cont" (click)="onDeleteJob($event, job)">
              <awc-icon [icon]="Icons.DELETE" [size]="iconSmall"></awc-icon>
            </div>
          </div>

          <div class="progress-bar">
            <awc-progress-bar
              *ngIf="job.jobId && loadingBar[job.jobId]"
              [color]="loadingcolor"
              [loading]="true"
              [progressBarItem]="[]"
              ></awc-progress-bar>
            <awc-progress-bar
              *ngIf="job.jobId &&  !loadingBar[job.jobId]"
              [color]="color"
              [loading]="loadingBar[job.jobId]"
              [progressBarItem]="progressBars[job?.jobId ?? '']"
              ></awc-progress-bar>

          </div>
        </div>

      </div>
    </ng-container>
  </ng-container>
  <ng-template #selectedJobCont>
    <div class="selected-job-cont">
      <div class="selected-job-action" (click)="deselectJob(true)">
        <awc-icon [icon]="Icons.LEFT_ARROW"></awc-icon>
        <span>Back to jobs</span>
      </div>
      <ng-container *ngIf="!$resultsLoaded; else resultsLoaded">
        <awc-icon [icon]="Icons.SPINNER"></awc-icon>
      </ng-container>
      <ng-template #resultsLoaded>

        <div class="selected-job-item-cont">
          <ng-container *ngFor="let result of results;">
            <div class="selected-job-item" [ngClass]="selectedResult === result.id ? 'selected-job-item-active' : ''" (click)="selectResult(result)">
              <awc-icon *ngIf="result.requirement_solved_type === ResultType.DRIVE_CYCLE" [icon]="Icons.ELECTRICAL" [color]="result.feasible ? 'green' : 'red'"></awc-icon>
              <awc-icon *ngIf="result.requirement_solved_type === ResultType.DYNAMIC" [icon]="Icons.ROCKET" [color]="result.feasible ? 'green' : 'red'"></awc-icon>
              <awc-icon *ngIf="result.requirement_solved_type === ResultType.STATIC" [icon]="Icons.PIN" [color]="result.feasible ? 'green' : 'red'"></awc-icon>
              <span>{{result.requirement.name}}</span>
            </div>
          </ng-container>
        </div>
      </ng-template>

    </div>
  </ng-template>
</div>
