import { AWFPage } from '@ansys/andromeda/contributions';
import { PagesEnum } from '../pages.enum';

export class WorkspacePage implements AWFPage {
  type = PagesEnum.WORKSPACE;
  title = 'Workspace';
  descritpion = 'Workspace';
  rootPage = true;
  fullPath = true;
}
