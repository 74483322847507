/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Injectable,
  Signal,
  Type,
  WritableSignal,
  signal,
} from '@angular/core';
import {
  ActionContributionsService,
  WorkspaceTab,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { State, StateNode } from '@ansys/andromeda/store';
import { ConceptSections } from '../enums';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { BehaviorSubject } from 'rxjs';
import {
  ActiveSectionState,
  ActiveTabState,
  SectionSelectAction,
  TabSelectAction,
} from '@ansys/andromeda/workspace';
import { SelectedItemState } from 'src/app/state/lib/selected-item.state';
import { SelectedComponentState } from 'src/app/state/lib/selected-component.state';
import { SelectedConfigurationsState } from 'src/app/state/lib/selected-configuration.state';
import { SelectedRequirementState } from 'src/app/state/lib/selected-requirement.state';
import { ConfigurationItemState } from 'src/app/state/lib/configurations.state';
import { ComponentsState } from 'src/app/state/lib/components.state';
import { RequirementItemState } from 'src/app/state/lib/requirements.state';

@Injectable({ providedIn: 'root' })
export class SelectionService {
  public sectionSelectionChanged: BehaviorSubject<AWCListItem[]> =
    new BehaviorSubject<AWCListItem[]>([]);
  public get instanceId(): Signal<string> {
    return this._instanceId;
  }
  private section: ConceptSections = ConceptSections.CONFIGURATIONS;
  private tabId!: string;
  private _instanceId: WritableSignal<string> = signal<string>('');
  private selectedStateFromType: Map<
    ConceptSections,
    Type<StateNode<string[]>>
  > = new Map([
    [ConceptSections.CONFIGURATIONS, SelectedConfigurationsState],
    [ConceptSections.COMPONENT, SelectedComponentState],
    [ConceptSections.REQUIREMENT, SelectedRequirementState],
  ]);
  private itemStateFromType: Map<
    ConceptSections,
    Type<StateNode<AWCListItem[]>>
  > = new Map([
    [ConceptSections.CONFIGURATIONS, ConfigurationItemState],
    [ConceptSections.COMPONENT, ComponentsState],
    [ConceptSections.REQUIREMENT, RequirementItemState],
  ]);
  constructor(
    private state: State,
    private actions: ActionContributionsService
  ) {
    this.actions
      .get(SectionSelectAction)
      .subscribe((section?: WorkspaceTabSection) => {
        this.section = section?.type as ConceptSections;
        const items = this.getSelectedItems();
        this.sectionSelectionChanged.next(items);
      });
    const section = this.state.value(ActiveSectionState)?.type;
    this.section = section
      ? (section as ConceptSections)
      : ConceptSections.CONFIGURATIONS;
    const items = this.getSelectedItems();
    if (items) {
      this.sectionSelectionChanged.next(items);
    }

    this.actions.get(TabSelectAction).subscribe((tab?: WorkspaceTab) => {
      this._instanceId.set(
        tab?.type === 'concept' ? tab.urlParams?.[0] || '' : ''
      );
      this.tabId = tab?.id ?? '';
    });
    this.tabId = this.state.value(ActiveTabState)?.id ?? '';

    this.state.get(ActiveSectionState).subscribe((activeSection) => {
      this.state
        .get(SelectedItemState, activeSection?.id)
        .subscribe((item?: AWCTreeItem) => {
          if (!item) {
            this.sectionSelectionChanged.next([]);
            return;
          }
          const items = this.getSelectedItems();
          this.sectionSelectionChanged.next(items);
        });
    });
  }

  public getSelectedItemIDs(): string[] {
    const state = this.selectedStateFromType.get(this.section);
    return state ? this.state.value(state, this.tabId) : [];
  }

  public getSectionItems(): AWCListItem[] {
    const state = this.itemStateFromType.get(this.section);
    return state ? this.state.value(state, this.tabId) : [];
  }

  public getSelectedItems(): AWCListItem[] {
    const selectedItems = this.getSelectedItemIDs();
    const items = this.getSectionItems();
    return (
      items?.filter((item) => selectedItems.includes(item.id.toString())) || []
    );
  }
}
