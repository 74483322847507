import { Injectable } from '@angular/core';
import { AndromedaMenuItem } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { DialogService } from '@ansys/andromeda/shared';
import { PopoutService } from '@ansys/awc-angular/popouts';

@Injectable({ providedIn: 'root' })
export class HelpMenu implements AndromedaMenuItem {
  type = 'help';
  priority = 100;
  id = 'help';
  prefixIcon = {
    icon: Icons.HELP,
  };
  text = 'Help';
  url: string =
    'https://ansyshelp.ansys.com/account/secured?returnurl=/Views/Secured/prod_page.html?pn=ConceptEV&pid=ConceptEV';

  constructor(
    private dialog: DialogService,
    private popoutService: PopoutService
  ) {}

  execute(): void {
    window.open(this.url, '_blank');
  }
}
