import {
  MassUnit,
  TimeUnit,
  ForceUnit,
  TorqueUnit,
  TemperatureUnit,
  LengthUnit,
  AreaUnit,
  VolumeUnit,
  SpeedUnit,
  AccelerationUnit,
  AngularSpeedUnit,
  AngularAccelerationUnit,
  EnergyUnit,
  PowerUnit,
  DensityUnit,
  InertiaUnit,
  PressureUnit,
  RatioUnit,
  VoltageUnit,
  CurrentUnit,
  ResistanceUnit,
  ElectricChargeUnit,
  ElectricalEnergyUnit,
  ElectricalPowerUnit,
  AngleUnit,
  RoadEfficiencyUnit,
  FrequencyUnit,
} from 'src/api';

export type UnitChoice =
  | MassUnit
  | TimeUnit
  | ForceUnit
  | TorqueUnit
  | TemperatureUnit
  | LengthUnit
  | AreaUnit
  | VolumeUnit
  | SpeedUnit
  | AccelerationUnit
  | AngularSpeedUnit
  | AngularAccelerationUnit
  | EnergyUnit
  | PowerUnit
  | DensityUnit
  | InertiaUnit
  | PressureUnit
  | RatioUnit
  | VoltageUnit
  | CurrentUnit
  | ResistanceUnit
  | ElectricChargeUnit
  | ElectricalEnergyUnit
  | ElectricalPowerUnit
  | AngleUnit
  | FrequencyUnit
  | RoadEfficiencyUnit;
