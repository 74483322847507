/* eslint-disable @typescript-eslint/naming-convention */
import { SolvedMotor } from 'src/api';
import {
  ComponentBounds,
  Coords,
  VehicleBounds,
} from '../types/blueprint.type';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import { BlueprintMarker } from '../markers/marker';
import { Axle } from '../enums/blueprint.enum';
import { MotorComponentMarker } from '../markers/motor-component';
import { MotorMechanicalMarker } from '../markers/motor-mechanical';
import { MotorElectricalMarker } from '../markers/motor-electrical';
export const MOTOR_DATA = new InjectionToken<
  [
    [ComponentBounds, ComponentBounds],
    [SolvedMotor, SolvedMotor],
    number[],
    VehicleBounds
  ]
>('TransmissionData');
@Injectable()
export class BlueprintMotorComponents {
  private frontComponentMarker!: MotorComponentMarker;
  private rearComponentMarker!: MotorComponentMarker;
  private frontMechanicalMarker!: MotorMechanicalMarker;
  private rearMechanicalMarker!: MotorMechanicalMarker;
  private frontElectricalMarker!: MotorElectricalMarker;
  private rearElectricalMarker!: MotorElectricalMarker;
  private bounds: [ComponentBounds, ComponentBounds];
  private data: [SolvedMotor, SolvedMotor];
  private motors: number[];
  private _vBounds: VehicleBounds;
  constructor(
    @Inject(MOTOR_DATA)
    private _data: [
      [ComponentBounds, ComponentBounds],
      [SolvedMotor, SolvedMotor],
      number[],
      VehicleBounds
    ],
    private units: ConceptUnitService
  ) {
    [this.bounds, this.data, this.motors, this._vBounds] = _data;
    const [frontCMP, rearCMP, frontMMP, rearMMP, frontEMP, rearEMP] =
      this.getCoords();
    const [frontData, rearData] = this.data;

    if (frontData) {
      this.frontComponentMarker = new MotorComponentMarker(
        [frontCMP, frontData, !!this.motors[0], Axle.FRONT],
        this.units
      );
      this.frontMechanicalMarker = new MotorMechanicalMarker(
        [frontMMP, frontData, !!this.motors[0], Axle.FRONT],
        this.units
      );
      this.frontElectricalMarker = new MotorElectricalMarker(
        [frontEMP, frontData, !!this.motors[0], Axle.FRONT],
        this.units
      );
    }
    if (rearData) {
      this.rearComponentMarker = new MotorComponentMarker(
        [rearCMP, rearData, !!this.motors[1], Axle.REAR],
        this.units
      );
      this.rearMechanicalMarker = new MotorMechanicalMarker(
        [rearMMP, rearData, !!this.motors[1], Axle.REAR],
        this.units
      );
      this.rearElectricalMarker = new MotorElectricalMarker(
        [rearEMP, rearData, !!this.motors[1], Axle.REAR],
        this.units
      );
    }
  }
  getCoords(): [
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[],
    Coords | Coords[]
  ] {
    let frontCMP,
      rearCMP,
      frontMMP,
      frontEMP,
      rearMMP,
      rearEMP: Coords | Coords[];
    const [frontBounds, rearBounds] = this.bounds;
    const [frontMotors, rearMotors] = this.motors;
    const gap = this._vBounds.componentGap;
    if (frontMotors === 2) {
      frontCMP = [
        {
          x: frontBounds.x + frontBounds.width / 2,
          y: frontBounds.y - this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width / 2,
          y: frontBounds.y + this._vBounds.componentHeight,
        },
      ];
    } else {
      frontCMP = {
        x: frontBounds.x + frontBounds.width / 2,
        y: frontBounds.y,
      };
    }
    if (rearMotors === 2) {
      rearCMP = [
        {
          x: rearBounds.x + rearBounds.width / 2,
          y: rearBounds.y - this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x + rearBounds.width / 2,
          y: rearBounds.y + this._vBounds.componentHeight,
        },
      ];
    } else {
      rearCMP = {
        x: rearBounds.x + rearBounds.width / 2,
        y: rearBounds.y,
      };
    }
    if (frontMotors === 2) {
      frontEMP = [
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 4 -
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 2 -
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height * 0.75 -
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 4 +
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 2 +
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y:
            frontBounds.y +
            frontBounds.height * 0.75 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      frontEMP = [
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y: frontBounds.y + frontBounds.height / 4,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y: frontBounds.y + frontBounds.height / 2,
        },
        {
          x: frontBounds.x + frontBounds.width + gap / 2,
          y: frontBounds.y + frontBounds.height * 0.75,
        },
      ];
    }
    // MechanicalMarkerPosition
    if (frontMotors === 2) {
      frontMMP = [
        {
          x: frontBounds.x - gap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 2 -
            this._vBounds.componentHeight,
        },
        {
          x: frontBounds.x - gap / 2,
          y:
            frontBounds.y +
            frontBounds.height / 2 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      frontMMP = {
        x: frontBounds.x - gap / 2,
        y: frontBounds.y + frontBounds.height / 2,
      };
    }
    if (rearMotors === 2) {
      rearEMP = [
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.25 -
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.5 -
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.75 -
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.25 +
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.5 +
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x - gap / 2,
          y:
            rearBounds.y +
            rearBounds.height * 0.75 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      rearEMP = [
        {
          x: rearBounds.x - gap / 2,
          y: rearBounds.y + rearBounds.height * 0.25,
        },
        {
          x: rearBounds.x - gap / 2,
          y: rearBounds.y + rearBounds.height * 0.5,
        },
        {
          x: rearBounds.x - gap / 2,
          y: rearBounds.y + rearBounds.height * 0.75,
        },
      ];
    }
    if (rearMotors === 2) {
      rearMMP = [
        {
          x: rearBounds.x + rearBounds.width + gap / 2,
          y:
            rearBounds.y +
            rearBounds.height / 2 -
            this._vBounds.componentHeight,
        },
        {
          x: rearBounds.x + rearBounds.width + gap / 2,
          y:
            rearBounds.y +
            rearBounds.height / 2 +
            this._vBounds.componentHeight,
        },
      ];
    } else {
      rearMMP = {
        x: rearBounds.x + rearBounds.width + gap / 2,
        y: rearBounds.y + rearBounds.height / 2,
      };
    }
    return [frontCMP, rearCMP, frontMMP, rearMMP, frontEMP, rearEMP];
  }
  getMarkers(): BlueprintMarker[] {
    const markers = [];
    if (this.frontComponentMarker) markers.push(this.frontComponentMarker);
    if (this.rearComponentMarker) markers.push(this.rearComponentMarker);
    if (this.frontMechanicalMarker) markers.push(this.frontMechanicalMarker);
    if (this.rearMechanicalMarker) markers.push(this.rearMechanicalMarker);
    if (this.frontElectricalMarker) markers.push(this.frontElectricalMarker);
    if (this.rearElectricalMarker) markers.push(this.rearElectricalMarker);
    return markers;
  }
  updateMarkerPositions(
    frontBounds: ComponentBounds,
    rearBounds: ComponentBounds
  ): void {
    this.bounds = [frontBounds, rearBounds];
    const [frontCMP, rearCMP, frontMMP, rearMMP, frontEMP, rearEMP] =
      this.getCoords();
    if (this.frontComponentMarker)
      this.frontComponentMarker.nodeCoords = frontCMP;
    if (this.rearComponentMarker) this.rearComponentMarker.nodeCoords = rearCMP;
    if (this.frontMechanicalMarker)
      this.frontMechanicalMarker.nodeCoords = frontMMP;
    if (this.rearMechanicalMarker)
      this.rearMechanicalMarker.nodeCoords = rearMMP;
    if (this.frontElectricalMarker)
      this.frontElectricalMarker.nodeCoords = frontEMP;
    if (this.rearElectricalMarker)
      this.rearElectricalMarker.nodeCoords = rearEMP;
  }
}
