/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Component axle.
 */
export enum ComponentAxle {
    FRONT = 'Front',
    REAR = 'Rear',
    NONE = 'None',
}
