import { Injectable } from '@angular/core';
import { AndromedaMenuItem } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { DialogService } from '@ansys/andromeda/shared';
import { AboutComponent } from '../components/dialogs/about/about.component';
import { PopoutService } from '@ansys/awc-angular/popouts';

@Injectable({ providedIn: 'root' })
export class AboutMenu implements AndromedaMenuItem {
  type = 'about';
  priority = 50;
  id = 'about';
  prefixIcon = {
    icon: Icons.INFO,
  };
  text = 'About';

  constructor(
    private dialog: DialogService,
    private popoutService: PopoutService
  ) {}

  execute(): void {
    this.dialog.open(AboutComponent, { title: 'About' });
    this.popoutService.closeAll();
  }
}
