export enum ActionsEnum {
  OPEN_CONCEPT = 'open-concept',
  UPDATE_JOB = 'update-job',
  ADD_JOB = 'add-job',
  SET_JOBS = 'set-jobs',
  SET_JOB_META = 'set-job-meta',
  UPDATE_JOB_PROGRESS = 'update-job-progress',
  UPDATE_JOB_STATUS = 'update-job-status',
  UPDATE_INPUTS = 'update-inputs',
  COMPOSE_FULL_DATASET = 'compose-full-dataset',
  COMPOSE_SINGLE_DATASET = 'compose-single-dataset',
  DOWNLOAD_FILES = 'download-files',
  BUILD_COMPONENT_CELL = 'build-component-cell',
  LOAD_ALL_JOBS = 'load-all-jobs',
  CONVERT_UNIT_VALUES = 'CONVERT_UNIT_VALUES',
  PROCESS_STATIC_RESULT_DATA = 'PROCESS_STATIC_RESULT_DATA',
  PROCESS_DYNAMIC_RESULT_DATA = 'PROCESS_DYNAMIC_RESULT_DATA',
  PROCESS_DRIVE_CYCLE_RESULT_DATA = 'PROCESS_DRIVE_CYCLE_RESULT_DATA',
  GET_DRIVE_CYCLE_DATA = 'GET_DRIVE_CYCLE_DATA',
  ADD_DRIVE_CYCLE_AXIS = 'ADD_DRIVE_CYCLE_AXIS',
  SELECT_RESULT = 'SELECT_RESULT',
  SOLVED_COMPONENT_TABLE = 'SOLVED_COMPONENT_TABLE',
  DEFAULT_CHART_AXIS_COLOUR_CONFIG = 'default-chart-axis-colour-config',
}
