import { AWCListContent } from '@ansys/awc-angular/lists';
import { AreaUnit } from 'src/api';

export const area: AWCListContent[] = [
  {
    text: 'm²',
    id: AreaUnit.M_,
  },
  {
    text: 'mm²',
    id: AreaUnit.MM_,
  },
  {
    text: 'cm²',
    id: AreaUnit.CM_,
  },
  {
    text: 'in²',
    id: AreaUnit.IN_,
  },
  {
    text: 'ft²',
    id: AreaUnit.FT_,
  },
  {
    text: 'yd²',
    id: AreaUnit.YD_,
  },
];
