import { AWCListContent } from '@ansys/awc-angular/lists';
import { ElectricalPowerUnit } from 'src/api';

export const electricPower: AWCListContent[] = [
  {
    text: 'kW',
    id: ElectricalPowerUnit.K_W,
  },
  {
    text: 'W',
    id: ElectricalPowerUnit.W,
  },
  {
    text: 'kVA',
    id: ElectricalPowerUnit.K_VA,
  },
  {
    text: 'VA',
    id: ElectricalPowerUnit.VA,
  },
];
