/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Wrapper for inverter simple model to handle units.
 */
export type InverterSimpleData = {
    modulation_index?: number;
    dc_harness_resistance?: number;
    ac_harness_resistance?: number;
    ac_resistance?: number;
    dc_resistance?: number;
    switch_resistance?: number;
    switch_forward_voltage?: number;
    switching_time?: number;
    switch_per_pwm_period?: number;
    fixed_loss?: number;
    inverter_type?: InverterSimpleData.inverter_type;
};
export namespace InverterSimpleData {
    export enum inverter_type {
        SIMPLE = 'simple',
    }
}

