import { LayoutAxis } from 'plotly.js-dist-min';
import { PlotlyColoursEnum } from '../../../../../../shared/enums/plotly-colours.enum';

export const defaultAxisColorLightConfig: Partial<LayoutAxis> = {
  gridcolor: PlotlyColoursEnum.LIGHT_MODE_GRID_COLOUR,
  tickcolor: PlotlyColoursEnum.LIGHT_MODE_TICK_COLOUR,
  color: PlotlyColoursEnum.LIGHT_MODE_AXIS_COLOUR, // colours the axis labels and the 0 axis line
};

export const defaultAxisColorDarkConfig: Partial<LayoutAxis> = {
  gridcolor: PlotlyColoursEnum.DARK_MODE_GRID_COLOUR,
  tickcolor: PlotlyColoursEnum.DARK_MODE_TICK_COLOUR,
  color: PlotlyColoursEnum.DARK_MODE_AXIS_COLOUR, // colours the axis labels and the 0 axis line
};
