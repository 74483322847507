/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectorRef,
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import * as Symbols from './src/render/symbols';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { ConceptSections } from 'src/app/shared/enums/concept.enum';
import { ArchitectureTemplate } from 'src/app/shared/enums/concept.enum';
import {
  BatteryBounds,
  ComponentBounds,
  VehicleBounds,
} from './src/types/blueprint.type';
import { Axle, LineWidth } from './src/enums/blueprint.enum';
import { BlueprintMarker } from './src/markers/marker';
import { ResultType } from '../../results-list/results-list.component';
import { IconType, Icons } from '@ansys/awc-angular/icons';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { FlexLayout } from '@ansys/awc-angular/core';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { reqSolvedComponents } from '../table-display/lib/columns';
import {
  DataDisplayService,
  DataDisplayState,
} from 'src/app/shared/services/data-display.service';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { Subscription } from 'rxjs';
import {
  SolvedBattery,
  SolvedInverter,
  SolvedMotor,
  SolvedTransmission,
  SolvedDisconnectClutch,
  SolvedWheel,
  SolvedRoad,
} from 'src/api';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import { BlueprintBatteryComponent } from './src/components/battery';
import { BlueprintTransmissionComponent } from './src/components/transmission';
import { BlueprintMotorComponents } from './src/components/motor';
import { BlueprintInverterComponents } from './src/components/inverter';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { PlotService } from '../../../../shared/services/plot.service';
import { AWCTableColumns, AWCTableRow } from '@ansys/awc-angular/tables';
import { DownloadDatasetComponent } from '../../../dialogs/download-dataset/download-dataset.component';
import { DialogService } from '@ansys/andromeda/shared';
export type SolvedComponent =
  | SolvedBattery
  | SolvedInverter
  | SolvedMotor
  | SolvedTransmission
  | SolvedDisconnectClutch
  | SolvedWheel
  | SolvedRoad;
export type SolvedComponents = Array<SolvedComponent>;
@Component({
  selector: 'app-blueprint-display',
  templateUrl: './blueprint-display.component.html',
  styleUrls: ['./blueprint-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlueprintDisplayComponent
  implements OnChanges, AfterViewInit, OnInit, OnDestroy
{
  @Input() section!: ConceptSections;
  @Input() part!: AWCTreeItem;
  @Input() result!: any;
  @Input() selectedPointIndex: string[] = [];
  @ViewChild('mainCont') mainContainer!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  @ViewChild('tyre') tyreImage!: ElementRef;
  readonly sections = ConceptSections;
  readonly resultType = ResultType;
  protected readonly ArchitectureTemplate = ArchitectureTemplate;
  protected template: ArchitectureTemplate = ArchitectureTemplate.SINGLE_FRONT;
  protected height!: number;
  protected width!: number;
  protected wheelsLoaded: boolean = false;
  protected graphIcon: IconType = { icon: Icons.FUNCTION };
  protected tableIcon: IconType = { icon: Icons.VIEW_2 };

  protected downloadIcon: IconType = { icon: Icons.DOWNLOAD };
  protected altType: ButtonType = ButtonType.SECONDARY;
  protected layout: FlexLayout = FlexLayout.ROW;
  protected buttonSize: ButtonSize = ButtonSize.LARGE;

  protected pointIndexes: AWCListItem[] = [];
  protected selectedRequirementColumns: string[] = ['speeds'];
  protected requirementColumns: AWCListItem[] = reqSolvedComponents;
  protected _markers: BlueprintMarker[] = [];
  protected solvedComponents!: any;
  protected columns: AWCTableColumns[] = [];
  protected rows: AWCTableRow[] = [];

  private _vBounds!: VehicleBounds;
  private _batteryBounds!: BatteryBounds;
  private _frontGearBounds!: ComponentBounds;
  private _rearGearBounds!: ComponentBounds;
  private _frontMotorBounds!: ComponentBounds;
  private _rearMotorBounds!: ComponentBounds;
  private _frontInverterBounds!: ComponentBounds;
  private _rearInverterBounds!: ComponentBounds;
  private _outlineColour: string = '#3A3B3C';
  private _fillColour: string = 'grey';
  private _lineColour: string = 'grey';
  private _clearCache: boolean = true;
  private _markersBuilt: boolean = false;
  private changeIndexSub!: Subscription;
  private batteryComponent!: BlueprintBatteryComponent;
  private transmissionComponent!: BlueprintTransmissionComponent;
  private motorComponent!: BlueprintMotorComponents;
  private inverterComponent!: BlueprintInverterComponents;

  constructor(
    private _cdr: ChangeDetectorRef,
    private displayService: DataDisplayService,
    private requirementService: RequirementsService,
    private units: ConceptUnitService,
    private actions: ActionContributionsService,
    private plotService: PlotService,
    private dialog: DialogService
  ) {
    // this._cdr.markForCheck();
    this.plotService.activeTableData.subscribe((data) => {
      [
        this.columns,
        this.rows,
        this.selectedRequirementColumns,
        this.selectedPointIndex = this.selectedPointIndex,
      ] = data;
      this._cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.changeIndexSub = this.requirementService.selectedTimeIndex.subscribe(
      (index) => {
        this.selectedPointIndex = [index.toString()];
        if (this.result?.requirement_solved_type !== this.resultType.STATIC) {
          this.solvedComponents = this.result.solved_components;
          this._markers.forEach((m) => {
            m.updateData(this.solvedComponents, index);
          });
        }
        this.pointIndexes = [...this.pointIndexes];
        setTimeout(() => {
          this._cdr.detectChanges();
        });
      }
    );
  }
  ngAfterViewInit(): void {
    this.tyreImage.nativeElement.onload = (): void => {
      this.wheelsLoaded = true;
      this.render();
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['result']) {
      this.getTemplateFromResult();
      this.solvedComponents = this.result.solved_components;

      if (this.result?.requirement_solved_type !== this.resultType.STATIC) {
        this.buildPointSelections();
      }
      this._markers.forEach((m) => {
        m.updateData(this.solvedComponents, this.selectedPointIndex[0]);
        setTimeout(() => m.updateBounds());
      });
      this.render();
      this._cdr.detectChanges();
    }
  }
  ngOnDestroy(): void {
    this.changeIndexSub.unsubscribe();
  }

  protected switchToGraphView(): void {
    this.displayService.displayState = DataDisplayState.GRAPH_DISPLAY;
  }

  protected switchToTableDisplay(): void {
    this.displayService.displayState = DataDisplayState.TABLE_DISPLAY;
  }

  protected async downloadData(): Promise<void> {
    this.dialog.open(DownloadDatasetComponent, { title: 'Download Dataset' });
  }

  protected changeTableComponentPoint($event: AWCListItem[]): void {
    this.selectedPointIndex = $event.map((e) => e.id);
    this.solvedComponents =
      this.result.solved_components[this.selectedPointIndex[0]];
    this.requirementService.selectedTimeIndex.next(this.selectedPointIndex[0]);
    this.requirementService.solvedComponentTable(this.result);
    this._markers.forEach((m) => {
      m.updateData(this.solvedComponents, this.selectedPointIndex[0]);
      m.updateBounds();
    });
    this.render();
    this._cdr.markForCheck();
  }
  /**
   * Main render chain. Clears cache if required to reset bounds and marker positions and renders all components.
   * @param forceCacheClear Force cache clear to reset bounds and marker positions
   * @returns void
   */
  protected render(forceCacheClear?: boolean): void {
    if (!this.canvas || !this.mainContainer) return;
    const canvas = this.canvas.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    if (forceCacheClear) {
      this._clearCache = true;
    }
    if (this._clearCache) {
      this.setDimensions(canvas);
      this._vBounds = this.setVehicleBounds();
      this.initBatteryBounds();
      this.initGearBounds();
      this.initMotorBounds();
      this.initInverterBounds();
      if (!this._markersBuilt && this.selectedPointIndex[0] !== '0') {
        this._markers.forEach((m) => {
          m.updateData(this.solvedComponents, this.selectedPointIndex[0]);
        });
      }
      this._markersBuilt = true;
      this._clearCache = false;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this._render(ctx);
    this._cdr.detectChanges();
  }
  private getTemplateFromResult(): void {
    if (this.result) {
      const components = this.result.solved_components[0].name
        ? this.result.solved_components
        : this.result.solved_components[0];
      if (this.result) {
        if (
          components.find((c: any) => c.axle === Axle.FRONT) &&
          components.find((c: any) => c.axle === Axle.REAR)
        ) {
          this.template = ArchitectureTemplate.DUAL;
        }
        if (
          components.find((c: any) => c.axle === Axle.FRONT) &&
          !components.find((c: any) => c.axle === Axle.REAR)
        ) {
          this.template = ArchitectureTemplate.SINGLE_FRONT;
        }
        if (
          !components.find((c: any) => c.axle === Axle.FRONT) &&
          components.find((c: any) => c.axle === Axle.REAR)
        ) {
          this.template = ArchitectureTemplate.SINGLE_REAR;
        }
      }
    }
  }
  /**
   * Init battery bounds based on vehicle bounds.
   * Will set min width of 125 and min height of 85.
   * @returns void
   */
  private initBatteryBounds(): void {
    const width = Math.max(125, this._vBounds.width * 0.15);
    const height = Math.max(85, this._vBounds.height * 0.2);
    const vBounds = this._vBounds;
    this._batteryBounds = {
      x: vBounds.x1 + vBounds.width / 2 - width / 2,
      y: vBounds.y2 - 50,
      positiveNodeOffset: vBounds.x1 + vBounds.width / 2 - width / 2.5 + 10,
      negativeNodeOffset: vBounds.x1 + vBounds.width / 2 + width / 2.5 - 10,
      width,
      height,
    };
    if (!this.batteryComponent) {
      const batteryResult = this.solvedComponents.find(
        (c: any) => c.solved_component_type === 'battery'
      );
      this.batteryComponent = new BlueprintBatteryComponent(
        [this._batteryBounds, batteryResult],
        this.units
      );
      this._markers.push(...this.batteryComponent.getMarkers());
      this._markers = this._markers.sort((a, b) => {
        return a.linkX - b.linkX;
      });
    }

    if (this._markersBuilt) {
      this.batteryComponent.updateMarkerPositions(this._batteryBounds);
    }
  }
  /**
   * Builds select input options for time intervals
   */
  private buildPointSelections(): void {
    if (!this.result) return;
    const mapArray = this.result?.distance ?? this.result.traction_limits;
    // const mapArray = [1, 2, 3];
    this.pointIndexes = mapArray.map((c: unknown, i: number) => {
      return {
        id: i.toString(),
        text: `Point: ${i.toString()}`,
      };
    });
    this.selectedPointIndex = [
      this.requirementService.selectedTimeIndex.value.toString(),
    ];
  }
  /**
   * Init Gear Bounds based on vehicle bounds.
   * Will set the transmission marker points also.
   */
  private initGearBounds(): void {
    const componentGap = this._vBounds.componentGap;
    const spacerReserve = componentGap * 3;
    const width =
      (this._vBounds.width * this._vBounds.componentSpaceFill -
        spacerReserve -
        this._vBounds.wheelR) *
      0.2;
    const height = 68;

    this._frontGearBounds = {
      x: this._vBounds.x1 + this._vBounds.wheelR + componentGap,
      y: this._vBounds.y1 + this._vBounds.height / 2 - height / 2,
      width,
      height,
    };
    this._rearGearBounds = {
      x: this._vBounds.x2 - this._vBounds.wheelR - width - componentGap,
      y: this._vBounds.y1 + this._vBounds.height / 2 - height / 2,
      width,
      height,
    };

    if (!this.transmissionComponent) {
      const frontResult = this.solvedComponents.find(
        (c: any) =>
          c.solved_component_type === 'transmission' && c.axle === 'Front'
      );
      const rearResult = this.solvedComponents.find(
        (c: any) =>
          c.solved_component_type === 'transmission' && c.axle === 'Rear'
      );
      this.transmissionComponent = new BlueprintTransmissionComponent(
        [
          [this._frontGearBounds, this._rearGearBounds],
          [frontResult, rearResult],
          this.getDisplayFlags(),
          this._vBounds,
        ],
        this.units
      );
      this._markers.push(...this.transmissionComponent.getMarkers());
      this._markers = this._markers.sort((a, b) => {
        return a.linkX - b.linkX;
      });
    }
    if (this._markersBuilt) {
      this.transmissionComponent.updateMarkerPositions(
        this._frontGearBounds,
        this._rearGearBounds
      );
    }
  }
  /**
   * Gets display matrix for motors and wheels
   * @returns [frontMotors, rearMotors, frontWheels, rearWheels]
   */
  private getDisplayFlags(): number[] {
    if (!this.result) {
      return [1, 0, 2, 2];
    }
    return [
      this.result.architecture_outline.number_of_front_motors,
      this.result.architecture_outline.number_of_rear_motors,
      this.result.architecture_outline.number_of_front_wheels,
      this.result.architecture_outline.number_of_rear_wheels,
    ];
  }
  private _getComponentOffsets(): number[] {
    const gap = this._vBounds.componentGap;
    const reserve = gap * 3;
    const available =
      this._vBounds.width * this._vBounds.componentSpaceFill -
      this._vBounds.wheelR -
      reserve;
    const width = available * 0.4;
    const height = this._vBounds.wheelR * 1.25;
    return [gap, available, width, height];
  }
  private initMotorBounds(): void {
    const [gap, available, width, height] = this._getComponentOffsets();
    const offset = gap * 2 + available * 0.2;
    this._frontMotorBounds = {
      x: this._vBounds.x1 + this._vBounds.wheelR + offset,
      y: this._vBounds.y1 + this._vBounds.height / 2 - height / 2,
      width,
      height,
      offset,
    };

    this._rearMotorBounds = {
      x: this._vBounds.x2 - this._vBounds.wheelR - width - offset,
      y: this._vBounds.y1 + this._vBounds.height / 2 - height / 2,
      width,
      height,
      offset: -offset,
    };

    if (!this.motorComponent) {
      const frontResult = this.solvedComponents.find(
        (c: any) => c.solved_component_type === 'motor' && c.axle === Axle.FRONT
      );
      const rearResult = this.solvedComponents.find(
        (c: any) => c.solved_component_type === 'motor' && c.axle === Axle.REAR
      );
      this.motorComponent = new BlueprintMotorComponents(
        [
          [this._frontMotorBounds, this._rearMotorBounds],
          [frontResult, rearResult],
          this.getDisplayFlags(),
          this._vBounds,
        ],
        this.units
      );
      this._markers.push(...this.motorComponent.getMarkers());
      this._markers = this._markers.sort((a, b) => {
        return a.linkX - b.linkX;
      });
    }
    if (this._markersBuilt) {
      this.motorComponent.updateMarkerPositions(
        this._frontMotorBounds,
        this._rearMotorBounds
      );
    }
  }
  private initInverterBounds(): void {
    const [gap, available, width, height] = this._getComponentOffsets();
    const offset = gap * 3 + available * 0.6;
    this._frontInverterBounds = {
      x: this._vBounds.x1 + this._vBounds.wheelR + offset,
      y: this._vBounds.y1 + this._vBounds.height / 2 - height / 2,
      width,
      height,
    };
    this._rearInverterBounds = {
      x: this._vBounds.x2 - this._vBounds.wheelR - width - offset,
      y: this._vBounds.y1 + this._vBounds.height / 2 - height / 2,
      width,
      height,
    };
    if (!this.inverterComponent) {
      const frontResult = this.solvedComponents.find(
        (c: any) =>
          c.solved_component_type === 'inverter' && c.axle === Axle.FRONT
      );
      const rearResult = this.solvedComponents.find(
        (c: any) =>
          c.solved_component_type === 'inverter' && c.axle === Axle.REAR
      );
      this.inverterComponent = new BlueprintInverterComponents(
        [
          [this._frontInverterBounds, this._rearInverterBounds],
          [frontResult, rearResult],
          this.getDisplayFlags(),
          this._vBounds,
        ],
        this.units
      );
      this._markers.push(...this.inverterComponent.getMarkers());
      this._markers = this._markers.sort((a, b) => {
        return a.linkX - b.linkX;
      });
    }
    if (this._markersBuilt) {
      this.inverterComponent.updateMarkerPositions(
        this._frontInverterBounds,
        this._rearInverterBounds
      );
    }
  }

  /**
   * Main render chain. Renders all components/markers
   */
  private _render(ctx: CanvasRenderingContext2D): void {
    this.renderBattery(ctx);
    this.renderGears(ctx);
    this.renderMotors(ctx);
    this.renderInverters(ctx);

    this.renderWheels(ctx);
    this.renderMarkers(ctx);
  }
  private renderMarkers(ctx: CanvasRenderingContext2D): void {
    this._renderMarkers(ctx);
  }
  private _renderMarkers(ctx: CanvasRenderingContext2D): void {
    this._markers.forEach((marker) => {
      marker.render && marker.render(ctx);
    });
  }

  private setVehicleBounds(): VehicleBounds {
    const vehicleX = 50;
    const vehicleLength = this.width - vehicleX * 2;
    const vehicleHeight = Math.max(this.height / 2, 450);
    const vehicleY = (this.height - vehicleHeight) / 2;
    return {
      x1: vehicleX,
      x2: vehicleX + vehicleLength,
      y1: vehicleY,
      y2: vehicleY + vehicleHeight,
      width: vehicleLength,
      height: vehicleHeight,
      wheelR: 60,
      wheelD: 50,
      componentGap: 30,
      reservedSpace: 0,
      componentSpaceFill: 0.4,
      componentHeight: 60 * 1.25,
    };
  }
  private setDimensions(canvas: HTMLCanvasElement): void {
    if (!this.mainContainer) new Error('No main container');
    canvas.width = this.mainContainer.nativeElement.clientWidth;
    canvas.height = this.mainContainer.nativeElement.clientHeight;
    this.height = canvas.height;
    this.width = canvas.width;
  }

  private renderWheels(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.globalCompositeOperation = 'overlay';
    this.setStrokeStyle(ctx, LineWidth.THIN, this._fillColour);
    const [frontMotors, rearMotors, frontWheels, rearWheels] =
      this.getDisplayFlags();
    this.renderAxles(
      ctx,
      this._vBounds.wheelR,
      frontMotors,
      rearMotors,
      frontWheels,
      rearWheels
    );

    this.renderWheel(
      ctx,
      this._vBounds.x1,
      frontWheels > 1
        ? this._vBounds.y1
        : this._vBounds.y1 + this._vBounds.height / 2
    );
    if (frontWheels > 1) {
      this.renderWheel(ctx, this._vBounds.x1, this._vBounds.y2);
    }

    this.renderWheel(
      ctx,
      this._vBounds.x2 - this._vBounds.wheelR * 2,
      rearWheels > 1
        ? this._vBounds.y1
        : this._vBounds.y1 + this._vBounds.height / 2
    );
    if (rearWheels > 1) {
      this.renderWheel(
        ctx,
        this._vBounds.x2 - this._vBounds.wheelR * 2,
        this._vBounds.y2
      );
    }
    ctx.restore();
  }
  private renderMotorSymbol(
    ctx: CanvasRenderingContext2D,
    bounds: ComponentBounds,
    reverse?: boolean
  ): void {
    ctx.save();
    ctx.fillStyle = this._outlineColour;
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 0.5;
    const innerWidth = bounds.width - 20;
    if (!reverse) {
      ctx.fillRect(bounds.x, bounds.y + bounds.height / 2 - 7.5, 5, 15);
      ctx.strokeRect(bounds.x, bounds.y + bounds.height / 2 - 7.5, 5, 15);
    } else {
      ctx.fillRect(
        bounds.x + bounds.width - 5,
        bounds.y + bounds.height / 2 - 7.5,
        5,
        15
      );
      ctx.strokeRect(
        bounds.x + bounds.width - 5,
        bounds.y + bounds.height / 2 - 7.5,
        5,
        15
      );
    }
    if (!reverse) {
      ctx.fillRect(bounds.x + 5, bounds.y + bounds.height / 2 - 25, 5, 50);
      ctx.strokeRect(bounds.x + 5, bounds.y + bounds.height / 2 - 25, 5, 50);
    } else {
      ctx.fillRect(
        bounds.x + bounds.width - 10,
        bounds.y + bounds.height / 2 - 25,
        5,
        50
      );
      ctx.strokeRect(
        bounds.x + bounds.width - 10,
        bounds.y + bounds.height / 2 - 25,
        5,
        50
      );
    }

    ctx.fillRect(
      bounds.x + 10,
      bounds.y + bounds.height / 2 - 30,
      innerWidth,
      60
    );
    ctx.strokeRect(
      bounds.x + 10,
      bounds.y + bounds.height / 2 - 30,
      innerWidth,
      60
    );
    if (!reverse) {
      ctx.fillRect(
        bounds.x + innerWidth + 10,
        bounds.y + bounds.height / 2 - 35,
        10,
        70
      );
      ctx.strokeRect(
        bounds.x + innerWidth + 10,
        bounds.y + bounds.height / 2 - 35,
        10,
        70
      );
    } else {
      ctx.fillRect(bounds.x, bounds.y + bounds.height / 2 - 35, 10, 70);
      ctx.strokeRect(bounds.x, bounds.y + bounds.height / 2 - 35, 10, 70);
    }
    // motor spokes
    const spokeX = bounds.x + 10 + innerWidth * 0.1;
    const spokeWidth = innerWidth * 0.8;
    ctx.lineWidth = 4;
    ctx.beginPath();
    ctx.moveTo(spokeX, bounds.y + bounds.height / 2 - 20);
    ctx.lineTo(spokeX + spokeWidth, bounds.y + bounds.height / 2 - 20);
    ctx.moveTo(spokeX, bounds.y + bounds.height / 2 - 10);
    ctx.lineTo(spokeX + spokeWidth, bounds.y + bounds.height / 2 - 10);
    ctx.moveTo(spokeX, bounds.y + bounds.height / 2);
    ctx.lineTo(spokeX + spokeWidth, bounds.y + bounds.height / 2);
    ctx.moveTo(spokeX, bounds.y + bounds.height / 2 + 10);
    ctx.lineTo(spokeX + spokeWidth, bounds.y + bounds.height / 2 + 10);
    ctx.moveTo(spokeX, bounds.y + bounds.height / 2 + 20);
    ctx.lineTo(spokeX + spokeWidth, bounds.y + bounds.height / 2 + 20);
    ctx.stroke();
    ctx.restore();
  }
  private renderMotors(ctx: CanvasRenderingContext2D): void {
    ctx.save();

    ctx.fillStyle = this._fillColour;
    this.setStrokeStyle(ctx, LineWidth.MEDIUM, this._outlineColour);
    const [frontMotors, rearMotors] = this.getDisplayFlags();
    if (frontMotors) {
      if (frontMotors === 2) {
        this.renderMotorSymbol(ctx, {
          ...this._frontMotorBounds,
          y: this._frontMotorBounds.y - this._vBounds.componentHeight,
        });
        this.renderMotorSymbol(ctx, {
          ...this._frontMotorBounds,
          y: this._frontMotorBounds.y + this._vBounds.componentHeight,
        });
      } else {
        this.renderMotorSymbol(ctx, this._frontMotorBounds);
      }
    }
    if (rearMotors) {
      if (rearMotors === 2) {
        this.renderMotorSymbol(
          ctx,
          {
            ...this._rearMotorBounds,
            y: this._rearMotorBounds.y - this._vBounds.componentHeight,
          },
          true
        );
        this.renderMotorSymbol(
          ctx,
          {
            ...this._rearMotorBounds,
            y: this._rearMotorBounds.y + this._vBounds.componentHeight,
          },
          true
        );
      } else this.renderMotorSymbol(ctx, this._rearMotorBounds, true);
    }

    // joining lines
    this.setStrokeStyle(ctx, LineWidth.MEDIUM, this._lineColour);
    ctx.beginPath();
    const yA = this._frontMotorBounds.y + this._frontMotorBounds.height * 0.25;
    const yB = this._frontMotorBounds.y + this._frontMotorBounds.height * 0.75;
    if (frontMotors) {
      if (frontMotors === 2) {
        ctx.moveTo(
          this._frontMotorBounds.x + this._frontMotorBounds.width,
          yA - this._vBounds.componentHeight
        );
        if (rearMotors === 2) {
          ctx.lineTo(
            this._rearMotorBounds.x,
            yA - this._vBounds.componentHeight
          );
        } else {
          ctx.lineTo(
            this._batteryBounds.positiveNodeOffset,
            yA - this._vBounds.componentHeight
          );
        }
        ctx.moveTo(
          this._frontMotorBounds.x + this._frontMotorBounds.width,
          yB - this._vBounds.componentHeight
        );
        const x = this._batteryBounds.positiveNodeOffset - 10;
        ctx.lineTo(x, yB - this._vBounds.componentHeight);
        ctx.bezierCurveTo(
          x,
          yB - this._vBounds.componentHeight,
          x + 10,
          yB - 15 - this._vBounds.componentHeight,
          x + 20,
          yB - this._vBounds.componentHeight
        );
        if (rearMotors === 2) {
          ctx.lineTo(
            this._rearMotorBounds.x,
            yB - this._vBounds.componentHeight
          );
        }
        {
          ctx.lineTo(
            this._batteryBounds.negativeNodeOffset,
            yB - this._vBounds.componentHeight
          );
        }

        ctx.moveTo(
          this._frontMotorBounds.x + this._frontMotorBounds.width,
          yA + this._vBounds.componentHeight
        );
        if (rearMotors === 2) {
          ctx.lineTo(
            this._rearMotorBounds.x,
            yA + this._vBounds.componentHeight
          );
        } else {
          ctx.lineTo(
            this._batteryBounds.positiveNodeOffset,
            yA + this._vBounds.componentHeight
          );
        }
        ctx.moveTo(
          this._frontMotorBounds.x + this._frontMotorBounds.width,
          yB + this._vBounds.componentHeight
        );

        ctx.lineTo(x, yB + this._vBounds.componentHeight);
        ctx.bezierCurveTo(
          x,
          yB + this._vBounds.componentHeight,
          x + 10,
          yB - 15 + this._vBounds.componentHeight,
          x + 20,
          yB + this._vBounds.componentHeight
        );
        if (rearMotors === 2) {
          ctx.lineTo(
            this._rearMotorBounds.x,
            yB + this._vBounds.componentHeight
          );
        } else {
          ctx.lineTo(
            this._batteryBounds.negativeNodeOffset,
            yB + this._vBounds.componentHeight
          );
        }

        if (rearMotors === 1) {
          ctx.moveTo(this._batteryBounds.positiveNodeOffset, yA);
          ctx.lineTo(this._batteryBounds.negativeNodeOffset - 10, yA);
          ctx.bezierCurveTo(
            this._batteryBounds.negativeNodeOffset - 10,
            yA,
            this._batteryBounds.negativeNodeOffset,
            yA - 15,
            this._batteryBounds.negativeNodeOffset + 10,
            yA
          );
          ctx.lineTo(this._rearMotorBounds.x, yA);

          ctx.moveTo(this._batteryBounds.negativeNodeOffset, yB);
          ctx.lineTo(this._rearMotorBounds.x, yB);
        }
      } else {
        if (rearMotors === 2) {
          ctx.moveTo(
            this._frontMotorBounds.x + this._frontMotorBounds.width,
            yA
          );
          ctx.lineTo(this._batteryBounds.positiveNodeOffset, yA);
          ctx.moveTo(
            this._frontMotorBounds.x + this._frontMotorBounds.width,
            yB
          );
          const x = this._batteryBounds.positiveNodeOffset - 10;
          ctx.lineTo(x, yB);
          ctx.bezierCurveTo(x, yB, x + 10, yB - 15, x + 20, yB);
          ctx.lineTo(this._batteryBounds.negativeNodeOffset, yB);

          ctx.moveTo(
            this._batteryBounds.positiveNodeOffset,
            yA - this._vBounds.componentHeight
          );
          ctx.lineTo(
            this._rearMotorBounds.x,
            yA - this._vBounds.componentHeight
          );

          ctx.moveTo(
            this._batteryBounds.negativeNodeOffset,
            yB - this._vBounds.componentHeight
          );
          ctx.lineTo(
            this._rearMotorBounds.x,
            yB - this._vBounds.componentHeight
          );

          ctx.moveTo(
            this._batteryBounds.positiveNodeOffset,
            yA + this._vBounds.componentHeight
          );
          ctx.lineTo(
            this._batteryBounds.negativeNodeOffset - 10,
            yA + this._vBounds.componentHeight
          );
          ctx.bezierCurveTo(
            this._batteryBounds.negativeNodeOffset - 10,
            yA + this._vBounds.componentHeight,
            this._batteryBounds.negativeNodeOffset,
            yA - 15 + this._vBounds.componentHeight,
            this._batteryBounds.negativeNodeOffset + 10,
            yA + this._vBounds.componentHeight
          );
          ctx.lineTo(
            this._rearMotorBounds.x,
            yA + this._vBounds.componentHeight
          );
          ctx.moveTo(
            this._batteryBounds.negativeNodeOffset,
            yB + this._vBounds.componentHeight
          );
          ctx.lineTo(
            this._rearMotorBounds.x,
            yB + this._vBounds.componentHeight
          );
        } else {
          ctx.moveTo(
            this._frontMotorBounds.x + this._frontMotorBounds.width,
            yA
          );
          ctx.lineTo(
            rearMotors
              ? this._rearMotorBounds.x
              : this._batteryBounds.positiveNodeOffset,
            yA
          );
          ctx.moveTo(
            this._frontMotorBounds.x + this._frontMotorBounds.width,
            yB
          );
          const x = this._batteryBounds.positiveNodeOffset - 10;
          ctx.lineTo(x, yB);
          ctx.bezierCurveTo(x, yB, x + 10, yB - 15, x + 20, yB);
          ctx.lineTo(
            rearMotors
              ? this._rearMotorBounds.x
              : this._batteryBounds.negativeNodeOffset,
            yB
          );
        }
      }
    } else {
      if (rearMotors === 2) {
        ctx.moveTo(
          this._batteryBounds.positiveNodeOffset,
          yA - this._vBounds.componentHeight
        );
        ctx.lineTo(this._rearMotorBounds.x, yA - this._vBounds.componentHeight);

        ctx.moveTo(
          this._batteryBounds.negativeNodeOffset,
          yB - this._vBounds.componentHeight
        );
        ctx.lineTo(this._rearMotorBounds.x, yB - this._vBounds.componentHeight);

        ctx.moveTo(
          this._batteryBounds.positiveNodeOffset,
          yA + this._vBounds.componentHeight
        );
        ctx.lineTo(
          this._batteryBounds.negativeNodeOffset - 10,
          yA + this._vBounds.componentHeight
        );
        ctx.bezierCurveTo(
          this._batteryBounds.negativeNodeOffset - 10,
          yA + this._vBounds.componentHeight,
          this._batteryBounds.negativeNodeOffset,
          yA - 15 + this._vBounds.componentHeight,
          this._batteryBounds.negativeNodeOffset + 10,
          yA + this._vBounds.componentHeight
        );
        ctx.lineTo(this._rearMotorBounds.x, yA + this._vBounds.componentHeight);
        ctx.moveTo(
          this._batteryBounds.negativeNodeOffset,
          yB + this._vBounds.componentHeight
        );
        ctx.lineTo(this._rearMotorBounds.x, yB + this._vBounds.componentHeight);
      } else {
        ctx.moveTo(this._rearMotorBounds.x, yA);
        ctx.lineTo(this._batteryBounds.positiveNodeOffset, yA);
        ctx.moveTo(this._rearMotorBounds.x, yB);
        ctx.lineTo(this._batteryBounds.negativeNodeOffset, yB);
      }
    }
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }

  private renderInverters(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.fillStyle = this._fillColour;
    this.setStrokeStyle(ctx, LineWidth.MEDIUM, this._outlineColour);
    const [frontMotors, rearMotors] = this.getDisplayFlags();

    this.setStrokeStyle(ctx, LineWidth.OUTLINE, 'black');
    if (frontMotors) {
      if (frontMotors > 1) {
        Symbols.inverter(
          ctx,
          {
            ...this._frontInverterBounds,
            y: this._frontInverterBounds.y - this._vBounds.componentHeight,
          },
          this._outlineColour
        );
        Symbols.inverter(
          ctx,
          {
            ...this._frontInverterBounds,
            y: this._frontInverterBounds.y + this._vBounds.componentHeight,
          },
          this._outlineColour
        );
      } else {
        Symbols.inverter(ctx, this._frontInverterBounds, this._outlineColour);
      }
    }
    if (rearMotors) {
      if (rearMotors > 1) {
        Symbols.inverter(
          ctx,
          {
            ...this._rearInverterBounds,
            y: this._rearInverterBounds.y - this._vBounds.componentHeight,
          },
          this._outlineColour,
          true
        );
        Symbols.inverter(
          ctx,
          {
            ...this._rearInverterBounds,
            y: this._rearInverterBounds.y + this._vBounds.componentHeight,
          },
          this._outlineColour,
          true
        );
      } else {
        Symbols.inverter(
          ctx,
          this._rearInverterBounds,
          this._outlineColour,
          true
        );
      }
    }

    ctx.restore();
  }
  private setStrokeStyle(
    ctx: CanvasRenderingContext2D,
    width: LineWidth,
    style: string,
    cap?: CanvasLineCap
  ): void {
    ctx.strokeStyle = style;
    ctx.lineWidth = width;
    ctx.lineCap = cap ?? 'round';
  }
  private renderBattery(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.fillStyle = this._outlineColour;
    this.setStrokeStyle(ctx, LineWidth.OUTLINE, 'black');
    ctx.beginPath();
    ctx.roundRect(
      this._batteryBounds.x,
      this._batteryBounds.y,
      this._batteryBounds.width,
      this._batteryBounds.height,
      5
    );
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    // + / - signs
    this.setStrokeStyle(ctx, LineWidth.MEDIUM, this._lineColour);
    const nodeSize = 20;
    Symbols.plus(
      ctx,
      {
        x: this._batteryBounds.positiveNodeOffset,
        y: this._vBounds.y2 - 25,
      },
      nodeSize,
      'black'
    );
    Symbols.minus(
      ctx,
      {
        x: this._batteryBounds.negativeNodeOffset,
        y: this._vBounds.y2 - 25,
      },
      nodeSize,
      'black'
    );
    const [frontMotors, rearMotors] = this.getDisplayFlags();
    // Joining lines
    this.setStrokeStyle(ctx, LineWidth.MEDIUM, this._lineColour);
    ctx.beginPath();
    ctx.moveTo(this._batteryBounds.positiveNodeOffset, this._vBounds.y2 - 55);
    if (frontMotors === 2 || rearMotors === 2) {
      ctx.lineTo(
        this._batteryBounds.positiveNodeOffset,
        this._vBounds.y1 +
          this._vBounds.height / 2 -
          this._vBounds.componentHeight * 1.25
      );
    } else {
      ctx.lineTo(
        this._batteryBounds.positiveNodeOffset,
        this._vBounds.y1 +
          this._vBounds.height / 2 -
          this._vBounds.componentHeight * 0.25
      );
    }
    ctx.moveTo(this._batteryBounds.negativeNodeOffset, this._vBounds.y2 - 55);
    if (frontMotors === 2 || rearMotors === 2) {
      ctx.lineTo(
        this._batteryBounds.negativeNodeOffset,
        this._vBounds.y1 +
          this._vBounds.height / 2 -
          this._vBounds.componentHeight * 0.75
      );
    } else {
      ctx.lineTo(
        this._batteryBounds.negativeNodeOffset,
        this._vBounds.y1 +
          this._vBounds.height / 2 +
          this._vBounds.componentHeight * 0.25
      );
    }

    ctx.stroke();
    ctx.closePath();
    // Nodes
    ctx.fillStyle = this._outlineColour;
    this.setStrokeStyle(ctx, LineWidth.OUTLINE, 'black');
    ctx.beginPath();
    ctx.rect(
      this._batteryBounds.positiveNodeOffset - nodeSize / 2,
      this._batteryBounds.y - nodeSize / 2,
      nodeSize,
      nodeSize / 2
    );
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
    ctx.beginPath();
    ctx.rect(
      this._batteryBounds.negativeNodeOffset - nodeSize / 2,
      this._batteryBounds.y - nodeSize / 2,
      nodeSize,
      nodeSize / 2
    );
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
    ctx.restore();
  }
  private renderGears(ctx: CanvasRenderingContext2D): void {
    ctx.save();

    ctx.fillStyle = 'rgba(255, 255, 255, 0.25)';
    const [frontMotors, rearMotors] = this.getDisplayFlags();
    if (frontMotors) {
      if (frontMotors > 1) {
        Symbols.gear(ctx, {
          ...this._frontGearBounds,
          y: this._frontGearBounds.y - this._vBounds.componentHeight,
        });
        Symbols.gear(ctx, {
          ...this._frontGearBounds,
          y: this._frontGearBounds.y + this._vBounds.componentHeight,
        });
      } else {
        Symbols.gear(ctx, this._frontGearBounds);
      }
    }
    if (rearMotors) {
      if (rearMotors > 1) {
        Symbols.gear(ctx, {
          ...this._rearGearBounds,
          y: this._rearGearBounds.y - this._vBounds.componentHeight,
        });
        Symbols.gear(ctx, {
          ...this._rearGearBounds,
          y: this._rearGearBounds.y + this._vBounds.componentHeight,
        });
      } else {
        Symbols.gear(ctx, this._rearGearBounds);
      }
    }

    ctx.restore();
  }
  private renderAxles(
    ctx: CanvasRenderingContext2D,
    wheelR: number,
    frontMotors: number,
    rearMotors: number,
    frontWheels: number,
    rearWheels: number
  ): void {
    ctx.save();
    this.setStrokeStyle(ctx, LineWidth.THICK, this._lineColour, 'butt');
    ctx.beginPath();
    //front axle
    if (frontWheels > 1) {
      if (frontMotors > 1) {
        ctx.moveTo(this._vBounds.x1 + wheelR, this._vBounds.y1);
        ctx.lineTo(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        ctx.moveTo(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
        ctx.lineTo(this._vBounds.x1 + wheelR, this._vBounds.y2);
      } else {
        ctx.moveTo(this._vBounds.x1 + wheelR, this._vBounds.y1);
        ctx.lineTo(this._vBounds.x1 + wheelR, this._vBounds.y2);
      }
    }
    //back axle
    if (rearWheels > 1) {
      if (rearMotors > 1) {
        ctx.moveTo(this._vBounds.x2 - wheelR, this._vBounds.y1);
        ctx.lineTo(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        ctx.moveTo(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
        ctx.lineTo(this._vBounds.x2 - wheelR, this._vBounds.y2);
      } else {
        ctx.moveTo(this._vBounds.x2 - wheelR, this._vBounds.y1);
        ctx.lineTo(this._vBounds.x2 - wheelR, this._vBounds.y2);
      }
    }
    //middle axles
    if (frontMotors) {
      if (frontMotors > 1) {
        ctx.moveTo(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        ctx.lineTo(
          this._vBounds.x1 +
            this._vBounds.width * this._vBounds.componentSpaceFill,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        ctx.moveTo(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
        ctx.lineTo(
          this._vBounds.x1 +
            this._vBounds.width * this._vBounds.componentSpaceFill,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
      } else {
        ctx.moveTo(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 + this._vBounds.height / 2
        );
        ctx.lineTo(
          this._vBounds.x1 +
            this._vBounds.width * this._vBounds.componentSpaceFill,
          this._vBounds.y1 + this._vBounds.height / 2
        );
      }
    }
    if (rearMotors) {
      if (rearMotors > 1) {
        ctx.moveTo(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        ctx.lineTo(
          this._vBounds.x2 -
            this._vBounds.width * this._vBounds.componentSpaceFill,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        ctx.moveTo(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
        ctx.lineTo(
          this._vBounds.x2 -
            this._vBounds.width * this._vBounds.componentSpaceFill,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
      } else {
        ctx.moveTo(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 + this._vBounds.height / 2
        );
        ctx.lineTo(
          this._vBounds.x2 -
            this._vBounds.width * this._vBounds.componentSpaceFill,
          this._vBounds.y1 + this._vBounds.height / 2
        );
      }
    }
    ctx.stroke();
    ctx.closePath();
    this.drawAxleComponents(ctx, wheelR);
    ctx.restore();
  }
  private drawAxleComponents(
    ctx: CanvasRenderingContext2D,
    wheelR: number
  ): void {
    ctx.save();
    this.setStrokeStyle(ctx, LineWidth.THIN, this._outlineColour);
    ctx.fillStyle = this._fillColour;
    const drawComponent = (x: number, y: number): void => {
      const devisionFactor = 4;
      ctx.beginPath();
      ctx.moveTo(x + wheelR / devisionFactor, y - wheelR / devisionFactor);
      ctx.lineTo(x - 5, y - wheelR / devisionFactor);
      ctx.lineTo(
        x - wheelR / (devisionFactor / 0.8),
        y - wheelR / (devisionFactor * 2)
      );
      ctx.lineTo(
        x - wheelR / (devisionFactor / 0.8),
        y + wheelR / (devisionFactor * 2)
      );
      ctx.lineTo(x - 5, y + wheelR / devisionFactor);
      ctx.lineTo(x + wheelR / devisionFactor, y + wheelR / devisionFactor);
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    };
    const [frontMotors, rearMotors, frontWheels, rearWheels] =
      this.getDisplayFlags();
    if (frontMotors && frontWheels > 1) {
      if (frontMotors > 1) {
        drawComponent(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        drawComponent(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
      } else {
        drawComponent(
          this._vBounds.x1 + wheelR,
          this._vBounds.y1 + this._vBounds.height / 2
        );
      }
    }
    if (rearMotors && rearWheels > 1) {
      if (rearMotors > 1) {
        drawComponent(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 -
            this._vBounds.componentHeight
        );
        drawComponent(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 +
            this._vBounds.height / 2 +
            this._vBounds.componentHeight
        );
      } else {
        drawComponent(
          this._vBounds.x2 - wheelR,
          this._vBounds.y1 + this._vBounds.height / 2
        );
      }
    }
    ctx.restore();
  }
  private renderWheel(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number
  ): void {
    const image = this.tyreImage.nativeElement;
    ctx.save();
    ctx.fillStyle = this._fillColour;
    ctx.fillRect(x + 54, y - image.width / 4.8, 12, image.width / 2.4);
    ctx.setTransform(0.5, 0, 0, 0.5, x + 60, y); // sets scale and origin
    ctx.rotate((-90 * Math.PI) / 180);
    ctx.globalCompositeOperation = 'multiply';
    ctx.drawImage(image, -image.width / 2, -image.height / 2);
    ctx.restore();
  }
}
