/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Angular Acceleration Unit.
 */
export enum AngularAccelerationUnit {
    RAD_S_ = 'rad/s²',
    RPM_S = 'rpm/s',
    RPS_S = 'rps/s',
    DEG_S_ = 'deg/s²',
}
