/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Motor Lab Data.
 *
 * Model is held as a dict, exported from Lab.
 */
export type MotorLabData = {
    lab_file_dict: Record<string, any>;
    component_file_type?: MotorLabData.component_file_type;
};
export namespace MotorLabData {
    export enum component_file_type {
        MOTOR_LAB = 'MotorLab',
    }
}

