/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Motor torque curve data.
 *
 * Input lists are two-dimensional, with each sub-list referring to
 * a different voltage.
 */
export type MotorTorqueCurvesData = {
    speeds: Array<Array<number>>;
    torques: Array<Array<number>>;
    voltages: Array<number>;
    generating_torques?: (Array<Array<any>> | null);
    component_file_type?: MotorTorqueCurvesData.component_file_type;
};
export namespace MotorTorqueCurvesData {
    export enum component_file_type {
        MOTOR_TORQUE_CURVE = 'MotorTorqueCurve',
    }
}

