import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { StaticRequirement, UnitType } from '../../../api';
import { RequirementPlotType } from '../../shared/enums/reqirement-plot-type.enum';
import { ConvertUnitValuesAction } from '../convert-unit-values/convert-unit-values.action';
import { ConceptUnitService } from '../../shared/services/unit.service';

// TODO: Don't use any[] in InputType or ReturnType
type InputType = any[];
type ReturnType = [any, number, number];

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class ProcessDynamicResultDataAction
  implements AWFAction<InputType, ReturnType>
{
  // This can be used to track end user operations and can be used to analyze the user behavior.
  readonly reportAnalysis = false;

  constructor(private actions: ActionContributionsService) {}

  async execute(results: InputType): Promise<ReturnType> {
    const data: Partial<any>[] = [];
    let maxSpeed = 0;
    let maxTorque = 0;
    let minSpeed = Infinity;
    let minTorque = Infinity;
    let convertedSpeeds: number[] = [];
    let convertedTorques: number[] = [];
    let convertedReqSpeeds: number[] = [];
    let convertedReqTorques: number[] = [];

    for (const req of results) {
      const color: string = 'grey';
      const lineColor: string = 'grey';

      [convertedSpeeds, minSpeed, maxSpeed] = await this.actions.execute(
        ConvertUnitValuesAction,
        [req.capability_curve.speeds, UnitType.SPEED, minSpeed, maxSpeed]
      );

      [convertedTorques, minTorque, maxTorque] = await this.actions.execute(
        ConvertUnitValuesAction,
        [req.capability_curve.torques, UnitType.TORQUE, minTorque, maxTorque]
      );

      maxTorque = Math.max(
        maxTorque,
        Math.max(...req.capability_curve.torques)
      );

      [convertedReqSpeeds, minSpeed, maxSpeed] = await this.actions.execute(
        ConvertUnitValuesAction,
        [
          req.requirement.static_requirements.map(
            (s_req: StaticRequirement) => s_req.speed
          ),
          UnitType.SPEED,
          minSpeed,
          maxSpeed,
        ]
      );

      [convertedReqTorques, minTorque, maxTorque] = await this.actions.execute(
        ConvertUnitValuesAction,
        [
          req.requirement.static_requirements.map(
            (s_req: StaticRequirement) => s_req.total_tractive_torque
          ),
          UnitType.TORQUE,
          minTorque,
          maxTorque,
        ]
      );

      data.push(
        {
          type: 'scatter',
          x: convertedSpeeds,
          y: convertedTorques,
          line: { color: lineColor },
          name: `${req.requirement.name} Capability`,
          _type: RequirementPlotType.CAPABILITY_CURVE,
          id: req.id,
        },
        {
          type: 'scatter',
          marker: { color: color },
          x: convertedReqSpeeds,
          y: convertedReqTorques,
          name: `${req.requirement.name}`,
          _type: RequirementPlotType.REQUIREMENT,
          id: req.id,
        }
      );
    }

    return [data, maxSpeed, maxTorque];
  }
}
