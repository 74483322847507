import { Injectable } from '@angular/core';
import {
  Design,
  DesignApiService,
  ProjectApiService,
} from '@ansys/andromeda/api/rest';
import {
  AWFAction,
  ActionContributionsService,
} from '@ansys/andromeda/contributions';
import { AccountService, Tabs } from '@ansys/andromeda/dashboard';
import { ProductContextService } from '@ansys/andromeda/shared';
import { State } from '@ansys/andromeda/store';
import {
  SectionSelectAction,
  SectionsState,
  TabSelectAction,
} from '@ansys/andromeda/workspace';
import { lastValueFrom } from 'rxjs';
import {
  Concept,
  ConceptCloneInput,
  ConceptPopulated,
  ConceptsService,
} from 'src/api';

type Input = [design: Design, templateId: string | null];
type Return = ConceptPopulated | Concept;
/*
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class CreateConceptFromTemplateAction
  implements AWFAction<Input, Return>
{
  // This can be used to track end user operations and can be used to analyze the user behavior.
  readonly reportAnalysis = false;

  constructor(
    private accountService: AccountService,
    private state: State,
    private projectAPIService: ProjectApiService,
    private productContextService: ProductContextService,
    private designAPIService: DesignApiService,
    private conceptAPIService: ConceptsService,
    private actions: ActionContributionsService
  ) {}

  async execute(input: Input): Promise<Return> {
    const [design, templateId] = input;

    if (!templateId) {
      throw new Error('No templateId found');
    }

    if (!design.designInstanceList) {
      throw new Error('No design instances found');
    }

    const requestData: ConceptCloneInput = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      old_design_instance_id: templateId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      new_design_instance_id: design.designInstanceList[0]
        .designInstanceId as string,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      copy_jobs: false,
    };

    const concept = lastValueFrom(
      this.conceptAPIService.copyConceptsCopyPost(
        false,
        design.designInstanceList[0].designInstanceId as string,
        requestData
      )
    );

    // Navigate to new project
    await this.actions.execute(TabSelectAction, Tabs.DASHBOARD);
    const sections = this.state.value(SectionsState);
    const projectSection = sections.find(
      (section) => section.type === 'projects'
    );

    if (!projectSection) {
      throw new Error('Project Section ID is undefined');
    }

    await this.actions.execute(SectionSelectAction, {
      sectionId: projectSection.id,
      urlParams: [design.projectId as string],
    });

    return concept;
  }
}
