/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Mass config with Database ID.
 */
export type MassInDB = {
    item_type?: MassInDB.item_type;
    name?: string;
    mass?: number;
    base_mass?: number;
    com_horizontal_offset?: (number | null);
    com_vertical_height?: (number | null);
    add_components_mass?: boolean;
    config_type?: MassInDB.config_type;
    _id?: string;
    id?: String;
};
export namespace MassInDB {
    export enum item_type {
      CONFIG = 'config',
    }
    export enum config_type {
        MASS = 'mass',
    }
}

