/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubmittedJob } from './SubmittedJob';
/**
 * Motor Loss Map ID. Data referenced by ID.
 */
export type MotorLossMapID = {
    item_type?: MotorLossMapID.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    _id?: string;
    id?: string;
    data_id?: (string | null);
    submitted_job?: (SubmittedJob | null);
    poles?: number;
    voltages?: Array<number>;
    inverter_losses_included?: boolean;
    component_type?: 'MotorLossMapID';
};
export namespace MotorLossMapID {
    export enum item_type {
        COMPONENT = 'component',
    }
}

