/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Ancillary Load Configuration.
 */
export type AncillaryLoad = {
    item_type?: AncillaryLoad.item_type;
    name?: string;
    load?: number;
    config_type?: AncillaryLoad.config_type;
};
export namespace AncillaryLoad {
    export enum item_type {
        CONFIG = 'config',
    }
    export enum config_type {
        ANCILLARY_LOAD = 'ancillary_load',
    }
}

