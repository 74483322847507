import { DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import { SolvedBattery, UnitType } from 'src/api';
import { Inject, Injectable } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import {
  SolvedComponent,
  SolvedComponents,
} from '../../blueprint-display.component';

@Injectable()
export class BatteryElectricalMarker extends BlueprintMarker {
  _type!: SolvedBattery;
  constructor(
    @Inject(MARKER_DATA) private markerData: [Coords[], SolvedBattery],
    private units: ConceptUnitService
  ) {
    const [coords, results] = markerData;
    super(DataType.ELECTRICAL, MarkerType.BATTERY, [], true, coords);
    this.updateData([results]);
    this.title = results.name || 'Battery';
  }
  override updateData(data: SolvedComponents, index: number = 0): void {
    const results: SolvedBattery = data.find(
      (c: SolvedComponent) => c.solved_component_type === 'battery'
    ) as SolvedBattery;
    this.data = [
      {
        label: 'I',
        value: toSN(
          this.units.convertUnit(
            UnitType.CURRENT,
            results.currents?.[index] ?? 0
          )
        ),
      },
      {
        label: 'V',
        value: toSN(
          this.units.convertUnit(UnitType.VOLTAGE, results.out_voltages[index])
        ),
        unit: UnitType.VOLTAGE,
      },
      {
        label: 'P',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.out_powers[index])
        ),
        unit: UnitType.POWER,
      },
    ];
  }
}
