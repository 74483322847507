/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Time Unit.
 */
export enum TimeUnit {
    S = 's',
    MS = 'ms',
    MIN = 'min',
    HR = 'hr',
}
