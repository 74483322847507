/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Unit of Road Efficiency.
 */
export enum RoadEfficiencyUnit {
    M_J = 'm/J',
    KM_K_WH = 'km/kWh',
    MILES_K_WH = 'miles/kWh',
    MPGE = 'MPGe',
}
