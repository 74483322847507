import { AWCListContent } from '@ansys/awc-angular/lists';
import { VoltageUnit } from 'src/api';

export const voltage: AWCListContent[] = [
  {
    text: 'V',
    id: VoltageUnit.V,
  },
  {
    text: 'mV',
    id: VoltageUnit.M_V,
  },
  {
    text: 'kV',
    id: VoltageUnit.K_V,
  },
];
