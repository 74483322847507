import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { DIALOG_DATA } from '@ansys/andromeda/shared';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  @Output() onConfirm: EventEmitter<void> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  protected type: ButtonType = ButtonType.PRIMARY;
  protected alttype: ButtonType = ButtonType.SECONDARY;
  protected size: ButtonSize = ButtonSize.MEDIUM;
  protected smallsize: ButtonSize = ButtonSize.SMALL;
  constructor(@Inject(DIALOG_DATA) public data: any) {}
  protected confirm(): void {
    this.data?.onConfirm?.();
    this.onConfirm.emit();
  }
  protected cancel(): void {
    this.data?.onCancel?.();
    this.onCancel.emit();
  }
}
