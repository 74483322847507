import { AWCListContent } from '@ansys/awc-angular/lists';
import { RatioUnit } from 'src/api';

export const ratio: AWCListContent[] = [
  {
    text: '%',
    id: RatioUnit._,
  },
  {
    text: 'No Unit',
    id: RatioUnit.RATIO,
  },
];
