/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Unit of Electrical Energy.
 */
export enum ElectricalEnergyUnit {
    J = 'J',
    K_WH = 'kWh',
    VA_HR = 'VA·hr',
    WH = 'Wh',
}
