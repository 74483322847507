/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Input Values for Fixed Voltages Battery.
 */
export type BatteryFixedVoltages = {
    item_type?: BatteryFixedVoltages.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: BatteryFixedVoltages.component_type | string;
    voltage_max?: number;
    voltage_min?: (number | null);
    charge_acceptance_limit?: (number | null);
    capacity?: (number | null);
    internal_resistance?: number;
};
export namespace BatteryFixedVoltages {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        BATTERY_FIXED_VOLTAGES = 'BatteryFixedVoltages',
    }
}

