/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Energy Unit.
 */
export enum EnergyUnit {
    J = 'J',
    K_J = 'kJ',
    MJ = 'MJ',
    M_J = 'mJ',
    WH = 'Wh',
    K_WH = 'kWh',
}
