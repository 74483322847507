/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Voltage Unit.
 */
export enum VoltageUnit {
    V = 'V',
    M_V = 'mV',
    K_V = 'kV',
}
