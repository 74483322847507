/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  Inject,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  ErrorListPageResponse,
  Job,
  JobApiService,
  JobStatus,
} from '@ansys/cloud-angular-client';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import {
  AWF_LOCATION,
  AWFLocation,
  DialogService,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { SectionSelectAction } from '@ansys/andromeda/workspace';
import { ColorBackground } from '@ansys/awc-angular/core';
import { IconComponent, Icons, IconSize } from '@ansys/awc-angular/icons';
import {
  ProgressBarComponent,
  ProgressBarItem,
} from '@ansys/awc-angular/loaders';
import { lastValueFrom, ReplaySubject, takeUntil } from 'rxjs';
import { JobsService, RequirementsSolved } from 'src/api';
import {
  JobMetaData,
  LoadAllJobs,
  LoadJob,
  SetJobs,
  UpdateJobProgress,
  UpdateJobStatus,
} from '../../../actions';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { ResultsService } from 'src/app/shared/services/results.services';
import { JobErrorDialogComponent } from '../../dialogs/job-error-dialog/job-error-dialog.component';
import { JobLoadingStatus } from '../../../shared/enums/job-loading-status.enum';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { AppThemeState, State } from '@ansys/andromeda/store';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import { ConceptService } from '../../../shared/services/concept.service';
import { PlotService } from '../../../shared/services/plot.service';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '@ansys/awc-angular/popouts';
import { LoadingComponent } from '../../dialogs/loading/loading.component';

export enum ResultType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  DRIVE_CYCLE = 'drive_cycle',
}

@Component({
  selector: 'results-list',
  templateUrl: './results-list.component.html',
  styleUrls: ['./results-list.component.scss'],
  standalone: true,
  imports: [
    ProgressBarComponent,
    CommonModule,
    IconComponent,
    TooltipDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsListComponent implements OnDestroy {
  protected jobsList: Job[] = [];
  protected readonly Icons = Icons;
  protected iconSmall: IconSize = IconSize.SMALL;
  protected iconXSmall: IconSize = IconSize.X_SMALL;
  protected selectedJob: string = '';
  protected selectedResult: string = '';
  protected $resultsLoaded: boolean = false;
  protected ResultType = ResultType;
  protected maxHeight: number = 500;
  protected now: Date = new Date();
  protected results: RequirementsSolved[] = [];
  protected color: ColorBackground = ColorBackground.PRIMARY_DEFAULT;
  protected loadingcolor: ColorBackground = ColorBackground.WARNING_DEFAULT;
  protected progressBars: { [key: string]: ProgressBarItem[] } = {};
  protected barlabels: { [key: string]: string } = {};
  protected loadingBar: { [key: string]: boolean } = {};
  protected hasJobProgressSocket: { [key: string]: boolean } = {};
  protected theme!: string;
  private instanceId!: string;
  private toupdate: boolean = false;
  private ngUnsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private requirementService: RequirementsService,
    private jobService: JobsService,
    private jobApiService: JobApiService,
    private _cdr: ChangeDetectorRef,
    private snackbar: SnackBarService,
    private resultsService: ResultsService,
    private actions: ActionContributionsService,
    private dialog: DialogService,
    @Inject(AWF_LOCATION) private location: AWFLocation,
    private elRef: ElementRef,
    private dialogService: DialogService,
    private state: State,
    private actionService: ActionContributionsService,
    private plotService: PlotService
  ) {
    this.requirementService.selectFromPlot
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((id: string) => {
        if (this.results && id) {
          const result = this.results.find((result) => result.id === id);
          this.selectResult(result);
        }
      });

    this.state
      .get(AppThemeState)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((theme: string) => {
        if (theme && this.theme !== theme) {
          const isDarkMode =
            theme === 'dark' ||
            (theme === 'auto' &&
              window.matchMedia &&
              window.matchMedia('(prefers-color-scheme: dark)').matches);

          this.theme = isDarkMode ? 'dark' : ('light' as string);
        }
      });

    effect(async () => {
      const selectedYaxis = this.requirementService.requirementYAxis();
      this.onYAxisChange(selectedYaxis);
    });
  }

  async ngOnInit(): Promise<void> {
    this.actions.execute(JobMetaData);
    this.actions.execute(SetJobs);
    this.actions
      .get(UpdateJobProgress)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: [string, number]) => this.updateJobProgress(data));

    this.actions
      .get(UpdateJobStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: [string, string]) => this.updateJobStatus(data));

    this.actions
      .get(SetJobs)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((jobs: Job[]) => this.setJobs(jobs));

    if (
      this.location.section?.params &&
      this.location.section?.params?.length > 1
    ) {
      const jobId = this.location.section.params[1];
      const job = await lastValueFrom(
        this.jobApiService.jobLoadHandler({ jobId })
      );
      this.selectJob(job);
    }
  }

  async onYAxisChange(yaxis: string): Promise<void> {
    if (
      this.location.section?.params &&
      this.location.section?.params?.length > 1
    ) {
      const jobId = this.location.section.params[1];
      const job = await lastValueFrom(
        this.jobApiService.jobLoadHandler({ jobId })
      );

      this.loadResults(job, false);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxHeight = this.elRef.nativeElement.offsetHeight;
    }, 0);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.requirementService.jobResultLoading.next(JobLoadingStatus.IDLE);
    this.deselectJob();
  }

  protected async getInstanceId(): Promise<string> {
    const instanceId = this.location.paramMap?.get('instanceId');
    if (!instanceId) throw new Error('No design instance found');

    return instanceId;
  }

  protected async selectJob(job: Job): Promise<void> {
    if (
      !job.finalStatus ||
      ![
        JobStatus.jobStatus.FINISHED,
        JobStatus.jobStatus.FAILED,
        'COMPLETED',
      ].includes(job.finalStatus as JobStatus.jobStatus)
    ) {
      return;
    }

    if (!this.instanceId) {
      this.instanceId = await this.getInstanceId();
    }

    if (
      job.finalStatus === JobStatus.jobStatus.FAILED ||
      job.lastStatus === JobStatus.jobStatus.FAILED
    ) {
      const errs = await lastValueFrom(
        this.jobApiService.errorListPageHandler({ jobId: job?.jobId as string })
      );

      const error =
        (errs as ErrorListPageResponse).errors?.[0]?.errorCode ?? '';
      this.dialog.open(JobErrorDialogComponent, {
        title: `Job Error: ${error}`,
        data: {
          ...errs,
          jobName: job?.jobName as string,
          jobDockerTag: job?.dockerTag as string,
          instanceId: this.instanceId as string,
        },
      });
    } else {
      const section = this.location.section;
      if (section?.id) {
        this.actions.execute(SectionSelectAction, {
          sectionId: section.id,
          urlParams: ['results', job.jobId as string],
        });
      }

      this.requirementService.resetDefaultLayoutRange();
      this.requirementService.jobResultLoading.next(JobLoadingStatus.LOADING);
      this.selectedJob = job.jobId as string;
      this._cdr.detectChanges();
      this.$resultsLoaded = false;
      this.requirementService.resetMergedData();
      this.plotService.setGraphData([]);
      this.results = [];

      this.loadResults(job);
    }

    return Promise.resolve();
  }

  protected isFailed(job: any): boolean {
    return (
      this.barlabels[job.jobId] === 'FAILED' || job.finalStatus === 'FAILED'
    );
  }

  protected isComplete(job: any): boolean {
    return (
      this.barlabels[job.jobId] === 'COMPLETED' ||
      job.finalStatus === 'COMPLETED'
    );
  }

  protected deselectJob(redirect?: boolean): void {
    this.selectedJob = '';
    this.selectedResult = '';
    this.results = [];
    this.requirementService.setSelectedResult(null);
    this.requirementService.resetMergedData();
    this.requirementService.jobResultLoading.next(JobLoadingStatus.IDLE);
    if (redirect) {
      const a = this.location.section;
      a &&
        a.id &&
        this.actions.execute(SectionSelectAction, {
          sectionId: a.id,
          urlParams: ['results'],
        });
    }
    this._cdr.markForCheck();
  }

  protected selectResult(result: any): void {
    if (!result) {
      return;
    }

    this.selectedResult = result.id;
    this.requirementService.selectedTimeIndex.next(0);
    this.requirementService.selectResult(
      result,
      result.requirement_solved_type === ResultType.DRIVE_CYCLE,
      this.selectedJob,
      () => {
        return this.selectedJob;
      }
    );
    this._cdr.detectChanges();
  }

  protected onDeleteJob($event: Event, job: Job): void {
    $event.stopPropagation();
    const cancelJobStatus = [
      JobStatus.jobStatus.CREATED,
      JobStatus.jobStatus.QUEUED,
      JobStatus.jobStatus.RUNNING,
      JobStatus.jobStatus.PAUSED,
    ].includes(
      (job.finalStatus as JobStatus.jobStatus) ||
        (job.lastStatus as JobStatus.jobStatus)
    );

    const title =
      (cancelJobStatus ? 'Cancel' : 'Delete') +
      ' job' +
      (job.jobName ? `: ${job.jobName}` : '');

    const dialog = this.dialogService.open(ConfirmComponent, {
      title,
      data: {
        onConfirm: async () => {
          if (cancelJobStatus) {
            const loadingRef = this.dialog.open(LoadingComponent, {
              title: 'Cancelling',
            });

            lastValueFrom(this.resultsService.cancelJob(job.jobId as string))
              .then(() => {
                const concept = this.state.value(ActiveConceptState);

                if (concept) {
                  const jobId = job.jobId as string;

                  // Only add the job ID if it doesn't already exist
                  if (!concept.jobs_ids.includes(jobId)) {
                    const updatedConcept = {
                      ...concept,
                      jobs_ids: [...concept.jobs_ids, jobId],
                    };

                    this.state.set(ActiveConceptState, updatedConcept);
                    this.actionService.execute(LoadAllJobs, updatedConcept);
                  } else {
                    this.actionService.execute(LoadAllJobs, concept);
                  }
                }
                loadingRef.close();
              })
              .catch(() => {
                this.snackbar.error('Failed to delete Job');
                dialog.close();
                throw Error('Failed to delete Job');
              });

            dialog.close();
          } else {
            const loadingRef = this.dialog.open(LoadingComponent, {
              title: 'Deleting',
            });
            lastValueFrom(
              this.jobService.deleteJobEndpointJobsDelete(
                job.designInstanceId as string,
                job.jobId as string
              )
            )
              .then(() => {
                loadingRef.close();

                const concept = this.state.value(ActiveConceptState);

                if (concept) {
                  // Remove the deleted job id from concept and reload all jobs
                  const updatedConcept = {
                    ...concept,
                    jobs_ids: concept.jobs_ids.filter(
                      (id: string) => id !== job.jobId
                    ),
                  };
                  this.state.set(ActiveConceptState, updatedConcept);
                  this.actionService.execute(LoadAllJobs, updatedConcept);
                }
              })
              .catch(() => {
                this.snackbar.error('Failed to delete Job');
                dialog.close();
              });

            dialog.close();
          }
        },
        onCancel: () => {
          dialog.close();
        },
      },
    });
  }

  private async loadResults(
    job: Job,
    selectFirstResult: boolean = true
  ): Promise<void> {
    if (this.results && this.results.length > 0) {
      this.results = await this.resultsService.getResults(
        job,
        false,
        this.results
      );
    } else {
      this.results = await this.resultsService.getResults(job);
    }

    this.$resultsLoaded = true;
    if (this.selectedJob.length) {
      this.requirementService.jobResultLoading.next(JobLoadingStatus.FINISHED);
    }
    !this.toupdate &&
      setTimeout(() => {
        const index = selectFirstResult ? 0 : +this.selectedResult;
        this.selectResult(this.results[index]);
      });
    this.requirementService.selectedTimeIndex.next(0);
    this._cdr.markForCheck();
  }

  private async updateJobStatus(data: [string, string]): Promise<void> {
    const [jobId, status] = data;
    const job = this.jobsList.find((j) => j.jobId === jobId);
    const finalStatus: string = job?.finalStatus
      ? job.finalStatus.toUpperCase()
      : status.toUpperCase();

    if (finalStatus === JobStatus.jobStatus.FAILED) {
      this.loadingBar[jobId] = false;
      this.progressBars[jobId] = [
        {
          percentage: 100,
          color: ColorBackground.DANGER_DEFAULT,
        },
      ];
      this.barlabels[jobId] = 'FAILED';
    } else if (finalStatus === 'COMPLETE') {
      this.loadingBar[jobId] = true;
      this.barlabels[jobId] = 'FINISHING UP';
    } else if (finalStatus === 'RUNNING') {
      this.barlabels[jobId] = 'INPUTTING DATA';
    } else {
      if (finalStatus === 'COMPLETED') {
        this.loadingBar[jobId] = false;
        this.progressBars[jobId] = [
          {
            percentage: 100,
            color: ColorBackground.SUCCESS_DEFAULT,
          },
        ];
      }
      this.barlabels[jobId] = finalStatus;
    }

    if (job && !job.jobName) {
      const jobMetaMap = await this.actions.execute(JobMetaData);
      job.jobName = jobMetaMap.get(jobId);
      this.jobsList = [...this.jobsList];
    }
    if (job) {
      // temp resolve while we wait for the backend to fix the status/socket desync
      (job as any)['lastStatus'] = status;
    }
    this._cdr.detectChanges();
  }

  private updateJobProgress(data: [string, number]): void {
    {
      const [jobId, progress] = data;
      const job = this.jobsList.find((j) => j.jobId === jobId);
      if (!job?.finalStatus) {
        this.hasJobProgressSocket[jobId] = true;
        this.loadingBar[jobId] = false;
        if (this.progressBars[jobId] && this.progressBars[jobId][0]) {
          this.progressBars[jobId][0].percentage = progress;
          this.progressBars[jobId][0].color = ColorBackground.WARNING_DEFAULT;
          this.progressBars[jobId] = [...this.progressBars[jobId]];
          this.barlabels[jobId] = `RUNNING: ${Math.round(progress)}%`;
        } else {
          this.barlabels[jobId] = 'FETCHING PROGRESS...';
        }
      }
      this._cdr.detectChanges();
    }
  }

  private setJobs(jobs: Job[]): void {
    this.progressBars = {};
    this.barlabels = {};
    if (jobs) {
      this.jobsList = jobs.sort(
        (j1, j2) =>
          (j2?.queuedStatusDate || j2?.lastStatusDate || Infinity) -
          (j1?.queuedStatusDate || j1?.lastStatusDate || Infinity)
      );
    } else if (this.jobsList.length) {
      this.jobsList = [];
    }

    this.jobsList.forEach(async (job) => {
      this.loadingBar[job.jobId as string] = false;

      const status = job.finalStatus || job.lastStatus;

      const jobRunning =
        status === JobStatus.jobStatus.RUNNING &&
        !this.hasJobProgressSocket[job.jobId as string];

      if (
        job.jobId &&
        (jobRunning ||
          status === JobStatus.jobStatus.QUEUED ||
          status === JobStatus.jobStatus.CREATED)
      ) {
        this.loadingBar[job.jobId as string] = true;
        if (jobRunning) {
          this.barlabels[job.jobId as string] = 'FETCHING PROGRESS...';
        }
      }

      if (
        job?.simulations?.[0]?.lastStatus === 'COMPLETED' &&
        status !== 'FINISHED' &&
        status !== 'CANCELLED' &&
        status !== 'COMPLETED'
      ) {
        this.loadingBar[job.jobId as string] = true;
        this.barlabels[job.jobId as string] = 'FINISHING UP';
      }
      this.progressBars[job.jobId as string] = [
        {
          percentage: 100,
          color: ((): string => {
            switch (job.finalStatus) {
              case 'FAILED':
                return ColorBackground.DANGER_DEFAULT;
              case 'CANCELLED':
                return ColorBackground.WARNING_DEFAULT;
              default:
                return ColorBackground.SUCCESS_DEFAULT;
            }
          })(),
        },
      ];
      this.progressBars[job.jobId as string] = [
        ...this.progressBars[job.jobId as string],
      ];
    });

    if (this.toupdate) {
      if (this.selectedResult && this.selectedJob.length) {
        this.selectJob(
          this.jobsList.find((j) => j.jobId === this.selectedJob) as Job
        ).then(() => {
          this.selectResult(
            this.results.find((r) => r.id === this.selectedResult)
          );
          this.toupdate = false;
        });
      }
    }
    this._cdr.detectChanges();
  }
}
