export enum AnalyticEventEnum {
  CONFIGURATION_ADDED = 'configuration-added',
  CONFIGURATION_UPDATED = 'configuration-updated',
  CONFIGURATION_DELETED = 'configuration-deleted',
  COMPONENT_ADDED = 'component-added',
  COMPONENT_UPDATED = 'component-updated',
  COMPONENT_DELETED = 'component-deleted',
  ARCHITECTURE_UPDATE = 'architecture-updated',
  REQUIREMENT_ADDED = 'requirement-added',
  REQUIREMENT_UPDATED = 'requirement-updated',
  REQUIREMENT_DELETED = 'requirement-deleted',
  REQUIREMENT_SOLVE_STARTED = 'requirement-solve-started',
  JOB_ERROR_LOG_DOWNLOAD = 'job-error-log-download',
  JOB_INPUT_DOWNLOAD = 'job-input-download',
  UNITS_UPDATED = 'units-updated',
}
