export enum ActiveConceptStateKeys {
  SELECTED_ITEM = 'selectedItem',
  DATA_LOADING = 'dataLoading',
  VEHICLE_DATA = 'configurations',
  CONFIGURATION_ENVIRONMENT_DATA = 'configuration-environment',
  COMPONENT_DATA = 'component',
  ARCHITECTURE_DATA = 'architecture',
  REQUIREMENTS_DATA = 'requirement',
  ENERGY_DATA = 'energy',
}
