<div class="two-col-inputs">
  <app-input
    label="Cost"
    placeholder="Optional..."
    [value]="part.cost"
    [type]="InputTypes.NUMBER"
    [id]="part.component_type+'_cost'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart.emit([''+$event, 'cost', part.component_type+'_cost'])"></app-input>
  <app-input
    label="Mass"
    placeholder="Optional..."
    [value]="part.mass"
    [unit]="UnitType.MASS"
    [type]="InputTypes.NUMBER"
    [id]="part.component_type+'_mass'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart.emit([''+$event, 'mass', part.component_type+'_mass'])"></app-input>
</div>
<ng-container *ngIf="part?.component_type === 'TransmissionLossCoefficients'; else shiftTime">
  <div class="two-col-inputs">
    <app-input
      label="Reference Speed"
      placeholder="Reference Speed..."
      [value]="part.max_speed"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_max_speed'"
      [updater]="update"
      [readonly]="readonly"
      [unit]="UnitType.ANGULAR_SPEED"
      (update)="setPart.emit([''+$event, 'max_speed', part.component_type+'_max_speed'])"></app-input>
    <app-input
      label="Reference Torque"
      placeholder="Reference Torque..."
      [value]="part.max_torque"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_max_torque'"
      [updater]="update"
      [readonly]="readonly"
      [unit]="UnitType.TORQUE"
      (update)="setPart.emit([''+$event, 'max_torque', part.component_type+'_max_torque'])"></app-input>
    <app-input
      label="Shift Time"
      placeholder="Shift Time..."
      [value]="part.shift_time"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_shift_time'"
      [updater]="update"
      [readonly]="readonly || !!(part.gear_ratios && part.gear_ratios.length <= 1)"
      [unit]="UnitType.TIME"
      (update)="setPart.emit([''+$event, 'shift_time', part.component_type+'_shift_time'])"></app-input>
  </div>
  <div class="part-edit-inputs__subtitle">
    <span>Gears</span>
    <awc-button
      *ngIf="!readonly"
      text="Add Gear"
      [suffixIcon]="addIcon"
      [type]="alttype"
      [size]="smallsize"
      [iconOnly]="true"
      (clicked)="addGear()"></awc-button>
    </div>
  <ng-container *ngFor="let ratio of part?.gear_ratios; let i = index;">
    <div class="part-edit-inputs__splitCol transmission-ratios">
      <app-input
        label="Ratio"
        placeholder="Ratio..."
        [value]="ratio"
        [type]="InputTypes.NUMBER"
        [id]="part.component_type+'_gear_ratios_'+i"
        [updater]="update"
        [readonly]="readonly"
        (update)="setPart.emit([''+$event, 'gear_ratios', part.component_type+'_gear_ratios_'+i, i])"></app-input>
      <app-input
        label="Reference Efficiency"
        placeholder="Reference Efficiency..."
        [value]="part.headline_efficiencies?.[i]"
        [type]="InputTypes.NUMBER"
        [id]="part.component_type+'_headline_efficiencies_'+i"
        [updater]="update"
        [readonly]="readonly"
        [unit]="UnitType.RATIO"
        (update)="setPart.emit([''+$event, 'headline_efficiencies', part.component_type+'_headline_efficiencies_'+i, i])"></app-input>
      <app-input
        label="Friction"
        altLabel="Windage"
        placeholder="Friction Ratio..."
        [value]="part.friction_ratios?.[i]"
        [altValue]="100 - (part.friction_ratios?.[i] ?? 0)"
        [type]="InputTypes.RATIO"
        [id]="part.component_type+'_friction_ratios_'+i"
        [updater]="update"
        [readonly]="readonly"
        [unit]="UnitType.RATIO"
        (update)="setPart.emit([''+$event, 'friction_ratios', part.component_type+'_friction_ratios_'+i, i])"></app-input>
      <app-input
        label="Static Drag"
        placeholder="Static Drag..."
        [value]="part.static_drags?.[i]"
        [type]="InputTypes.NUMBER"
        [id]="part.component_type+'_static_drags_'+i"
        [updater]="update"
        [readonly]="readonly"
        [unit]="UnitType.TORQUE"
        (update)="setPart.emit([''+$event, 'static_drags', part.component_type+'_static_drags_'+i, i])"></app-input>
      <div class="remove-gear-icon">
        <awc-button
          *ngIf="!readonly"
          text="Add Gear"
          [suffixIcon]="delIcon"
          [type]="alttype"
          [size]="normalsize"
          [iconOnly]="true"
          [disabled]="!!(part.gear_ratios && part.gear_ratios.length <= 1)"
          (clicked)="removeGear(i)"></awc-button>
      </div>

    </div>
  </ng-container>
</ng-container>

<ng-template #shiftTime>
  <div class="two-col-inputs">
    <app-input
      label="Shift Time"
      placeholder="Shift Time..."
      [value]="part.shift_time"
      [type]="InputTypes.NUMBER"
      [id]="part.component_type+'_shift_time'"
      [updater]="update"
      [readonly]="readonly"
      [unit]="UnitType.TIME"
      (update)="setPart.emit([''+$event, 'shift_time', part.component_type+'_shift_time'])"></app-input>
  </div>
</ng-template>
