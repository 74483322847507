<awc-select 
  label="Aerodynamics"
  placeholder="Aerodynamics..."
  [items]="aerodynamics"
  [layout]="layout"
  [selectedItems]="selectedAero"
  (selectionChanged)="selectionIDSet($event[0].id, 'aero_id')"></awc-select>
<awc-select 
  label="Mass"
  placeholder="Mass..."
  [items]="massses"
  [layout]="layout"
  [selectedItems]="selectedMass"
  (selectionChanged)="selectionIDSet($event[0].id, 'mass_id')"></awc-select>
<awc-select 
  label="Wheel"
  placeholder="Wheel..."
  [items]="wheels"
  [layout]="layout"
  [selectedItems]="selectedWheel"
  (selectionChanged)="selectionIDSet($event[0].id, 'wheel_id')"></awc-select>
<awc-select 
  label="Deceleration Limit"
  placeholder="Optional"
  [items]="limits"
  [layout]="layout"
  [selectedItems]="selectedLimit"
  (selectionChanged)="selectionIDSet($event[0].id, 'deceleration_limit_id')"></awc-select>
<div class="two-col-inputs">
  <app-input
    label="Speed"
    placeholder="speed..."
    [value]="part.speed"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.SPEED"
    [id]="part.id+'_speed'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'speed', part.id+'_speed'])"></app-input>
  <app-input
    label="State of Charge"
    placeholder="State of Charge..."
    [value]="part.state_of_charge"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.RATIO"
    [id]="part.id+'_state_of_charge'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'state_of_charge', part.id+'_state_of_charge'])"></app-input>
</div>
<div class="two-col-inputs">
  <app-input
    label="Altitude"
    placeholder="altitude..."
    [value]="part.altitude"
    [unit]="UnitType.LENGTH"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_altitude'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'altitude', part.id+'_altitude'])"></app-input>
  <app-input
    label="Headwind"
    placeholder="Headwind..."
    [unit]="UnitType.SPEED"
    [value]="part.headwind"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_headwind'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'headwind', part.id+'_headwind'])"></app-input>
</div>
<div class="two-col-inputs">
  <app-input
    label="Gradient"
    placeholder="Gradient..."
    [value]="part.gradient"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.GRADIENT"
    [id]="part.id+'_gradient'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'gradient', part.id+'_gradient'])"></app-input>
  <app-input
    label="Acceleration"
    placeholder="Acceleration..."
    [value]="part.acceleration"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.ACCELERATION"
    [id]="part.id+'_acceleration'"
    [updater]="update"
    (update)="setPart.emit([''+$event, 'acceleration', part.id+'_acceleration'])"></app-input>
</div>