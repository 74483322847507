import { TransmissionLossCoefficients as TLC } from 'src/api';
import { PartType as PT } from 'src/app/shared/enums';
import { batteryComponent } from 'src/app/shared/models/concept-components/battery.model';
import { clutchComponent } from 'src/app/shared/models/concept-components/clutch.model';
import { invertAnalytical } from 'src/app/shared/models/concept-components/inverter-analytical.model';
import { motorLabModel } from 'src/app/shared/models/concept-components/motor-lab.model';
import { motorLossMap } from 'src/app/shared/models/concept-components/motor-loss-map.model';
import { motorTorque } from 'src/app/shared/models/concept-components/motor-torque-curve.model';
import { transmissionComponent } from 'src/app/shared/models/concept-components/transmission.model';
import { aeroConfig } from 'src/app/shared/models/concept-configurations/aero.model';
import { ancillaryConfig } from 'src/app/shared/models/concept-configurations/ancillary.model';
import { decelerationConfig } from 'src/app/shared/models/concept-configurations/deceleration-limit.model';
import { massConfig } from 'src/app/shared/models/concept-configurations/mass.model';
import { wheelRadiusConfig } from 'src/app/shared/models/concept-configurations/wheel-radius.model';
import { driveCycle } from 'src/app/shared/models/concept-requirements/drive-cycle-requirement.model';
import { dynamicRequirement } from 'src/app/shared/models/concept-requirements/dyanmic-requriement.model';
import { staticRequirement } from 'src/app/shared/models/concept-requirements/static-requirement.model';
import { ConceptPartType as CPT } from 'src/app/shared/types';
const newObject = (object: unknown): unknown => {
  return JSON.parse(JSON.stringify(object));
};
export const defaultPartsMap = new Map([
  [PT.AERO, newObject(aeroConfig) as CPT],
  [PT.MASS, newObject(massConfig) as CPT],
  [PT.WHEEL, newObject(wheelRadiusConfig) as CPT],
  [PT.ANCILLARY, newObject(ancillaryConfig) as CPT],
  [PT.DECELERATION, newObject(decelerationConfig) as CPT],
  [PT.BATTERY, newObject(batteryComponent) as CPT],
  [PT.TRANSMISSION, newObject(transmissionComponent) as TLC],
  [PT.CLUTCH, newObject(clutchComponent) as CPT],
  [PT.MOTOR, newObject(motorLossMap) as CPT],
  [PT.MOTOR_LAB, newObject(motorLabModel) as CPT],
  [PT.MOTOR_TORQUE_CURVE, newObject(motorTorque) as CPT],
  [PT.INVERTER, newObject(invertAnalytical as CPT)],
  [PT.STATIC_REQUIREMENT, newObject(staticRequirement) as CPT],
  [PT.DYNAMIC_REQUIREMENT, newObject(dynamicRequirement) as CPT],
  [PT.DRIVE_CYCLE, newObject(driveCycle) as CPT],
]);
