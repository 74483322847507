import { AWCListContent } from '@ansys/awc-angular/lists';
import { EnergyUnit } from 'src/api';

export const energy: AWCListContent[] = [
  {
    text: 'Wh',
    id: EnergyUnit.WH,
  },
  {
    text: 'J',
    id: EnergyUnit.J,
  },
  {
    text: 'kJ',
    id: EnergyUnit.K_J,
  },
  {
    text: 'mJ',
    id: EnergyUnit.M_J,
  },
  {
    text: 'MJ',
    id: EnergyUnit.MJ,
  },
  {
    text: 'kWhr',
    id: EnergyUnit.K_WH,
  },
];
