/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubmittedJob } from './SubmittedJob';
/**
 * Motor Lab with the data referenced by ID.
 */
export type MotorLabID = {
    item_type?: MotorLabID.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    _id?: string;
    data_id?: (string | null);
    submitted_job?: (SubmittedJob | null);
    inverter_losses_included?: boolean;
    max_speed: number;
    stator_winding_temp?: (number | null);
    rotor_temp?: (number | null);
    stator_current_limit?: (number | null);
    component_type?: 'MotorLabID';
};
export namespace MotorLabID {
    export enum item_type {
        COMPONENT = 'component',
    }
}

