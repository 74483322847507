/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Unit of frequency.
 */
export enum FrequencyUnit {
    HZ = 'Hz',
}
