import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TabContributionsService,
  SectionContributionsService,
  ContentContributionsService,
} from '@ansys/andromeda/contributions';
import { ConceptEnum } from './enums/concept.enum';
import { ConceptTab } from './concept-tab';
import { RequirementsSection } from './sections/requirement-section/requirement-section';
import { ArchitectureSection } from './sections/architecture-section/architecture-section';

import { ConceptLayoutComponent } from '../../components/layout/concept-layout/concept-layout.component';
import { ComponentSection } from './sections/component-section/component-section';
import { VehicleSection } from './sections/configurations-section/configurations-section';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ConceptTabModule {
  constructor(
    private _tabContributions: TabContributionsService,
    private _sectionContributions: SectionContributionsService,
    private _contentContributions: ContentContributionsService
  ) {
    this.contributeContent();
  }

  private async contributeContent(): Promise<void> {
    const _tab = await this._tabContributions.contribute(
      ConceptEnum.TAB_TYPE,
      ConceptTab
    );
    this._tabContributions.contributeWorkspaceLayout(
      _tab,
      ConceptLayoutComponent
    );
    await this._sectionContributions.contribute(_tab, VehicleSection);
    await this._sectionContributions.contribute(_tab, ComponentSection);
    await this._sectionContributions.contribute(_tab, ArchitectureSection);
    await this._sectionContributions.contribute(_tab, RequirementsSection);
  }
}
