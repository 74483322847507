/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Wheel as a configuration.
 *
 * This is what is stored in the database and the class used for creation.
 */
export type WheelInput = {
    item_type?: WheelInput.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    rolling_radius?: number;
    rolling_resistance_coefficient?: number;
    traction_coefficient?: number;
    config_type?: WheelInput.config_type;
};
export namespace WheelInput {
    export enum item_type {
        CONFIG = 'config',
    }
    export enum config_type {
        WHEEL = 'wheel',
    }
}

