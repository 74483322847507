import { AWCListContent } from '@ansys/awc-angular/lists';
import { LengthUnit } from 'src/api';

export const length: AWCListContent[] = [
  {
    text: 'km',
    id: LengthUnit.KM,
  },
  {
    text: 'm',
    id: LengthUnit.M,
  },
  {
    text: 'mm',
    id: LengthUnit.MM,
  },
  {
    text: 'cm',
    id: LengthUnit.CM,
  },
  {
    text: 'in',
    id: LengthUnit.IN,
  },
  {
    text: 'ft',
    id: LengthUnit.FT,
  },
  {
    text: 'yd',
    id: LengthUnit.YD,
  },
  {
    text: 'miles',
    id: LengthUnit.MILES,
  },
];
