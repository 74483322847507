import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@ansys/andromeda/shared';
import { ButtonComponent } from '@ansys/awc-angular/buttons';
import { LogoComponent } from '@ansys/awc-angular/images';
import { APP_VERSION } from '../../../providers/tokens/app-version.token';
import { TITLE } from '@ansys/andromeda/auth';
import { SystemStatusService } from '../../../../api';
import { lastValueFrom } from 'rxjs';
import { IconComponent, Icons } from '@ansys/awc-angular/icons';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [ButtonComponent, LogoComponent, IconComponent],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent {
  protected apiVersion?: { version: string };
  protected api?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Icons = Icons;

  constructor(
    @Inject(DIALOG_DATA) public data: undefined,
    @Inject(APP_VERSION) protected version: string,
    @Inject(TITLE) protected appName: string,
    private systemStatusApiService: SystemStatusService,
    private cdr: ChangeDetectorRef
  ) {
    this.getApiVersion();
  }

  async getApiVersion(): Promise<void> {
    this.apiVersion = (await lastValueFrom(
      this.systemStatusApiService.versionVersionGet()
    )) as { version: string };
    this.cdr.detectChanges();
  }
}
