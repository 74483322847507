import { AWCListContent } from '@ansys/awc-angular/lists';
import { TemperatureUnit } from 'src/api';

export const temperature: AWCListContent[] = [
  {
    text: '°C',
    id: TemperatureUnit._C,
  },
  {
    text: '°F',
    id: TemperatureUnit._F,
  },
  {
    text: 'K',
    id: TemperatureUnit.K,
  },
];
