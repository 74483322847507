import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { DriveCycleSolved, UnitType } from '../../../api';
import { DriveCyclePlotAxis } from '../../shared/types';
import { ConvertUnitValuesAction } from '../convert-unit-values/convert-unit-values.action';
import { SolvedComponent } from '../../components/widgets/charts/blueprint-display/blueprint-display.component';
import { RequirementPlotType } from '../../shared/enums/reqirement-plot-type.enum';
import { ConceptUnitService } from '../../shared/services/unit.service';
import { PlotService } from '../../shared/services/plot.service';
import { RequirementsService } from '../../shared/services/requirements.service';

type InputType = [DriveCycleSolved, DriveCyclePlotAxis[]];
type ReturnType = void;

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class AddDriveCycleAxisAction
  implements AWFAction<InputType, ReturnType>
{
  constructor(
    private actions: ActionContributionsService,
    private unitChoices: ConceptUnitService,
    private plotService: PlotService,
    private requirementService: RequirementsService
  ) {}

  async execute(context: InputType): Promise<ReturnType> {
    const [result, axis] = context;
    const data: any[] = [];

    let axisMin = Infinity;
    let axisMax = 0;
    let convertedTime: number[] = [];
    let convertedYAttribute: number[] = [];
    let minTime = Infinity;
    let maxTime = 0;
    let layout = {};
    for (const axis1 of axis) {
      const index = axis.indexOf(axis1);

      [convertedTime, minTime, maxTime] = await this.actions.execute(
        ConvertUnitValuesAction,
        [result.time, UnitType.TIME, minTime, maxTime]
      );

      [convertedYAttribute, axisMin, axisMax] = await this.actions.execute(
        ConvertUnitValuesAction,
        [
          (result?.solved_components?.find(
            (comp: SolvedComponent) => comp.name === axis1.component
          )?.[axis1.attribute as keyof SolvedComponent] as number[]) ?? [],
          axis1.unit,
          axisMin,
          axisMax,
        ]
      );

      const newScatter = {
        type: 'scatter',
        name: axis1.component,
        marker: { color: 'rgb(133, 150, 243)' },
        _type: RequirementPlotType.EXTRA,
        x: convertedTime,
        y: convertedYAttribute,
        yaxis: `y${index + 4}`,
        line: { color: axis1.color },
        id: 'speeds',
      };

      data.push(newScatter);
      const label = `${axis1.component} / ${axis1.attribute}`;
      const title = axis1.unit
        ? `${label} (${this.unitChoices.getChoice(axis1.unit)})`
        : label;
      const newLayout = {
        [`yaxis${index + 4}`]: {
          range: [axisMin, axisMax],
          title,
          titlefont: { color: axis1.color },
          tickfont: { color: axis1.color },
          overlaying: 'y',
          side: 'right',
          anchor: 'free',
          autoshift: true,
          mirror: 'ticks',
          gridcolor: 'rgba(120,0,0,0.1)',
          visible: true,
          gridwidth: 0.5,
          zeroLine: false,
          tickmode: 'sync',
          rangemode: 'tozero',
          showgrid: true,
        },
      };
      layout = { ...layout, ...newLayout };
    }

    this.plotService.setGraphData([
      ...this.requirementService.filterDriveCycleData(result?.id as string),
      ...data,
    ] as any);

    this.plotService.updateGraphLayout({
      ...this.requirementService.filterDriveCycleLayout(result?.id as string),
      ...layout,
    });
  }
}
