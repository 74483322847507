/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class SystemStatusService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Version
     * API Version.
     * @returns any Successful Response
     * @throws ApiError
     */
    public versionVersionGet(): Observable<Record<string, any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/version',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Health Check
     * Health Check.
     * @returns any Successful Response
     * @throws ApiError
     */
    public healthCheckHealthGet(): Observable<Record<string, any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/health',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Readiness Check
     * Health Check.
     * @returns string Successful Response
     * @throws ApiError
     */
    public readinessCheckReadinessGet(): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/readiness',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Authenticated Token
     * Authenticated Token.
     * @returns string Successful Response
     * @throws ApiError
     */
    public authenticatedTokenAuthenticatedTokenGet(): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/authenticated_token',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Authenticated User
     * Authenticated User.
     * @returns string Successful Response
     * @throws ApiError
     */
    public authenticatedUserAuthenticatedUserGet(): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/authenticated_user',
            errors: {
                404: `Not found`,
            },
        });
    }
    /**
     * Authenticated Design Instance
     * Authenticated Design Instance.
     * @param designInstanceId
     * @returns string Successful Response
     * @throws ApiError
     */
    public authenticatedDesignInstanceAuthenticatedDesignInstanceGet(
        designInstanceId: string,
    ): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/authenticated_design_instance',
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
