/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, effect } from '@angular/core';
import { IconSize } from '@ansys/awc-angular/icons';
import { AWCListGroup, AWCListItem } from '@ansys/awc-angular/lists';
import { ArchitectureTemplate } from 'src/app/shared/enums';
import { Icons } from '@ansys/awc-angular/icons';
import { ArchitectureService } from 'src/app/shared/services/architecture.service';
import { UnitType } from 'src/api';
import { InputTypes } from '../forms/input/input.component';

@Component({
  selector: 'app-architecture-configurations',
  templateUrl: './architecture-configurations.component.html',
  styleUrls: ['./architecture-configurations.component.scss'],
})
export class ArchitectureConfigurationsComponent {
  public label: string = 'Template';
  public placeholder: string = 'Select a template';
  public headerIconSize: IconSize = IconSize.MEDIUM;
  public selectitems: (AWCListGroup | AWCListItem)[] = [
    {
      text: 'Single Motor',
      id: ArchitectureTemplate.SINGLE_FRONT,
      prefixIcon: { icon: Icons.FORCE_ANGLE },
    },
    {
      text: 'Dual Motor',
      id: ArchitectureTemplate.DUAL,
      prefixIcon: { icon: Icons.FORCE_ANGLE },
    },
  ];
  readonly UnitType = UnitType;
  readonly InputTypes = InputTypes;
  protected wheelItems: AWCListItem[] = [
    {
      text: '1',
      id: '1',
    },
    {
      text: '2',
      id: '2',
    },
  ];
  protected motorItems: AWCListItem[] = [
    {
      text: '0',
      id: '0',
    },
    ...this.wheelItems,
  ];
  protected motorItemsRear: AWCListItem[] = [...this.motorItems];
  protected wheelItemsRear: AWCListItem[] = [...this.wheelItems];
  protected motorItemsFront: AWCListItem[] = [...this.motorItems];
  protected wheelItemsFront: AWCListItem[] = [...this.wheelItems];
  private wheelbase!: number;
  constructor(
    protected architecture: ArchitectureService,
    private _cdr: ChangeDetectorRef
  ) {
    effect(() => {
      this.architecture.architecture();
      this.setSelects();
    });
  }
  /**
   * Changes the architecture template.
   * @param {AWCListItem} $event event from the select component
   */
  protected changeArchTemplate($event: AWCListItem[]): void {
    this.architecture.template = $event[0].id as ArchitectureTemplate;
  }
  protected changeArchitectureWheels(id: string, rear?: boolean): void {
    this.architecture.alterWheelArchitecture(id, rear);
  }
  protected changeArchitectureMotors(id: string, rear?: boolean): void {
    this.architecture.alterMotorArchitecture(id, rear);
  }
  protected updateWheelbase($event: string | number): void {
    this.wheelbase = parseFloat($event as string);
    const architecture = this.architecture.architecture();
    if (architecture) {
      architecture.wheelbase = this.wheelbase;
      this.architecture.setArchitecture(architecture);
    }
  }
  private setSelects(): void {
    const startFrontMotorIndex =
      this.architecture.rearMotorsCount() > 0 ? 0 : 1;
    const startRearMotorIndex =
      this.architecture.frontMotorsCount() > 0 ? 0 : 1;

    this.motorItemsFront = this.motorItems.slice(
      startFrontMotorIndex,
      this.architecture.frontWheelsCount() + 1
    );
    this.wheelItemsFront = this.wheelItems.slice(
      this.architecture.frontMotorsCount()
        ? this.architecture.frontMotorsCount() - 1
        : 0
    );

    this.motorItemsRear = this.motorItems.slice(
      startRearMotorIndex,
      this.architecture.rearWheelsCount() + 1
    );
    this.wheelItemsRear = this.wheelItems.slice(
      this.architecture.rearMotorsCount()
        ? this.architecture.rearMotorsCount() - 1
        : 0
    );
  }
}
