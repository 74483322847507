/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Mass Configuration.
 */
export type Mass = {
    item_type?: Mass.item_type;
    name?: string;
    mass?: number;
    com_horizontal_offset?: (number | null);
    com_vertical_height?: (number | null);
    add_components_mass?: boolean;
    config_type?: Mass.config_type;
};
export namespace Mass {
    export enum item_type {
        CONFIG = 'config',
    }
    export enum config_type {
        MASS = 'mass',
    }
}

