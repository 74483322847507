/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from '@angular/core';
import { UnitType, DriveCycleRequirementIds, ConceptPopulated } from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import { ComponentWidth, FlexLayout } from '@ansys/awc-angular/core';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import { LabelPosition, SwitchSize } from '@ansys/awc-angular/forms';
import { Icons } from '@ansys/awc-angular/icons';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { State, StateType } from '@ansys/andromeda/store';
import { DriveCyclesState } from 'src/app/state/lib/drive-cycles.state';
import { Subject, takeUntil } from 'rxjs';
import { AWFLocation, AWF_LOCATION } from '@ansys/andromeda/shared';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { ConceptService } from '../../../../../shared/services/concept.service';
import { ActiveConceptState } from '../../../../../state/lib/active-concept.state';
export type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-drive-cycles-inputs',
  templateUrl: './drive-cycles.component.html',
  styleUrls: ['../inputs.scss'],
})
export class DriveCyclesComponent implements OnInit {
  @Input() part!: DriveCycleRequirementIds;
  @Input() update!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('fileUpload') upload!: ElementRef;
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected aerodynamics: AWCListItem[] = [];
  protected massses: AWCListItem[] = [];
  protected wheels: AWCListItem[] = [];
  protected drivecycles: AWCListItem[] = [];
  protected limits: AWCListItem[] = [];
  protected loads: AWCListItem[] = [];

  protected selectedAero: string[] = [];
  protected selectedMass: string[] = [];
  protected selectedWheel: string[] = [];
  protected selectedLimit: string[] = [];
  protected selectedLoad: string[] = [];
  protected selectedDC: string[] = [];

  protected labelPosition: LabelPosition = LabelPosition.BEFORE;
  protected size: SwitchSize = SwitchSize.MEDIUM;
  protected awcSizing: ComponentWidth = ComponentWidth.FILL;
  protected iconButton: Icons = Icons.ADD_GEOMETRY;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private configurationService: ConfigurationsService,
    private requirementService: RequirementsService,
    private conceptService: ConceptService,
    private state: State,
    @Inject(AWF_LOCATION) private location: AWFLocation
  ) {
    this.state
      .get(ActiveConceptState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (concept?: ConceptPopulated) => {
        if (!concept) return;
        this.drivecycles = await this.conceptService.getConceptDriveCycles(
          concept
        );
      });
  }
  ngOnInit(): void {
    this.selectedAero = [this.part.aero_id];
    this.selectedMass = [this.part.mass_id];
    this.selectedWheel = [this.part.wheel_id];
    this.selectedLoad = [this.part.ancillary_load_id ?? ''];
    this.selectedLimit = [this.part.deceleration_limit_id ?? ''];
    this.selectedDC = [this.part.drive_cycle_id];

    [this.aerodynamics, this.massses, this.wheels, this.limits, this.loads] =
      this.configurationService.configurationLists.map((list) => {
        return list.map((item) => {
          const clone = { ...item };
          delete (clone as AWCTreeItem).parent;
          return clone;
        });
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  protected selectionIDSet($event: string, type: string): void {
    this.part[type as keyof DriveCycleRequirementIds] = $event as never;
    this.forceUpdate.emit('');
  }
  protected addDriveCycle(): void {
    this.upload.nativeElement.click();
  }
  protected uploadClick(): void {
    this.upload.nativeElement.value = '';
  }
  /**
   * Import file event
   * @param {any} $event
   */
  protected async importDriveCycle($event: Event): Promise<void> {
    const input = $event.target as HTMLInputElement;
    if (!input?.files?.length) return;
    const file: File = input.files[0];
    if (file) {
      const dc = await this.requirementService.addDriveCycleFromFile(
        file.name.split('.')[0],
        file
      );
      this.drivecycles.push({
        id: dc.id as string,
        text: dc.name,
        userData: dc,
      });
      this.selectedDC = [dc.id as string];
      this.part.drive_cycle_id = dc.id as string;
      this.drivecycles = [...this.drivecycles];
      this.forceUpdate.emit('');
    }
  }
  protected toggleIFullRange($event: boolean): void {
    this.part.full_range_calculation = $event;
    this.forceUpdate.emit('');
  }
}
