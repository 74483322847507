import { Injectable } from '@angular/core';
import {
  AWCTableCell,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { TableColumn } from '@ansys/andromeda/contributions';

@Injectable({ providedIn: 'root' })
export class StudyProjectColumn implements TableColumn {
  public header: string = 'Project';
  public id: string = 'study-project-column';
  public priority: number = 200;
  public type: TableCellTypes = TableCellTypes.TEXT;
  public align: TableCellAlign = TableCellAlign.CENTER;

  public render(data: any): Promise<AWCTableCell> {
    return Promise.resolve({
      value: data.projectTitle,
    });
  }
}
