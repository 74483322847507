import { LibraryObject } from '@ansys/andromeda/api/rest';
import { Injectable } from '@angular/core';
import {
  AWCTableCell,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { TableColumn } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { ConceptService } from '../shared/services/concept.service';

@Injectable({ providedIn: 'root' })
export class LibraryIconColumn implements TableColumn {
  public header: string = '';
  public id: string = 'library-table-icon';
  public priority: number = 0;
  public type: TableCellTypes = TableCellTypes.ICON;
  public align: TableCellAlign = TableCellAlign.CENTER;
  public width: number = 50;
  private typeMap: Map<string, [string, boolean]> = new Map([
    ['aero', [Icons.STREAM_LINES, false]],
    ['mass', [Icons.PRESSURE_LOAD, false]],
    ['wheel', [Icons.RADIO_ACTIVE, false]],
    ['ancillary_load', [Icons.ACOUSTIC, false]],
    ['deceleration_limit', [Icons.TRACTION_LOAD, false]],
    ['clutch', ['assets/icons/clutch.svg', true]],
    ['battery', ['assets/icons/battery.svg', true]],
    ['inverter', [Icons.ELECTRICAL, false]],
    ['motor', ['assets/icons/Motor.svg', true]],
    ['transmission', [Icons.SETTINGS, false]],
    ['drive_cycle', [Icons.ELECTRICAL, false]],
    ['static_requirement', [Icons.PIN, false]],
    ['dynamic_requirement', [Icons.ROCKET, false]],
  ]);

  constructor(private conceptService: ConceptService) {}

  public render(data: LibraryObject): Promise<AWCTableCell> {
    const meta = JSON.parse(data?.metadata ?? '{}');
    const type =
      this.conceptService.typeMap.get(meta?.type) ?? meta?.type ?? '';
    const [value, externalSrc] = this.typeMap.get(type) ?? ['', false];
    return Promise.resolve({
      value,
      externalSrc,
    });
  }
}
