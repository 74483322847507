import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Icons, IconType } from '@ansys/awc-angular/icons';
import { Layout, LayoutAxis, PlotlyDataLayoutConfig } from 'plotly.js-dist-min';
import { DialogService } from '@ansys/andromeda/shared';
import { Subject, takeUntil } from 'rxjs';
import { State } from '@ansys/andromeda/store';
import { PlotsToolBarDisplay } from '@ansys/awc-angular/charts';
import { ButtonType } from '@ansys/awc-angular/buttons';
import {
  DataDisplayService,
  DataDisplayState,
} from 'src/app/shared/services/data-display.service';
import { ConceptSections } from 'src/app/shared/enums/concept.enum';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { DownloadDatasetComponent } from '../../../dialogs/download-dataset/download-dataset.component';
import { PlotState } from '../../../../state/lib/plot.state';
import { PlotService } from '../../../../shared/services/plot.service';

@Component({
  selector: 'app-graph-display',
  templateUrl: './graph-display.component.html',
  styleUrls: ['./graph-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphDisplayComponent implements OnDestroy {
  @Input() section!: ConceptSections;
  readonly sections = ConceptSections;
  protected tableIcon: IconType = { icon: Icons.VIEW_2 };
  protected blueprintIcon: IconType = { icon: Icons.BODIES };
  protected downloadIcon: IconType = { icon: Icons.DOWNLOAD };
  protected altType: ButtonType = ButtonType.SECONDARY;
  // protected graph!: Partial<PlotlyDataLayoutConfig>;
  protected toolbarDisplay: PlotsToolBarDisplay = PlotsToolBarDisplay.ALWAYS;
  protected showPlotNav = true;
  protected plotState!: Partial<PlotlyDataLayoutConfig>;
  protected xaxis!: Partial<LayoutAxis>;
  protected yaxis!: Partial<LayoutAxis>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private displayService: DataDisplayService,
    private requirementService: RequirementsService,
    private state: State,
    private _cdr: ChangeDetectorRef,
    private dialog: DialogService,
    private plotService: PlotService
  ) {
    this.state
      .get(PlotState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((plot) => {
        if (plot && plot.data) {
          this.showPlotNav =
            plot.data?.length > 1 &&
            (plot.data?.[0] as any)._type !== 'pre_solve';
        }

        this.plotState = plot;
        this._cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected switchToTableView(): void {
    this.displayService.displayState = DataDisplayState.TABLE_DISPLAY;
  }

  protected switchToBlueprintView(): void {
    this.displayService.displayState = DataDisplayState.BLUEPRINT_DISPLAY;
    this._cdr.markForCheck();
  }

  protected plotSelect($event: { event: MouseEvent; points: any[] }): void {
    if ($event.points[0] && $event.points[0].data?._type === 'requirement') {
      this.requirementService.openTableFromPlot($event.points[0]);
    }
  }

  protected legendSelect($event: any): void {
    const curveNumber = Number($event.curveNumber);

    if (!curveNumber) return;
    const data = $event.data[curveNumber];
    if (data._type === 'extra') {
      const axis =
        $event.layout[`yaxis${data.yaxis?.slice(1)}` as keyof Layout];

      if (axis) {
        // @TODO find a more robust solution for enforcing this render does not get out of sync
        axis.visible = data.visible === 'legendonly';
        axis.autoshift = axis.visible;
        axis.side = axis.visible ? 'right' : 'left';
        this.plotService.updateGraphLayout({
          [`yaxis${data.yaxis?.slice(1)}`]: axis,
        });
        this._cdr.markForCheck();
      }
    }
  }

  protected async downloadAllData(): Promise<void> {
    this.dialog.open(DownloadDatasetComponent, { title: 'Download Dataset' });
  }
}
