/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UnitType, DisconnectClutchInputInDB } from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
export type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-clutch-inputs',
  templateUrl: './clutch.component.html',
  styleUrls: ['../inputs.scss'],
})
export class ClutchComponent {
  @Input() part!: DisconnectClutchInputInDB;
  @Input() update!: boolean;
  @Input() readonly!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
}
