import { AWCListContent } from '@ansys/awc-angular/lists';
import { CurrentUnit } from 'src/api';

export const current: AWCListContent[] = [
  {
    text: 'A',
    id: CurrentUnit.A,
  },
  {
    text: 'mA',
    id: CurrentUnit.M_A,
  },
  {
    text: 'kA',
    id: CurrentUnit.K_A,
  },
];
