import { AWFAction } from '@ansys/andromeda/contributions';
import { AWCTableColumns, AWCTableRow } from '@ansys/awc-angular/tables';
import { ActionsEnum } from '../../shared/enums';

type Input = [AWCTableColumns[], AWCTableRow[]];

export class ComposeSingleDatasetAction implements AWFAction<Input, string> {
  public readonly type = ActionsEnum.COMPOSE_SINGLE_DATASET;

  execute: (context: Input) => Promise<string> = async (context: Input) => {
    const [columns, rows] = context;

    return (
      columns.map((e) => e.header).join(',') +
      '\n' +
      rows.map((e) => e.cells.map((cell) => cell.value).join(',')).join('\n')
    );
  };
}
