import { TabSectionType } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { DashboardSection } from '@ansys/andromeda/dashboard';
import { ConceptSections } from '../../../../shared/enums';
import { sectionState } from '../../../../shared/models/section-state';

export class ArchitectureSection
  extends DashboardSection
  implements TabSectionType
{
  public override type: ConceptSections = ConceptSections.ARCHITECTURE;
  public override title: string = 'Architecture';
  public override icon: Icons = Icons.FORCE_ANGLE;
  public disabled: boolean = true;
  constructor() {
    super();
  }
  public isDisabled(): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(!sectionState.architecture);
    });
  }
}
