/* eslint-disable @typescript-eslint/naming-convention */
import { AWCListItem } from '@ansys/awc-angular/lists';
import { InverterIGBTData, InverterMOSFETData } from 'src/api';

export const inverterSelectTypes: AWCListItem[] = [
  {
    text: 'IGBT',
    id: 'InverterIGBTData',
    userData: {} as InverterIGBTData,
  },
  {
    text: 'MOSFET',
    id: 'InverterMOSFETData',
    userData: {} as InverterMOSFETData,
  },
];
