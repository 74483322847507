/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ConceptSections } from '../enums/concept.enum';
import { PartServiceType } from '../types/part-service.type';
import { ConfigurationsService } from './configurations.service';
import { ComponentsService } from './components.service';
import { RequirementsService } from './requirements.service';

/**
 * This service will return the appropriate service for the section type. This is used to abstract the logic of getting the correct service from the section type away from the shared component.
 */

@Injectable({ providedIn: 'root' })
export class SectionService {
  constructor(
    private configService: ConfigurationsService,
    private componentService: ComponentsService,
    private requirementsService: RequirementsService
  ) {}

  /**
   * Returns the appropariate service from the section type
   * @param {ConceptSections} section - Section to get service for
   * @returns {PartServiceType} - Returns the service for the section
   */
  public getService(section: ConceptSections): PartServiceType {
    const serviceMap: Map<ConceptSections, PartServiceType> = new Map([
      [ConceptSections.CONFIGURATIONS, this.configService as PartServiceType],
      [ConceptSections.COMPONENT, this.componentService as PartServiceType],
      [
        ConceptSections.REQUIREMENT,
        this.requirementsService as PartServiceType,
      ],
    ]);
    const service = serviceMap.get(section);
    if (!service) throw new Error('No service matching section type');
    return service;
  }
}
