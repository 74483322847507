/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  UnitType,
  InverterAnalyticalInDB,
  InverterIGBTData,
  InverterMOSFETData,
  InverterSimpleData,
  PWMFrequencyDefinition,
} from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { FlexLayout } from '@ansys/awc-angular/core';
export type SetPart = [value: string, type: string, id: string, index?: number];
type InverterData = InverterSimpleData | InverterIGBTData | InverterMOSFETData;
@Component({
  selector: 'app-inverter-inputs',
  templateUrl: './inverter.component.html',
  styleUrls: ['../inputs.scss'],
})
export class InverterComponent {
  @Input() part!: InverterAnalyticalInDB;
  @Input() update!: boolean;
  @Input() readonly!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  readonly PWMFrequencyDefinition = PWMFrequencyDefinition;
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  protected simpleData!: InverterSimpleData;
  protected igbtData!: InverterIGBTData;
  protected mosfetData!: InverterMOSFETData;
  protected pwmDefinitions: AWCListItem[] = [
    {
      text: 'Fixed',
      id: '1',
    },
    {
      text: 'Synchronous',
      id: '2',
    },
  ];
  protected selectedDefinition: string[] = ['1'];
  protected layout: FlexLayout = FlexLayout.COLUMN;
  ngOnInit(): void {
    if (this.part.data.inverter_type === 'simple') {
      this.simpleData = this.part.data as InverterSimpleData;
    } else {
      this.igbtData = this.part.data as InverterIGBTData;
      this.mosfetData = this.part.data as InverterMOSFETData;
      this.selectedDefinition = [
        this.igbtData.pwm_definition === PWMFrequencyDefinition._1 ? '1' : '2',
      ];
    }
  }
  /**
   * Set Inverter data
   */
  protected setInverterData(
    value: string | number,
    type: string,
    id: string
  ): void {
    this.part.data[type as keyof InverterData] = value as any;
    this.forceUpdate.emit(id);
  }
  protected changeDefinition(value: AWCListItem): void {
    this.igbtData.pwm_definition =
      value.id === '1' ? PWMFrequencyDefinition._1 : PWMFrequencyDefinition._2;
    this.mosfetData.pwm_definition =
      value.id === '1' ? PWMFrequencyDefinition._1 : PWMFrequencyDefinition._2;
    this.forceUpdate.emit('pwm_definition');
  }
}
