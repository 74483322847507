<ng-container *ngIf="!loading; else loadingEl">
  <div class="selects-cont">
    <ng-container *ngFor="let label of unitChoiceLabels;">
      <awc-select 
        [label]="label | unitNameTransform | titlecase"
        placeholder="Unit"
        [items]="unitChoiceMap.get(label) || []"
        [layout]="layout"
        [selectedItems]="[choices[label]]"
        (selectionChanged)="selectionChanged($event, label)"></awc-select>
    </ng-container>
  </div>
  <div class="selects-action">
    <awc-button (clicked)="onSubmit()" text="Update"></awc-button>
  </div>
</ng-container>
<ng-template #loadingEl>
  <div class="loading-cont">
    <awc-icon [icon]="spinner"></awc-icon>
  </div>
</ng-template>