/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransmissionLossMapData } from './TransmissionLossMapData';
/**
 * Input values for transmission model with loss data.
 */
export type TransmissionLossMap = {
    item_type?: TransmissionLossMap.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: TransmissionLossMap.component_type;
    shift_time?: number;
    loss_map: TransmissionLossMapData;
};
export namespace TransmissionLossMap {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        TRANSMISSION_LOSS_MAP = 'TransmissionLossMap',
    }
}

