import { ComponentBounds } from '../types/blueprint.type';

/**
 * Plus symbol for UI
 * @param ctx Canvas context
 * @param position Position of the symbol
 * @param size Size of the symbol
 */
export const plus = (
  ctx: CanvasRenderingContext2D,
  position: { x: number; y: number },
  size: number,
  color: string = '#000'
): void => {
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.moveTo(position.x - size / 2, position.y);
  ctx.lineTo(position.x + size / 2, position.y);
  ctx.moveTo(position.x, position.y - size / 2);
  ctx.lineTo(position.x, position.y + size / 2);
  ctx.stroke();
  ctx.closePath();
};
/**
 * Minus symbol for UI
 */
export const minus = (
  ctx: CanvasRenderingContext2D,
  position: { x: number; y: number },
  size: number,
  color: string = '#000'
): void => {
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.moveTo(position.x - size / 2, position.y);
  ctx.lineTo(position.x + size / 2, position.y);
  ctx.stroke();
  ctx.closePath();
};
/**
 * Gear Symbol for UI
 * @param ctx Canvas context
 * @param position Position of the symbol
 */
export const gear = (
  ctx: CanvasRenderingContext2D,
  position: ComponentBounds,
  fill: string = '#fff',
  stroke: string = '#000'
): void => {
  const height = position.height / 2 + 16;
  const width = 12;
  const toothGap = 4;
  const gearL = {
    x: position.x + 2,
    y: position.y,
  };
  const gearR = {
    x: position.x + width + 2,
    y: position.y + height - 28,
  };

  ctx.save();
  ctx.fillStyle = fill;
  ctx.strokeStyle = stroke;
  ctx.lineWidth = 0.5;
  ctx.beginPath();
  ctx.fillRect(gearL.x, gearL.y, width, height);
  ctx.strokeRect(gearL.x, gearL.y, width, height);
  ctx.fillRect(gearR.x, gearR.y, width, height);
  ctx.strokeRect(gearR.x, gearR.y, width, height);
  ctx.fill();
  ctx.closePath();
  let tOffset = 0;
  ctx.beginPath();
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.lineWidth = 2;
  do {
    const lOffset = tOffset > height ? gearL.y + height : gearL.y + tOffset;
    const rOffset = tOffset > height ? gearR.y + height : gearR.y + tOffset;

    ctx.moveTo(gearL.x, lOffset);
    ctx.lineTo(gearL.x + width, lOffset);
    ctx.moveTo(gearR.x, rOffset);
    ctx.lineTo(gearR.x + width, rOffset);
    tOffset += toothGap;
  } while (tOffset < height);
  ctx.closePath();
  ctx.stroke();
  ctx.restore();
};
/**
 * Inverter symbol for UI
 */
export const inverter = (
  ctx: CanvasRenderingContext2D,
  position: ComponentBounds,
  fill: string = '#000',
  inverted: boolean = false
): void => {
  ctx.beginPath();
  ctx.fillStyle = fill;
  ctx.font = `bold ${position.width / 2.5}px serif`;
  ctx.textBaseline = 'middle';

  ctx.roundRect(position.x, position.y, position.width, position.height, 5);
  ctx.fill();
  ctx.stroke();
  ctx.fillStyle = '#000';
  if (!inverted) {
    ctx.moveTo(position.x + 2.5, position.y + 2.5);
    ctx.lineTo(
      position.x + position.width - 2.5,
      position.y + position.height - 2.5
    );

    ctx.fillText(
      '=',
      position.x + position.width * 0.65,
      position.y + position.height * 0.3
    );
    ctx.fillText(
      '~',
      position.x + position.width * 0.15,
      position.y + position.height * 0.7
    );
  } else {
    ctx.moveTo(position.x + position.width - 2.5, position.y + 2.5);
    ctx.lineTo(position.x + 2.5, position.y + position.height - 2.5);
    ctx.fillText(
      '=',
      position.x + position.width * 0.15,
      position.y + position.height * 0.3
    );
    ctx.fillText(
      '~',
      position.x + position.width * 0.65,
      position.y + position.height * 0.7
    );
  }

  ctx.stroke();
  ctx.closePath();
};
