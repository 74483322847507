import { Component, Inject } from '@angular/core';
import {
  DIALOG_DATA,
  DIALOG_REF,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { DashboardTables } from '@ansys/andromeda/dashboard';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { lastValueFrom } from 'rxjs';
import { Size } from '@ansys/awc-angular/core';
import { LibraryTransferService } from '../../../shared/services/library-transfer';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { Icons } from '@ansys/awc-angular/icons';
import { LibraryService as LibraryAPIService } from '../../../../api/services/LibraryService';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import { State } from '@ansys/andromeda/store';

@Component({
  selector: 'app-import-library-asset',
  templateUrl: './import-library-asset.component.html',
  styleUrl: './import-library-asset.component.scss',
})
export class ImportLibraryAssetComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly Icons = Icons;
  protected readonly dashboardTables = DashboardTables;
  protected readonly size = Size;
  protected total: number = 0;
  protected loading: boolean = false;
  protected selectedResource: string = '';
  protected buttonSize: ButtonSize = ButtonSize.SMALL;
  protected btnTypeSecondary: ButtonType = ButtonType.SECONDARY;
  protected libraryItemLoaded: boolean = false;

  constructor(
    @Inject(DIALOG_DATA) protected data: any,
    @Inject(DIALOG_REF) private dialogRef: DialogComponent,
    private transferService: LibraryTransferService,
    private snackbar: SnackBarService,
    private libraryAPIService: LibraryAPIService,
    private state: State
  ) {}

  protected setLibraryItemLoaded(): void {
    this.libraryItemLoaded = true;
  }

  protected async importResource(id: string): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const concept = this.state.value(ActiveConceptState);
    if (concept) {
      const resource2 = await lastValueFrom(
        this.libraryAPIService.getFromLibraryLibraryObjectIdGet(
          id,
          concept.design_instance_id as string
        )
      );

      await this.transferService.importResourceFromData(resource2);
    }

    this.snackbar.success('Resource imported successfully');
    this.dialogRef.close();
  }

  protected toggleSelection(id: string): void {
    this.selectedResource = !this.selectedResource ? id : '';
  }

  protected back(): void {
    this.libraryItemLoaded = false;
    this.selectedResource = '';
  }
}
