import { ConceptSections } from 'src/app/shared/enums';

export const backgroundMap = new Map([
  [ConceptSections.CONFIGURATIONS, 'url("assets/icons/_vehicle.svg")'],
  [
    ConceptSections.COMPONENT,
    'url(https://ansys-web-components.s3.eu-west-2.amazonaws.com/os-icons/modeler.svg)',
  ],
  [
    ConceptSections.REQUIREMENT,
    'url(https://ansys-web-components.s3.eu-west-2.amazonaws.com/os-icons/plots.svg)',
  ],
]);
