import { AWCListContent } from '@ansys/awc-angular/lists';
import { AngularAccelerationUnit } from 'src/api';

export const angularAccelerations: AWCListContent[] = [
  {
    text: 'rad/s²',
    id: AngularAccelerationUnit.RAD_S_,
  },
  {
    text: 'rpm/s',
    id: AngularAccelerationUnit.RPM_S,
  },
  {
    text: 'rps/s',
    id: AngularAccelerationUnit.RPS_S,
  },
  {
    text: 'deg/s²',
    id: AngularAccelerationUnit.DEG_S_,
  },
];
