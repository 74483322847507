import { Axle, DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import { SolvedMotor, UnitType } from 'src/api';
import { Inject } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';

export class MotorMechanicalMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA)
    private markerData: [Coords | Coords[], SolvedMotor, boolean, Axle],
    private units: ConceptUnitService
  ) {
    const [coords, results, display, axle] = markerData;
    super(DataType.MECHANICAL, MarkerType.MOTOR, [], display, coords);
    this.axle = axle;
    this.updateData([results]);
    this.title = results.name || 'Motor';
  }
  override updateData(data: any, index: number = 0): void {
    const results = data.find(
      (c: any) => c.solved_component_type === 'motor' && c.axle === this.axle
    );
    this.data = [
      {
        label: 'T',
        value: toSN(
          this.units.convertUnit(UnitType.TORQUE, results.out_torques[index])
        ),
        unit: UnitType.TORQUE,
      },
      {
        label: 'S',
        value: toSN(
          this.units.convertUnit(UnitType.ANGULAR_SPEED, results.speeds[index])
        ),
        unit: UnitType.ANGULAR_SPEED,
      },
      {
        label: 'P',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.out_powers[index])
        ),
        unit: UnitType.POWER,
      },
    ];
  }
}
