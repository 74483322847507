<ng-container *ngIf="part.component_type === 'MotorLabID'">
  <app-input
    label="Max Speed"
    placeholder="Optional..."
    [value]="part.max_speed"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.ANGULAR_SPEED"
    [id]="part.id+'_max_speed'"
    [updater]="update"
    [readonly]="readonly"
    [disabled]="!fileResponse && !update"
    (update)="setPart.emit([''+$event, 'max_speed', part.id+'_max_speed'])"></app-input>
  <app-input
    label="Stator Winding Temperature"
    placeholder="Optional..."
    [value]="part.stator_winding_temp"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.TEMPERATURE"
    [id]="part.id+'_stator_winding_temp'"
    [updater]="update"
    [readonly]="readonly"
    [disabled]="!fileResponse && !update"
    (update)="setPart.emit([''+$event, 'stator_winding_temp', part.id+'_stator_winding_temp'])"></app-input>
  <app-input
    label="Rotor Temperature"
    placeholder="Optional..."
    [value]="part.rotor_temp"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.TEMPERATURE"
    [id]="part.id+'_rotor_temp'"
    [updater]="update"
    [readonly]="readonly"
    [disabled]="!fileResponse && !update"
    (update)="setPart.emit([''+$event, 'rotor_temp', part.id+'_rotor_temp'])"></app-input>
  <app-input
    label="Stator Current Limit"
    placeholder="Optional..."
    [value]="part.stator_current_limit"
    [type]="InputTypes.NUMBER"
    [unit]="UnitType.CURRENT"
    [id]="part.id+'_stator_current_limit'"
    [updater]="update"
    [readonly]="readonly"
    [disabled]="!fileResponse && !update"
    (update)="setPart.emit([''+$event, 'stator_current_limit', part.id+'_stator_current_limit'])"></app-input>
</ng-container>
<ng-container *ngIf="part.component_type === 'MotorLossMapID'">
  <app-input
    label="Poles"
    placeholder="Optional..."
    [value]="part.poles"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_poles'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart.emit([''+$event, 'poles', part.id+'_poles'])"
  ></app-input>
</ng-container>
<div class="two-col-inputs">
  <app-input
    label="Cost"
    placeholder="Optional..."
    [value]="part.cost"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_cost'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart.emit([''+$event, 'cost', part.id+'_cost'])"></app-input>
  <app-input
    label="Mass"
    placeholder="Optional..."
    [value]="part.mass"
    [unit]="UnitType.MASS"
    [type]="InputTypes.NUMBER"
    [id]="part.id+'_mass'"
    [updater]="update"
    [readonly]="readonly"
    (update)="setPart.emit([''+$event, 'mass', part.id+'_mass'])"></app-input>
</div>
<div>
  <awc-switch
    [disabled]="readonly"
    [checked]="part.inverter_losses_included || false"
    [label]="label"
    [labelPosition]="labelPosition"
    [size]="size"
    [awcSizing]="awcSizing"
    [awcTooltip]="awcTooltip"
    (changes)="toggleIncludeLossMaps($event)"></awc-switch>
</div>
