<div class="blueprint-cont" (window:resize)="render(true)">
  <div class="blueprint-actions">
    <concept-select
      *ngIf="section === sections.REQUIREMENT && result?.requirement_solved_type !== resultType.STATIC"
      label="Time"
      placeholder="Time Point"
      [items]="pointIndexes"
      [layout]="layout"
      [(selectedItems)]="selectedPointIndex"
      (selectedChanged)="changeTableComponentPoint($event)"
      ></concept-select>
    <awc-button
      *ngIf="section === sections.REQUIREMENT"
      text="Switch to graph"
      awcTooltip="Graph View"
      [suffixIcon]="graphIcon"
      [type]="altType"
      [iconOnly]="true"
      [size]="buttonSize"
      (clicked)="switchToGraphView()"></awc-button>
    <awc-button
      *ngIf="section === sections.REQUIREMENT"
      text="Switch to table"
      awcTooltip="Table View"
      [suffixIcon]="tableIcon"
      [type]="altType"
      [iconOnly]="true"
      [size]="buttonSize"
      (clicked)="switchToTableDisplay()"></awc-button>
    <awc-button
      text="Download"
      awcTooltip="Download"
      [suffixIcon]="downloadIcon"
      [type]="altType"
      [iconOnly]="true"
      (clicked)="downloadData()"></awc-button>
  </div>
  <div #mainCont class="blueprint-canvas">
    <canvas #canvas></canvas>
    <div class="drag-zone">
      <ng-container *ngFor="let marker of _markers">
        <app-marker-display [marker]="marker" [boundsWidth]="width" [boundsHeight]="height" [_markers]="_markers" cdkDragBoundary=".drag-zone" *ngIf="marker.display" cdkDrag (render)="render()" [style.left]="marker.linkX+'px'" [style.top]="marker.linkY+'px'"></app-marker-display>
      </ng-container>
    </div>
  </div>
</div>

<img #tyre class="tyre" [hidden]="true" src="assets/Frame.svg" alt="">
