/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  ApiError,
  ArchitecturesService,
  BatteryFixedVoltages,
  BatteryLookupTableData,
  BatteryLookupTableID,
  ComponentFileType,
  ComponentInDB,
  ComponentsService as API,
  ConceptPopulated,
  InverterAnalytical,
  MotorCTCP,
  MotorLabID,
  MotorLossMap,
  MotorLossMapID,
  MotorTorqueCurves,
  TransmissionLossCoefficients,
  TransmissionLossMap,
  UnitType,
} from 'src/api';
import { ConceptPartType, PartServiceType } from '../types';
import { State } from '@ansys/andromeda/store';
import { PlotService } from './plot.service';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import {
  ActiveSectionState,
  ActiveTabState,
  SectionSelectAction,
  SectionUpdateAction,
} from '@ansys/andromeda/workspace';
import { sectionState } from '../models/section-state';
import {
  AWCTableColumns,
  AWCTableRow,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { ConceptUnitService } from './unit.service';
import {
  ActionContributionsService,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { lastValueFrom } from 'rxjs';
import { AnalyticEventEnum, ConceptInputType, ConceptSections } from '../enums';
import { SnackBarService } from '@ansys/andromeda/shared';
import { FileService } from './file.service';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { ComponentsState } from 'src/app/state/lib/components.state';
import { SelectedItemState } from 'src/app/state/lib/selected-item.state';
import { NotificationService } from './notification.service';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { ScientificNotationPipe } from '../pipes/scientific-notation/scientific-notation.pipe';
import { HttpResponse } from '@angular/common/http';
import { PlotTypeEnum } from '../enums/plot-type.enum';
import { LossTypeEnum } from '../enums/loss-type.enum';
import {
  UploadFileResponseObjectType,
  UploadFileResponseType,
} from '../types/upload-file-response.type';

export type ComponentType =
  | BatteryFixedVoltages
  | MotorCTCP
  | MotorTorqueCurves
  | MotorLossMap
  | TransmissionLossCoefficients
  | TransmissionLossMap
  | InverterAnalytical;

export type MotorType = MotorLossMap | MotorLossMapID;
/**
 */

@Injectable({ providedIn: 'root' })
export class ComponentsService implements PartServiceType {
  private instanceId!: string;
  private activeSection!: string;
  constructor(
    private API: API,
    private state: State,
    private plotService: PlotService,
    private unitService: ConceptUnitService,
    private architectureAPI: ArchitecturesService,
    private notificationService: NotificationService,
    private uploadService: FileService,
    private snackbar: SnackBarService,
    private actions: ActionContributionsService,
    private unitChoices: ConceptUnitService,
    private snPipe: ScientificNotationPipe
  ) {
    this.state
      .get(ActiveConceptState)
      .subscribe((concept?: ConceptPopulated) => {
        if (concept) {
          this.instanceId = concept.design_instance_id as string;
        }
      });
    this.activeSection = this.state.value(ActiveSectionState)?.type || '';
    this.actions
      .get(SectionSelectAction)
      .subscribe((section?: WorkspaceTabSection) => {
        if (section) {
          this.activeSection = section.type;
        }
      });
  }
  public async addPart(
    item: ConceptPartType,
    file?: File,
    type?: ComponentFileType | string,
    uploadResponse?: UploadFileResponseType
  ): Promise<ComponentInDB> {
    let resp: ComponentInDB;
    if (file) {
      resp = await this.addPartFromFile(
        item,
        file,
        type as ComponentFileType,
        uploadResponse
      );
    } else {
      resp = await lastValueFrom(
        this.API.createComponentsPost(this.instanceId, {
          ...item,
        } as ComponentType)
      ).catch((err: ApiError) => {
        const message = err.body?.message ?? 'Error Creating Component!';
        this.snackbar.error(message);
        throw new Error(err.body);
      });
    }

    const eventData: AnalyticsEventPayload = {
      type: AnalyticEventEnum.COMPONENT_ADDED,
      data: { component_type: resp.component_type },
    };
    this.actions.execute(SubmitAnalyticsEventAction, eventData);

    this.snackbar.success('Component Created!');
    return resp;
  }

  public async updatePart(item: AWCTreeItem): Promise<ComponentInDB> {
    const id = item.userData ? (item.userData['id'] as string) : null;
    if (!id) throw new Error('No ID provided');
    this.snackbar.info('Updating Component!');
    const resp = await lastValueFrom(
      this.API.updateComponentsItemIdPut(
        id,
        this.instanceId,
        item.userData as ComponentType
      )
    ).catch((err) => {
      this.notificationService.error('Error Updating Component', err);
      throw new Error(err);
    });
    item.text = resp.name;
    item.userData = {
      ...item.userData,
      ...resp,
    };
    if (item.parent) {
      (item.parent as any).dirty = true;
    }

    this.toUpdateArchitecture(item);

    const tab = this.state.value(ActiveTabState);
    const currentState: AWCTreeItem[] = this.state.value(
      ComponentsState,
      tab?.id
    );
    const index = currentState.findIndex(
      (stateItem) => stateItem.id === item.id
    );
    currentState[index] = item;
    this.state.set(ComponentsState, currentState, tab?.id);

    if (item.userData?.['component_type']) {
      const eventData: AnalyticsEventPayload = {
        type: AnalyticEventEnum.COMPONENT_UPDATED,
        data: { config_type: item.userData?.['component_type'] },
      };
      this.actions.execute(SubmitAnalyticsEventAction, eventData);
    }

    this.snackbar.success('Component Updated!');
    return resp;
  }
  public async buildBatteryLookup(
    battery: BatteryLookupTableID
  ): Promise<[AWCTableColumns[], AWCTableRow[]]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<[AWCTableColumns[], AWCTableRow[]]>(async (resolve) => {
      const columns: AWCTableColumns[] = [
        {
          id: 'voltage',
          header: `Voltage (${this.unitChoices.getChoice(UnitType.VOLTAGE)})`,
        },
        {
          id: 'usable_charge',
          header: `Usable Charge (${this.unitChoices.getChoice(
            UnitType.ELECTRICAL_ENERGY
          )})`,
        },
        {
          id: 'state_of_charge',
          header: `State of Charge (${this.unitChoices.getChoice(
            UnitType.RATIO
          )})`,
        },
        {
          id: 'power_limit_charge',
          header: `Power Limit (${this.unitChoices.getChoice(
            UnitType.ELECTRICAL_POWER
          )})`,
        },
        {
          id: 'internal_resistance',
          header: `Internal Resistance (${this.unitChoices.getChoice(
            UnitType.RESISTANCE
          )})`,
        },
      ];
      const batteryData: BatteryLookupTableData = await lastValueFrom(
        this.API.calcDisplayDataComponentsGetDisplayDataPost(
          battery.id ?? '',
          this.instanceId
        )
      );
      const rows: AWCTableRow[] = batteryData.voltage.map(
        (v: number, index: number) => {
          return {
            id: index.toString(),
            cells: [
              {
                value: this.snPipe.transform(v),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(batteryData.usable_charge[index]),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(
                  batteryData.state_of_charge[index]
                ),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(
                  batteryData.power_limit_charge[index]
                ),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(
                  batteryData.internal_resistance[index]
                ),
                type: TableCellTypes.NUMBER,
              },
            ],
          };
        }
      );
      this.plotService.setTableData([columns, rows, [], []]);
      resolve([columns, rows]);
    });
  }
  public displayData(
    displayPartId?: string,
    opts?: {
      voltage?: number;
      gearRatio?: number;
      type?: string;
      lossType?: string;
      speed?: number;
    }
  ): Promise<void> {
    interface LossMapGrid {
      losses: [number[]];
      losses_total?: [number[]];
      losses_iron?: [number[]];
      speeds: number[];
      torques: number[];
      currents?: number[];
      phase_advances?: number[];
      slips?: number[];
    }

    return new Promise<void>((resolve, reject) => {
      this.API.calcDisplayDataComponentsGetDisplayDataPost(
        displayPartId as string,
        this.instanceId,
        {
          voltage: opts?.voltage,
          gear_ratio: opts?.gearRatio,
          speed: opts?.speed,
        }
      ).subscribe(async (resp: LossMapGrid) => {
        const lossType =
          opts?.lossType === LossTypeEnum.IRON
            ? resp.losses_iron
            : resp.losses_total;
        const zAxis = (lossType ?? resp.losses) as [never[]];
        const plotType = zAxis ? PlotTypeEnum.CONTOUR : PlotTypeEnum.SCATTER;
        const data = [
          {
            type: plotType,
            colorscale: 'Electric',
            x: (resp.currents ?? resp.speeds) as never[],
            y: (resp.phase_advances ?? resp.slips ?? resp.torques) as never[],
            z: zAxis,
            colorbar: {
              title: `Loss (${this.unitService.getChoice(
                opts?.type === 'transmission' ? UnitType.TORQUE : UnitType.POWER
              )})`,
            },
          },
        ];
        let yTitle: string = '';
        if (resp.phase_advances) {
          yTitle = `Phase Advance (${this.unitService.getChoice(
            UnitType.GRADIENT
          )})`;
        } else if (resp.slips) {
          yTitle = 'Slip';
        } else {
          yTitle = `Torque (${this.unitService.getChoice(UnitType.TORQUE)})`;
        }

        let showlegend = true;
        if (
          opts?.type === ConceptInputType.MOTOR &&
          !resp.currents &&
          !resp.phase_advances &&
          !resp.slips
        ) {
          showlegend = false;
        }

        const layout = {
          xaxis: {
            range: [],
            title: resp.currents
              ? `Stator Current (${this.unitService.getChoice(
                  UnitType.CURRENT
                )})`
              : `Speed (${this.unitService.getChoice(UnitType.ANGULAR_SPEED)})`,
          },
          yaxis: {
            range: [],
            title: yTitle,
          },
          showlegend,
        };
        const activeSection = this.state.value(ActiveSectionState);
        const currentSelection = this.state.value(
          SelectedItemState,
          activeSection?.id
        );

        if (
          displayPartId === currentSelection?.id &&
          this.activeSection === ConceptSections.COMPONENT
        ) {
          this.plotService.setGraphData(data);
          this.plotService.updateGraphLayout(layout);
          resolve();
        } else reject();
      });
    });
  }
  public deletePart(item: AWCTreeItem): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const id = item.userData ? (item.userData['id'] as string) : null;
      if (!id) throw new Error('No ID provided');
      this.API.deleteComponentsItemIdDelete(id, this.instanceId).subscribe(
        () => {
          const tab = this.state.value(ActiveTabState);
          const currentState: AWCTreeItem[] = this.state.value(
            ComponentsState,
            tab?.id
          );
          const index = currentState.findIndex(
            (stateItem) => stateItem.id === item.id
          );
          currentState.splice(index, 1);
          this.state.set(ComponentsState, currentState, tab?.id);

          if (item.userData?.['component_type']) {
            const eventData: AnalyticsEventPayload = {
              type: AnalyticEventEnum.COMPONENT_DELETED,
              data: { config_type: item.userData?.['component_type'] },
            };
            this.actions.execute(SubmitAnalyticsEventAction, eventData);
          }

          this.snackbar.success('Component Deleted!');
          resolve({});
        },
        (err) => {
          const message = err.body?.message ?? 'Error Deleting Component!';
          this.snackbar.error(message);
          reject();
          throw new Error(err);
        }
      );
    });
  }
  private async addPartFromFile(
    item: ConceptPartType,
    file: File,
    type?: ComponentFileType,
    uploadResponse?: UploadFileResponseType
  ): Promise<ComponentInDB> {
    const name = item.name || 'New Component';
    let id;
    let responseObject!: UploadFileResponseObjectType;

    if (!uploadResponse) {
      const uploadResp = await this.uploadFilePart(file, type);
      [id, responseObject] = uploadResp.body;
    } else {
      [id, responseObject] = [uploadResponse.id, uploadResponse.responseObject];
    }

    const resp = await lastValueFrom(
      this.API.createComponentsPost(this.instanceId, {
        ...item,
        name,
        mass: (item as MotorType).mass,
        cost: (item as MotorType).cost,
        inverter_losses_included: (item as MotorType).inverter_losses_included,
        component_type: (item as MotorLossMapID).component_type,
        data_id: id as string,
        max_speed: (item as MotorLabID).max_speed ?? responseObject?.max_speed,
        rotor_temp:
          (item as MotorLabID).rotor_temp ?? responseObject?.rotor_temp,
        stator_current_limit:
          (item as MotorLabID).stator_current_limit ??
          responseObject?.stator_current_limit,
        stator_winding_temp:
          (item as MotorLabID).stator_winding_temp ??
          responseObject?.stator_winding_temp,
      } as never)
    ).catch((err) => {
      this.notificationService.error('Error Creating Component', err);
      throw new Error(err);
    });
    this.snackbar.success('Component Created!');
    return resp;
  }

  public async uploadFilePart(
    file: File,
    type?: ComponentFileType
  ): Promise<HttpResponse<any>> {
    return await lastValueFrom(
      this.uploadService.uploadFile(
        type as ComponentFileType,
        this.instanceId,
        file
      )
    ).catch((err) => {
      this.notificationService.error('Error Uploading File', err);
      throw new Error(err);
    });
  }

  private async toUpdateArchitecture(item: AWCTreeItem): Promise<void> {
    const concept = this.state.value(ActiveConceptState);
    const architecture = concept?.architecture;
    let toUpdateArchitecture = false;
    if (!architecture) return;

    if (item.parent?.id === ConceptInputType.TRANSMISSION) {
      toUpdateArchitecture =
        architecture.front_transmission_id === item.id ||
        architecture.rear_transmission_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.MOTOR) {
      toUpdateArchitecture =
        architecture.front_motor_id === item.id ||
        architecture.rear_motor_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.BATTERY) {
      toUpdateArchitecture = architecture.battery_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.INVERTER) {
      toUpdateArchitecture =
        architecture.front_inverter_id === item.id ||
        architecture.rear_inverter_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.CLUTCH) {
      toUpdateArchitecture =
        architecture.front_clutch_id === item.id ||
        architecture.rear_clutch_id === item.id;
    }
    if (toUpdateArchitecture) {
      concept.architecture = await lastValueFrom(
        this.architectureAPI.updateArchitectureArchitecturesItemIdPut(
          architecture.id as string,
          this.instanceId,
          architecture
        )
      );
      this.state.set(ActiveConceptState, concept);
    }
  }

  public shouldUpdateSection(item: ConceptPartType, parent?: string): boolean {
    const tab = this.state.value(ActiveTabState);
    const currentState = this.state.value(ComponentsState, tab?.id || '') || [];
    const transmissionCheck =
      currentState.find(
        (part: AWCTreeItem) =>
          part.userData && part.userData['_parentID'] === 'transmission'
      ) || parent === 'transmission';
    const motorCheck =
      currentState.find(
        (part: AWCTreeItem) =>
          part.userData && part.userData['_parentID'] === 'motor'
      ) || parent === 'motor';
    const batteryCheck =
      currentState.find(
        (part: AWCTreeItem) =>
          part.userData && part.userData['_parentID'] === 'battery'
      ) || parent === 'battery';

    if (transmissionCheck && motorCheck && batteryCheck) {
      sectionState.architecture = true;
      this.actions.execute(SectionUpdateAction);
    }
    return !!(transmissionCheck && motorCheck && batteryCheck);
  }
  /**
   * Gets component relative to the config id and design id
   * @param {string} id - ID of the component to get
   * @param {string} designId - ID of the design to get the component from
   * @returns {void}
   */
  public async get(id: string, designId: string): Promise<ComponentInDB> {
    return await lastValueFrom(this.API.readComponentsItemIdGet(id, designId));
  }
}
