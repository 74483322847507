import { Injectable } from '@angular/core';
import { State } from '@ansys/andromeda/store';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { TemplatesService } from '../../../api';

@Injectable({
  providedIn: 'root',
})
export class TemplateListService implements TemplateListService {
  constructor(
    private state: State,
    private templatesService: TemplatesService
  ) {}

  async getTemplates(): Promise<AWCListItem[]> {
    return new Promise((resolve, reject) => {
      this.templatesService.listTemplatesTemplatesListGet().subscribe(
        (templateList) => {
          const mappedTemplate: AWCListItem[] = templateList.map(
            (template) => ({
              id: template.design_instance_id,
              text: template.name,
            })
          );

          resolve(mappedTemplate);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
