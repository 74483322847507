import { Pipe, PipeTransform } from '@angular/core';
import { UnitType } from 'src/api';
import { ConceptUnitService } from '../../services/unit.service';

@Pipe({
  name: 'unitFromType',
})
export class UnitFromTypePipe implements PipeTransform {
  constructor(private unitService: ConceptUnitService) {}
  transform(value: UnitType): string | null {
    return this.unitService.getChoice(value) || '';
  }
}
