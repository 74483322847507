import {
  AWFAction,
  ActionContributionsService,
} from '@ansys/andromeda/contributions';
import { Injectable } from '@angular/core';
import { TabOpenAction } from '@ansys/andromeda/workspace';
import { ActionsEnum } from '../../shared/enums';
import { ConceptEnum } from '../../tabs/concept-tab/enums/concept.enum';

@Injectable({ providedIn: 'root' })
export class OpenConceptAction implements AWFAction<string, void> {
  type: string = ActionsEnum.OPEN_CONCEPT;
  constructor(private actions: ActionContributionsService) {}
  execute: (instanceId: string) => Promise<void> = async (
    instanceId: string
  ) => {
    await this.actions.execute(TabOpenAction, {
      type: ConceptEnum.TAB_TYPE,
      urlParams: [instanceId],
      userData: { id: instanceId },
      selectTab: true,
    });
  };
}
