/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Statuses.
 */
export enum Statuses {
    FINISHED = 'FINISHED',
    QUEUED = 'QUEUED',
    RUNNING = 'RUNNING',
}
