/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { ArchitectureInputIds } from '../models/ArchitectureInputIds';
import type { ComponentInDB } from '../models/ComponentInDB';
import type { Concept } from '../models/Concept';
import type { ConceptCloneInput } from '../models/ConceptCloneInput';
import type { ConceptPopulated } from '../models/ConceptPopulated';
import type { ConceptUpdate } from '../models/ConceptUpdate';
import type { ConfigurationInDB } from '../models/ConfigurationInDB';
import type { DriveCycleInDB } from '../models/DriveCycleInDB';
import type { PartNames } from '../models/PartNames';
import type { Requirement } from '../models/Requirement';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ConceptsService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Create Concept Check
     * Create with additional checks..
     * @param designInstanceId
     * @param requestBody
     * @returns Concept Successful Response
     * @throws ApiError
     */
    public createConceptCheckConceptsPost(
        designInstanceId: string,
        requestBody: Concept,
    ): Observable<Concept> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/concepts',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read
     * Get from ID.
     * @param designInstanceId
     * @param populated
     * @returns any Successful Response
     * @throws ApiError
     */
    public readConceptsDesignInstanceIdGet(
        designInstanceId: string,
        populated: boolean = false,
    ): Observable<(ConceptPopulated | Concept)> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/concepts/{design_instance_id}',
            path: {
                'design_instance_id': designInstanceId,
            },
            query: {
                'populated': populated,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Parts
     * Get the parts of a concept.
     * @param designInstanceId
     * @param partName
     * @param skip
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public listPartsConceptsDesignInstanceIdPartNameGet(
        designInstanceId: string,
        partName: PartNames,
        skip?: number,
        limit: number = 100,
    ): Observable<Array<(ArchitectureInputIds | ComponentInDB | ConfigurationInDB | Requirement | DriveCycleInDB)>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/concepts/{design_instance_id}/{part_name}',
            path: {
                'design_instance_id': designInstanceId,
                'part_name': partName,
            },
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * Update with new parameters.
     * @param itemId
     * @param designInstanceId
     * @param requestBody
     * @returns Concept Successful Response
     * @throws ApiError
     */
    public updateConceptsItemIdPatch(
        itemId: string,
        designInstanceId: string,
        requestBody: ConceptUpdate,
    ): Observable<Concept> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/concepts/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * Delete by ID.
     * @param itemId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteConceptsItemIdDelete(
        itemId: string,
        designInstanceId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/concepts/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Copy
     * Clone Concept.
     * @param populated
     * @param designInstanceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public copyConceptsCopyPost(
        populated: boolean,
        designInstanceId: string,
        requestBody: ConceptCloneInput,
    ): Observable<(ConceptPopulated | Concept)> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/concepts:copy',
            query: {
                'populated': populated,
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
