import { TabSectionType } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { DashboardSection } from '@ansys/andromeda/dashboard';
import { ConceptSections } from '../../../../shared/enums';
import { sectionState } from '../../../../shared/models/section-state';

export class RequirementsSection
  extends DashboardSection
  implements TabSectionType
{
  public override type: ConceptSections = ConceptSections.REQUIREMENT;
  public override title: string = 'Requirements';
  public override icon: Icons = Icons.PLOTS;
  constructor() {
    super();
  }
  public isDisabled(): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(!sectionState.requirement);
    });
  }
}
