/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Power Unit.
 */
export enum PowerUnit {
    W = 'W',
    K_W = 'kW',
    M_W = 'mW',
    MW = 'MW',
    HP = 'hp',
}
