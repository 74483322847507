/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Acceleration Unit.
 */
export enum AccelerationUnit {
    M_S_ = 'm/s²',
    KM_HR_S = 'km/hr/s',
    MPH_S = 'mph/s',
}
