/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Angular Speed Unit.
 */
export enum AngularSpeedUnit {
    RAD_S = 'rad/s',
    RPM = 'rpm',
    RPS = 'rps',
    DEG_S = 'deg/s',
}
