import { Axle, DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import * as Utils from './util';
import { SolvedMotor, UnitType } from 'src/api';
import { Inject } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';

export class MotorComponentMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA)
    private markerData: [Coords | Coords[], SolvedMotor, boolean, Axle],
    private units: ConceptUnitService
  ) {
    const [coords, results, display, axle] = markerData;
    super(DataType.COMPONENT, MarkerType.MOTOR, [], display, coords);
    this.axle = axle;
    this.updateData([results]);
    const title = results.name
      .replace('(Front) (Left)', '(Front)')
      .replace('(Front) (Right)', '(Front)')
      .replace('(Rear) (Left)', '(Rear)')
      .replace('(Rear) (Right)', '(Rear)');
    this.title = title || 'Motor';
  }
  override updateData(data: any, index: number = 0): void {
    const results = data.find(
      (c: any) => c.solved_component_type === 'motor' && c.axle === this.axle
    );
    this.data = [
      {
        label: 'Losses',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.losses[index])
        ),
        unit: UnitType.POWER,
      },
      {
        label: 'Efficiency',
        value:
          toSN(
            Utils.calculateEff(
              results.out_powers[index],
              results.in_powers[index]
            )
          ) + '%',
      },
      {
        label: 'Id',
        value:
          toSN(
            this.units.convertUnit(UnitType.CURRENT, results.currents_d[index])
          ) || '0',
        unit: UnitType.CURRENT,
      },
      {
        label: 'Iq',
        value:
          toSN(
            this.units.convertUnit(UnitType.CURRENT, results.currents_q[index])
          ) || '0',
        unit: UnitType.CURRENT,
      },
    ];
  }
}
