/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { Aero } from '../models/Aero';
import type { AncillaryLoad } from '../models/AncillaryLoad';
import type { ConfigurationInDB } from '../models/ConfigurationInDB';
import type { DecelerationLimit } from '../models/DecelerationLimit';
import type { Mass } from '../models/Mass';
import type { TotalTractiveTorqueGraph } from '../models/TotalTractiveTorqueGraph';
import type { WheelInput } from '../models/WheelInput';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ConfigurationsService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Create
     * Create from parameters.
     * @param designInstanceId
     * @param requestBody
     * @returns ConfigurationInDB Successful Response
     * @throws ApiError
     */
    public createConfigurationsPost(
        designInstanceId: string,
        requestBody: (Aero | Mass | WheelInput | DecelerationLimit | AncillaryLoad),
    ): Observable<ConfigurationInDB> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/configurations',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read
     * Get from ID.
     * @param itemId
     * @param designInstanceId
     * @returns ConfigurationInDB Successful Response
     * @throws ApiError
     */
    public readConfigurationsItemIdGet(
        itemId: string,
        designInstanceId: string,
    ): Observable<ConfigurationInDB> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/configurations/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * Update with new parameters.
     * @param itemId
     * @param designInstanceId
     * @param requestBody
     * @returns ConfigurationInDB Successful Response
     * @throws ApiError
     */
    public updateConfigurationsItemIdPut(
        itemId: string,
        designInstanceId: string,
        requestBody: (Aero | Mass | WheelInput | DecelerationLimit | AncillaryLoad),
    ): Observable<ConfigurationInDB> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/configurations/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * Delete by ID.
     * @param itemId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteConfigurationsItemIdDelete(
        itemId: string,
        designInstanceId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/configurations/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Calculate Total Forces
     * Calculate the total tractive torque.
     * @param designInstanceId
     * @param aeroId
     * @param massId
     * @param wheelId
     * @param maxSpeed
     * @param acceleration
     * @param altitude
     * @param headwind
     * @param gradient
     * @param stepSizeSpeed
     * @returns TotalTractiveTorqueGraph Successful Response
     * @throws ApiError
     */
    public calculateTotalForcesConfigurationsCalculateForcesGet(
        designInstanceId: string,
        aeroId: string,
        massId: string,
        wheelId: string,
        maxSpeed: number = 40,
        acceleration?: number,
        altitude?: number,
        headwind?: number,
        gradient?: number,
        stepSizeSpeed: number = 0.2,
    ): Observable<TotalTractiveTorqueGraph> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/configurations:calculate_forces',
            query: {
                'design_instance_id': designInstanceId,
                'aero_id': aeroId,
                'mass_id': massId,
                'wheel_id': wheelId,
                'max_speed': maxSpeed,
                'acceleration': acceleration,
                'altitude': altitude,
                'headwind': headwind,
                'gradient': gradient,
                'step_size_speed': stepSizeSpeed,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
