export interface RequirementsDefaultLayoutType {
  xaxis: {
    gridwidth: number;
    gridcolor: string;
    range: number[];
    title: string;
  };
  yaxis: {
    gridwidth: number;
    gridcolor: string;
    range: number[];
    title: string;
  };
}
