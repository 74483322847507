import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AWFPage,
  PageContributionsService,
} from '@ansys/andromeda/contributions';
import { PagesEnum } from './pages.enum';
import { WorkspacePage } from './workspace/workspace.page';
import { WorkspaceComponent } from '@ansys/andromeda/workspace';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class PagesModule {
  constructor(private pages: PageContributionsService) {
    this.pages
      .contribute(PagesEnum.WORKSPACE, WorkspacePage)
      .then((page: AWFPage) => {
        this.pages.contributeContent(page, WorkspaceComponent);
      });
  }
}
