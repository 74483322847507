/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Unit of Electrical Charge.
 */
export enum ElectricChargeUnit {
    A_S = 'A·s',
}
