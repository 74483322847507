<app-input
  label="Cross Sectional Area"
  placeholder="Cross Sectional Area..."
  [value]="part.cross_sectional_area || '0'"
  [type]="InputTypes.NUMBER"
  [unit]="UnitType.AREA"
  [id]="part.id+'_cross_sectional_area'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit(['' + $event, 'cross_sectional_area', part.id+'_cross_sectional_area'])"></app-input>
<app-input
  label="Drag Coefficient"
  placeholder="Drag Coefficient...."
  [value]="part.drag_coefficient"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_drag_coefficient'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit(['' + $event, 'drag_coefficient', part.id+'_drag_coefficient'])"></app-input>