import { BffUrls } from '@ansys/andromeda/api/graph';
import { AuthEnvironment } from '@ansys/andromeda/auth';

export const environment = {
  production: true,
  type: AuthEnvironment.TEST,
  bff: BffUrls.DEV,
  msalClientId: '9c9edb71-06f9-4c4b-afed-9201668385de',
  title: 'ConceptEV',
  apiURL: 'https://dev-conceptev.awsansys3np.onscale.com/api',
  appURL: 'https://dev-conceptev.awsansys3np.onscale.com',
  ocmUrl: 'https://solve.test.portal.onscale.com/api',
  msalb2c:
    'https://a365dev.b2clogin.com/a365dev.onmicrosoft.com/B2C_1A_ANSYSID_SIGNUP_SIGNIN',
  msalAuthDomain: 'https://a365dev.b2clogin.com',
  msalPortalScope: 'https://a365dev.onmicrosoft.com/AnsysID/Authentication',
};
