/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Area Unit.
 */
export enum AreaUnit {
    M_ = 'm²',
    MM_ = 'mm²',
    CM_ = 'cm²',
    IN_ = 'in²',
    FT_ = 'ft²',
    YD_ = 'yd²',
}
