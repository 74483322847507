import { Axle, DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import { SolvedInverter, UnitType } from 'src/api';
import { Inject } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import {
  SolvedComponent,
  SolvedComponents,
} from '../../blueprint-display.component';

export class InverterElectricalMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA)
    private markerData: [Coords | Coords[], SolvedInverter, boolean, Axle],
    private units: ConceptUnitService
  ) {
    const [coords, results, display, axle] = markerData;
    super(DataType.ELECTRICAL, MarkerType.Inverter, [], display, coords);
    this.title = results.name || 'Inverter';
    this.axle = axle;
    this.updateData([results]);
  }
  override updateData(data: SolvedComponents, index: number = 0): void {
    const results = data.find(
      (c: SolvedComponent) =>
        c.solved_component_type === 'inverter' && c.axle === this.axle
    ) as SolvedInverter;
    this.data = [
      {
        label: 'I',
        value: toSN(
          this.units.convertUnit(
            UnitType.CURRENT,
            results.currents?.[index] ?? 0
          )
        ),
      },
      {
        label: 'V',
        value: toSN(
          this.units.convertUnit(UnitType.VOLTAGE, results.in_voltages[index])
        ),
        unit: UnitType.VOLTAGE,
      },
      {
        label: 'P',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.in_powers[index])
        ),
        unit: UnitType.POWER,
      },
    ];
  }
}
