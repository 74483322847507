/* eslint-disable @typescript-eslint/naming-convention */
import { DynamicRequirementInputsIds } from 'src/api';
import { RequirementType } from '../../enums';

export const dynamicRequirement: DynamicRequirementInputsIds = {
  requirement_type: RequirementType.DYNAMIC_INPUT,
  mass_id: '',
  aero_id: '',
  wheel_id: '',
};
