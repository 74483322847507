/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  ErrorListPageResponse,
  Job,
  JobApiService,
  JobStatus,
} from '@ansys/andromeda/api/rest';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import {
  AWFLocation,
  AWF_LOCATION,
  DialogService,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { SectionSelectAction } from '@ansys/andromeda/workspace';
import { ColorBackground } from '@ansys/awc-angular/core';
import { IconSize, Icons } from '@ansys/awc-angular/icons';
import { ProgressBarItem } from '@ansys/awc-angular/loaders';
import { ReplaySubject, lastValueFrom, takeUntil } from 'rxjs';
import { ConceptPopulated, JobsService, RequirementsSolved } from 'src/api';
import {
  JobMetaData,
  LoadAllJobs,
  SetJobs,
  UpdateJobProgress,
  UpdateJobStatus,
} from '../../../actions';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { ResultsService } from 'src/app/shared/services/results.services';
import { JobErrorDialogComponent } from '../../dialogs/job-error-dialog/job-error-dialog.component';
import { JobLoadingStatus } from '../../../shared/enums/job-loading-status.enum';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { AppThemeState, State, StateType } from '@ansys/andromeda/store';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import { ConceptService } from '../../../shared/services/concept.service';
import { JobState } from '../../../state/lib/jobs.state';
import { PlotService } from '../../../shared/services/plot.service';

export enum ResultType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  DRIVE_CYCLE = 'drive_cycle',
}

@Component({
  selector: 'results-list',
  templateUrl: './results-list.component.html',
  styleUrls: ['./results-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsListComponent implements OnDestroy {
  protected jobsList: Job[] = [];
  protected readonly Icons = Icons;
  protected iconSmall: IconSize = IconSize.SMALL;
  protected iconXSmall: IconSize = IconSize.X_SMALL;
  protected selectedJob: string = '';
  protected selectedResult: string = '';
  protected $resultsLoaded: boolean = false;
  protected ResultType = ResultType;
  protected maxHeight: number = 500;
  protected now: Date = new Date();
  protected results: RequirementsSolved[] = [];
  protected color: ColorBackground = ColorBackground.PRIMARY_DEFAULT;
  protected loadingcolor: ColorBackground = ColorBackground.WARNING_DEFAULT;
  protected progressBars: { [key: string]: ProgressBarItem[] } = {};
  protected barlabels: { [key: string]: string } = {};
  protected loadingBar: { [key: string]: boolean } = {};
  protected hasJobProgressSocket: { [key: string]: boolean } = {};
  protected theme!: string;
  private instanceId!: string;
  private toupdate: boolean = false;
  private ngUnsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private requirementService: RequirementsService,
    private jobService: JobsService,
    private jobApiService: JobApiService,
    private _cdr: ChangeDetectorRef,
    private snackbar: SnackBarService,
    private resultsService: ResultsService,
    private actions: ActionContributionsService,
    private dialog: DialogService,
    @Inject(AWF_LOCATION) private location: AWFLocation,
    private elRef: ElementRef,
    private dialogService: DialogService,
    private state: State,
    private actionService: ActionContributionsService,
    private conceptService: ConceptService,
    private plotService: PlotService
  ) {
    this.requirementService.selectFromPlot
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((id: string) => {
        if (this.results && id) {
          const result = this.results.find((result) => result.id === id);
          this.selectResult(result);
        }
      });

    this.state
      .get(AppThemeState)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((theme: StateType) => {
        if (theme && this.theme !== theme) {
          const isDarkMode =
            theme === 'dark' ||
            (theme === 'auto' &&
              window.matchMedia &&
              window.matchMedia('(prefers-color-scheme: dark)').matches);

          this.theme = isDarkMode ? 'dark' : ('light' as string);
        }
      });
  }
  async ngOnInit(): Promise<void> {
    this.actions.execute(JobMetaData);
    this.actions.execute(SetJobs);
    this.actions
      .get(UpdateJobProgress)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: [string, number]) => this.updateJobProgress(data));

    this.actions
      .get(UpdateJobStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: [string, string]) => this.updateJobStatus(data));

    this.actions
      .get(SetJobs)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((jobs: Job[]) => this.setJobs(jobs));

    if (
      this.location.section?.params &&
      this.location.section?.params?.length > 1
    ) {
      const jobId = this.location.section.params[1];
      const job = await lastValueFrom(
        this.jobApiService.jobLoadHandler({ jobId })
      );
      this.selectJob(job);
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxHeight = this.elRef.nativeElement.offsetHeight;
    }, 0);
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.requirementService.jobResultLoading.next(JobLoadingStatus.IDLE);
    this.deselectJob();
  }
  protected async getInstanceId(): Promise<string> {
    const instanceId = this.location.paramMap?.get('instanceId');
    if (!instanceId) throw new Error('No design instance found');

    return instanceId;
  }
  protected async selectJob(job: Job): Promise<void> {
    if (!this.instanceId) {
      this.instanceId = await this.getInstanceId();
    }

    if (
      job.finalStatus === JobStatus.jobStatus.FAILED ||
      job.lastStatus === JobStatus.jobStatus.FAILED
    ) {
      const errs = await lastValueFrom(
        this.jobApiService.errorListPageHandler({ jobId: job?.jobId as string })
      );

      this.dialog.open(JobErrorDialogComponent, {
        title: `Job Error: ${
          (errs as ErrorListPageResponse).errors?.[0].errorCode
        }`,
        data: {
          ...errs,
          jobName: job?.jobName as string,
          jobDockerTag: job?.dockerTag as string,
          instanceId: this.instanceId as string,
        },
      });
    } else {
      const section = this.location.section;
      if (section?.id) {
        this.actions.execute(SectionSelectAction, {
          sectionId: section.id,
          urlParams: ['results', job.jobId as string],
        });
      }

      this.requirementService.resetDefaultLayoutRange();
      this.requirementService.jobResultLoading.next(JobLoadingStatus.LOADING);
      this.selectedJob = job.jobId as string;
      this._cdr.detectChanges();
      this.$resultsLoaded = false;
      this.requirementService.resetMergedData();
      this.plotService.setGraphData([]);
      this.results = [];
      this.results = await this.resultsService.getResults(job);

      this.$resultsLoaded = true;
      if (this.selectedJob.length) {
        this.requirementService.jobResultLoading.next(
          JobLoadingStatus.FINISHED
        );
      }
      !this.toupdate &&
        setTimeout(() => {
          this.selectResult(this.results[0]);
        });
      this.requirementService.selectedTimeIndex.next(0);
      this._cdr.markForCheck();
      // this._cdr.detectChanges();
    }

    return Promise.resolve();
  }

  protected deselectJob(redirect?: boolean): void {
    this.selectedJob = '';
    this.selectedResult = '';
    this.results = [];
    this.requirementService.setSelectedResult(null);
    this.requirementService.resetMergedData();
    this.requirementService.jobResultLoading.next(JobLoadingStatus.IDLE);
    if (redirect) {
      const a = this.location.section;
      a &&
        a.id &&
        this.actions.execute(SectionSelectAction, {
          sectionId: a.id,
          urlParams: ['results'],
        });
    }
    this._cdr.markForCheck();
  }
  protected selectResult(result: any): void {
    this.selectedResult = result.id;
    this.requirementService.selectedTimeIndex.next(0);
    this.requirementService.selectResult(
      result,
      result.requirement_solved_type === ResultType.DRIVE_CYCLE,
      this.selectedJob,
      () => {
        return this.selectedJob;
      }
    );
    this._cdr.detectChanges();
  }

  protected onDeleteJob($event: Event, job: Job): void {
    $event.stopPropagation();
    const title = 'Delete job' + (job.jobName ? `: ${job.jobName}` : '');

    const dialog = this.dialogService.open(ConfirmComponent, {
      title,
      data: {
        onConfirm: async () => {
          await lastValueFrom(
            this.jobService.deleteJobEndpointJobsDelete(
              job.designInstanceId as string,
              job.jobId as string
            )
          )
            .then(async () => {
              dialog.close();

              this.conceptService
                .getConcept(job.designInstanceId as string)
                .then(async (concept) => {
                  this.actionService.execute(LoadAllJobs, concept);
                });
            })
            .catch(() => {
              this.snackbar.error('Failed to delete Job');
            });
        },
        onCancel: () => {
          dialog.close();
        },
      },
    });
  }

  private async updateJobStatus(data: [string, string]): Promise<void> {
    const [jobId, status] = data;
    if (status === JobStatus.jobStatus.FAILED) {
      this.loadingBar[jobId] = false;
      this.progressBars[jobId] = [
        {
          percentage: 100,
          color: ColorBackground.DANGER_DEFAULT,
        },
      ];
      this.barlabels[jobId] = 'FAILED';
    } else if (status === 'complete') {
      this.loadingBar[jobId] = true;
      this.barlabels[jobId] = 'FINISHING UP';
    } else if (status === 'RUNNING') {
      this.barlabels[jobId] = 'INPUTTING DATA';
    } else {
      if (status === 'COMPLETED') {
        this.loadingBar[jobId] = false;
        this.progressBars[jobId] = [
          {
            percentage: 100,
            color: ColorBackground.SUCCESS_DEFAULT,
          },
        ];
      }
      this.barlabels[jobId] = status;
    }
    const job = this.jobsList.find((j) => j.jobId === jobId);
    if (job && !job.jobName) {
      const jobMetaMap = await this.actions.execute(JobMetaData);
      const jobName = jobMetaMap.get(jobId);
      job.jobName = jobName;
      this.jobsList = [...this.jobsList];
    }
    if (job) {
      // temp resolve while we wait for the backend to fix the status/socket desync
      (job as any)['lastStatus'] = status;
    }
    this._cdr.detectChanges();
  }
  private updateJobProgress(data: [string, number]): void {
    {
      const [jobId, progress] = data;
      this.hasJobProgressSocket[jobId] = true;
      this.loadingBar[jobId] = false;
      if (this.progressBars[jobId] && this.progressBars[jobId][0]) {
        this.progressBars[jobId][0].percentage = progress;
        this.progressBars[jobId][0].color = ColorBackground.WARNING_DEFAULT;
        this.progressBars[jobId] = [...this.progressBars[jobId]];
        this.barlabels[jobId] = `RUNNING: ${Math.round(progress)}%`;
      } else {
        this.barlabels[jobId] = 'FETCHING PROGRESS...';
      }
      this._cdr.detectChanges();
    }
  }
  private setJobs(jobs: Job[]): void {
    this.progressBars = {};
    this.barlabels = {};
    if (jobs) {
      this.jobsList = jobs.sort(
        (j1, j2) =>
          (j2?.queuedStatusDate || Infinity) -
          (j1?.queuedStatusDate || Infinity)
      );
    } else if (this.jobsList.length) {
      this.jobsList = [];
    }

    this.jobsList.forEach(async (job) => {
      this.loadingBar[job.jobId as string] = false;
      const jobRunning =
        job.lastStatus === JobStatus.jobStatus.RUNNING &&
        !this.hasJobProgressSocket[job.jobId as string];

      if (
        job.jobId &&
        (jobRunning ||
          job.lastStatus === JobStatus.jobStatus.QUEUED ||
          job.lastStatus === JobStatus.jobStatus.CREATED)
      ) {
        this.loadingBar[job.jobId as string] = true;
        if (jobRunning) {
          this.barlabels[job.jobId as string] = 'FETCHING PROGRESS...';
        }
      }
      if (
        job?.simulations?.[0]?.lastStatus === 'COMPLETED' &&
        job?.lastStatus !== 'FINISHED'
      ) {
        this.loadingBar[job.jobId as string] = true;
        this.barlabels[job.jobId as string] = 'FINISHING UP';
      }
      this.progressBars[job.jobId as string] = [
        {
          percentage: 100,
          color:
            job.finalStatus === 'FAILED'
              ? ColorBackground.DANGER_DEFAULT
              : ColorBackground.SUCCESS_DEFAULT,
        },
      ];
      this.progressBars[job.jobId as string] = [
        ...this.progressBars[job.jobId as string],
      ];
    });

    if (this.toupdate) {
      if (this.selectedResult && this.selectedJob.length) {
        this.selectJob(
          this.jobsList.find((j) => j.jobId === this.selectedJob) as Job
        ).then(() => {
          this.selectResult(
            this.results.find((r) => r.id === this.selectedResult)
          );
          this.toupdate = false;
        });
      }
    }
    this._cdr.detectChanges();
  }
}
