/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * How user has defined PWM frequency.
 */
export enum PWMFrequencyDefinition {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
}
