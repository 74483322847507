/* eslint-disable @typescript-eslint/naming-convention */
/* /* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FlexLayout } from '@ansys/awc-angular/core';
import { InputTypes } from '../input/input.component';
import { AWCListItem } from '@ansys/awc-angular/lists';

@Component({
  selector: 'concept-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class ConceptSelectComponent {
  @Input() label!: string;
  @Input() items: AWCListItem[] = [];
  @Input() placeholder = 'Select an item';
  @Input() layout: FlexLayout = FlexLayout.ROW;
  @Input() selectedItems: string[] = [];
  @Input() fillWidth: boolean = false;
  @Output() selectedItemsChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() selectedChanged: EventEmitter<AWCListItem[]> = new EventEmitter<
    AWCListItem[]
  >();
  readonly InputTypes = InputTypes;
  protected labelWidth: number = 0;
  protected optionsActive: boolean = false;
  protected _selectedItems: AWCListItem[] = [];
  constructor(private eRef: ElementRef) {}
  ngOnInit(): void {
    if (this.selectedItems.length > 0) {
      this._selectedItems = this.items.filter(
        (item) => this.selectedItems.indexOf(item.id) > -1
      );
    }
  }
  ngOnChanges(change: SimpleChanges): void {
    if (change['selectedItems'] && change['selectedItems'].currentValue) {
      this._selectedItems = this.items.filter(
        (item) => this.selectedItems.indexOf(item.id) > -1
      );
    }
  }
  @HostListener('document:click', ['$event'])
  close(event: MouseEvent): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.optionsActive = false;
    }
  }
  protected selectItem(item: AWCListItem): void {
    this.selectedItems = [item.id];
    this.selectedItemsChange.emit(this.selectedItems);
    this._selectedItems = [item];
    this.selectedChanged.emit(this._selectedItems);
    this.optionsActive = false;
  }
}
