/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Unit of Angle.
 */
export enum AngleUnit {
    RAD = 'rad',
    DEG = 'deg',
}
