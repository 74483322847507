/* eslint-disable @typescript-eslint/naming-convention */
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ComponentFileType } from 'src/api';

export const batterySelectTypes: AWCListItem[] = [
  {
    text: 'Fixed Voltages',
    id: 'item1',
  },
  {
    text: 'Lookup Data',
    id: 'BatteryLookupTableID',
    userData: {
      type: ComponentFileType.BATTERY_LOOKUP_FILE,
      upload: true,
      file_types: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
      defaultPart: {},
    },
  },
];
