import { Component } from '@angular/core';
import { IconComponent, IconSize, Icons } from '@ansys/awc-angular/icons';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {
  protected spinner: Icons = Icons.SPINNER;
  protected size: IconSize = IconSize.LARGE;
}
