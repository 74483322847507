import { Axle, DataType, MarkerType } from '../enums/blueprint.enum';
import { BlueprintMarker, MARKER_DATA } from './marker';
import { toSN } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { Coords } from '../types/blueprint.type';
import { SolvedTransmission, UnitType } from 'src/api';
import { Inject } from '@angular/core';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import {
  SolvedComponent,
  SolvedComponents,
} from '../../blueprint-display.component';

export class TransmissionMechanicalMarker extends BlueprintMarker {
  constructor(
    @Inject(MARKER_DATA)
    private markerData: [Coords | Coords[], SolvedTransmission, boolean, Axle],
    private units: ConceptUnitService // coords: Coords | Coords[], // results: any, // display: boolean, // axle: Axle, // converter: (unit: UnitType, value: number) => number
  ) {
    const [coords, results, display, axle] = markerData;
    super(DataType.MECHANICAL, MarkerType.TRANSMISSION, [], display, coords);
    this.axle = axle;
    this.updateData([results]);
    this.title = results.name || 'Transmission';
  }
  override updateData(data: SolvedComponents, index: number = 0): void {
    const results = data.find(
      (c: SolvedComponent) =>
        c.solved_component_type === 'transmission' && c.axle === this.axle
    ) as SolvedTransmission;
    this.data = [
      {
        label: 'T',
        value: toSN(
          this.units.convertUnit(UnitType.TORQUE, results.out_torques[index])
        ),
        unit: UnitType.TORQUE,
      },
      {
        label: 'S',
        value: toSN(
          this.units.convertUnit(
            UnitType.ANGULAR_SPEED,
            results.out_speeds[index]
          )
        ),
        unit: UnitType.ANGULAR_SPEED,
      },
      {
        label: 'P',
        value: toSN(
          this.units.convertUnit(UnitType.POWER, results.out_powers[index])
        ),
        unit: UnitType.POWER,
      },
    ];
  }
}
