/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubmittedJob } from './SubmittedJob';
/**
 * Transmission Loss Map ID. Data referenced by ID.
 */
export type TransmissionLossMapID = {
    item_type?: TransmissionLossMapID.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    _id?: string;
    data_id?: (string | null);
    submitted_job?: (SubmittedJob | null);
    shift_time?: number;
    gear_ratios?: Array<number>;
    component_type?: 'TransmissionLossMapID';
};
export namespace TransmissionLossMapID {
    export enum item_type {
        COMPONENT = 'component',
    }
}

