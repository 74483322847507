import { Component, Inject } from '@angular/core';
import { LibraryService } from '@ansys/andromeda/dashboard';
import {
  DIALOG_DATA,
  DIALOG_REF,
  ProductContextService,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { LibraryService as LibraryAPIService } from '../../../../api/services/LibraryService';
import { SelectedAccountState, State } from '@ansys/andromeda/store';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import { ConceptPopulated } from '../../../../api';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-library-export',
  templateUrl: './library-export.component.html',
  styleUrls: ['./library-export.component.scss'],
})
export class LibraryExportComponent {
  protected description: string = '';
  constructor(
    private libraryService: LibraryService,
    private libraryAPIService: LibraryAPIService,
    @Inject(DIALOG_DATA)
    private data: { type: string; item: AWCTreeItem; blob: Blob },
    @Inject(DIALOG_REF) private dialogRef: DialogComponent,
    private snackbar: SnackBarService,
    private productContextService: ProductContextService,
    private state: State
  ) {}
  protected async confirmExport(): Promise<void> {
    this.snackbar.info('Exporting Resource');
    const concept = this.state.value(ActiveConceptState);
    if (concept) {
      const product = await this.productContextService.getProduct();
      if (product) {
        const accountId = this.state.value(SelectedAccountState);
        const asset = await lastValueFrom(
          this.libraryAPIService.addToLibraryLibraryFromIdPost(
            this.data.item.id,
            accountId,
            product.partNumber,
            this.description,
            concept.design_instance_id
          )
        );
        if (asset) {
          this.snackbar.success('Resource Exported');
        } else {
          this.snackbar.error('Resource Export Failed');
        }
      }

      this.dialogRef.close();
    }
  }

  protected cancel(): void {
    this.dialogRef.close();
  }
  protected stopProp($event: Event): void {
    $event.stopPropagation();
  }
}
