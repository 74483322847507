import { ActionContributionsService } from '@ansys/andromeda/contributions';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  Type,
} from '@angular/core';
import { BlobFilesApiService, LibraryObject } from '@ansys/andromeda/api/rest';
import {
  LIBRARY_RESOURCE_DATA,
  LibraryResourceData,
  LibraryService,
  Sections,
} from '@ansys/andromeda/dashboard';
import { LibraryService as LibraryAPIService } from '../../../../api/services/LibraryService';
import { SnackBarService } from '@ansys/andromeda/shared';
import { State } from '@ansys/andromeda/store';
import { ButtonType, ButtonSize } from '@ansys/awc-angular/buttons';
import { IconType, Icons } from '@ansys/awc-angular/icons';
import { ComponentInDB, ConceptPopulated, ConfigurationInDB } from 'src/api';
import { EventBus } from 'src/app/shared/tools/event-bus';
import {
  SectionSelectAction,
  ActiveSectionState,
} from '@ansys/andromeda/workspace';
import { lastValueFrom } from 'rxjs';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import { ConceptService } from '../../../shared/services/concept.service';

@Component({
  selector: 'app-library-panel',
  templateUrl: './library-panel.component.html',
  styleUrls: ['./library-panel.component.scss'],
})
export class LibraryPanelComponent {
  @Input() resourceId!: string;
  @Output() resourceLoaded = new EventEmitter<void>();
  protected libraryObject!: LibraryObject | Promise<Record<string, any>>;
  protected resource!: Type<ConfigurationInDB | ComponentInDB>;
  protected parentType!: string;
  protected type: ButtonType = ButtonType.PRIMARY;
  protected size: ButtonSize = ButtonSize.MEDIUM;
  protected suffixIcon: IconType = { icon: Icons.SUCCESS };
  private bus = EventBus.getInstance();

  constructor(
    @Inject(LIBRARY_RESOURCE_DATA) public data: LibraryResourceData,
    private libraryService: LibraryService,
    private libraryAPIService: LibraryAPIService,
    private blobFile: BlobFilesApiService,
    private snackbar: SnackBarService,
    private actions: ActionContributionsService,
    private state: State,
    private _cdr: ChangeDetectorRef,
    private conceptService: ConceptService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.resourceId) {
      const concept = this.state.value(ActiveConceptState);
      if (concept) {
        this.libraryObject = lastValueFrom(
          this.libraryAPIService.getFromLibraryLibraryObjectIdGet(
            this.resourceId,
            concept.design_instance_id as string
          )
        );

        this.libraryObject.then(async (libObject) => {
          this.resource = libObject as Type<ConfigurationInDB | ComponentInDB>;

          this.parentType = (this.resource as ConfigurationInDB)
            .config_type as string;

          if (
            !this.parentType &&
            (this.resource as ComponentInDB).component_type
          ) {
            this.parentType = this.conceptService.typeMap.get(
              (this.resource as ComponentInDB).component_type as string
            ) as string;
          }

          this._cdr.detectChanges();
          this.resourceLoaded.emit();
        });
      }
    } else {
      this.data?.file?.text().then((content: string) => {
        const parsedData = JSON.parse(content);
        this.resource = parsedData;
        this.parentType = parsedData._parentID;
        this._cdr.detectChanges();
        this.resourceLoaded.emit();
      });
    }
  }

  protected changeResource(
    value: string | number,
    resource: Type<ConfigurationInDB | ComponentInDB>
  ): void {
    const assetJson = JSON.stringify(resource);
    const blob = new Blob([assetJson], { type: 'application/json' });
    this.snackbar.info('Updating resource...');
    this.libraryService
      .updateLibraryAsset(
        this.data?.resource?.objectId ?? '',
        blob as any,
        this.resource.name,
        this.data?.resource?.objectDescription ?? ''
      )
      .then((libObjectResp: LibraryObject) => {
        this.bus.dispatch('input:update:complete', [value, false]);
        this.snackbar.success('Resource updated successfully');
        if (libObjectResp.objectId) {
          const section = this.state.value(ActiveSectionState);
          this.data.resource = libObjectResp;
          section &&
            section.type === Sections.DASHBOARD_LIBRARY &&
            this.actions.execute(SectionSelectAction, {
              sectionId: section.id,
              urlParams: [libObjectResp.objectId ?? ''],
            });
        }
      })
      .catch((err: Error) => {
        console.error(err);
        this.bus.dispatch('input:update:complete', [value, true]);
        this.snackbar.error('Error updating resource');
      });
  }

  protected cancelEdit(): void {
    this.libraryService.setEditable(false);
  }
}
