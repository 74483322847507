/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Temperature Unit.
 */
export enum TemperatureUnit {
    K = 'K',
    _C = '°C',
    _F = '°F',
}
